import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import DesignCard from 'views/components/DesignCard/DesignCard';
import LoadMore from 'views/components/LoadMore/LoadMore';
import PromotePopover from 'views/components/Designs/PromotePopover';
import StagedDesignCardMenu from 'views/components/StagedDesignCardMenu/StagedDesignCardMenu';
import { FAILED, READY } from 'lib/Status';
import { isBrandManagerSelector, tokenSelector } from 'state/app/SessionSelectors';
import { fetchUser } from 'state/api/users/UsersApiActions';
import { userSelector } from 'state/api/users/UsersApiSelectors';
import {
  fetchStagedCollections,
  updateCollection,
  updateCollectionSubscription,
  updateDesignThumbnail,
} from 'state/api/assets/AssetApiActions';
import {
  thumbnailUpdateDesignSelector,
  thumbnailUpdateStatusSelector
} from 'state/api/assets/AssetApiSelectors';

const StagedDesignsContainer = () => {
  const [menuEl, setMenuEl] = useState(null);
  const [promoteEl, setPromoteEl] = useState(null);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const accessToken = useSelector(tokenSelector);
  const author = useSelector(userSelector);
  const dispatch = useDispatch();
  const isBrandManager = useSelector(isBrandManagerSelector);
  const stagedDesigns = useSelector(state => state.entities.collections.stagedDesigns);
  const thumbnailUpdateDesignId = useSelector(thumbnailUpdateDesignSelector);
  const thumbnailUpdateStatus = useSelector(thumbnailUpdateStatusSelector);
  const tagSuggestions = useSelector(state => state.entities.searchSuggestions);

  useEffect(() => {
    if (stagedDesigns === null) {
      dispatch(fetchStagedCollections());
    }
  });

  useEffect(() => {
    if (thumbnailUpdateStatus === 'READY') {
      setMenuEl(null);
    }
  }, [thumbnailUpdateStatus]);

  function handleMenuClick(event, design) {
    dispatch(fetchUser({ accessToken, userId: design.userId }));
    setSelectedDesign(design);
    setMenuEl(event.target);
  }

  if (stagedDesigns === null) {
    return null;
  }

  if (stagedDesigns.meta.status === FAILED) {
    return <>Failed to fetch staged designs from API.</>;
  }

  if (stagedDesigns.meta.status !== READY) {
    return <>Loading...</>;
  }

  if (!stagedDesigns.payload.length) {
    return <>No staged designs found.</>;
  }

  return (
    <>
      <h2>Staged Designs</h2>

      <Grid container spacing={3}>
        {stagedDesigns.payload.map(collection => (
          collection.designs.map(design => (
            <Grid item key={design.id}>
              <DesignCard
                design={design}
                isUpdatingThumbnail={design.id === thumbnailUpdateDesignId}
                onDropDownClick={event => handleMenuClick(event, design)}
              />
            </Grid>
          ))
        ))}
      </Grid>
      {stagedDesigns.meta.isMoreAvailable && (
        <LoadMore onClick={() => dispatch(fetchStagedCollections())} />
      )}
      <StagedDesignCardMenu
        anchorEl={menuEl}
        author={author}
        designId={(selectedDesign || {}).id}
        collectionId={(selectedDesign || {}).collectionId}
        isBrandManager={isBrandManager}
        keepMounted
        open={!!menuEl}
        onClose={() => setMenuEl(null)}
        onThumbnailOverride={file => {
          dispatch(updateDesignThumbnail(selectedDesign.id, selectedDesign.collectionId, file))
        }}
        onPromote={() => {
          setPromoteEl(menuEl);
          setMenuEl(null);
        }}
        onRevert={() => {
          dispatch(updateCollection(selectedDesign.collectionId, 'DRAFT'));
          setMenuEl(null);
        }}
        tagSuggestions={tagSuggestions}
      />
      <PromotePopover
        anchorEl={promoteEl}
        onClose={() => setPromoteEl(null)}
        onSubmit={(event, priority, subscriptionCode, priceUsd, priceAud) => {
          event.preventDefault();
          dispatch(updateCollectionSubscription('POST', selectedDesign.collectionId, priority, subscriptionCode, priceUsd, priceAud));
          setPromoteEl(null);
        }}
      />
    </>
  );
};

export default StagedDesignsContainer;
