import { FAILED, LOADING, READY } from 'lib/Status';
import { createAction as createActionImport } from 'redux-api-middleware';

const IGNORE_META_KEYS = [
  'count',
  'isMoreAvailable',
  'offset',
  'status',
];

export const createAction = ({
  endpoint: endpointParam,
  paginate,
  params: paramsParam,
  types: typesParam,
  ...other
}) => {
  const params = { ...paramsParam };

  if (paginate) {
    let count = params.count || 20;
    let offset = 0;

    if (paginate.meta && paginate.meta.offset !== undefined) {
      const keysFilter = (key) => !IGNORE_META_KEYS.includes(key);
      const keysUnique = new Set([
        ...Object.keys(params).filter(keysFilter),
        ...Object.keys(paginate.meta).filter(keysFilter),
      ]);

      const isCountDiff = count !== paginate.meta.count;
      const isMetaDiff = [...keysUnique].some((key) => {
        return params[key] !== paginate.meta[key] && !paginate.meta.isUserDrafts
      });

      if (!isCountDiff && !isMetaDiff) {
        offset = paginate.meta.offset + count;
      }
    }

    params.count = count;
    params.offset = offset;
  }

  const filteredParams = Object.entries(params || {})
    .filter(([key, val]) => val !== undefined && val !== null)
    .reduce((map, [key, val]) => ({ ...map, [key]: val }), {});

  const endpoint = endpointParam + (
    !Object.keys(filteredParams).length ? '' : (
      (/\?/.test(endpointParam) ? '&' : '?')
      + new URLSearchParams(filteredParams).toString()
    )
  );

  const types = typesParam.map((type, index) => {
    const status = [LOADING, READY, FAILED][index];
    const meta = { ...params, status };

    return (
      typeof type === 'object'
        ? { ...type, meta: { ...meta, ...(type.meta || {}) } }
        : { meta, type }
    );
  });

  return createActionImport({
    ...other,
    endpoint,
    types,
  });
};

export const reduceAction = (state, action) => {
  switch ((action.meta || {}).status) {
    case FAILED: {
      return action;
    }

    case LOADING: {
      return (action.meta || {}).offset > 0
        ? state
        : action;
    }

    case READY: {
      const { count, offset } = action.meta ?? {};
      const payload = action.payload ?? [];

      return {
        ...action,
        meta: {
          ...action.meta,
          isMoreAvailable: payload.length >= count,
        },
        payload: (offset ? [...state.payload, ...payload] : payload),
      };
    }

    default: {
      return state;
    }
  }
};
