import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import mediaCategories from 'lib/mediaCategories';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

export const MediaUploadBatch = ({ onSubmit, urlCategory }) => {
  const [category, setCategory] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [zipFile, setZipFile] = useState();

  useEffect(() => {
    if (urlCategory && urlCategory !== 'all') {
      setCategory(urlCategory);
    } else {
      setCategory('');
    }
  }, [urlCategory]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        return onSubmit(event, {
          categories: [category],
          referenceId,
          zipFile,
        });
      }}
    >
      <Grid
        container
        justify="center"
        spacing={3}
      >
        <Grid item>
          <TextField
            helperText="Ensure files aren't zipped within a folder."
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: '.zip' }}
            label="ZIP File"
            onChange={(e) => setZipFile(e.target.files[0])}
            required
            type="file"
            variant="outlined"
          />
        </Grid>
        <Grid item>
          <Box width="340px">
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Reference ID"
              onChange={(e) => setReferenceId(e.target.value)}
              required
              value={referenceId}
              variant="outlined"
            />

            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Category"
              onChange={(e) => setCategory(e.target.value)}
              select
              value={category}
              variant="outlined"
            >
              {mediaCategories
                .filter(({ isUnselectable }) => !isUnselectable)
                .map(({ label, name }) => (
                  <MenuItem key={name} value={name}>{label}</MenuItem>
                )
              )}
            </TextField>

            <Box mt={2}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
              >
                Upload
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default MediaUploadBatch;
