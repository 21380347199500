import { addMediaCategories, removeMediaCategory } from "./AssetMediaApiActions";

export default function handleUpdateMediaCategories({
  accessToken,
  categories,
  categoriesOld = [],
  dispatch,
  mediaId,
}) {
  const toAdd = {};
  const toRemove = {};

  categoriesOld.forEach(({ id, name }) => {
    toRemove[name] = id;
  });

  categories.forEach(({ name }) => {
    if (toRemove[name]) {
      delete toRemove[name];
    } else {
      toAdd[name] = name;
    }
  });

  const promises = Object.values(toRemove).map((id) => dispatch(
    removeMediaCategory({
      accessToken,
      categoryId: id,
      mediaId,
    })
  ));

  if (Object.values(toAdd).length) {
    promises.push(dispatch(
      addMediaCategories({
        accessToken,
        categoryNames: Object.values(toAdd),
        mediaId,
      })
    ));
  };

  return Promise.all(promises);
}
