import store from 'state/store';
import * as types from './DesignTagTypes';
import { createAction } from 'lib/apiMiddlewareUtils';
import { tokenSelector } from 'state/app/SessionSelectors';

const BASE_API = process.env.REACT_APP_HOST_ASSET_SERVICE;

export const addTagToCollection = (collectionId, categoryName, designId) => {
  const accessToken = tokenSelector(store.getState());

  return createAction({
    body: JSON.stringify({ categoryNames: [categoryName], collectionId }),
    endpoint: `${BASE_API}/v1/collections/${collectionId}/categories`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    types: [
      {
        type: types.ADD_FOR_COLLECTION_REQUEST,
        meta: { categoryName, designId }
      },
      {
        type: types.ADD_FOR_COLLECTION_SUCCESS,
        meta: { categoryName, designId }
      },
      {
        type: types.ADD_FOR_COLLECTION_FAILURE,
        meta: { categoryName , collectionId, designId }
      }
    ]
  });
};

export const fetchDesignTags = (designId) => {
  const accessToken = tokenSelector(store.getState());

  return createAction({
    endpoint: `${BASE_API}/v1/designs/${designId}/categories`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
    method: 'GET',
    params: { designId },
    types: [
      types.FETCH_REQUEST,
      types.FETCH_SUCCESS,
      {
        type: types.FETCH_FAILURE,
        payload: {
          designId,
        }
      },
    ],
  });
};

export const removeTagFromCatalogueCollection = (collectionId, categoryId) => {
  const collectionDesigns = store.getState().entities.collections.catalogue.payload.filter(design =>
    design.collectionId === collectionId
  );
  const designIds = collectionDesigns.map(design => design.id);
  return removeTagFromCollection(collectionId, designIds, categoryId);
};

export const removeTagFromMyDraftsCollection = (collectionId, categoryId) => {
  const designIdsForCollection = getDesignIdsForCollection(collectionId, 'myDrafts');
  return removeTagFromCollection(collectionId, designIdsForCollection, categoryId);
};

export const removeTagFromMyStagedCollection = (collectionId, categoryId) => {
  const designIdsForCollection = getDesignIdsForCollection(collectionId, 'myStaged');
  return removeTagFromCollection(collectionId, designIdsForCollection, categoryId);
};

export const removeTagFromStagedCollection = (collectionId, categoryId) => {
  const designIdsForCollection = getDesignIdsForCollection(collectionId, 'staged');
  return removeTagFromCollection(collectionId, designIdsForCollection, categoryId);
};

const getDesignIdsForCollection = (collectionId, designType) => {
  let collectionDesigns;
  switch(designType) {
    case 'myDrafts':
      collectionDesigns = store.getState().entities.collections.myDrafts.payload.find(collection =>
        collection.id === collectionId
      );
      break;
    case 'myStaged':
      collectionDesigns = store.getState().entities.collections.myStaged.payload.find(collection =>
        collection.id === collectionId
      );
      break;
    case 'staged':
      collectionDesigns = store.getState().entities.collections.stagedDesigns.payload.find(collection =>
        collection.id === collectionId
      );
      break;
    default:
      throw new Error(`No collection found for design type ${designType}`);
  }
  return collectionDesigns.designs.map(design => design.id);
}

const removeTagFromCollection = (collectionId, designsForCollection, categoryId) => {
  const accessToken = tokenSelector(store.getState());
  return createAction({
    endpoint: `${BASE_API}/v1/collections/${collectionId}/categories/${categoryId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
    method: 'DELETE',
    params: { collectionId, categoryId },
    types: [
      {
        type: types.REMOVE_FOR_COLLECTION_REQUEST,
        meta: { designsForCollection }
      },
      {
        type: types.REMOVE_FOR_COLLECTION_SUCCESS,
        meta: { designsForCollection }
      },
      types.REMOVE_FOR_COLLECTION_FAILURE,
    ],
  });
}
