import * as types from 'state/api/authentication/AuthtenticationApiTypes';

const initState = {
  isProcessing: false,
}

const authenticationApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_REQUEST: {
      return {
        ...state,
        isProcessing: true,
      }
    }
    case types.AUTHENTICATION_SUCCESS: {
      return {
        ...state,
        isProcessing: false,
      }
    }
    case types.AUTHENTICATION_FAILURE: {
      return {
        ...state,
        isProcessing: false,
      }
    }
    default:
      return state;
  }
};

export default authenticationApiReducers;