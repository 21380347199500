import React, { useEffect, useState } from 'react';
import DesignCard from 'views/components/DesignCard/DesignCard';
import LoadMore from 'views/components/LoadMore/LoadMore';
import PromotePopover from 'views/components/Designs/PromotePopover';
import { FAILED, READY } from 'lib/Status';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchStagedCollectionsForCurrentUser,
  updateCollection,
  updateCollectionSubscription,
  updateDesignThumbnail,
} from 'state/api/assets/AssetApiActions';
import { Grid } from '@material-ui/core';
import MyStagedDesignCardMenu from 'views/components/MyStagedDesignCardMenu/MyStagedDesignCardMenu';

const MyStagedContainer = () => {
  const [collectionId, setCollectionId] = useState(null);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [menuEl, setMenuEl] = useState(null);
  const [promoteEl, setPromoteEl] = useState(null);
  const dispatch = useDispatch();
  const myStaged = useSelector(state => state.entities.collections.myStaged);
  const tagSuggestions = useSelector(state => state.entities.searchSuggestions);

  useEffect(() => {
    if (myStaged === null) {
      dispatch(fetchStagedCollectionsForCurrentUser());
    }
  });

  if (myStaged === null) {
    return null;
  }

  if (myStaged.meta.status === FAILED) {
    return <>Failed to fetch staged designs from API.</>;
  }

  if (myStaged.meta.status !== READY) {
    return <>Loading...</>;
  }

  if (!myStaged.payload.length) {
    return <>No staged designs found.</>;
  }

  return (
    <>
      <h2>My Staged Designs</h2>

      <Grid container spacing={3}>
        {myStaged.payload.map(collection => (
          collection.designs.map(design => (
            <Grid item key={design.id}>
              <DesignCard
                design={design}
                onDropDownClick={e => {
                  setMenuEl(e.target);
                  setCollectionId(collection.id);
                  setSelectedDesign(design);
                }}
              />
            </Grid>
          ))
        ))}
      </Grid>
      {myStaged.meta.isMoreAvailable && (
        <LoadMore onClick={() => dispatch(fetchStagedCollectionsForCurrentUser())} />
      )}
      <MyStagedDesignCardMenu
        anchorEl={menuEl}
        designId={(selectedDesign || {}).id}
        collectionId={(selectedDesign || {}).collectionId}
        keepMounted
        open={!!menuEl}
        onClose={() => setMenuEl(null)}
        onThumbnailOverride={file => {
          dispatch(updateDesignThumbnail(selectedDesign.id, selectedDesign.collectionId, file));
          setMenuEl(null);
        }}
        onRevert={() => {
          dispatch(updateCollection(selectedDesign.collectionId, 'DRAFT'));
          setMenuEl(null);
        }}
        tagSuggestions={tagSuggestions}
      />
      <PromotePopover
        anchorEl={promoteEl}
        onClose={() => setPromoteEl(null)}
        onSubmit={(event, priority, subscriptionCode) => {
          event.preventDefault();
          dispatch(updateCollectionSubscription('POST', collectionId, priority, subscriptionCode));
          setPromoteEl(null);
        }}
      />
    </>
  );
};

export default MyStagedContainer;
