import { createAction } from 'lib/apiMiddlewareUtils';
import * as types from 'state/api/users/UsersApiTypes';

const V1 = `${process.env.REACT_APP_HOST_USER_SERVICE}/v1`;
const V2 = `${process.env.REACT_APP_HOST_USER_SERVICE}/v2`;

export const fetchUser = ({
  accessToken,
  userId,
}) => createAction({
  endpoint: `${V1}/users/${userId}`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    accept: 'application/json',
  },
  method: 'GET',
  params: { userId },
  types: [
    types.FETCH_USER_REQUEST,
    types.FETCH_USER_SUCCESS,
    types.FETCH_USER_FAILURE,
  ]
});

export const fetchUserTeams = ({
  accessToken,
  userId,
}) => createAction({
  endpoint: `${V2}/users/${userId}/teams`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    accept: 'application/json',
  },
  method: 'GET',
  params: { userId },
  types: [
    types.USER_TEAMS_REQUEST,
    types.USER_TEAMS_SUCCESS,
    types.USER_TEAMS_FAILURE,
  ]
});

export const fetchUsers = ({
  accessToken,
  paginate,
  term,
}) => createAction({
  endpoint: `${V1}/users`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    accept: 'application/json',
  },
  method: 'GET',
  paginate,
  params: {
    term,
  },
  types: [
    types.FETCH_USERS_REQUEST,
    types.FETCH_USERS_SUCCESS,
    types.FETCH_USERS_FAILURE,
  ]
});

export const fetchUserPreferences = ({
  accessToken,
  userId,
}) => createAction({
  endpoint: `${V1}/users/${userId}/preferences`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    accept: 'application/json',
  },
  method: 'GET',
  types: [
    types.FETCH_USER_PREFERENCES_REQUEST,
    types.FETCH_USER_PREFERENCES_SUCCESS,
    types.FETCH_USER_PREFERENCES_FAILURE,
  ]
});

export const updateUserPreferences = ({
  accessToken,
  teamId,
  userId,
  createdAt
}) => createAction({
  endpoint: `${V1}/users/${userId}/preferences`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    userId: userId,
    defaultTeamId: teamId,
    updatedAt: new Date(),
    createdAt: createdAt,
  }),  
  method: 'PUT',
  types: [
    types.UPDATE_USER_PREFERENCES_REQUEST,
    types.UPDATE_USER_PREFERENCES_SUCCESS,
    types.UPDATE_USER_PREFERENCES_FAILURE,
  ]
});

export const deleteUser = ({
  accessToken,
  userId
}) => createAction({
  endpoint: `${V1}/users/${userId}/active`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'PUT',
  body: JSON.stringify({
    userId,
    active: false
  }),
  types: [
    types.DELETE_USER_REQUEST,
    types.DELETE_USER_SUCCESS,
    types.DELETE_USER_FAILURE,
  ]
});

export const deleteUserGDPR = ({
  accessToken,
  userId
}) => createAction({
  endpoint: `${V1}/users/${userId}`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    accept: 'application/json',
  },
  method: 'DELETE',
  types: [
    types.GDPR_DELETE_USER_REQUEST,
    types.GDPR_DELETE_USER_SUCCESS,
    types.GDPR_DELETE_USER_FAILURE,
  ]
});