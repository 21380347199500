import { removeItem } from 'lib/storageHandler';

const { REACT_APP_HOST_AUTH_SERVICE } = process.env;

export const logout = ({ href } = {}) => {
  const { location: { origin } } = window;

  removeItem('sessionEnd');
  removeItem('token');

  window.location.href = href || (
    REACT_APP_HOST_AUTH_SERVICE
    + '/session/end'
    + `?post_logout_redirect_uri=${encodeURIComponent(origin)}`
  );
};
