import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { toDateInputStr } from 'lib/dateUtil';

const ordersCouponsApplyToConstants = {
  MEDIA: "MEDIA",
  ORDERS: "ORDERS",
  PRINT: 'PRINT',
  SHIPPING: 'SHIPPING',
  COLLECTION: 'COLLECTION'
}

const valueTypeConstants = {
  DOLLAR: "$",
  PERCENT: "%",
}

export default function OrdersCouponsDialog({
  createOrdersCoupons,
  ordersCoupons = {},
  onClose,
  setSuccessActionString,
  updateOrdersCoupons,
}) {

  const [applyTo, setApplyTo] = useState(ordersCoupons.applyTo || "");
  const [code, setCode] = useState(ordersCoupons.code || "");
  const [createdAt] = useState(ordersCoupons.createdAt || "");
  const [endAt, setEndAt] = useState(ordersCoupons.endAt);
  const [updatedAt] = useState(ordersCoupons.updatedAt || "")
  const [startAt, setStartAt] = useState(ordersCoupons.startAt) ;
  const [value, setValue] = useState(ordersCoupons.value || "");
  const [valueType, setValueType] = useState(ordersCoupons.valueType || valueTypeConstants.PERCENT);
  const [error, setError] = useState("")

  const handleCodeInputError = () => {
    if (code.length < 3 && code.length > 0) {
      setError({...error, code: "Please provide a CODE with at least 3 characters"})
    } else if (code.indexOf(" ") >= 0) {
      setError({...error, code: "Please provide a CODE without space between the characters"})
    } 
  }

  const handleValueInputError = () => {
    if (isNaN(value)) {
      setError({value: "Please provide a number to VALUE"})
    } else if (valueType === '%' && value > 100) {
      setError({...error, value: "Please provide a VALUE less than 100"})
    } else if (value < 0) {
      setError({...error, value: "Please provide a VALUE greater than 0"})
    }
  }

  const handleDateInputError = () => {
    if (startAt > endAt) {
      setError({...error, endAt: "Please provide an END DATE greater than the START DATE"})
    }
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault()
    let response

    if (ordersCoupons.code) { // PUT coupon
      response = await updateOrdersCoupons({
        applyTo,
        code,
        createdAt,
        endAt,
        startAt,
        updatedAt, 
        value,
        valueType,
      });

      if (!response.error) {
        setSuccessActionString("updated")
        onClose(event);
      }
    } 
    else { // POST coupon
      response = await createOrdersCoupons({
        applyTo,
        code,
        endAt,
        startAt,
        value: parseInt(value, 10),
        valueType,
      });
      if (!response.error) {
        setSuccessActionString("created")
        onClose(event)
      }
      else {
        let responseErrors = response.payload.response.error.errors
        let collectionOfErrors = responseErrors.reduce((errors, error) => {
          errors[error.path] = error.message 
          return errors;
        }, {});
        setError(collectionOfErrors)
      } 
    }
  };

  return (
    <Dialog aria-labelledby="form-dialog-title" onClose={onClose} open={true} maxWidth="xs">
      <DialogTitle id="form-dialog-title">
        {ordersCoupons.code ? (
          <>Edit Coupon</>
        ) : (
          <>Add Coupon</>
        )}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {ordersCoupons.code ? (
            <>
              Please complete this form then click "Save" below
              to update this coupon.
            </>
          ) : (
            <>
              Please complete this form then click "Create" below
              to create this new coupon.
            </>
          )}
        </DialogContentText>

        <TextField
          fullWidth 
          InputLabelProps={{ shrink: true }}
          label="Code"
          margin="dense"
          onChange={e => {
            setCode(e.target.value); 
            setError({ ...error, code: ""})
          }}
          onBlur={handleCodeInputError}
          value={code}
          variant="outlined"
          disabled={!!ordersCoupons.code}
          error={!!error.code}
          helperText={error.code}
        />

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Apply to"
          margin="dense"
          onChange={e => {
            setApplyTo(e.target.value); 
            setError({ ...error, applyTo: "" })
          }}
          select
          value={applyTo}
          variant="outlined"
          disabled={!!ordersCoupons.applyTo}
          error={!!error.applyTo}
          helperText={error.applyTo}    

        >
          {(ordersCoupons.applyTo === "MEDIA") &&
            <MenuItem value={ordersCouponsApplyToConstants.MEDIA}>MEDIA</MenuItem>
          }
          {(ordersCoupons.applyTo === "ORDERS") &&
            <MenuItem value={ordersCouponsApplyToConstants.ORDERS}>ORDERS</MenuItem>
          }
          <MenuItem value={ordersCouponsApplyToConstants.PRINT}>PRINT</MenuItem>
          <MenuItem value={ordersCouponsApplyToConstants.SHIPPING}>SHIPPING</MenuItem>
          <MenuItem value={ordersCouponsApplyToConstants.COLLECTION}>COLLECTION</MenuItem>

        </TextField>

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Value"
          margin="dense"
          onChange={e => {
            setValue(e.target.value); 
            setError({ ...error, value: "" })
          }}
          onBlur={handleValueInputError}
          value={value}
          variant="outlined"
          disabled={!!ordersCoupons.code}
          error={!!error.value}
          helperText={error.value}
        />

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Type"
          margin="dense"
          onChange={e => {
            setValueType(e.target.value); 
            setError({ ...error, valueType: "" })
          }}
          select
          value={valueType || valueTypeConstants.PERCENT}
          variant="outlined"
          disabled={!!ordersCoupons.code}
        >
          <MenuItem value={valueTypeConstants.PERCENT}>%</MenuItem>
          <MenuItem value={valueTypeConstants.DOLLAR}>$</MenuItem>
        </TextField>

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Start Date"
          margin="dense"
          onChange={e => {
            setStartAt(e.target.value); 
            setError({ ...error, startAt: "" })
          }}
          onBlur={handleDateInputError}
          type="date"
          value={toDateInputStr(startAt)}
          variant="outlined"
          error={!!error.startAt}
          helperText={error.startAt}
        />

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="End Date"
          margin="dense"
          onChange={e => {
            setEndAt(e.target.value); 
            setError({ ...error, endAt: "" });
          }}
          onBlur={handleDateInputError}
          type="date"
          value={toDateInputStr(endAt)}
          variant="outlined"
          error={!!error.endAt}
          helperText={error.endAt}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={handleSubmit}
        >
          {ordersCoupons.code ? (
            <>Save</>
          ) : (
            <>Create</>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}