import * as types from './DesignTagTypes';

const initState = {};

export default function DesignTagReducers(state = initState, action) {
  switch (action.type) {
    case types.ADD_SUCCESS: {
      const { meta: { designId } } = action;

      if (!state[designId]) {
        return state;
      }

      const { [designId]: { payload } } = state;

      return {
        ...state,
        [designId]: {
          ...state[designId],
          payload: [...(payload || []), action.payload],
        },
      };
    }

    case types.ADD_FOR_COLLECTION_REQUEST: {
      const { meta:{ categoryName, designId } } = action;

      return {
        ...state,
        [designId]: {
          ...(state[designId] || {}),
          payload: [
            ...(state[designId].payload || []),
            {
              id: categoryName,
              name: categoryName,
              createdAt: Date.now(),
              updatedAt: Date.now()
            }
          ]
        }
      };
    }

    case types.ADD_FOR_COLLECTION_SUCCESS: {
      // collect the payload (array of categories)
      const { payload: categories, meta:{ categoryName } } = action;

      const updatedDesignListings = categories.reduce((newDesignListings, currentCategory) => {
        currentCategory.name = categoryName;
        const currentStateForCategory = state[currentCategory.designId];
        if (!currentStateForCategory) {
          // no current categories for design, make a new key for it
          return {
            ...newDesignListings,
            [currentCategory.designId]: {
              payload: [{ ...currentCategory, id: currentCategory.categoryId }]
            }
          };
        } else {
          return {
            ...newDesignListings,
            [currentCategory.designId]: {
              ...currentStateForCategory,
              // V ensure here we filter out temp tag from optimistic add V
              payload: [...(currentStateForCategory.payload || []).filter(tag => tag.id !== tag.name), { ...currentCategory, id: currentCategory.categoryId }]
            }
          };
        }
      }, {});

      return {
        ...state,
        ...updatedDesignListings
      };
    }
    
    case types.REMOVE_FOR_COLLECTION_REQUEST:
    case types.REMOVE_FOR_COLLECTION_SUCCESS: {
      return action.meta.designsForCollection.reduce(
        (newState, currentDesignId) => {
          if(!state[currentDesignId]) {
            // when no tags already recorded for this design just ignore it
            return newState;
          }
          // tags already here for design, filter out the removed one
          return ({
            ...newState,
            [currentDesignId]: {
              ...state[currentDesignId],
              payload: state[currentDesignId].payload.filter(tag => tag.id !== action.meta.categoryId)
            }
          });
        },
        {}
      );
    }

    case types.FETCH_FAILURE:
    case types.FETCH_REQUEST:
    case types.FETCH_SUCCESS: {
      return { ...state, [action.meta.designId]: action };
    }

    case types.REMOVE_SUCCESS: {
      const { meta: { categoryId, designId } } = action;

      if (!state[designId]) {
        return state;
      }

      return {
        ...state,
        [designId]: {
          ...state[designId],
          payload: (state[designId].payload || []).filter(({ id }) => id !== categoryId),
        }
      };
    }

    default: {
      return state;
    }
  }
}
