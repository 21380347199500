import React, { useRef } from 'react';
import DesignTags from '../DesignTags/DesignTags';
import { removeTagFromStagedCollection } from 'state/api/designTag/DesignTagActions';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Design card menu for designs that are in STAGED status.
 * @param props
 * @returns {JSX.Element}
 */
const StagedDesignsCardMenu = ({
  anchorEl,
  author,
  designId,
  collectionId,
  isBrandManager,
  keepMounted,
  open,
  onClose,
  onPromote,
  onRevert,
  onThumbnailOverride,
  tagSuggestions
}) => {
  const classes = useStyles();
  const fileUploadRef = useRef(null);

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.menuPaper }}
        keepMounted={keepMounted}
        open={open}
        onClose={onClose}
      >
        <li className={classes.author}>
          <small>Author</small>
          <br />
          {author && author.payload ? (
            <span>{author.payload.name}</span>
          ) : (
            <Skeleton variant="text" />
          )}
        </li>
        {isBrandManager && (
          <MenuItem onClick={onPromote}>
            Promote Collection
          </MenuItem>
        )}
        <MenuItem onClick={onRevert}>
          Revert Collection
        </MenuItem>
        <MenuItem onClick={() => fileUploadRef.current.click()}>
          Override Thumbnail
        </MenuItem>
        <DesignTags
          collectionId={collectionId}
          designId={designId}
          tagSuggestions={tagSuggestions}
          removeTagFunction={removeTagFromStagedCollection}
        />
      </Menu>
      <input
        accept='.png,.jpg,.jpeg,.mp4'
        id='raised-button-file'
        onChange={event => onThumbnailOverride(event.target.files[0])}
        ref={fileUploadRef}
        style={{ display: 'none' }}
        type='file'
      />
    </>
  );
}

const useStyles = makeStyles(theme => ({
  author: {
    borderBottom: '1px solid #bbb',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0, 2, 1)
  },
  menuPaper: {
    width: '220px',
  },
}));

export default StagedDesignsCardMenu;
