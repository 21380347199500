import * as React from 'react';
import {
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuList,
  makeStyles,
  ListItem
} from '@material-ui/core';
import Tooltip from "views/components/Tooltip/Tooltip";
import ConfirmationDialog from "views/components/ConfirmationDialog/ConfirmationDialog";
import ViewListIcon from '@material-ui/icons/ViewList';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "14px",
    minHeight: "16px",
    color: "white"
  },
  popout: {
    width: "200px"
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between"
  },
  code: {
    color: "black"
  }
}));

const TemplateSizesPopout = ({
  deleteTemplateCode,
  fetchTemplateSizesByOrderLabel,
  labelName,
  setSuccessActionString,
  templateSizes = []
} = {}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [deleteDialog, setDeleteDialog] = React.useState(null);
  const anchorRef = React.useRef();
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const handleDeleteTemplateSize = (event, templateCode) => {
    event.preventDefault();
    let response = deleteTemplateCode({ templateCode, orderLabel: labelName });

    if (!response.error) {
      setSuccessActionString("Template code successfully removed.");
      setDeleteDialog(null);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    if (prevOpen.current === false && open === true) {
      fetchTemplateSizesByOrderLabel({ labelName })
    }

    prevOpen.current = open;
  }, [open, labelName, fetchTemplateSizesByOrderLabel]);

  return (
      <div>
        <IconButton
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          classes={{ root: classes.button }}
          data-tip={"Show Template Sizes"}
          data-for="show-templates-tip"
        >
          <ViewListIcon />
        </IconButton>
        <Tooltip id="show-templates-tip" place="bottom" />
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-end"
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    classes={{ root: classes.popout }}
                  >
                    {templateSizes.length <= 0 && <ListItem></ListItem>}
                    {templateSizes.length > 0 && (
                      templateSizes.map(templateSize => (
                        <>
                          <ListItem
                            classes={{ root: classes.listItem }}
                          >
                            {templateSize.name}
                            <IconButton onClick={() => 
                              setDeleteDialog({
                                isOpen: true,
                                templateCode: templateSize.templateCode
                              })}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItem>
                        </>
                      ))
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        {deleteDialog && deleteDialog.isOpen && (
          <ConfirmationDialog
            onSubmit={e => handleDeleteTemplateSize(e, deleteDialog.templateCode)}
            onClose={() => setDeleteDialog(null)}
            title="Remove template code?"
            message={(
              <>
                Are you sure you want to permanently remove the template code{" "}
                <strong className={classes.code}>{deleteDialog.templateCode}</strong>?
              </>
            )}
          />
        )}
      </div>
  );
}
export default TemplateSizesPopout;