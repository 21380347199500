import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  foldersContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "44px auto 24px",
  },
  folderButton: {
    minWidth: '200px'
  }
}));

export default function TeamFolders({folders, handleMoveIntoFolder, className}) {
  const classes = useStyles();

  return(
    <div className={className ? className : classes.foldersContainer}>
      <Grid container spacing={2}>
        {folders.map((folder) => (
          <Grid item key={folder.id}>
            <Button
              className={classes.folderButton}
              variant="contained"
              color="default"
              startIcon={<FolderOpenIcon />}
              onClick={() => handleMoveIntoFolder(folder.id)}
            >
              {folder.name}
            </Button>
          </Grid>

      ))}
      </Grid>
    </div>
  )
}