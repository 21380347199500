import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
  errorMessage: {
    paddingTop: theme.spacing(2),
    color: "red"
  }
}));
export default function EditPriceDialog({
  printPrice,
  onClose,
  setSuccessActionString,
  onUpdatePrintOptionPrice
}) {
  const classes = useStyles();
  const [price, setPrice] = useState(printPrice.pricing.price);
  const [minQuantity, setMinQuantity] = useState(printPrice.pricing.minQuantity);
  const [errors, setErrors] = useState({});
  const isUpdateDisabled = Object.values(errors).some(error => !!error);
  const handleOnBlur = event => {
    const inputName = event.target.name;
    const value = event.target.value;
    // set error if no value has been provided
    if (!value) {
      setErrors({ 
        ...errors,
        [inputName]: `Please provide a ${inputName}`
      });
      return;
    }
    // set error if negative integer is provided
    if (event.target.type === "number" && (Number(value) < 0)) {
      setErrors({ 
        ...errors,
        [inputName]: `Please provide a positive value for ${inputName}`
      });
      return;
    }
    // clear error
    setErrors({ 
      ...errors,
      [inputName]: ""
    });
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    onUpdatePrintOptionPrice({
      ...printPrice.pricing,
      minQuantity,
      price,
    });
    
    onClose();
    setSuccessActionString("Print price successfully updated");
  };

  return (
    <Dialog
      aria-labelledby="dialog-title"
      onClose={onClose}
      open={true}
      maxWidth="xs"
      id="price-update-dialog"
    >
      <DialogTitle id="price-update-dialog-title">
        Edit Print Price
      </DialogTitle>
      <DialogContent id="price-update-dialog-content">
        <TextField
          fullWidth
          id="min-quantity-input"
          label="Min Quantity"
          margin="dense"
          onChange={e => {
            setMinQuantity(e.target.value); 
            setErrors({ ...errors, minQuantity: "" })
          }}
          required
          onBlur={e => handleOnBlur(e)}
          name="minQuantity"
          value={minQuantity}
          variant="outlined"
          type="number"
          error={!!errors.minQuantity}
          helperText={errors.minQuantity}
          InputProps={{
            inputProps: {
              min: 0,
              autoFocus: true,
              onClick: event => {
                event.preventDefault();
                event.stopPropagation();
                const target = event.target;
                setTimeout(() => target.focus(), 0);
              }
            }
          }}

        />
        <TextField
          fullWidth
          id="price-input"
          label="Unit Price"
          margin="dense"
          onChange={e => {
            setPrice(e.target.value); 
            setErrors({ ...errors, price: "" })
          }}
          required
          onBlur={e => handleOnBlur(e)}
          name="price"
          value={price}
          variant="outlined"
          type="number"
          error={!!errors.price}
          helperText={errors.price}
          InputProps={{
            inputProps: {
              min: 0,
              onClick: event => {
                event.preventDefault();
                event.stopPropagation();
                const target = event.target;
                setTimeout(() => target.focus(), 0);
              }
            }
          }}
        />
        {errors.response && (
          <div className={classes.errorMessage}>{errors.response}</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={isUpdateDisabled}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}