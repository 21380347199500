import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mediaItemSelector, mediaItemCategoriesSelector } from 'state/api/assets-media/AssetMediaApiSelectors';
import { tokenSelector } from 'state/app/SessionSelectors';
import handleUpdateMedia from 'state/api/assets-media/handleUpdateMedia';
import MediaItem from 'views/components/Media/MediaItem';
import {
  deleteMedia,
  fetchMedia,
  fetchMediaCategories,
} from 'state/api/assets-media/AssetMediaApiActions';

export default function MediaItemContainer({
  history,
  match: { params: { mediaId } },
}) {
  const accessToken = useSelector(tokenSelector)
  const dispatch = useDispatch();
  const mediaItem = useSelector(mediaItemSelector);
  const mediaItemCategories = useSelector(mediaItemCategoriesSelector);

  useEffect(() => {
    if (!mediaItem
      || mediaItem.meta.mediaId !== mediaId
    ) {
      dispatch(fetchMedia({ accessToken, mediaId }));
    }
  });

  useEffect(() => {
    if (!mediaItemCategories
      || mediaItemCategories.meta.mediaId !== mediaId
    ) {
      dispatch(fetchMediaCategories({ accessToken, mediaId }));
    }
  });

  return (
    <MediaItem
      mediaItem={mediaItem}
      mediaItemCategories={mediaItemCategories}
      onDelete={async (e, params) => {
        history.push('/media');
        await dispatch(deleteMedia({
          accessToken,
          ...params,
        }));
      }}
      onUpdate={async (e, params) => {
        const updateMediaResponse = await handleUpdateMedia(e, {
          accessToken,
          dispatch,
          history,
          ...params,
        });
        dispatch(fetchMedia({ accessToken, mediaId }));
        return updateMediaResponse;
      }
    }
    />
  );
}
