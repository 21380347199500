import gifFrames from 'gif-frames';

export const ACCEPTABLE_MEDIA_TYPES = '.jpeg, .jpg, .png, .svg, .gif, .mp4';

// the media source types supported by html5 video tag
export const VIDEO_MEDIA_TYPES = ["mp4"];

/**
 * Retrieve the name of the file from the path to the file.
 */
export const getFilename = (filePath) => {
  if (!filePath) return null;
  const startIndex = (filePath.indexOf('\\') >= 0 ? filePath.lastIndexOf('\\') : filePath.lastIndexOf('/'));
  let filename = filePath.substring(startIndex);
  if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
    return filename.substring(1);
  }
  return null;
};

const getFileExtension = file => {
  const name = file.name;
  const lastDot = name.lastIndexOf('.');
  return name.substring(lastDot + 1);
}

export const isGifFile = file => getFileExtension(file).toLowerCase() === 'gif';

/**
 * Process the gif to determine the gif duration. If the file is not a gif then null is returned.
 * @param file - Required: File to process.
 * @returns {Promise<null|number>}
 */
export const getGifDuration = async file => {
  if (!isGifFile(file)) {
    return null;
  }

  const imageUrl = window.URL.createObjectURL(file);
  const frameData = await gifFrames({ url: imageUrl, frames: "all" });
  let duration = 0;
  frameData.forEach(frame => {
    duration += frame.frameInfo.delay * 10;
  });

  return duration;
};

export const getVideoDuration = (file) =>
  new Promise((resolve) => {
    let video = document.createElement("video");
    video.preload = "metadata";
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src);
      resolve(video.duration * 1000);
    };
    video.src = window.URL.createObjectURL(file);
  });

export const isMp4 = file => getFileExtension(file).toLowerCase() === 'mp4';

export const isMediaVideoFormat = mediaSource => {
  if (!mediaSource || !(typeof mediaSource === "string")) {
    return false;
  }
  const sourceSuffix = mediaSource.split(".").pop();
  if (!sourceSuffix) {
    return false;
  }

  return VIDEO_MEDIA_TYPES.includes(sourceSuffix.toLowerCase());
};
