import { authenticate as authenticateApi } from 'state/api/authentication/AuthenticationApiActions';

/**
 * Attempt to authenticate the user using username and password authentication
 * @param username Required: The users username (most likely email in our case)
 * @param password Required: The users password.
 */
export const authenticate = (username, password) => async dispatch => {
  dispatch(authenticateApi(username, password));
}
