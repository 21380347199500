import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCancellingTeams } from 'state/api/teams/TeamApiActions';
import { teamsCancellingSelector } from 'state/api/teams/TeamApiSelectors';
import { tokenSelector } from 'state/app/SessionSelectors';
import TeamCancellingList from 'views/components/Team/TeamCancellingList';

export default function TeamListContainer(props) {
  const accessToken = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const teams = useSelector(teamsCancellingSelector);

  return (
    <TeamCancellingList
      fetchCancellingTeams={() => dispatch(
        fetchCancellingTeams({
          accessToken,
          paginate: teams || {},
        })
      )}
      teams={teams}
      {...props}
    />
  );
}
