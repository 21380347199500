
import * as types from 'state/api/orders/OrdersApiTypes';

const initState = {};

export default function printPricesReducers(state = initState, action) {
    switch(action.type) {
      case types.PRINT_OPTIONS_BY_PRINT_PROVIDER_SUCCESS: {
        const printOptions = action.payload || [];
        const printPrices = printOptions.reduce((prices, currentPrintOption) => {
          const newPrices = {};
          currentPrintOption.pricing.forEach(currentPrice => {
            newPrices[currentPrice.id] = currentPrice;
          });
          return {
            ...prices,
            ...newPrices
          }
        }, {});

        return {
          ...state,
          ...printPrices
        }
      }

      case types.UPDATE_PRINT_PRICE_SUCCESS: {
        const {
          id,
          minQuantity,
          price
        } = action.payload;

        return {
          ...state,
          [id]: {
            ...state[id],
            minQuantity,
            price
          }
        }
      }

      case types.ADD_PRINT_OPTION_PRICING_SUCCESS: {
        const printPrice = action.payload;
        return {
          ...state,
          [printPrice.id]: printPrice
        }
      }

      default:
        return state;
    }
}