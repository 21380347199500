import * as types from 'state/api/assets-media/AssetMediaApiTypes';
import { reduceAction } from 'lib/apiMiddlewareUtils';
// import fontUploadPayload from 'test/fontUploadPayload';

const initState = {
  mediaItem: null,
  mediaItemCategories: null,
  mediaItems: null,
  fontUploads: {},
};

export default function AssetMediaApiReducers(state = initState, action) {
  switch (action.type) {
    case types.FETCH_FAILURE:
    case types.FETCH_REQUEST:
    case types.FETCH_SUCCESS: {
      return { ...state, mediaItem: action };
    }

    case types.FETCH_CATEGORIES_FAILURE:
    case types.FETCH_CATEGORIES_REQUEST:
    case types.FETCH_CATEGORIES_SUCCESS: {
      return { ...state, mediaItemCategories: action };
    
    }

    case types.UPLOAD_FONT_REQUEST:
    case types.UPLOAD_FONT_FAILURE:
    case types.UPLOAD_FONT_SUCCESS: {      
      return { ...state, fontUploads: action };
    }

    case types.SEARCH_FAILURE:
    case types.SEARCH_REQUEST:
    case types.SEARCH_SUCCESS: {
      return { ...state, mediaItems: reduceAction(state.mediaItems, action) };
    }

    case types.ADD_CATEGORY_SUCCESS:
    case types.CREATE_SUCCESS:
    case types.DELETE_SUCCESS:
    case types.REMOVE_CATEGORY_SUCCESS: {
      return {
        ...state,
        mediaItem: null,
        mediaItemCategories: null,
        mediaItems: null,
      };
    }

    default: {
      return state;
    }
  }
}
