import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'state/app/SessionSelectors';
import MediaUpload from 'views/components/Media/MediaUpload';
import handleUploadMedia from 'state/api/assets-media/handleUploadMedia';
import handleUploadZip from 'state/api/assets-media/handleUploadZip';

export default function MediaUploadContainer({ history, location }) {
  const accessToken = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const query = new URLSearchParams(location.search);
  const urlCategory = query.get('category');

  return (
    <MediaUpload
      history={history}
      location={location}
      onSubmitBatch={(e, params) => handleUploadZip(e, {
        ...params,
        accessToken,
        dispatch,
        history,
      })}
      onSubmitIndividual={(e, params) => handleUploadMedia(e, {
        ...params,
        accessToken,
        dispatch,
        history,
      })}
      urlCategory={urlCategory}
    />
  );
}
