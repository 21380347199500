import { createAction } from 'redux-api-middleware';
import * as types from 'state/api/authentication/AuthtenticationApiTypes';

const BASE_API = process.env.REACT_APP_HOST_AUTH_SERVICE;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
const EASIL_TEAM_ID = process.env.REACT_APP_EASIL_TEAM_ID;

export const authenticate = (email, password) => {
  const formData = new URLSearchParams();
  formData.append('client_id', CLIENT_ID);
  formData.append('client_secret', CLIENT_SECRET);
  formData.append('email', email);
  formData.append('grant_type', 'password');
  formData.append('password', password);
  formData.append('scope', 'openid profile email');
  formData.append('strategy', 'password');
  formData.append('team_id', EASIL_TEAM_ID);

  return createAction({
    body: formData,
    endpoint: `${BASE_API}/token`,
    headers: {
      accept: 'application/x-www-form-urlencoded',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
    types: [
      types.AUTHENTICATION_REQUEST,
      types.AUTHENTICATION_SUCCESS,
      types.AUTHENTICATION_FAILURE,
    ]
  });
}