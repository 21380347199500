import * as types from 'state/api/searchSuggestions/searchSuggestionsTypes';

const initState = {};

export default function searchSuggestionsReducers(state = initState, action) {
  switch (action.type) {

    case types.FETCH_SUGGESTIONS_REQUEST: {
      return {
        ...state,
        [action.meta.term]: []
      };
    }

    case types.FETCH_SUGGESTIONS_SUCCESS: {
      if (!action.payload) return state;
      return {
        ...state,
        [action.meta.term]: action.payload.map(suggestion => ({ name: suggestion }))
      };
    }

    default: {
      return state;
    }
  }
}
