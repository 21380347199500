export const FETCH_USER_FAILURE = Symbol('API/USERS/FETCH_USER_FAILURE');
export const FETCH_USER_REQUEST = Symbol('API/USERS/FETCH_USER_REQUEST');
export const FETCH_USER_SUCCESS = Symbol('API/USERS/FETCH_USER_SUCCESS');

export const FETCH_USERS_FAILURE = Symbol('API/USERS/FETCH_USERS_FAILURE');
export const FETCH_USERS_REQUEST = Symbol('API/USERS/FETCH_USERS_REQUEST');
export const FETCH_USERS_SUCCESS = Symbol('API/USERS/FETCH_USERS_SUCCESS');

export const FETCH_USER_PREFERENCES_FAILURE = Symbol('API/USERS/FETCH_USER_PREFERENCES_FAILURE');
export const FETCH_USER_PREFERENCES_REQUEST = Symbol('API/USERS/FETCH_USER_PREFERENCES_REQUEST');
export const FETCH_USER_PREFERENCES_SUCCESS = Symbol('API/USERS/FETCH_USER_PREFERENCES_SUCCESS');

export const UPDATE_USER_PREFERENCES_FAILURE = Symbol('API/USERS/UPDATE_USER_PREFERENCES_FAILURE');
export const UPDATE_USER_PREFERENCES_REQUEST = Symbol('API/USERS/UPDATE_USER_PREFERENCES_REQUEST');
export const UPDATE_USER_PREFERENCES_SUCCESS = Symbol('API/USERS/UPDATE_USER_PREFERENCES_SUCCESS');

export const USER_TEAMS_FAILURE = Symbol('API/USERS/USER_TEAMS_FAILURE');
export const USER_TEAMS_REQUEST = Symbol('API/USERS/USER_TEAMS_REQUEST');
export const USER_TEAMS_SUCCESS = Symbol('API/USERS/USER_TEAMS_SUCCESS');

export const GDPR_DELETE_USER_FAILURE = Symbol('API/USERS/GDPR_DELETE_USER_FAILURE');
export const GDPR_DELETE_USER_REQUEST = Symbol('API/USERS/GDPR_DELETE_USER_REQUEST');
export const GDPR_DELETE_USER_SUCCESS = Symbol('API/USERS/GDPR_DELETE_USER_SUCCESS');

export const DELETE_USER_FAILURE = Symbol('API/USERS/DELETE_USER_FAILURE');
export const DELETE_USER_REQUEST = Symbol('API/USERS/DELETE_USER_REQUEST');
export const DELETE_USER_SUCCESS = Symbol('API/USERS/DELETE_USER_SUCCESS');
