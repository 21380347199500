import React, { useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import Close from '@material-ui/icons/Close';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

const ErrorSnack = ({ errorAction, onClose }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => setIsExpanded(false), [errorAction]);

  if (!errorAction) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={10000}
      onClose={onClose}
      open
    >
      <Alert
        action={
          <>
            <IconButton
              size="small"
              color="inherit"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? (
                <ExpandLess fontSize="small" />
              ) : (
                <ExpandMore fontSize="small" />
              )}
            </IconButton>

            <IconButton
              size="small"
              color="inherit"
              onClick={onClose}
            >
              <Close fontSize="small" />
            </IconButton>
          </>
        }
        severity="error"
        variant="filled"
      > 
        {isExpanded
          ? JSON.stringify(errorAction)
          : (typeof errorAction.type === 'symbol'
            ? errorAction.type.description
            : errorAction.type
          )
        }
      </Alert>
    </Snackbar>
  );
};

export default ErrorSnack;