import React from 'react';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  Grid,
  IconButton,
} from '@material-ui/core';
import EditorLinkContainer from "views/containers/EditorLink/EditorLinkContainer";
import Skeleton from '@material-ui/lab/Skeleton';
import DesignCardThumbnail from './DesignCardThumbnail';

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
  },
  divider: {
    margin: '0',
  },
  label: {
    fontSize: '8px',
    padding: '2px 4px',
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  subtitle: {
    flex: '1 1 auto',
    fontSize: '0.9em',
    overflow: 'hidden',
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  title: {
    fontSize: '1.1em',
    overflow: 'hidden',
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(2),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
}));

const DesignCard = ({ design, isUpdatingThumbnail, onDropDownClick, showDropDown = true }) => {
  const classes = useStyles();
  const width = design.width > design.height ? '250px' : '200px';

  return (
    <Card className={classes.card} style={{ width }}>
      {isUpdatingThumbnail && (
        <Skeleton variant="rect" >
          <DesignCardThumbnail width={width} design={design}/>
        </Skeleton>
      )}
      {!isUpdatingThumbnail && (
        <EditorLinkContainer designId={design.id}>
          <DesignCardThumbnail width={width} design={design}/>
        </EditorLinkContainer>
      )}
      <hr className={classes.divider} />
      <div
        className={classes.title}
        title={design.title}
      >
        {design.title}
      </div>
      <Grid
        alignItems="center"
        container
        wrap="nowrap"
      >
        <Grid
          item
          className={classes.subtitle}
          title={design.subtitle}
        >
          {design.subtitle}
        </Grid>
        {showDropDown && (
          <Grid item>
            <IconButton onClick={onDropDownClick}>
              <ArrowDropDownCircleIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

export default DesignCard;