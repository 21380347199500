import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import DesignsDrawer from './DesignsDrawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from "@material-ui/core/ListSubheader";
import MediaDrawer from './MediaDrawer';
import FontDrawer from './FontDrawer';
import MaterialDrawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DescriptionIcon from '@material-ui/icons/Description';
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import PrintIcon from '@material-ui/icons/Print';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import { Divider } from '@material-ui/core';

const Drawer = (props) => {
  const location = useLocation();

  return (
    <>
      <Toolbar />
      <Divider />
      <Switch>
        <Route path='/designs'><DesignsDrawer {...props} /></Route>
        <Route path='/media'><MediaDrawer /></Route>
        <Route path='/fonts'><FontDrawer /></Route>
        <Route path='/'>
          <List>
            <ListSubheader>Dashboards</ListSubheader>
            <ListItem button disabled>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary='Analytics' />
            </ListItem>
            <ListSubheader>Content</ListSubheader>
            <ListItem component={Link} to="/designs">
              <ListItemIcon>
                <DescriptionIcon />
              </ListItemIcon>
              <ListItemText primary='Designs' />
            </ListItem>
            <ListItem component={Link} to="/fonts/upload">
              <ListItemIcon>
                <FontDownloadIcon />
              </ListItemIcon>
              <ListItemText primary='Fonts' />
            </ListItem>
            <ListItem component={Link} to="/media">
              <ListItemIcon>
                <BurstModeIcon />
              </ListItemIcon>
              <ListItemText primary='Media' />
            </ListItem>
            {props.isAdmin &&
              <>
                <ListSubheader>Customers</ListSubheader>
                <ListItem
                  component={Link}
                  selected={location.pathname === '/teams'}
                  to="/teams"
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary='Teams' />
                </ListItem>
                <ListItem
                  component={Link}
                  selected={location.pathname === '/cancelling'}
                  to="/cancelling"
                >
                  <ListItemIcon>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary='Cancelling' />
                </ListItem>
                <ListItem
                  component={Link}
                  selected={location.pathname === '/users'}
                  to="/users"
                >
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText primary='Users' />
                </ListItem>
                <ListSubheader>Orders</ListSubheader>
                <ListItem
                  component={Link}
                  selected={location.pathname === '/orders/coupons'}
                  to="/orders/coupons"
                >
                  <ListItemIcon>
                    <MoneyOffIcon />
                  </ListItemIcon>
                  <ListItemText primary='Coupons' />
                </ListItem>
                <ListItem
                  component={Link}
                  selected={location.pathname === '/orders/print-marketing'}
                  to="/orders/print-marketing"
                >
                  <ListItemIcon>
                    <PrintIcon />
                  </ListItemIcon>
                  <ListItemText primary='Print Marketing' />
                </ListItem>
                <ListItem
                  component={Link}
                  selected={location.pathname === '/orders/print-pricing'}
                  to="/orders/print-pricing"
                >
                  <ListItemIcon>
                    <PrintIcon />
                  </ListItemIcon>
                  <ListItemText primary='Print Pricing' />
                </ListItem>
                <ListItem button disabled>
                  <ListItemIcon>
                    <ShoppingCartIcon />
                  </ListItemIcon>
                  <ListItemText primary='User Orders' />
                </ListItem>
                <ListSubheader>Subscriptions</ListSubheader>
                <ListItem
                  component={Link}
                  selected={location.pathname === '/coupons'}
                  to="/coupons"
                >
                  <ListItemIcon>
                    <MoneyOffIcon/>
                  </ListItemIcon>
                  <ListItemText primary='Coupons'/>
                </ListItem>
                <ListItem
                  component={Link}
                  selected={location.pathname === '/plans'}
                  to="/plans"
                >
                  <ListItemIcon>
                    <LocalAtmIcon/>
                  </ListItemIcon>
                  <ListItemText primary='Plans'/>
                </ListItem>
              </>
            }
          </List>
        </Route>
      </Switch>
    </>
  );
};

const DrawerContainer = (props) => {
  const classes = useStyles();

  return (
    <nav className={classes.drawer}>
      <Hidden mdUp implementation="css">
        <MaterialDrawer
          classes={{ paper: classes.drawerPaper }}
          container={window.document.body}
          ModalProps={{ keepMounted: true }}
          onClick={props.onDrawerClose}
          onClose={props.onDrawerClose}
          open={props.isDrawOpen}
          variant="temporary"
        >
          <Drawer {...props} />
        </MaterialDrawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <MaterialDrawer
          classes={{ paper: classes.drawerPaper }}
          open
          variant="permanent"
        >
          <Drawer {...props} />
        </MaterialDrawer>
      </Hidden>
    </nav>
  );
};

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: '280px',
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: '280px',
    '& ul > a': {
      color: 'inherit',
    },
    '& ul > a:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
}));

export default DrawerContainer;
