import { updateMedia } from 'state/api/assets-media/AssetMediaApiActions';
import handleUpdateMediaCategories from './handleUpdateMediaCategories';
import handleUpdateThumb from './handleUpdateThumb';

const handleUpdateMedia = async (event, {
  accessToken,
  categories,
  categoriesOld,
  dispatch,
  history,
  id: mediaId,
  name,
  thumbFile,
  ...other
}) => {
  const results = [];

  const update = await dispatch(
    updateMedia({
      accessToken,
      id: mediaId,
      name,
      ...other
    }),
  );
  results.push(update);

  if (update.error) {
    return results;
  }

  const updates = await handleUpdateMediaCategories({
    accessToken,
    categories,
    categoriesOld,
    dispatch,
    mediaId,
  });

  updates.forEach(u => results.push(u));

  if (thumbFile) {
    results.push(await handleUpdateThumb({
      accessToken,
      dispatch,
      mediaId,
      thumbFile
    }));
  }

  return results;
};

export default handleUpdateMedia;