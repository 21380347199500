export const ADD_CATEGORY_REQUEST = 'API/ASSET_MEDIA/ADD_CATEGORY_REQUEST';
export const ADD_CATEGORY_SUCCESS = 'API/ASSET_MEDIA/ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'API/ASSET_MEDIA/ADD_CATEGORY_FAILURE';

export const CREATE_REQUEST = 'API/ASSET_MEDIA/CREATE_REQUEST';
export const CREATE_SUCCESS = 'API/ASSET_MEDIA/CREATE_SUCCESS';
export const CREATE_FAILURE = 'API/ASSET_MEDIA/CREATE_FAILURE';

export const DELETE_REQUEST = 'API/ASSET_MEDIA/DELETE_REQUEST';
export const DELETE_SUCCESS = 'API/ASSET_MEDIA/DELETE_SUCCESS';
export const DELETE_FAILURE = 'API/ASSET_MEDIA/DELETE_FAILURE';

export const FETCH_REQUEST = 'API/ASSET_MEDIA/FETCH_REQUEST';
export const FETCH_SUCCESS = 'API/ASSET_MEDIA/FETCH_SUCCESS';
export const FETCH_FAILURE = 'API/ASSET_MEDIA/FETCH_FAILURE';

export const FETCH_CATEGORIES_REQUEST = 'API/ASSET_MEDIA/FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'API/ASSET_MEDIA/FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'API/ASSET_MEDIA/FETCH_CATEGORIES_FAILURE';

export const REMOVE_CATEGORY_REQUEST = 'API/ASSET_MEDIA/REMOVE_CATEGORY_REQUEST';
export const REMOVE_CATEGORY_SUCCESS = 'API/ASSET_MEDIA/REMOVE_CATEGORY_SUCCESS';
export const REMOVE_CATEGORY_FAILURE = 'API/ASSET_MEDIA/REMOVE_CATEGORY_FAILURE';

export const SEARCH_REQUEST = 'API/ASSET_MEDIA/SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'API/ASSET_MEDIA/SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'API/ASSET_MEDIA/SEARCH_FAILURE';

export const UPLOAD_IMAGE_REQUEST = 'API/ASSET_MEDIA/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'API/ASSET_MEDIA/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_FAILURE = 'API/ASSET_MEDIA/UPLOAD_IMAGE_FAILURE';

export const UPLOAD_FONT_REQUEST = 'API/ASSET_MEDIA/UPLOAD_FONT_REQUEST';
export const UPLOAD_FONT_SUCCESS = 'API/ASSET_MEDIA/UPLOAD_FONT_SUCCESS';
export const UPLOAD_FONT_FAILURE = 'API/ASSET_MEDIA/UPLOAD_FONT_FAILURE';

export const UPLOAD_THUMB_REQUEST = 'API/ASSET_MEDIA/UPLOAD_THUMB_REQUEST';
export const UPLOAD_THUMB_SUCCESS = 'API/ASSET_MEDIA/UPLOAD_THUMB_SUCCESS';
export const UPLOAD_THUMB_FAILURE = 'API/ASSET_MEDIA/UPLOAD_THUMB_FAILURE';

export const UPDATE_REQUEST = 'API/ASSET_MEDIA/UPDATE_REQUEST';
export const UPDATE_SUCCESS = 'API/ASSET_MEDIA/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'API/ASSET_MEDIA/UPDATE_FAILURE';

export const UPDATE_THUMB_REQUEST = 'API/ASSET_MEDIA/UPDATE_THUMB_REQUEST';
export const UPDATE_THUMB_SUCCESS = 'API/ASSET_MEDIA/UPDATE_THUMB_SUCCESS';
export const UPDATE_THUMB_FAILURE = 'API/ASSET_MEDIA/UPDATE_THUMB_FAILURE';

export const UPLOAD_ZIP_REQUEST = 'API/ASSET_MEDIA/UPLOAD_ZIP_REQUEST';
export const UPLOAD_ZIP_SUCCESS = 'API/ASSET_MEDIA/UPLOAD_ZIP_SUCCESS';
export const UPLOAD_ZIP_FAILURE = 'API/ASSET_MEDIA/UPLOAD_ZIP_FAILURE';
