import { combineReducers } from 'redux';
import { default as api } from 'state/api';
import { default as app } from 'state/app';
import { default as entities } from 'state/entities';
import { default as ui } from 'state/ui';

export default combineReducers({
  api,
  app,
  entities,
  ui,
});
