import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { designTagsSelector } from 'state/api/designTag/DesignTagSelectors';
import { addTagToCollection, fetchDesignTags } from 'state/api/designTag/DesignTagActions';
import { isBrandManagerSelector, isDesignerSelector } from 'state/app/SessionSelectors';
import { fetchSearchSuggestions } from 'state/api/searchSuggestions/searchSuggestionsActions';

const useStyles = makeStyles(theme => ({
  container: {
    borderTop: '1px solid #bbb',
    padding: theme.spacing(0, 1),
  },
  addTagWrapper: {
    display: 'flex',
    margin: theme.spacing(1, 0, 0),
  },
  addTagInput: {
    margin: theme.spacing(0, 1, 0, 0),
  },
  tag: {
    margin: theme.spacing(1, 1, 0, 0),
  },
}));

export const DesignTags = ({ designId, collectionId, tagSuggestions, removeTagFunction }) => {
  const [isSuggestionsShown, setIsSuggestionsShown] = useState(false);
  const [latestInputValue, setLatestInputValue] = useState("");
  const classes = useStyles();
  const designTags = useSelector(designTagsSelector)[designId];
  const dispatch = useDispatch();
  const isBrandManager = useSelector(isBrandManagerSelector);
  const isDesigner = useSelector(isDesignerSelector);

  useEffect(() => {
    // meta wont exist if there has not been a fetch for this designs tags yet
    if (designId && (!designTags || !designTags.meta)) {
      dispatch(fetchDesignTags(designId));
    }
  });

  if (!designId || !designTags) {
    return null;
  }

  const options = isSuggestionsShown ? tagSuggestions[latestInputValue] || [] : [];

  // set up handling for submitted value change
  const handleChange = (event, value, action) => {
    switch (action) {
      case 'blur': {
        // Ignore
        break;
      }

      case 'select-option': {
        const name = value[value.length - 1].name.trim();

        if (name) {
          dispatch(addTagToCollection(collectionId, name, designId));
        }

        setLatestInputValue("");
        break;
      }

      case 'create-option': {
        const name = value[value.length - 1].trim();

        if (name) {
          dispatch(addTagToCollection(collectionId, name, designId));
        }

        setLatestInputValue("");
        break;
      }

      case 'clear':
      case 'remove-option': {
        const reduceFn = (map, tag) => ({ ...map, [tag.id]: true });
        const idMap = value.reduce(reduceFn, {});

        (designTags.payload || []).forEach((tag) => {
          if (!idMap[tag.id]) {
            dispatch(removeTagFunction(collectionId, tag.id));
          }
        });

        setLatestInputValue("");
        break;
      }

      default: {
        throw new Error(`Invalid action: ${action}`);
      }
    }
  };

  // set up handling for text input changes
  const handleInputChange = (event, value, action) => {
    switch(action) {
      case 'input': {
        // when the input is 3 or more characters show suggestions
        setLatestInputValue(value);
        setIsSuggestionsShown(value && value.length >= 3);
        if (value && value.length >= 3 && !tagSuggestions[value]) {
          // suggestions for this term do not exist so fetch them
          dispatch(fetchSearchSuggestions(value));
        }
        break;
      }

      case 'clear':
      case 'reset': {
        // Ignore
        break;
      }

      default: {
        throw new Error(`Invalid action: ${action}`);
      }
    }
  };

  return (
    <li className={classes.container}>
      <Autocomplete
        clearOnBlur
        disabled={!(isBrandManager || isDesigner)}
        freeSolo
        fullWidth
        getOptionLabel={({ name }) => name}
        multiple
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={(e) => e.stopPropagation()}
        options={options}
        renderInput={(params) => (
          <TextField {...params}
            InputLabelProps={{ shrink: true }}
            label="Tags"
            variant="outlined"
          />
        )}
        selectOnFocus
        size="small"
        value={designTags.payload || []}
        inputValue={latestInputValue}
      />
    </li>
  );
};

export default DesignTags;
