import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { isMediaVideoFormat } from 'lib/fileUtil';

const useStyles = makeStyles({
  card: {
    width: '120px',
    '& > a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    backgroundColor: "#484a4c",
  },
  cardContent: {
    background: 'whitesmoke',
    overflow: 'hidden',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  cardMedia: {
    height: '120px',
  },
});

export default function MediaSearchItem({ media }) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Link to={`/media/${media.id}`}>
        <CardActionArea>
        {isMediaVideoFormat(media.url) ? (
            <CardMedia
              className={classes.cardMedia}
              src={media.thumbnailUrl}
              component={"video"}
              autoPlay
              loop
              style={{ backgroundSize: "contain" }}
              title={media.name}
            />
          ) : (
            <CardMedia
              className={classes.cardMedia}
              image={media.thumbnailUrl}
              style={{ backgroundSize: "contain" }}
              title={media.name}
            />
          )}
          <CardContent className={classes.cardContent}>
            <strong title={media.name}>{media.name}</strong>
            {media.duration && <div>{media.duration/1000} sec</div>}
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}
