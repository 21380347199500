import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles, MenuItem } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    paddingTop: theme.spacing(2),
    color: "red"
  }
}));

export default function AddPrintOptionDialog({
  addOrderLabel,
  fetchUnassignedTemplateCodes,
  onClose,
  providerId,
  setSuccessActionString,
  unassignedTemplateCodes
}) {
  const classes = useStyles();
  const [orderLabel, setLabel] = useState(null);
  const [templateCode, setTemplateCode] = useState(null);
  const [errors, setErrors] = useState({});
  const isAddDisabled = !(orderLabel && templateCode) || Object.values(errors).some(error => !!error);

  useEffect(() => {
    if (!unassignedTemplateCodes) {
      fetchUnassignedTemplateCodes();
    }
  })

  const handleOnBlur = event => {
    const inputName = event.target.name;
    const value = event.target.value;
    const isValidString = value.match(/^[a-z0-9]+(?:_[a-z0-9]+)*$/);
    
    // set error if no value has been provided
    if (!value) {
      setErrors({ 
        ...errors,
        [inputName]: `Please provide a ${inputName}.`
      });
      return;
    } else if (!isValidString) {
      setErrors({ 
        ...errors,
        [inputName]: `Value does not match casing requirements. Please see above examples.`
      });
      return;
    } else {
      // clear error
      setErrors({ 
        ...errors,
        [inputName]: ""
      });
    }

  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await addOrderLabel({ orderLabel, templateCode, providerId });

    if (response.error) {
      setErrors({
        ...errors,
        response: "There was an issue processing your request. Please check the example casing above, or contact devs for support."
      });
      return
    }
    
    onClose();
    setSuccessActionString("Order label successfully created");
  };

  const handleOnChange = (e) => {
    switch(e.target.name) {
      case 'orderLabel':
        setLabel(e.target.value); 
        setErrors({ ...errors, orderLabel: "" })
        break;
      case 'templateCode': 
        setTemplateCode(e.target.value);
        setErrors({ ...errors, templateCode: "" })
        break;
      default: 
      return null;
    }
  }

  return (
    <Dialog aria-labelledby="form-dialog-title" onClose={onClose} open={true} maxWidth="xs">
      <DialogTitle id="form-dialog-title">
        Add Print Label
      </DialogTitle>
      <DialogContent>
        <div>Label must only contain lower case letters and numbers, with underscores separating each word</div>
        <div>Eg. <strong>order_label, size_1_new</strong></div>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="dense"
          label="Label"
          name="orderLabel"
          value={orderLabel}
          onChange={handleOnChange}
          variant="outlined"
          onBlur={handleOnBlur}
          required
          error={!!errors.orderLabel}
          helperText={errors.orderLabel}
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="dense"
          label="Template Code"
          value={templateCode}
          variant="outlined"
          select
          required
          error={!!errors.templateCode}
          helperText={errors.templateCode}
          onChange={handleOnChange}
          name={"templateCode"}
        >
          {unassignedTemplateCodes && unassignedTemplateCodes.length > 1 && unassignedTemplateCodes.map(code => (
            <MenuItem value={code}>
              {code}
            </MenuItem>
          ))}
        </TextField>
        {errors.response && (
          <div className={classes.errorMessage}>{errors.response}</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={isAddDisabled}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}