import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import mediaCategories from 'lib/mediaCategories';
import Publish from '@material-ui/icons/Publish';

export default function MediaDrawer() {
  const location = useLocation();
  const qs = new URLSearchParams(location.search);
  const qsCategory = qs.get('category');

  return (
    <List>
      <ListSubheader>
        <Box
          component={Link}
          ml={-2}
          onClick={(e) => e.stopPropagation()}
          to="/"
        >
          <IconButton>
            <ChevronLeft />
          </IconButton>
        </Box>
        Media
      </ListSubheader>
      <ListItem
        component={Link}
        selected={location.pathname.startsWith('/media/upload')}
        to="/media/upload"
      >
        <ListItemIcon>
          <Publish />
        </ListItemIcon>
        <ListItemText primary='Upload' />
      </ListItem>
      {mediaCategories.map(({ icon: Icon, label, name }) => (
        <ListItem
          component={Link}
          key={name}
          selected={(location.pathname === '/media' && qsCategory === name)
            || (location.pathname === '/media' && !qsCategory && name === 'all')
          }
          to={name === 'all' ? '/media' : `/media?category=${name}`}
        >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText primary={label} />
        </ListItem>
      ))}
    </List>
  );
}
