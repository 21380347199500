import React, {useState} from 'react';
import { 
  Box,
  Button,
  Table,
  makeStyles,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddPrintOptionDialog from './AddPrintOptionDialog';
import TemplateSizesPopout from "./TemplateSizesPopoutContainer";

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(4)
  },
  white: {
    color: 'white',
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
  },
  size: {
    display: "flex",
    alignItems: "center"
  }
}));

const UnassignedPrintOption = ({
  createPrintOption,
  fetchTemplateSizesByOrderLabel,
  label,
  setSuccessActionString,
  providerName,
}) => {
  const [addPrintOptionDialog, setAddPrintOptionDialog] = useState(false);

  const classes = useStyles();
  return (
    <>
      <Table classes={{ root: classes.table }}>
        <TableHead>
          <TableRow>
            <Box
              component={TableCell}
              width="100%"
              bgcolor="#666666"
              classes={{ root: classes.white }}
            >
              <div>{label}</div>
              <TemplateSizesPopout
                fetchTemplateSizesByOrderLabel={fetchTemplateSizesByOrderLabel}
                labelName={label}
                setSuccessActionString={setSuccessActionString}
              />
            </Box>
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow>
            <Box
              component={TableCell}
              width="100%"
              bgcolor="#dddddd"
              classes={{ root: classes.size }}
            >
              <Button
                onClick={() => setAddPrintOptionDialog(true)}
                startIcon={<AddIcon />}
                color="primary"
                variant="contained"
                size="small"
              >
               Add
              </Button>
            </Box>
          </TableRow>
        </TableHead>
      </Table>
      {addPrintOptionDialog && (
        <AddPrintOptionDialog
          label={label}
          onClose={() => setAddPrintOptionDialog(false)}
          createPrintOption={createPrintOption}
          disabled={[]}
          setSuccessActionString={setSuccessActionString}
          providerName={providerName}
        />
      )}
    </>
  )
}

export default UnassignedPrintOption