import React from 'react';

export default function EasilIcon() {
  return (
    <svg width="32px" height="32px" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title" fill="white" stroke="white">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="easil-logo" fill="white" fillRule="nonzero">
          <path d="M9.24825,10.3035379 L0,15.7079232 L0,5.37220441 L9.24825,0 L9.24825,10.3035379 Z M7.421875e-05,27.1721289 L18.6401133,38 L18.6401133,27.4908908 L7.421875e-05,16.6629454 L7.421875e-05,27.1721289 Z M14.3579883,24.140212 L14.36875,24.1338948 L23.0075898,19.0893708 L23.0075898,19.0753984 L23.331332,18.8858061 L23.331332,8.1718688 L5.40134375,18.8264238 L14.3579883,24.140212 Z M19.4493203,27.4998836 L19.4493203,38 L38,27.2234846 L38,16.6451827 L19.4493203,27.4998836 Z" id="Shape" />
        </g>
      </g>
    </svg>
  );
}
