import { combineReducers } from "redux";
import authentication from 'state/api/authentication/AuthenticationApiReducers';
import assets from 'state/api/assets/AssetApiReducers';
import assetsMedia from 'state/api/assets-media/AssetMediaApiReducers';
import common from 'state/api/common/CommonApiReducers';
import designTags from 'state/api/designTag/DesignTagReducers';
import subscriptions from 'state/api/subscriptions/SubscriptionApiReducers';
import teams from 'state/api/teams/TeamApiReducers';
import users from 'state/api/users/UsersApiReducers';
import orders from 'state/api/orders/OrdersApiReducers'

export default combineReducers({
  assets,
  assetsMedia,
  authentication,
  common,
  designTags,
  subscriptions,
  teams,
  users,
  orders,
});
