import * as types from 'state/api/authentication/AuthtenticationApiTypes';
import { setItem } from 'lib/storageHandler';
import { jwtDecoder } from 'lib/tokenParser';

const initState = {
  token: null,
  roles: [],
  sessionEnd: null,
  teamId: null,
  userId: null,
}

const processAuthenticationSuccess = (state, payload) => {
  const sessionEnd = new Date();
  sessionEnd.setSeconds(payload.expires_in);

  setItem('sessionEnd', sessionEnd);
  setItem('token', payload.access_token);

  return {
    sessionEnd: sessionEnd,
    token: payload.access_token,
    ...jwtDecoder(payload.access_token),
  }
}

const sessionReducers = (state = initState, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_SUCCESS: {
      return {
        ...state,
        ...processAuthenticationSuccess(state, action.payload),
      }
    }
    default:
      return state;
  }
};

export default sessionReducers;