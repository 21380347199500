import { reduceAction } from "lib/apiMiddlewareUtils";
import * as types from "state/api/orders/OrdersApiTypes";
import { immutableUpdate } from 'lib/immutability-helper/index';
import { get as getPath, cloneDeep, uniq } from 'lodash';

const initState = {
  ordersCoupons: null,
  descriptionUrls: null,
  printProviders: null,
  printOptions: null,
  unassignedOrderLabels: null,
}

/**
 * @description - Takes a print option update and applies its results to a provided sizes state
 * @param {Object} args.originalPrintOption - The Print option being updated before our update was applied
 * @param {Object} args.updatedPrintOption - The Print option after out update has been applied
 * @param {Object} args.sizeState - The current size state to apply our update upon
 * @returns sizesState updated with the print option changes
 */
const updateSizeForPrintOptionUpdate = ({ originalPrintOption, updatedPrintOption, sizeState }) => {
  // remove the original print option reference and replace it with new reference
  const sizeStateClone = Object.assign({}, sizeState);
  let stockStateClone = sizeStateClone[originalPrintOption.stock];
  const originalStockIndex = Object.keys(sizeStateClone).findIndex(stock => stock === originalPrintOption.stock);
  let originalPrintOptionIndex = 0;

  // check if there is anything in the original stock clone and if not remove it
  if (stockStateClone && stockStateClone.length > 0) {
    originalPrintOptionIndex = stockStateClone.findIndex(printOptionId => printOptionId === originalPrintOption.id);
    sizeStateClone[originalPrintOption.stock].splice(originalPrintOptionIndex, 1);
    if (stockStateClone.length === 0) {
      // since there is nothing left for this stock key we should remove it
      delete sizeStateClone[originalPrintOption.stock];
    }
  }

  const currentPrintOptionIds = (sizeStateClone[updatedPrintOption.stock] || []);

  // make a unique list of print option ids and add print option id back
  // to the position our original came from
  const updatedIds = uniq([
    ...currentPrintOptionIds.slice(0, originalPrintOptionIndex),
    updatedPrintOption.id,
    ...currentPrintOptionIds.slice(originalPrintOptionIndex),
  ]);

  // create a new object for stocks to ensure orders are persisted
  let updatedStocks = {};
  
  const sizeStateKeys = Object.keys(sizeStateClone);
  sizeStateKeys.forEach((stock, stockIndex) => {
    // insert updated stock at index original stock was taking up
    if (stockIndex === originalStockIndex) {
      updatedStocks = immutableUpdate(updatedStocks, {
        [updatedPrintOption.stock]: {
          $autoArray: {
            $set: updatedIds
          }
        }
      });
    }
    // insert current stock copy back into same place
    if (stock !== updatedPrintOption.stock) {
      updatedStocks = immutableUpdate(updatedStocks, {
        [stock]: {
          $autoArray: {
            $set: sizeStateClone[stock]
          }
        }
      });
    }
  });

  // handle the case that our stock was the last in order and so its index no longer exists
  if (originalStockIndex >= sizeStateKeys.length) {
    updatedStocks = immutableUpdate(updatedStocks, {
      [updatedPrintOption.stock]: {
        $autoArray: {
          $set: updatedIds
        }
      }
    });
  }

  // update the new option and add our unique id list
  return updatedStocks;
}

export default function OrdersApiReducers (state = initState, action) {
  switch (action.type) {
    case types.ORDERS_COUPONS_FAILURE:
    case types.ORDERS_COUPONS_REQUEST:
    case types.ORDERS_COUPONS_SUCCESS: {
      return {
        ...state, ordersCoupons: reduceAction(state.ordersCoupons, action) 
      }
    }

    case types.CREATE_ORDERS_COUPONS_SUCCESS:{
      return {
        ...state,
        ordersCoupons: {
          ...state.ordersCoupons,
          payload: [
            action.payload,
            ...state.ordersCoupons.payload
          ]
        }
      }
    }

    case types.UPDATE_ORDERS_COUPONS_SUCCESS: {
      return {
        ...state,    
        ordersCoupons: {
          ...state.ordersCoupons,
          payload:
            state.ordersCoupons.payload.map(coupon => 
              coupon.code === action.payload.code ? 
              action.payload 
              : 
              coupon
            )
        }
      }
    }

    case types.DESCRIPTION_URL_SUCCESS: {
      return {
        ...state, descriptionUrls: reduceAction(state.descriptionUrl, action) 
      }
    }
    
    case types.PRINT_PROVIDER_SUCCESS: {
      return {
        ...state, printProviders: reduceAction(state.printProviders, action)
      }
    }

    case types.CREATE_DESCRIPTION_URL_SUCCESS: {
      return {
        ...state,
        descriptionUrls: {
          ...state.descriptionUrls,
          payload: [
            ...state.descriptionUrls.payload,
            action.payload,
          ]
        }
      }
    }

    case types.DELETE_DESCRIPTION_URL_SUCCESS: {
      // Find index of URL to delete
      const urlIndex = state.descriptionUrls.payload.findIndex(url => url.templateCode === action.meta.templateCode && url.country === action.meta.country)

      // Remove the URL via index
      const updatedUrls = state.descriptionUrls.payload
      updatedUrls.splice(urlIndex, 1);

      // Update state with new URLs
      return {
        ...state,
        descriptionUrls: {
          ...state.descriptionUrls,
          payload: updatedUrls
        }
      }
    }

    case types.UPDATE_DESCRIPTION_URL_SUCCESS: {
      // Find index of URL to update
      const urlIndex = state.descriptionUrls.payload.findIndex(url => url.templateCode === action.payload.templateCode && url.country === action.payload.country)

      // Update the URL via index
      const updatedUrls = state.descriptionUrls.payload;
      updatedUrls[urlIndex] = {...action.payload};

      // Update state with new URLs
      return {
        ...state,
        descriptionUrls: {
          ...state.descriptionUrls,
          payload: updatedUrls
        }
      }
    }

    case types.PRINT_OPTIONS_BY_PRINT_PROVIDER_REQUEST: {
      return {
        ...state,
        printOptions: {
          ...state.printOptions,
          isFetching: true
        }
      }
    }

    case types.PRINT_OPTIONS_BY_PRINT_PROVIDER_SUCCESS: {
      const { printProviderId } = action.meta;
      const printOptions = action.payload || [];
      // organise the response print options into nested object
      // where nested keys are label, sizeDesciption, and stock
      const sortedPrintOptions = printOptions.reduce((acc, option) => {
        return immutableUpdate(acc, {
          [option.label]: {
            $auto: {
              [option.sizeDescription]: {
                $auto: {
                  [option.stock]: {
                    $autoArray: {
                      $push: [option.id]
                    }
                  }
                }
              }
            }
          }
        });
      }, {});

      return immutableUpdate(state, {
        printOptions: {
          $auto: {
            [printProviderId]: {
              $auto: {
                options: {
                  $auto: {
                    $merge: sortedPrintOptions
                  }
                },
                meta: {
                  $set: {
                    ...action.meta,
                    isMoreAvailable: action.payload?.length >= action.meta.count,
                  }
                },
              }
            }
          },
          isFetching: {
            $set: false,
          }
        }
      });
    }
    
    case types.ADD_PRINT_OPTION_SUCCESS: {
      const {
        label,
        sizeDescription,
        stock,
        printProviderId,
        id
      } = action.payload;

      if (state.unassignedOrderLabels !== null && state.unassignedOrderLabels[printProviderId]) {
        const unassignedOrderLabels = getPath(state.unassignedOrderLabels, printProviderId, []);
        const updatedUnassignedLabels = unassignedOrderLabels.filter(orderLabel => orderLabel !== label);
        return immutableUpdate(state, {
          printOptions: {
            $auto: {
              [printProviderId]: {
                $auto: {
                  options: {
                    $auto: {
                      [label]: {
                        $auto: {
                          [sizeDescription]: {
                            $auto: {
                              [stock]: {
                                $autoArray: {
                                  $push: [id]
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          unassignedOrderLabels: {
            $auto: {
              [printProviderId]: {
                $autoArray: {
                  $set: updatedUnassignedLabels
                }
              },
            }
          }
        });
      } else {
        return immutableUpdate(state, {
          printOptions: {
            $auto: {
              [printProviderId]: {
                $auto: {
                  options: {
                    $auto: {
                      [label]: {
                        $auto: {
                          [sizeDescription]: {
                            $auto: {
                              [stock]: {
                                $autoArray: {
                                  $push: [id]
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          },
        });
      }
    }
    
    case types.TEMPLATE_SIZES_BY_ORDER_LABEL_REQUEST: {
      const { 
        meta: { 
          labelName 
        }
      } = action;

      return immutableUpdate(state, {
        labelTemplateCodes: {
          $auto: {
            [labelName]: {
              $auto: {
                isFetching: {
                  $set: true
                },
                templateCodes: {
                  $set: []
                }
              }
            }
          }
        }
      });
    }

    case types.TEMPLATE_SIZES_BY_ORDER_LABEL_SUCCESS: {
      const { 
        meta: { 
          labelName 
        },
        payload: templateSizes = []
      } = action;

      const labelTemplateCodes = [];

      templateSizes.forEach(templateSize => {
        if (!templateSize) return;
        labelTemplateCodes.push(templateSize.templateCode);
      });

      return immutableUpdate(state, {
        labelTemplateCodes: {
          $auto: {
            [labelName]: {
              $auto: {
                isFetching: {
                  $set: false
                },
                templateCodes: {
                  $set: labelTemplateCodes
                } 
              }
            }
          }
        }
      });
    }

    case types.DELETE_TEMPLATE_CODE_FROM_PRINT_LABEL_REQUEST: {
      const { orderLabel: labelName } = action.meta;

      return immutableUpdate(state, {
        labelTemplateCodes: {
          $auto: {
            [labelName]: {
              $auto: {
                isFetching: {
                  $set: true
                }
              }
            }
          }
        }
      });
    }
    
    case types.DELETE_TEMPLATE_CODE_FROM_PRINT_LABEL_SUCCESS: {
      const {
        orderLabel: labelName,
        templateCode
      } = action.meta;
      const labelTemplateCodesState = getPath(state.labelTemplateCodes, labelName);
      const updatedTemplateCodes = labelTemplateCodesState.templateCodes.filter(code => code !== templateCode);

      return immutableUpdate(state, {
        labelTemplateCodes: {
          $auto: {
            [labelName]: {
              $auto: {
                isFetching: {
                  $set: false
                },
                templateCodes: {
                  $set: updatedTemplateCodes
                } 
              }
            }
          }
        }
      });
    }

    case types.UPDATE_PRINT_PROVIDER_SUCCESS: {
      const updatedPrintProvider = action.payload;
      const printProviderIndex = state.printProviders.payload.findIndex(provider => provider.id === updatedPrintProvider.id);

      return immutableUpdate(state, {
        printProviders: {
          $auto: {
            payload: {
              $autoArray: {
                $splice: [[printProviderIndex, 1, updatedPrintProvider]]
              }
            }
          }
        }
      });
    }

    case types.DELETE_PRINT_PROVIDER_SUCCESS: {
      const { providerId } = action.meta;
      const updatedPrintProviders = state.printProviders.payload.filter(provider => provider.id !== providerId);

      return {
        ...state,
        printProviders: {
          ...state.printProviders,
          payload: updatedPrintProviders
        }
      }
    }

    case types.DELETE_PRINT_OPTION_SUCCESS: {
      const printOption = action.meta.printOption;

      const currentOptions = state.printOptions[printOption.pricing.printProviderId].options[printOption.label][printOption.sizeDescription][printOption.stock]
      const currentOptionIndex = currentOptions.findIndex(option => option === printOption.id);

      const updatedState = immutableUpdate(state, {
        printOptions: {
          $auto: {
            [printOption.pricing.printProviderId]: {
              options: {
                $auto: {
                  [printOption.label]: {
                    $auto: {
                      [printOption.sizeDescription]: {
                        $auto: {
                          [printOption.stock]: {
                            $autoArray: {
                              $splice: [[currentOptionIndex, 1]]
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      })
      return updatedState;
    }

    case types.UPDATE_PRINT_OPTION_SUCCESS: {
      const updatedPrintOption = action.payload;
      const { originalPrintOption } = action.meta;

      let updatedState = cloneDeep(state);

      if (originalPrintOption.stock !== updatedPrintOption.stock) {
        // when the stock has been changed we need to replace it
        updatedState = immutableUpdate(state, {
          printOptions: {
            $auto: {
              [updatedPrintOption.providerId]: {
                $auto: {
                  options: {
                    $auto: {
                      [originalPrintOption.label]: {
                        $auto: {
                          [originalPrintOption.sizeDescription]: {
                            $auto: {
                              $apply: sizeState => updateSizeForPrintOptionUpdate({ originalPrintOption, updatedPrintOption, sizeState })
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        })
      }

      return updatedState;
    }

    case types.UNASSIGNED_PRINT_OPTION_REQUEST: {
      return immutableUpdate(state, {
        unassignedOrderLabels: {
          $auto: {
            isFetching: {
              $set: true
            },
          }
        }
      });
    }

    case types.UNASSIGNED_PRINT_OPTION_SUCCESS: {
      const providerId = action.meta.providerId;
      return immutableUpdate(state, {
        unassignedOrderLabels: {
          $auto: {
            [providerId]: {
              $autoArray: {
                $set: [...action.payload]
              }
            },
            isFetching: {
              $set: false
            }
          }
        }
      });
    }

    case types.UNASSIGNED_TEMPLATE_CODES_REQUEST: {
      return immutableUpdate(state, {
        unassignedTemplateCodes: {
          $auto: {
            isFetching: {
              $set: false
            }
          }
        }
      });
    }
    
    case types.UNASSIGNED_TEMPLATE_CODES_SUCCESS: {
      return immutableUpdate(state, {
        unassignedTemplateCodes: {
          $autoArray: {
            $set: [...action.payload]
          },
          isFetching: {
            $set: false
          }
        }
      });
    }

    case types.ADD_ORDER_LABEL_REQUEST: {
      return immutableUpdate(state, {
        unassignedOrderLabels: {
          $auto: {
            isFetching: {
              $set: true
            },
          }
        }
      });
    }

    case types.ADD_ORDER_LABEL_SUCCESS: {
      const { providerId } = action.meta;

      if (getPath(state.unassignedOrderLabels, providerId)) {
        const x = immutableUpdate(state, {
          unassignedOrderLabels: {
            $auto: {
              [providerId]: {
                $autoArray: {
                  $unshift: [action.payload.orderLabel]
                }
              },
              isFetching: {
                $set: false
              }
            }
          }
        });

        return x;
      }

      return immutableUpdate(state, {
        unassignedOrderLabels: {
          $auto: {
            isFetching: {
              $set: false
            },
          }
        }
      });
    }
    
    case types.ADD_ORDER_LABEL_FAILURE: {
      return immutableUpdate(state, {
        unassignedOrderLabels: {
          $auto: {
            isFetching: {
              $set: false
            },
          }
        }
      });
    }

    default: 
      return state;
    }
}