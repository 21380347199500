import React, { useEffect, useState } from 'react';
import { FAILED, LOADING, READY } from 'lib/Status';
import { makeStyles } from '@material-ui/core';
import { toDateTimeStr } from 'lib/dateUtil';
import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import LoadMore from 'views/components/LoadMore/LoadMore';
import MuiTable from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  tight: {
    whiteSpace: 'nowrap',
    width: '1%',
  }
});

export default function UserSearch({
  fetchUsers,
  history,
  location,
  users,
  setShowDeleteSuccess,
  showDeleteSuccess
}) {
  const params = new URLSearchParams(location.search);
  const styles = useStyles();
  const term = params.get('term');
  const [input, setInput] = useState(term || '');

  useEffect(() => {
    if (users === null
      || users.meta.term !== term
    ) {
      fetchUsers({ term });
    }
  });

  if (!users || users.meta.status === FAILED) {
    return null;
  }

  return (
    <div>
      <h2>Users</h2>
  
      <Paper
        component="form"
        onSubmit={(event) => {
          event.preventDefault();
          const encodedname = encodeURIComponent(input);

          if (encodedname) {
            history.replace(`/users?term=${encodedname}`);
          } else {
            history.replace('/users');
          }
        }}
      >
        <Box
          display="flex"
          mb={2}
          pl={2}
        >
          <InputBase
            fullWidth
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Backspace'
                && event.target.value.length > 0
                && (term || '').length
              ) {
                setInput('');
                history.replace('/users');
              }
            }}
            placeholder="Search Users"
            value={input}
          />
          {!!input && (
            <>
              <IconButton
                onClick={() => {
                  setInput('');
                  history.replace('/users');
                }}
              >
                <ClearIcon />
              </IconButton>
              <Divider
                orientation="vertical"
                style={{ height: '28px', marginTop: '10px' }}
              />
            </>
          )}
          <IconButton type="submit">
            <SearchIcon />
          </IconButton>
        </Box>
      </Paper>
  
      <Box
        component={Paper}
        padding={2}
      >
        {(users.meta.status === LOADING
          || (users.meta.status === READY
            && (users.payload || []).length > 0)
          )
          && (
          <>
            {term ? (
              <em>Results for "<strong>{term}</strong>":</em>
            ) : (
              <em>New users:</em>
            )}

            <TableContainer>
              <MuiTable>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell align="right" className={styles.tight}>
                      Created At
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(users.payload || []).map(user =>
                    <TableRow
                      hover={true}
                      key={user.id}
                      onClick={() => history.push(`/users/${user.id}`)}
                    >
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell align="right" className={styles.tight}>
                        {toDateTimeStr(user.createdAt)}
                      </TableCell>
                    </TableRow>
                  )}

                  {users.meta.status === LOADING && (
                    <TableRow>
                      <TableCell><Skeleton variant='text' /></TableCell>
                      <TableCell><Skeleton variant='text' /></TableCell>
                      <TableCell><Skeleton variant='text' /></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </MuiTable>

              {users.meta.isMoreAvailable && (
                <LoadMore
                  onClick={() => fetchUsers({ term })}
                />
              )}
            </TableContainer>
          </>
        )}

        {users.meta.status === READY
          && (users.payload || []).length === 0
          && (
          <Box
            fontStyle="italic"
            p={4}
            textAlign="center"
          >
            No results.
          </Box>
        )}
      </Box>

      {showDeleteSuccess && (
        <Snackbar
          autoHideDuration={10000}
          onClose={() => setShowDeleteSuccess(false)}
          open
        >
          <Alert severity="success" variant="filled">
            User deletion successful
          </Alert>
        </Snackbar>
      )}
    </div>
  );
}
