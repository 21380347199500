import React, { useRef } from 'react';
import DesignTags from '../DesignTags/DesignTags';
import { removeTagFromMyStagedCollection } from 'state/api/designTag/DesignTagActions';
import {
  Menu,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const MyStagedDesignCardMenu = ({
  anchorEl,
  designId,
  collectionId,
  keepMounted,
  open,
  onClose,
  onThumbnailOverride,
  onRevert,
  tagSuggestions,
}) => {
  const classes = useStyles();
  const fileUploadRef = useRef(null);

  return(
    <>
      <Menu
        anchorEl={anchorEl}
        classes={{ paper: classes.menuPaper }}
        keepMounted={keepMounted}
        open={open}
        onClose={onClose}
      >
        <MenuItem
          onClick={onRevert}
        >
          Revert Collection
        </MenuItem>
        <MenuItem onClick={() => fileUploadRef.current.click()}>
          Override Thumbnail
        </MenuItem>
        <DesignTags
          collectionId={collectionId}
          designId={designId}
          tagSuggestions={tagSuggestions}
          removeTagFunction={removeTagFromMyStagedCollection}
        />
      </Menu>
      <input
        accept='.png,.jpg,.jpeg,.mp4'
        id='raised-button-file'
        onChange={event => onThumbnailOverride(event.target.files[0])}
        ref={fileUploadRef}
        style={{ display: 'none' }}
        type='file'
      />
    </>
  );
};

const useStyles = makeStyles(theme => ({
  menuPaper: {
    width: '220px',
  },
}));

export default MyStagedDesignCardMenu;
