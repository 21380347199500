import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FAILED, READY } from 'lib/Status';
import { Grid, Menu, MenuItem, Snackbar } from '@material-ui/core';
import { removeTagFromMyDraftsCollection } from 'state/api/designTag/DesignTagActions';
import DesignCard from 'views/components/DesignCard/DesignCard';
import DesignTags from 'views/components/DesignTags/DesignTags';
import LoadMore from 'views/components/LoadMore/LoadMore';
import {
  fetchDraftCollectionsForCurrentUser,
  updateCollection,
  fetchCollectionRemixUrl,
} from 'state/api/assets/AssetApiActions';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  menuPaper: {
    width: '220px',
  },
}));

export default function MyDraftsContainer() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [collectionId, setCollectionId] = useState(null);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [copyLinkToClipboard, setCopyLinkToClipboard] = useState(false);
  const [remixNotification, setRemixNotification] = useState(false)
  const dispatch = useDispatch();
  const myDrafts = useSelector(state => state.entities.collections.myDrafts);
  const tagSuggestions = useSelector(state => state.entities.searchSuggestions);
  const remixUrls = useSelector(state => state.api.assets.collectionRemixUrls)
  const classes = useStyles();

  useEffect(() => {
    if (myDrafts === null) {
      dispatch(fetchDraftCollectionsForCurrentUser());
    }
  });

  if (myDrafts === null) {
    return null;
  }

  if (myDrafts.meta.status === FAILED) {
    return <>Failed to fetch drafts from API.</>;
  }

  if (myDrafts.meta.status !== READY) {
    return <>Loading...</>;
  }

  if (!myDrafts.payload.length) {
    return <>No drafts found.</>;
  }

  const handleCollectionRemixUrl = async ({collectionId}) => {
    let remixUrl;
    if(remixUrls[collectionId]) {
      remixUrl = remixUrls[collectionId];
    } else {
      const response = await dispatch(fetchCollectionRemixUrl(collectionId));
      remixUrl = response.payload.url;
    }
    navigator.clipboard.writeText(remixUrl);
    setCopyLinkToClipboard(true);
    setRemixNotification(true);
  };

  return (
    <>
      <h2>My Drafts</h2>

      <Grid container spacing={3}>
        {myDrafts.payload.map(collection => (
          collection.designs.map(design => (
            <Grid item key={design.id}>
              <DesignCard
                design={design}
                onDropDownClick={e => {
                  setAnchorEl(e.target);
                  setCollectionId(collection.id);
                  setSelectedDesign(design);
                }}
              />
            </Grid>
          ))
        ))}
      </Grid>
      {myDrafts.meta.isMoreAvailable && (
        <LoadMore onClick={() => dispatch(fetchDraftCollectionsForCurrentUser())} />
      )}
      <Menu
        classes={{ paper: classes.menuPaper }}
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setSelectedDesign(null);
        }}
      >
        <MenuItem
          onClick={() => {
            dispatch(updateCollection(collectionId, 'STAGED'));
            setAnchorEl(null);
          }}
        >
          Stage Collection
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleCollectionRemixUrl(selectedDesign);
          }}
        >
        Get Collection Remix URL
        </MenuItem>
        <DesignTags
          collectionId={collectionId}
          designId={(selectedDesign || {}).id}
          tagSuggestions={tagSuggestions}
          removeTagFunction={removeTagFromMyDraftsCollection}
        />
      </Menu>
      {copyLinkToClipboard && (
        <Snackbar
          autoHideDuration={8000}
          open
          onClose={() =>{ 
            setCopyLinkToClipboard(false)
            setRemixNotification(false)
          }}
        >
          <Alert
            severity="success"
            variant="filled"
          >
            {remixNotification ? 'Collection Remix link copied to clipboard' : 'Purchase Collection link copied to clipboard'}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
