import * as React from 'react';
import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  makeStyles
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "14px",
    height: '30px',
    width: '30px',
    padding: "5px",
    left: '-24px'
  }
}));
const PrintOptionEllipsis = ({
  setIsConfirming,
  setIsEditingPrintOption,
  setAddPriceDialog
} = {}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handleOpenConfirm = event => {
    setIsConfirming();
    handleClose(event);
  };

  const handleOpenEdit = event => {
    setIsEditingPrintOption();
    handleClose(event);
  }
  
  const handleOpenAddPrice = event => {
    setAddPriceDialog(true);
    handleClose(event);
  };
  return (
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          classes={{ root: classes.button }}
        >
          <MoreHorizIcon/>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    classes={{ root: classes.popout }}
                  >
                    <MenuItem onClick={handleOpenAddPrice}>Add Print Option Price</MenuItem>
                    <MenuItem onClick={handleOpenEdit}>Edit Print Option</MenuItem>
                    <MenuItem onClick={handleOpenConfirm}>Delete Print Option</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
  );
}
export default PrintOptionEllipsis;