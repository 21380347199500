import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTeams, fetchTeamSubteams } from 'state/api/teams/TeamApiActions';
import { teamsSelector, teamSubteamsSelector } from 'state/api/teams/TeamApiSelectors';
import { currentUserIdSelector, tokenSelector } from 'state/app/SessionSelectors';
import TeamList from 'views/components/Team/TeamList';

export default function TeamListContainer(props) {
  const accessToken = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const userId = useSelector(currentUserIdSelector);
  const teams = useSelector(teamsSelector);
  const teamSubteams = useSelector(teamSubteamsSelector);

  return (
    <TeamList
      fetchTeams={({ name }) => dispatch(
        fetchTeams({
          accessToken,
          name,
          paginate: teams || {},
        })
      )}
      fetchTeamSubteams={({ parentId, sortBy, sortOrder }) => dispatch(
        fetchTeamSubteams({
          accessToken,
          paginate: teamSubteams || {},
          parentId,
          sortBy,
          sortOrder,
          userId,
        })
      )}
      teams={teams}
      teamSubteams={teamSubteams}
      {...props}
    />
  );
}
