import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    paddingTop: theme.spacing(2),
    color: "red"
  }
}));

export default function AddPrintOptionDialog({
  createPrintOption,
  disabled,
  height,
  label,
  onClose,
  providerName,
  setSuccessActionString,
  size,
  width,
}) {
  const classes = useStyles();
  const [stock, setStock] = useState(null);
  const [sizeDescription, setSizeDescription] = useState(size || null);
  const [sizeWidth, setSizeWidth] = useState(width || null);
  const [sizeHeight, setSizeHeight] = useState(height || null);
  const [finishing, setFinishing] = useState(null);
  const [description, setDescription] = useState(null);
  const [minQuantity, setMinQuantity] = useState(null);
  const [price, setPrice] = useState(null);
  const [errors, setErrors] = useState({});
  const isAddDisabled = !(stock && price && minQuantity) || Object.values(errors).some(error => !!error);

  const handleOnBlur = event => {
    const inputName = event.target.name;
    const value = event.target.value;

    // set error if no value has been provided
    if (!value) {
      setErrors({ 
        ...errors,
        [inputName]: `Please provide a ${inputName}`
      });
      return;
    }

    // set error if negative integer is provided
    if (event.target.type === "number" && (Number(value) < 0)) {
      setErrors({ 
        ...errors,
        [inputName]: `Please provide a positive value for ${inputName}`
      });
      return;
    }
    // clear error
    setErrors({ 
      ...errors,
      [inputName]: ""
    });
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await createPrintOption({
      label,
      sizeDescription,
      stock,
      width: sizeWidth,
      height: sizeHeight,
      finishing,
      description,
      minQuantity,
      price
    });

    if (response.error) {
      setErrors({
        ...errors,
        response: "There was an issue processing your request. Contact devs for support."
      });
      return
    }
    
    onClose();
    setSuccessActionString("Print option successfully created");
  };

  const isDisabled = (input) => {
    return disabled.includes(input);
  }

  const handleOnChange = (e) => {
    let text
    switch(e.target.name) {
      case 'size':
        setSizeDescription(e.target.value); 
        setErrors({ ...errors, stock: "" })
        break;
      case 'width': 
        setSizeWidth(e.target.value);
        setErrors({ ...errors, stock: "" })
        break;
      case 'height': 
        setSizeHeight(e.target.value);
        setErrors({ ...errors, stock: "" })
        break;
      case 'stock': 
        setStock(e.target.value); 
        setErrors({ ...errors, stock: "" })
        break;
      case 'finishing':
        text = e.target.value;
        if (!text) text = null;
        setFinishing(text);
        break;
      case 'description':
        text = e.target.value;
        if (!text) text = null;
        setDescription(text);
        break;
      case 'minQuantity':
        setMinQuantity(e.target.value);
        setErrors({ ...errors, minQuantity: "" })
        break;
      case 'price':
        setPrice(e.target.value); 
        setErrors({ ...errors, price: "" })
        break;
      default: 
      return null;
    }
  }

  return (
    <Dialog aria-labelledby="form-dialog-title" onClose={onClose} open={true} maxWidth="xs">
      <DialogTitle id="form-dialog-title">
        Add Print Option for {providerName}
      </DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="dense"
          label="Size"
          name="size"
          value={sizeDescription}
          onChange={handleOnChange}
          variant="outlined"
          disabled={isDisabled('size')}
          onBlur={handleOnBlur}
          required
          error={!!errors.size}
          helperText={errors.size}
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="dense"
          label="Width (mm)"
          name='width'
          type="number"
          onChange={handleOnChange}
          value={sizeWidth}
          variant="outlined"
          disabled={isDisabled('width')}
          onBlur={handleOnBlur}
          required
          InputProps={{
            inputProps: { min: 0 }
          }}
          error={!!errors.width}
          helperText={errors.width}
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="dense"
          label="Height (mm)"
          name="height"
          type="number"
          onChange={handleOnChange}
          value={sizeHeight}
          variant="outlined"
          disabled={isDisabled('height')}
          onBlur={handleOnBlur}
          required
          InputProps={{
            inputProps: { min: 0 }
          }}
          error={!!errors.height}
          helperText={errors.height}
        />

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Stock (User Facing)"
          margin="dense"
          onChange={handleOnChange}
          required
          onBlur={handleOnBlur}
          name="stock"
          value={stock}
          variant="outlined"
          error={!!errors.stock}
          helperText={errors.stock}
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          name="finishing"
          label="Finishing (User Facing - Optional)"
          margin="dense"
          onChange={handleOnChange}
          value={finishing}
          variant="outlined"
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Print Description (Internal Use Only)"
          name="description"
          margin="dense"
          onChange={handleOnChange}
          value={description}
          variant="outlined"
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Min Quantity"
          margin="dense"
          onChange={handleOnChange}
          required
          onBlur={handleOnBlur}
          name="minQuantity"
          value={minQuantity}
          variant="outlined"
          type="number"
          error={!!errors.minQuantity}
          helperText={errors.minQuantity}
          InputProps={{
            inputProps: { min: 0 }
          }}
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Unit Price"
          margin="dense"
          onChange={handleOnChange}
          required
          onBlur={handleOnBlur}
          name="price"
          value={price}
          variant="outlined"
          type="number"
          error={!!errors.price}
          helperText={errors.price}
          InputProps={{
            inputProps: { min: 0 }
          }}
        />
        {errors.response && (
          <div className={classes.errorMessage}>{errors.response}</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={isAddDisabled}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}