import * as types from 'state/api/orders/OrdersApiTypes';
import { get as getPath } from 'lodash';

const initState = {};

export default function searchSuggestionsReducers(state = initState, action) {
  switch (action.type) {
    case types.TEMPLATE_SIZES_BY_ORDER_LABEL_SUCCESS: {
      const { 
        payload: templateSizes = []
      } = action;

      const updatedTemplateSizes = getPath(state, "templateSizes", {});

      templateSizes.forEach(templateSize => {
        if (!templateSize) return;
        updatedTemplateSizes[templateSize.templateCode] = templateSize;
      });

      return {
        ...state,
        ...updatedTemplateSizes
      };
    }

    default: {
      return state;
    }
  }
}
