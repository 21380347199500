import { reduceAction } from 'lib/apiMiddlewareUtils';
import * as types from 'state/api/assets/AssetApiTypes';
import { cloneDeep, omit } from "lodash";
import moment from "moment";

const initState = {
  catalogue: null,
  myDrafts: null,
  myStaged: null,
  stagedDesigns: null,
};

export default function CollectionsReducers(state = initState, action) {
  switch (action.type) {
    case types.ASSET_CATALOGUE_FAILURE:
    case types.ASSET_CATALOGUE_REQUEST:
    case types.ASSET_CATALOGUE_SUCCESS:
    case types.ASSET_DESIGNS_FAILURE:
    case types.ASSET_DESIGNS_REQUEST:
    case types.ASSET_DESIGNS_SUCCESS: {
      return { ...state, catalogue: reduceAction(state.catalogue, action) };
    }

    case types.ASSET_COLLECTIONS_STAGED_FAILURE:
    case types.ASSET_COLLECTIONS_STAGED_REQUEST:
    case types.ASSET_COLLECTIONS_STAGED_SUCCESS: {
      return { ...state, stagedDesigns: reduceAction(state.stagedDesigns, action) };
    }

    case types.ASSET_USER_COLLECTIONS_DRAFTS_FAILURE:
    case types.ASSET_USER_COLLECTIONS_DRAFTS_REQUEST:
    case types.ASSET_USER_COLLECTIONS_DRAFTS_SUCCESS: {
      return { ...state, myDrafts: reduceAction(state.myDrafts, action) };
    }

    case types.ASSET_USER_COLLECTIONS_STAGED_FAILURE:
    case types.ASSET_USER_COLLECTIONS_STAGED_REQUEST:
    case types.ASSET_USER_COLLECTIONS_STAGED_SUCCESS: {
      return { ...state, myStaged: reduceAction(state.myStaged, action) };
    }

    case types.ASSET_UPDATE_COLLECTION_SUBSCRIPTION_SUCCESS: {
      const { 
        catalogue,
        myDrafts,
        myStaged,
        stagedDesigns
      } = cloneDeep(state);

      // apply the updates to the designs in the payload so we don't have to refetch the designs just to update one thing
      const applyDesignUpdates = designSource => {
        designSource.payload.forEach((stateDesign, index) => {
          // go through the design updates and apply matching updates
          const designUpdate = omit(action.payload, "method");

          if (stateDesign.collectionId === designUpdate.collectionId) {
            designSource.payload[index] = {
              ...stateDesign,
              ...designUpdate
            };
          }
        });
      }

      const applyCollectionDelete = designSource => {
        designSource.payload = designSource.payload.filter(stateDesign => stateDesign.collectionId !== action.payload.collectionId);
      }

      let applicationFunction = () => {};

      if (action.payload && action.payload.collectionId && action.payload.method === "PUT") {
        applicationFunction = applyDesignUpdates;
      } else if (action.payload && action.payload.collectionId && action.payload.method === "DELETE") {
        applicationFunction = applyCollectionDelete;
      }

      [catalogue, myDrafts, myStaged, stagedDesigns].filter(x => x).forEach(designSource => {
        // map through the designs in all sources and update them if needed
        applicationFunction(designSource);

        // sort the designs by priority and break any ties with updatedAt sorting
        designSource.payload.sort((designA, designB) => {
          const prioritySortValue = designA.priority - designB.priority;
          if (prioritySortValue !== 0) {
            return prioritySortValue;
          }
          // when priorities match we should sort by updatedAt
          return moment.utc(designA.updatedAt).isBefore(moment.utc(designB.updatedAt)) ? 1 : -1;
        });
      });

      return {
        ...state,
        catalogue,
        myDrafts,
        myStaged,
        stagedDesigns,
      };
    }

    case types.ASSET_UPDATE_COLLECTION_SUCCESS: {
      let { 
        catalogue,
        myDrafts,
        myStaged,
        stagedDesigns
      } = cloneDeep(state);

      const { status, collectionId } = action.payload;

      // get all collections in state
      const allCollections = [catalogue, myDrafts, myStaged, stagedDesigns].filter(x => x).reduce((joinedCollectionSources, designSource) => [...joinedCollectionSources, ...designSource.payload], []);

      // find the current version of the collection we are changing
      const currentVersionOfCollection = allCollections.find(collection => collection.id === collectionId);

      [catalogue, myDrafts, myStaged, stagedDesigns].filter(x => x).forEach(designSource => {
        // map through the designs in all sources and update them if needed
        designSource.payload.forEach((stateCollection, index) => {
          if (stateCollection.id === collectionId) {
            designSource.payload[index] = null;
          }
        });
      });

      switch(status) {
        case "DRAFT": {
          if (myDrafts && myDrafts.payload) {
            myDrafts.payload.push({ ...currentVersionOfCollection, status });
          }
          break;
        }
        case "STAGED": {
          if (myStaged && myStaged.payload) {
            myStaged.payload.push({ ...currentVersionOfCollection, status });
          }
          if (stagedDesigns && stagedDesigns.payload) {
            stagedDesigns.payload.push({ ...currentVersionOfCollection, status });
          }
          break;
        }
        default: {
          break;
        }
      }

      if (catalogue) {
        catalogue.payload = catalogue.payload.filter(x => x);
        if (catalogue.payload.length === 0) {
          catalogue = null;
        }
      }
      if (myDrafts) {
        myDrafts.payload = myDrafts.payload.filter(x => x);
        if (myDrafts.payload.length === 0) {
          myDrafts = null;
        }
      }
      if (myStaged) {
        myStaged.payload = myStaged.payload.filter(x => x);
        if (myStaged.payload.length === 0) {
          myStaged = null;
        }
      }
      if (stagedDesigns) {
        stagedDesigns.payload = stagedDesigns.payload.filter(x => x);
        if (stagedDesigns.payload.length === 0) {
          stagedDesigns = null;
        }
      }

      return {
        ...state,
        catalogue,
        myDrafts,
        myStaged,
        stagedDesigns,
      };
    }

    case types.ASSET_UPDATE_DESIGN_THUMBNAIL_FILE_SUCCESS: {
      const { payload: design } = action;

      const updateDesignInSection = (section) => {
        if (!state[section]) return null;
        const payload = state[section].payload.map(collection => {
          if (collection.id === design.collectionId) {
            collection.designs = collection.designs.map(collectionDesign => {
              if (collectionDesign.id !== design.id) {
                return collectionDesign;
              }
              return design;
            });
          }
          return collection;
        });

        return {
          ...state[section],
          payload,
        }
      };

      return {
        ...state,
        myStaged: updateDesignInSection('myStaged'),
        stagedDesigns: updateDesignInSection('stagedDesigns'),
      };
    }

    default: {
      return state;
    }
  }
}
