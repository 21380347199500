import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    paddingTop: theme.spacing(2),
    color: "red"
  }
}));

export default function AddPrintOptionDialog({
  printProvider,
  updatePrintProvider,
  onClose,
  setSelectedProvider,
  setSuccessActionString,
  deletePrintProvider
}) {
  
  const classes = useStyles();
  const [updatedPrintProvider, setUpdatedPrintProvider] = useState(printProvider);
  const [errors, setErrors] = useState({});
  const isUpdateDisabled = !(updatedPrintProvider.name && updatedPrintProvider.country) || Object.values(errors).some(error => !!error);
  
  const handleOnBlur = event => {
    const inputName = event.target.name;
    const value = event.target.value;

    // set error if no value has been provided
    if (!value) {
      setErrors({ 
        ...errors,
        [inputName]: `Please provide a ${inputName}`
      });
      return;
    }
    // clear error
    setErrors({ 
      ...errors,
      [inputName]: ""
    });
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    let printProvider = {
      ...updatedPrintProvider,
      updatedAt: new Date()
    };
    
    // perform separate delete action when active status is toggled off
    let deleteProviderResponse, response;
    if (printProvider.hasOwnProperty("active") && !printProvider.active) {
      deleteProviderResponse = await deletePrintProvider({ providerId: printProvider.id });
      printProvider = null;
    } else {
      response = await updatePrintProvider({ printProvider });
    }
    
    if ((response && response.error) || (deleteProviderResponse && deleteProviderResponse.error)) {
      setErrors({
        ...errors,
        response: "There was an issue processing your request. Contact devs for support."
      });
      return
    }

    setSelectedProvider(printProvider);
    setSuccessActionString("Print Provider successfully updated");
    onClose();
  };
  
  if (!printProvider || isEmpty(printProvider)) return null;
  
  const { name, country, active } = updatedPrintProvider;
  
  return (
    <Dialog aria-labelledby="form-dialog-title" onClose={onClose} open={true} maxWidth="xs">
      <DialogTitle id="form-dialog-title">
        Update Print Provider
      </DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Name"
          margin="dense"
          onChange={e => {
            setUpdatedPrintProvider({
              ...updatedPrintProvider,
              name: e.target.value
            }); 
            setErrors({ ...errors, name: "" })
          }}
          required
          onBlur={e => handleOnBlur(e)}
          name="name"
          value={name}
          variant="outlined"
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Country"
          margin="dense"
          onChange={e => {
            setUpdatedPrintProvider({
              ...updatedPrintProvider,
              country: e.target.value
            }); 
            setErrors({ ...errors, country: "" })
          }}
          required
          onBlur={e => handleOnBlur(e)}
          name="country"
          value={country}
          variant="outlined"
          error={!!errors.country}
          helperText={errors.country}
        />
        <FormControlLabel 
          control={
            <Switch
              checked={active}
              onChange={() => setUpdatedPrintProvider({
                ...updatedPrintProvider,
                active: !updatedPrintProvider.active
              })}
            />
          } 
          label="Active" 
        />
        {errors.response && (
          <div className={classes.errorMessage}>{errors.response}</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={e => handleSubmit(e)}
          disabled={isUpdateDisabled}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}