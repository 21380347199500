import uploadToS3 from 'lib/uploadToS3';
import { createSubscriptionZipMedia } from 'state/api/assets-media/AssetMediaApiActions';

export default async (event, {
  accessToken,
  dispatch,
  categories,
  history,
  referenceId,
  zipFile,
}) => {
  const zipMedia = await dispatch(createSubscriptionZipMedia({
    accessToken,
    categories,
    filename: zipFile.name,
    referenceId,
    responseContentType: zipFile.type,
  }));

  await uploadToS3(zipMedia.payload.url, zipFile);

  history.replace(`/media?category=${encodeURIComponent(categories[0])}`);
};
