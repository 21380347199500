import React from 'react';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import MediaUploadBatch from './MediaUploadBatch';
import MediaUploadIndividual from './MediaUploadIndividual';
import { Paper } from '@material-ui/core';

export const MediaUpload = ({
  history,
  location,
  onSubmitBatch,
  onSubmitIndividual,
  urlCategory,
}) => (
  <>
    <h2>Upload Media</h2>

    <Box component={Paper} pb={4} pt={1} px={4}>
      <Box py={2}>
        <RadioGroup
          onChange={(event) => history.replace(event.target.value)}
          value={location.pathname}
        >
          <FormControlLabel
            control={<Radio />}
            label="Batch"
            value="/media/upload/batch"
          />
          <FormControlLabel
            control={<Radio />}
            label="Individual"
            value="/media/upload"
          />
        </RadioGroup>
      </Box>

      {location.pathname === '/media/upload/batch' && (
        <MediaUploadBatch
          onSubmit={onSubmitBatch}
          urlCategory={urlCategory}
        />
      )}
      {location.pathname === '/media/upload' && (
        <MediaUploadIndividual
          onSubmit={onSubmitIndividual}
          urlCategory={urlCategory}
        />
      )}
    </Box>
  </>
);

export default MediaUpload;
