import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FAILED, LOADING, READY } from 'lib/Status';
import { makeStyles } from '@material-ui/core';
import { toDateTimeStr } from 'lib/dateUtil';
import Box from '@material-ui/core/Box';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import getTeamType from 'lib/getTeamType';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import LoadMore from 'views/components/LoadMore/LoadMore';
import MuiTable from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { CLEAR_TEAM_CATALOGUE } from 'state/api/assets/AssetApiTypes';

const EASIL_TEAM_ID = process.env.REACT_APP_EASIL_TEAM_ID;

const useStyles = makeStyles({
  tight: {
    whiteSpace: 'nowrap',
    width: '1%',
  }
});

export default function TeamList({
  fetchTeams,
  fetchTeamSubteams,
  history,
  location,
  teams,
  teamSubteams,
}) {
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const styles = useStyles();
  const name = params.get('name');
  const data = name ? teams : teamSubteams;
  const [input, setInput] = useState(name || '');

  useEffect(() => {
    if (name) {
      if (teams === null
        || teams.meta.name !== name
      ) {
        fetchTeams({ name });
      }
    } else {
      if (teamSubteams === null
        || teamSubteams.meta.parentId !== EASIL_TEAM_ID
      ) {
        fetchTeamSubteams({
          parentId: EASIL_TEAM_ID,
          sortBy: 'createdAt',
          sortOrder: 'desc',
        });
      }
    }
  });

  if (!data || data.meta.status === FAILED) {
    return null;
  }

  return (
    <div>
      <h2>Teams</h2>
  
      <Paper
        component="form"
        onSubmit={(event) => {
          event.preventDefault();
          const encodedname = encodeURIComponent(input);
          history.replace(`/teams?name=${encodedname}`);
        }}
      >
        <Box
          display="flex"
          mb={2}
          pl={2}
        >
          <InputBase
            placeholder="Search Teams"
            fullWidth={true}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === 'Backspace'
                && event.target.value.length > 0
                && (name || '').length
              ) {
                setInput('');
                history.replace('/teams');
              }
            }}
            value={input}
          />
          {!!input && (
            <>
              <IconButton
                onClick={() => {
                  setInput('');
                  history.replace('/teams');
                }}
              >
                <ClearIcon />
              </IconButton>
              <Divider
                orientation="vertical"
                style={{ height: '28px', marginTop: '10px' }}
              />
            </>
          )}
          <IconButton type="submit">
            <SearchIcon />
          </IconButton>
        </Box>
      </Paper>
  
      <Box
        component={Paper}
        padding={2}
      >

        {(data.meta.status === LOADING
          || (data.meta.status === READY
            && (data.payload || []).length > 0)
          )
          && (
          <>
            {name ? (
              <em>Results for "<strong>{name}</strong>":</em>
            ) : (
              <em>Easil sub-teams:</em>
            )}

            <TableContainer>
              <MuiTable>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell className={styles.tight}>
                      Type
                    </TableCell>
                    <TableCell align="right" className={styles.tight}>
                      Created At
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(data.payload || []).map(team =>
                    <TableRow
                      hover={true}
                      key={team.id}
                      onClick={() => {
                        history.push(`/teams/${team.id}`);
                        dispatch({ type: CLEAR_TEAM_CATALOGUE });
                      }}
                    >
                      <TableCell>{team.name}</TableCell>
                      <TableCell className={styles.tight}>
                        {getTeamType(team)}
                      </TableCell>
                      <TableCell align="right" className={styles.tight}>
                        {toDateTimeStr(team.createdAt)}
                      </TableCell>
                    </TableRow>
                  )}

                  {data.meta.status === LOADING && (
                    <TableRow>
                      <TableCell><Skeleton variant='text' /></TableCell>
                      <TableCell><Skeleton variant='text' /></TableCell>
                      <TableCell><Skeleton variant='text' /></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </MuiTable>

              {data.meta.isMoreAvailable && (
                <LoadMore
                  onClick={() => {
                    if (name) {
                      fetchTeams({ name });
                    } else {
                      fetchTeamSubteams({
                        parentId: EASIL_TEAM_ID,
                        sortBy: 'createdAt',
                        sortOrder: 'desc',
                      });
                    }
                  }}
                />
              )}
            </TableContainer>
          </>
        )}

        {data.meta.status === READY
          && (data.payload || []).length === 0
          && (
          <Box
            fontStyle="italic"
            p={4}
            textAlign="center"
          >
            No results.
          </Box>
        )}
      </Box>
    </div>
  );
}
