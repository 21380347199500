import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import EditIcon from '@material-ui/icons/Edit';
import getTeamType from 'lib/getTeamType';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { toDateStr } from 'lib/dateUtil';

const useStyles = makeStyles((theme) => ({
  detailsContainer: {
    backgroundColor: '#f7fafc',
    padding: theme.spacing(2),
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  headingLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginRight: theme.spacing(2),
  },
}));

export default function TeamDetail({
  fetchTeam,
  onEditClick,
  team,
  teamId,
}) {
  const classes = useStyles();

  if (!team
    || team.error
    || !team.payload
    || !team.meta
    || team.meta.teamId !== teamId
  ) {
    return null;
  }

  return (
    <Paper>
      <div className={classes.headingContainer}>
        <div className={classes.headingLeft}>
          <Avatar className={classes.logo} src={team.payload.logoUrl} />
          <Typography inline='true' variant="h6">{team.payload.name}</Typography>
        </div>
        <Button onClick={onEditClick} startIcon={<EditIcon />} variant="outlined">
          Edit Details
        </Button>
      </div>
      <Divider />
      <div className={classes.detailsContainer}>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="subtitle1">Country</Typography>
            <Typography variant="body2">{team.payload.country}</Typography>
          </Grid>
          <Grid item >
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">Type</Typography>
            <Typography variant="body2">
              {getTeamType(team.payload)}
            </Typography>
          </Grid>
          <Grid item >
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">Sub Teams</Typography>
            <Typography variant="body2">{team.payload.subTeamCount}</Typography>
          </Grid>
          <Grid item >
            <Divider orientation="vertical" />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">Created</Typography>
            <Typography variant="body2">{toDateStr(team.payload.createdAt)}</Typography>
          </Grid>
        </Grid>
      </div>
    </Paper>
  );
}
