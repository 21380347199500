import { decode } from "jsonwebtoken";

export const jwtDecoder = token => {
  const {
    team_id: teamId,
    sub: userId,
    roles,
    name,
    email,
    team_plan: teamPlan
  } = decode(token);

  return {
    teamId,
    userId,
    roles,
    name,
    email,
    teamPlan
  };
};