import ReduxThunk from 'redux-thunk';
import { configureStore } from '@reduxjs/toolkit'
import { createMiddleware } from 'redux-api-middleware';
import { default as rootReducer } from 'state';
import { getItem } from 'lib/storageHandler';
import { jwtDecoder } from 'lib/tokenParser';

const apiMiddleware = createMiddleware();

const preloadedState = () => {
  // Session end is stored as the expiration date in seconds from epoch
  let sessionEndDate = null;
  const sessionEnd = getItem('sessionEnd') || null;
  if (sessionEnd) {
    sessionEndDate = Date.parse(sessionEnd);
  }

  const authToken = getItem('token') || null;
  let tokenInfo = {};
  if (authToken) {
    tokenInfo = jwtDecoder(authToken);
  }

  return {
    app: {
      session: {
        token: getItem('token') || null,
        sessionEnd: sessionEndDate,
        ...tokenInfo,
      }
    }
  }
}

const store = configureStore({
  reducer: rootReducer,
  middleware: [ReduxThunk, apiMiddleware],
  preloadedState: preloadedState(),
  devTools: {
    serialize: true
  }
});

export default store;