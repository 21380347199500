import { reduceAction } from 'lib/apiMiddlewareUtils';
import * as types from 'state/api/subscriptions/SubscriptionApiTypes';

const initState = {
  subscriptionPlans: null,
  teamSubscription: null,
  subscriptionCoupons: null,
};

export default function SubscriptionApiReducers(state = initState, action) {
  switch (action.type) {
    case types.CONVERT_ACC_TO_SUBSCRIPTION_FAILURE:
    case types.CONVERT_ACC_TO_SUBSCRIPTION_REQUEST:
    case types.CONVERT_ACC_TO_SUBSCRIPTION_SUCCESS:
    case types.CONVERT_TO_ACC_SUCCESS:
    case types.CREATE_TRIAL_SUCCESS:
    case types.UPDATE_SUCCESS: {
      return { ...state, teamSubscription: reduceAction(state.teamSubscription, action) };
    }

    case types.SUBSCRIPTION_FAILURE:
    case types.SUBSCRIPTION_REQUEST:
    case types.SUBSCRIPTION_SUCCESS: {
      return { ...state, teamSubscription: reduceAction(state.teamSubscription, action) };
    }

    case types.SUBSCRIPTION_PLANS_REQUEST:
    case types.SUBSCRIPTION_PLANS_FAILURE:
    case types.SUBSCRIPTION_PLANS_SUCCESS: {
      return {
        ...state,
        subscriptionPlans: reduceAction(state.subscriptionPlans, action),
      };
    }

    case types.SUBSCRIPTION_COUPONS_REQUEST:
    case types.SUBSCRIPTION_COUPONS_FAILURE:
    case types.SUBSCRIPTION_COUPONS_SUCCESS: {
      return {
        ...state,
        subscriptionCoupons: reduceAction(state.subscriptionCoupons, action),
      };
    }

    case types.CREATE_SUBSCRIPTION_COUPON_SUCCESS:
    case types.UPDATE_SUBSCRIPTION_COUPON_SUCCESS: {
      return {
        ...state,
        subscriptionCoupons: null,
      };
    }

    default:
      return state;
  }
}
