import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const UserDeleteDialog = ({
  handleSubmit,
  isOpen,
  onClose,
  heading,
  content,
  GPDRError
}) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onClose}
      >
        <DialogTitle id="alert-dialog-title">
          {heading}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
          {GPDRError && (
              <span style={{ color: "red", marginBottom: "0px" }}>{GPDRError}</span>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>Cancel</Button>
          <Button color="primary" onClick={handleSubmit} autoFocus disabled={GPDRError}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserDeleteDialog;