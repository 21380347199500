import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Collapse from '@material-ui/core/Collapse';
import DesignCard from 'views/components/DesignCard/DesignCard';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import Loading from 'views/components/Loading/Loading';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import LoadMore from 'views/components/LoadMore/LoadMore';
import TeamFolders from '../Team/TeamFolders';
import Typography from '@material-ui/core/Typography';
import usePrevious from 'lib/usePrevious';
import { fetchCollectionRemixUrl } from 'state/api/assets/AssetApiActions';

const useStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(1, 1, 1, 2),
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  emtpyHeadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  headingDivider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  breadcrumbs: {
    cursor: 'pointer'
  },
  menuPaper: {
    width: '350px',
  },
  foldersWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "0px auto 24px",
  },
}));

export default function UserWorkspaceDrafts({
  fetchUserTeamDrafts,
  fetchUserTeamDraftsSizes,
  fetchUserWorkspaceFolders,
  fetchUserWorkspaceFolderSizes,
  userDrafts,
  userDraftsSizes,
  userId,
  userTeamWorkspaceFolders,
  userWorkspaceFoldersSizes,
  remixUrls,
  openRow,
  team
}) {
  const [userDraftSize, setUserDraftSize] = useState({ name: "All Designs", templateCode: "All Designs" });
  const [userDraftFolderSize, setUserDraftFolderSize] = useState({ name: "All Designs", templateCode: "All Designs" });
  const [currentFolder, setCurrentFolder] = useState(null);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [copyIdToClipboard, setCopyIdToClipboard] = useState(false);
  const [designIdCopy, setDesignIdCopy] = useState(false);
  const [copyCollectionLinkToClipboard, setCopyCollectionLinkToClipboard] = useState(false);
  const [copyDesignLinkToClipboard, setCopyDesignLinkToClipboard] = useState(false);
  const [menuEl, setMenuEl] = useState(null);
  const dispatch = useDispatch();

  const classes = useStyles();

  const prevUserDraftSize = usePrevious(userDraftSize)
  const prevUserFolderDraftSize = usePrevious(userDraftFolderSize)
  const prevCurrentFolder = usePrevious(currentFolder);


  const { REACT_APP_WEB_APP_HOST } = process.env;
  const teamId = team.id

  const handleMoveIntoFolder = (folderId) => {
    setCurrentFolder(folderId)
    setUserDraftFolderSize({ name: "All Designs", templateCode: "All Designs" })
    fetchUserTeamDrafts({ teamId: team.id, userId, folderId, size: userDraftSize.templateCode });
    fetchUserWorkspaceFolderSizes({ teamId: team.id, userId, folderId });
  }

  useEffect(() => {
    if((!currentFolder && userDraftSize && userDraftSize !== prevUserDraftSize) || (prevCurrentFolder && prevCurrentFolder !== currentFolder)) { 
      fetchUserTeamDrafts({ teamId: team.id, userId, size: userDraftSize.templateCode });
    }
    fetchUserTeamDraftsSizes({ teamId: team.id, userId });
    fetchUserWorkspaceFolders({ teamId: team.id, userId });
    setCurrentFolder(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDraftSize])

  useEffect(() => {
    if(
      currentFolder &&
      userDrafts[teamId].hasOwnProperty("folders") &&
      userDrafts[teamId].folders.hasOwnProperty(currentFolder) &&
      !userDrafts[teamId].folders[currentFolder].hasOwnProperty(userDraftFolderSize.templateCode) &&
      userWorkspaceFoldersSizes[teamId][currentFolder].hasOwnProperty &&
      userDraftFolderSize && userDraftFolderSize !== prevUserFolderDraftSize
    )
    fetchUserTeamDrafts({ teamId: team.id, userId, folderId: currentFolder, size: userDraftFolderSize.templateCode });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDraftFolderSize])

  const getFolderName = (folderId) => {
    const folder = userTeamWorkspaceFolders[teamId].find(folder => folder.id === folderId);
    return folder.name
  }

  const handleCollectionRemixUrl = async ({ collectionId }) => {
    let remixUrl;
    if (remixUrls[collectionId]) {
      remixUrl = remixUrls[collectionId];
    } else {
      const response = await dispatch(fetchCollectionRemixUrl(collectionId));
      remixUrl = response.payload.url;
    }
    navigator.clipboard.writeText(remixUrl);
    setCopyCollectionLinkToClipboard(true);
  };

  const handleDesignRemixUrl = async (designId) => {
    const DESIGN_REMIX_URL = selectedDesign ? `${REACT_APP_WEB_APP_HOST}/mix/${designId}` : "";
    navigator.clipboard.writeText(DESIGN_REMIX_URL);
    setCopyDesignLinkToClipboard(true);
  };

  let designs = [];
  let sizes = [];

  if (!currentFolder && userDrafts && userDrafts.hasOwnProperty(teamId) && userDrafts[teamId].hasOwnProperty(userDraftSize.templateCode)) {
    designs = userDrafts[teamId][userDraftSize.templateCode].designs;
  } else if (
    currentFolder &&
    userDrafts[teamId].hasOwnProperty('folders') &&
    userDrafts[teamId].folders.hasOwnProperty(currentFolder) &&
    userDrafts[teamId].folders[currentFolder].hasOwnProperty(userDraftFolderSize.templateCode) &&
    userDrafts[teamId].folders[currentFolder][userDraftFolderSize.templateCode].hasOwnProperty("designs") &&
    userDrafts[teamId].folders[currentFolder][userDraftFolderSize.templateCode].designs &&
    userDrafts[teamId].folders[currentFolder][userDraftFolderSize.templateCode].designs.length
  ) {
    designs = userDrafts[teamId].folders[currentFolder][userDraftFolderSize.templateCode].designs;
  } else {
    designs = [];
  }

  let filteredDesigns;
  if ((!currentFolder && userDraftSize.name === "All Designs") || (currentFolder && userDraftFolderSize.name === "All Designs")) {
    filteredDesigns = designs
  } else {
    filteredDesigns = designs.filter(design => {
      let matching;
      if (currentFolder) {
        matching = design.templateSize === userDraftFolderSize.name
      } else {
        matching = design.templateSize === userDraftSize.name
      }
      return matching;
    });
  }

  if (userDraftsSizes) {
    if (currentFolder &&
      userWorkspaceFoldersSizes &&
      userWorkspaceFoldersSizes.hasOwnProperty(teamId)
    ) {
      sizes = userWorkspaceFoldersSizes[teamId][currentFolder];
    } else {
      sizes = userDraftsSizes[team.id];
    }
  }

  const currentSize = currentFolder ? userDraftFolderSize.name : userDraftSize.name;

  if ((!designs.length || !designs) && !userDrafts?.isFetching) {
    return (
      <TableRow>
        <TableCell colSpan={4}>
          <Collapse in={openRow === team.id} timeout="auto" unmountOnExit>
            <div className={classes.headingContainer}>
              <div className={classes.headingLeft}>
                <Typography variant="h6">
                  User Workspace Drafts
                </Typography>
                {currentFolder && userWorkspaceFoldersSizes && (
                  <>
                    <Divider orientation="vertical" flexItem className={classes.headingDivider} />
                    <Breadcrumbs separator="›">
                      <div color="inherit" onClick={() => { setCurrentFolder(null) }} className={classes.breadcrumbs}>
                        Catalogue
                      </div>
                      <Typography color="textPrimary">{getFolderName(currentFolder)}</Typography>
                    </Breadcrumbs>
                  </>
                )}
              </div>
              {sizes && userDraftsSizes && userDraftsSizes[teamId] && (
                <Select
                  autoWidth={true}
                  className={classes.select}
                  label={currentSize}
                  onChange={(event) => {
                    if (currentFolder) {
                      const newSize = userDraftsSizes[teamId].find((size) => size.name === event.target.value)
                      setUserDraftFolderSize(newSize)
                    } else {
                      const newSize = userDraftsSizes[teamId].find((size) => size.name === event.target.value)
                      setUserDraftSize(newSize)
                    }
                  }}
                  value={currentSize}
                >
                  {sizes.map((size) => (
                    <MenuItem key={size.name} value={size.name}>
                      {size.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
            <Box fontStyle="italic" textAlign="center">
              No User Workspace designs
            </Box>
            {userDrafts?.isFetching && (
              <Box fontStyle="italic" p={6} textAlign="center">
                <Loading />
              </Box>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={openRow === team.id} timeout="auto" unmountOnExit>
            <div className={classes.headingContainer}>
              <div className={classes.headingLeft}>
                <Typography variant="h6">
                  User Workspace Drafts
                </Typography>
                {currentFolder && userWorkspaceFoldersSizes && (
                  <>
                    <Divider orientation="vertical" flexItem className={classes.headingDivider} />
                    <Breadcrumbs separator="›">
                      <div color="inherit" onClick={() => { setCurrentFolder(null) }} className={classes.breadcrumbs}>
                        Catalogue
                      </div>
                      <Typography color="textPrimary">{getFolderName(currentFolder)}</Typography>
                    </Breadcrumbs>
                  </>
                )}
              </div>
              {sizes && userDraftsSizes && userDraftsSizes[teamId] && (
                <Select
                  autoWidth={true}
                  className={classes.select}
                  label={currentSize}
                  onChange={(event) => {
                    if (currentFolder) {
                      const newSize = userDraftsSizes[teamId].find((size) => size.name === event.target.value)
                      setUserDraftFolderSize(newSize)
                    } else {
                      const newSize = userDraftsSizes[teamId].find((size) => size.name === event.target.value)
                      setUserDraftSize(newSize)
                    }
                  }}
                  value={currentSize}
                >
                  {sizes.map((size) => (
                    <MenuItem key={size.name} value={size.name}>
                      {size.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </div>
            <Box p={2}>
              {(userTeamWorkspaceFolders && userTeamWorkspaceFolders[team.id] && Object.keys(userTeamWorkspaceFolders[team.id].length) !== 0 && !currentFolder) &&
                <TeamFolders
                  className={classes.foldersWrapper}
                  folders={userTeamWorkspaceFolders[team.id]}
                  handleMoveIntoFolder={handleMoveIntoFolder}
                />
              }
              <Grid container spacing={3}>
                {filteredDesigns.map(design => {
                  return (
                    <Grid item key={design.id}>
                      <DesignCard
                        design={design}
                        onDropDownClick={e => {
                          setMenuEl(e.target);
                          setSelectedDesign(design);
                        }}
                      />
                    </Grid>
                  )
                }
                )
                }
                {selectedDesign && menuEl && (
                  <Menu
                    anchorEl={menuEl}
                    classes={{ paper: classes.menuPaper }}
                    open
                    onClose={() => setMenuEl(null)}
                  >
                    <MenuItem
                      onClick={() => {
                        navigator.clipboard.writeText(selectedDesign.id);
                        setCopyIdToClipboard(true);
                      }}
                    >
                      Copy Design ID:
                      <br />
                      {selectedDesign.id}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigator.clipboard.writeText(selectedDesign.collectionId);
                        setCopyIdToClipboard(true);
                      }}
                    >
                      Copy Collection ID:
                      <br />
                      {selectedDesign.collectionId}
                    </MenuItem>
                    <MenuItem onClick={() => handleCollectionRemixUrl(selectedDesign)}>
                      Get Collection Remix URL
                    </MenuItem>
                    <MenuItem onClick={() => handleDesignRemixUrl(selectedDesign.id)}>
                      Get Design Remix URL
                    </MenuItem>
                  </Menu>
                )}
              </Grid>
              {userDrafts?.isFetching && (
              <Box fontStyle="italic" p={6} textAlign="center">
                <Loading />
              </Box>
            )}
              {userDrafts.hasOwnProperty(teamId) && userDrafts[teamId][userDraftSize.templateCode]?.meta.isMoreAvailable && (
                <LoadMore
                  onClick={() => {
                    if (currentFolder) {
                      fetchUserTeamDrafts({ teamId: team.id, userId, folderId: currentFolder, size: userDraftFolderSize.templateCode });
                    } else {
                      fetchUserTeamDrafts({ teamId: team.id, userId, size: userDraftSize.templateCode })
                    }
                  }}
                />
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {copyIdToClipboard && (
        <Snackbar
          autoHideDuration={8000}
          open
          onClose={() => {
            setCopyIdToClipboard(false)
            setDesignIdCopy(false)
          }}
        >
          <Alert
            severity="success"
            variant="filled"
          >
            {designIdCopy ? 'designId copied to clipboard' : 'collectionId link copied to clipboard'}
          </Alert>
        </Snackbar>
      )}
      {(copyDesignLinkToClipboard || copyCollectionLinkToClipboard) && (
        <Snackbar
          autoHideDuration={8000}
          open
          onClose={() => {
            setCopyDesignLinkToClipboard(false)
            setCopyCollectionLinkToClipboard(false)
          }}
        >
          <Alert
            severity="success"
            variant="filled"
          >
            {copyDesignLinkToClipboard ? 'Design Remix link copied to clipboard' : 'Collection Remix link copied to clipboard'}
          </Alert>
        </Snackbar>
      )}
    </>
  )
}
