export default async (signedUrl, file) => {
  const response = await fetch(signedUrl, {
    body: file,
    headers: { Accept: 'application/json' },
    method: 'PUT',
  });

  if (!response.ok) {
    throw new Error('S3 file upload failed.');
  }

  return response;
};
