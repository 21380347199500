import * as types from 'state/api/common/CommonApiTypes';
import { logout } from 'state/app/SessionActions';

const initState = {
  errorAction: null,
};

export default function CommonApiReducers(state = initState, action) {
  if (action.type === types.CLEAR_API_ERROR) {
    return { ...state, errorAction: null };
  }
  
  if (action.error) {
    if (action.payload && action.payload.status === 401) {
      logout({ href: window.location.href });
    }

    return { ...state, errorAction: action };
  }

  return state;
}
