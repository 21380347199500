/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import CatalogueCard from 'views/components/CatalogueCard/CatalogueCard';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Snackbar from '@material-ui/core/Snackbar';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import LoadMore from 'views/components/LoadMore/LoadMore';
import Loading from 'views/components/Loading/Loading'
import { useHistory, useLocation } from 'react-router-dom';
import TeamFolders from './TeamFolders';
import { fetchCollectionRemixUrl } from 'state/api/assets/AssetApiActions';

const useStyles = makeStyles((theme) => ({
  select: {
    padding: theme.spacing(1, 1, 1, 2),
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  headingLeft: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuPaper: {
    width: '350px',
  },
  designContainer: {
    padding: theme.spacing(2),
  },
  breadcrumbs: {
    cursor: 'pointer'
  },
  headingDivider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

export default function TeamCatalogue({
  fetchTeamCatalogue,
  fetchTeamCatalogueSizes,
  fetchTeamFolders,
  fetchTeamCatalogueFolderContent,
  remixUrls,
  teamCatalogue,
  teamId,
  teamCatalogueSizes, 
  teamFolders,
}) {
  // STATE
  const [catalogueSize, setCatalogueSize] = useState({ name: 'CATEGORY', });
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [menuEl, setMenuEl] = useState(null);
  const [copyIdToClipboard, setCopyIdToClipboard] = useState(false);
  const [designIdCopy, setDesignIdCopy] = useState(false);
  const [copyCollectionLinkToClipboard, setCopyCollectionLinkToClipboard] = useState(false);
  const [copyDesignLinkToClipboard, setCopyDesignLinkToClipboard] = useState(false);
  const dispatch = useDispatch();

  // URL
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const urlSize = query.get('size');
  const urlFolderId = query.get('folderId');

  const classes = useStyles();



  const { REACT_APP_WEB_APP_HOST } = process.env;



  // API CALLS
  // On Mount fetch sizes and folders
  useEffect(() => {
    if (teamCatalogueSizes === null || (teamCatalogueSizes)) {
      fetchTeamCatalogueSizes({ teamId });
      fetchTeamFolders({ teamId });
    }
  }, []);


  // When there is no urlSize assign size to state and url. Then fetch teamCatalogue
  useEffect(() => {
    if (!urlSize) {
      if (Array.isArray(teamCatalogueSizes) && teamCatalogueSizes.length !== 0) {
        handleRedirect(teamCatalogueSizes[0].templateCode);
        setCatalogueSize(teamCatalogueSizes[0]);
        fetchTeamCatalogue({ teamId, size: teamCatalogueSizes[0].templateCode })
      }
    }
  })


  // prevProps/PrevState helper function
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }
  
  const prevSizeState = usePrevious(teamCatalogueSizes)
  useEffect(() => {
    if (urlSize) {
      if (Array.isArray(teamCatalogueSizes) && !Array.isArray(prevSizeState)) {
        handleRedirect(teamCatalogueSizes[0].templateCode);
        setCatalogueSize(teamCatalogueSizes[0]);
        fetchTeamCatalogue({ teamId, size: teamCatalogueSizes[0].templateCode })
      }
      // When teamCatalogue size changes when entering/leaving folders
      if(teamCatalogueSizes && teamCatalogueSizes.length !== 0 && teamCatalogueSizes !== prevSizeState){
        handleRedirect(teamCatalogueSizes[0].templateCode);
        setCatalogueSize(teamCatalogueSizes[0]);
      }
    }
  })

  const getPrevUrl = () => {
    const { pathname, search } = history.location
    const url = `${pathname}${search}`
    return url
  }

  const prevUrl = usePrevious(getPrevUrl());

  // When there url changes(Size change) & folderId
  useEffect(() => {
    const { pathname, search } = history.location
    const url = `${pathname}${search}`
    if(url !== prevUrl && urlFolderId) {
      fetchTeamCatalogueFolderContent({teamId, size: catalogueSize.templateCode, folderId: urlFolderId})
    }
  })

  const handleMoveIntoFolder = (folderId) => {
    const { pathname, search } = history.location
    let url = `${pathname}${search}&folderId=${folderId}`
    history.replace(url);
    fetchTeamCatalogueSizes({ teamId, folderId })
  }

  const handleCatalogueSizeChange = (size) => {
    // Find catalogue size obj that contains the same name within current teamCatalogueSizes
    const newSize = teamCatalogueSizes.find(obj => obj.name === size);
    setCatalogueSize(newSize)
    handleRedirect(newSize.templateCode)
    if(urlFolderId){
      return;
    }
    fetchTeamCatalogue({ teamId, size: newSize.templateCode })
  }

  const handleCollectionRemixUrl = async ({collectionId}) => {
    let remixUrl;
    if(remixUrls[collectionId]) {
      remixUrl = remixUrls[collectionId];
    } else {
      const response = await dispatch(fetchCollectionRemixUrl(collectionId));
      remixUrl = response.payload.url;
    }
    navigator.clipboard.writeText(remixUrl);
    setCopyCollectionLinkToClipboard(true);
  };

  const handleDesignRemixUrl = async (designId) => {
    const DESIGN_REMIX_URL = selectedDesign ? `${REACT_APP_WEB_APP_HOST}/mix/${designId}` : "";
    navigator.clipboard.writeText(DESIGN_REMIX_URL);
    setCopyDesignLinkToClipboard(true);
  };

  const handleRedirect = (size) => {
    let url = `/teams/${teamId}`;

    if (size) {
      url += `?size=${encodeURIComponent(size)}`;
    }
    if(urlFolderId) {
      url += `&folderId=${encodeURIComponent(urlFolderId)}`
    }

    history.replace(url);
  };

  if (teamCatalogue === null || teamCatalogueSizes === null) {
    return null;
  }

  const renderList = (teamCatalogueDesigns) => {
    return (
      <>
        {(!teamCatalogueDesigns?.isFetching && teamCatalogueDesigns !== undefined) && (
          <Grid container spacing={2}>
            {teamCatalogueDesigns.map(design => (
              <Grid item key={design.id}>
                <CatalogueCard
                  design={design}
                  onDropDownClick={e => {
                    setMenuEl(e.target);
                    setSelectedDesign(design);
                  }}
                />
              </Grid>
            ))}
          </Grid>)}
        {teamCatalogue.sizes[urlSize]?.meta?.isMoreAvailable && (
          <LoadMore onClick={() => {
            fetchTeamCatalogue({ teamId, size: urlSize });
          }} />
        )}
        {selectedDesign && menuEl && (
          <Menu
            anchorEl={menuEl}
            classes={{ paper: classes.menuPaper }}
            open
            onClose={() => setMenuEl(null)}
          >
            <MenuItem
              onClick={() => {
                navigator.clipboard.writeText(selectedDesign.id);
                setCopyIdToClipboard(true);
              }}
            >
              Copy Design ID:
              <br />
              {selectedDesign.id}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigator.clipboard.writeText(selectedDesign.collectionId);
                setCopyIdToClipboard(true);
              }}
            >
              Copy Collection ID:
              <br />
              {selectedDesign.collectionId}
            </MenuItem>
          <MenuItem onClick={() => handleCollectionRemixUrl(selectedDesign)}>
            Get Collection Remix URL
          </MenuItem>
          <MenuItem onClick={() => handleDesignRemixUrl(selectedDesign.id)}>
            Get Design Remix URL
          </MenuItem>
          </Menu>
        )}
      </>
    )
  }

  const getDesignsFromFolder = () => {
    // Gets all foldered designs and filter them by the current catalogue size
    if(!teamFolders || !teamCatalogue.folders[urlFolderId] || !teamCatalogue.folders[urlFolderId].designs) return [];
    const folderDesigns = teamCatalogue.folders[urlFolderId]?.designs.filter(design => design.templateCode === urlSize)
    return folderDesigns
  }

  const getCatalogueDesigns = () => {
    return urlFolderId ? getDesignsFromFolder() : teamCatalogue.sizes[urlSize]?.designs
  };

  let teamCatalogueDesigns = getCatalogueDesigns();

  const getContent = () => {
    switch (true) {
      case (teamCatalogue.sizes[urlSize]?.isFetching || teamCatalogue.folders[urlFolderId]?.designs?.isFetching): {
        return (
          <Box fontStyle="italic" p={6} textAlign="center">
            <Loading />
          </Box>
        );
      }
      case ((teamCatalogue.sizes[urlSize]?.designs
        && (teamCatalogue.sizes[urlSize].designs.length === 0)
        && (!teamCatalogue.sizes[urlSize]?.isFetching))
        || teamCatalogueSizes.length === 0): {
          return (<Box fontStyle="italic" p={6} textAlign="center">
            No catalogue designs
          </Box>
          )
        }
      default: return renderList(teamCatalogueDesigns);
    }
  }

  const getFolderName = () => {
    if(teamFolders){
      const currentFolder = teamFolders.find(folder => folder.id === urlFolderId)
      return currentFolder.name
    }
  }

  const onBreadcrumbClick = (size) => {
    let url = `/teams/${teamId}`;

    if (size) {
      url += `?size=${encodeURIComponent(size)}`;
    }
    fetchTeamCatalogueSizes({ teamId })
    history.replace(url);
  }
  
  return (
    <>
      <div className={classes.headingContainer}>
        <div className={classes.headingLeft}>
          <Typography variant="h6">
            Team Catalogue
          </Typography>
          {urlFolderId && (
            <>
              <Divider orientation="vertical" flexItem className={classes.headingDivider}/>
              <Breadcrumbs separator="›">
                <Link color="inherit" onClick={() => onBreadcrumbClick(urlSize)} className={classes.breadcrumbs}>
                  Catalogue
                </Link>
                <Typography color="textPrimary">{getFolderName()}</Typography>
              </Breadcrumbs>
            </>
          )}
        </div>
        <Select
          autoWidth={true}
          className={classes.select}
          label={catalogueSize.name}
          onChange={(event) => {
            handleCatalogueSizeChange(event.target.value);
          }}
          value={catalogueSize.name}
        >
          {teamCatalogueSizes.map(({ name }) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <Divider />
      <div className={classes.designContainer}>
        {(teamFolders && teamFolders.length !== 0 && !urlFolderId) &&
            <TeamFolders
              folders={teamFolders}
              handleMoveIntoFolder={handleMoveIntoFolder}
            />
          }
        {getContent()}
      </div>
      {copyIdToClipboard && (
        <Snackbar
          autoHideDuration={8000}
          open
          onClose={() => {
            setCopyIdToClipboard(false)
            setDesignIdCopy(false)
          }}
        >
          <Alert
            severity="success"
            variant="filled"
          >
            {designIdCopy ? 'designId copied to clipboard' : 'collectionId link copied to clipboard'}
          </Alert>
        </Snackbar>
      )}
      {(copyDesignLinkToClipboard || copyCollectionLinkToClipboard) && (
        <Snackbar
          autoHideDuration={8000}
          open
          onClose={() => {
            setCopyDesignLinkToClipboard(false)
            setCopyCollectionLinkToClipboard(false)
          }}
        >
          <Alert
            severity="success"
            variant="filled"
          >
            {copyDesignLinkToClipboard ? 'Design Remix link copied to clipboard' : 'Collection Remix link copied to clipboard'}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
