import store from 'state/store';
import * as types from './folderTypes';
import { createAction } from 'lib/apiMiddlewareUtils';
import { tokenSelector } from 'state/app/SessionSelectors';

const BASE_ASSET_API = process.env.REACT_APP_HOST_ASSET_SERVICE;

export const fetchTeamFolders = ({ type, teamId }) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
    types: [
      types.FETCH_TEAM_FOLDERS_REQUEST,
      types.FETCH_TEAM_FOLDERS_REQUEST_SUCCESS,
      types.FETCH_TEAM_FOLDERS_REQUEST_FAILURE
    ],
    endpoint: `${BASE_ASSET_API}/v1/teams/${teamId}/folders`,
    params: type ? { type } : undefined,
    paginate: state.entities.folders.teamFolders || {},
  });
};

export const fetchTeamCatalogueFolderContent = ({ teamId, folderId }) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);
  const _params = {
      orderBy: 'priority',
      scope: 'team',
      folderId,
  }

  return createAction({
    endpoint: `${BASE_ASSET_API}/v1/teams/${teamId}/catalogue?=${folderId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
    method: 'GET',
    paginate: state.api.assets.teamCatalogue.folders || {},
    params: _params,
    types: [
      types.ASSET_TEAM_CATALOGUE_FOLDER_CONTENT_REQUEST,
      types.ASSET_TEAM_CATALOGUE_FOLDER_CONTENT_SUCCESS,
      types.ASSET_TEAM_CATALOGUE_FOLDER_CONTENT_FAILURE,
    ],
  });
};

export const fetchUserWorkspaceFolders = ({
  accessToken,
  userId,
  teamId,
}) => createAction({
  endpoint: `${BASE_ASSET_API}/v1/teams/${teamId}/users/${userId}/folders`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    accept: 'application/json',
  },
  method: 'GET',
  params: {
    count: 100,
    type: "DESIGN"
  },
  types: [
    types.ASSET_USER_WORKSPACE_FOLDERS_REQUEST,
    {type: types.ASSET_USER_WORKSPACE_FOLDERS_SUCCESS,
      meta: {teamId}
    },
    types.ASSET_USER_WORKSPACE_FOLDERS_FAILURE,
  ]
});

export const fetchUserWorkspaceFolderSizes = ({
  accessToken,
  userId,
  teamId,
  folderId
}) => createAction({
  endpoint: `${BASE_ASSET_API}/v1/teams/${teamId}/users/${userId}/folders/${folderId}/sizes`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    accept: 'application/json',
  },
  method: 'GET',
  params: {
    count: 100,
  },
  types: [
    types.ASSET_USER_WORKSPACE_FOLDER_SIZES_REQUEST,
    {type: types.ASSET_USER_WORKSPACE_FOLDER_SIZES_SUCCESS,
      meta: {teamId, folderId}
    },
    types.ASSET_USER_WORKSPACE_FOLDER_SIZES_FAILURE,
  ]
});