import React from 'react';
import * as actions from 'state/api/subscriptions/SubscriptionApiActions';
import * as selectors from 'state/api/subscriptions/SubscriptionApiSelectors';
import { Route, Switch } from 'react-router';
import { tokenSelector } from 'state/app/SessionSelectors';
import { useDispatch, useSelector } from 'react-redux';
import CouponList from 'views/components/Subscriptions/CouponList';
import SubscriptionPlansTable from 'views/components/Subscriptions/SubscriptionPlansTable';

export default function SubscriptionPlansListContainer() {
  const accessToken = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const coupons = useSelector(selectors.subscriptionCouponsSelector);
  const subscriptions = useSelector(selectors.subscriptionPlansSelector);

  return (
    <Switch>
      <Route path="/coupons">
        <CouponList
          coupons={useSelector(selectors.subscriptionCouponsSelector)}
          createSubscriptionCoupon={(params) => dispatch(actions.createSubscriptionCoupon({ accessToken, ...params }))}
          fetchCoupons={() => dispatch(actions.fetchCoupons({ accessToken, paginate: coupons || {}}))}
          updateSubscriptionCoupon={(params) => dispatch(actions.updateSubscriptionCoupon({ accessToken, ...params }))}
        />
      </Route>
      <Route path="/plans">
        <SubscriptionPlansTable
          fetchSubscriptionPlans={() => dispatch(actions.fetchSubscriptionPlans({ accessToken, paginate: subscriptions || {} }))}
          subscriptionPlans={useSelector(selectors.subscriptionPlansSelector)}
        />
      </Route>
    </Switch>
  );
}
