import React from 'react';
import { Route, Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from 'state/api/orders/OrdersApiActions';
import PrintPricingList from 'views/components/Orders/PrintPricingList';
import { get as getPath } from "lodash";
import {
  printOptionsSelector,
  printProvidersSelector,
  unassignedOrderLabelsSelector,
  unassignedTemplateCodesSelector
} from 'state/api/orders/OrdersApiSelectors';

export default function PrintPricingContainer() {
  const dispatch = useDispatch();
  const addOrderLabel = args => dispatch(actions.createOrderLabel(args));
  const addPrintOptionPrice = args => dispatch(actions.createPrintOptionPrice(args));
  const createPrintOption = args => dispatch(actions.createPrintOption(args));
  const deletePrintOption = args => dispatch(actions.deletePrintOption(args));
  const deletePrintPriceOption = args => dispatch(actions.deletePrintPriceOption(args));
  const deletePrintProvider =  args => dispatch(actions.deactivatePrintProvider(args));
  const fetchPrintOptionsByProvider = args => dispatch(actions.fetchPrintOptionsByProvider(args));
  const fetchPrintProviders = () => dispatch(actions.fetchPrintProviders({ paginate: printProviders || {} }));
  const fetchTemplateSizesByOrderLabel = args => dispatch(actions.fetchTemplateSizesByOrderLabel(args));
  const fetchUnassignedOrderLabels = (providerId) => dispatch(actions.fetchUnassignedOrderLabels({ providerId, }))
  const fetchUnassignedTemplateCodes = () => dispatch(actions.fetchUnassignedTemplateCodes({ paginate: unassignedTemplateCodes }));
  const printOptions = useSelector(printOptionsSelector);
  const isFetchingPrintOptions = getPath(printOptions, "isFetching", false);
  const unassignedOrderLabels = useSelector(unassignedOrderLabelsSelector);
  const unassignedTemplateCodes = useSelector(unassignedTemplateCodesSelector);
  const isFetchingUnassignedOrderLabels = getPath(unassignedOrderLabels, "isFetching", false);
  const printProviders = useSelector(printProvidersSelector);
  const updatePrintOptionPrice = args => dispatch(actions.updatePrintOptionPrice(args));
  const updatePrintProvider = args => dispatch(actions.updatePrintProvider(args));
  const updatePrintOption = (...args) => dispatch(actions.updatePrintOption(...args));

  return (
    <Switch>
      <Route path="/orders/print-pricing">
        <PrintPricingList
          addOrderLabel={addOrderLabel}
          addPrintOptionPrice={addPrintOptionPrice}
          createPrintOption={createPrintOption}
          deletePrintOption={deletePrintOption}
          deletePrintPriceOption={deletePrintPriceOption}
          deletePrintProvider={deletePrintProvider}
          dispatch={dispatch}
          fetchPrintOptionsByProvider={fetchPrintOptionsByProvider}
          fetchPrintProviders={fetchPrintProviders}
          fetchTemplateSizesByOrderLabel={fetchTemplateSizesByOrderLabel}
          fetchUnassignedOrderLabels={fetchUnassignedOrderLabels}
          fetchUnassignedTemplateCodes={fetchUnassignedTemplateCodes}
          isFetchingPrintOptions={isFetchingPrintOptions}
          isFetchingUnassignedOrderLabels={isFetchingUnassignedOrderLabels}
          printOptions={printOptions}
          printProviders={printProviders}
          unassignedOrderLabels={unassignedOrderLabels}
          unassignedTemplateCodes={unassignedTemplateCodes}
          updatePrintOptionPrice={updatePrintOptionPrice}
          updatePrintOption={updatePrintOption}
          updatePrintProvider={updatePrintProvider}
        />
      </Route>
    </Switch>
  );
}
