import React, { useEffect } from 'react';
import { LOADING, READY } from 'lib/Status';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import LoadMore from 'views/components/LoadMore/LoadMore';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default function SubscriptionPlansTable ({
  fetchSubscriptionPlans,
  subscriptionPlans,
}) {
  useEffect(() => {
    if (subscriptionPlans === null) {
      fetchSubscriptionPlans();
    }
  });

  if (!subscriptionPlans) {
    return null;
  }

  return (
    <>
      <h2>Subscription Plans</h2>

      <Box component={Paper} p={3}>
        <Table>
          <TableHead>
            <TableRow>
              <Box component={TableCell} width="10%">Code</Box>
              <Box component={TableCell} width="10%">Interval</Box>
              <Box component={TableCell} width="30%">Description</Box>
              <Box component={TableCell} width="30%">Stripe Reference</Box>
              <Box component={TableCell} width="10%">Currency</Box>
              <Box component={TableCell} width="10%" align="right">Price</Box>
            </TableRow>
          </TableHead>
          <TableBody>
            {subscriptionPlans.meta.status === READY
              && (subscriptionPlans.payload || []).map((subscriptionPlan) =>
              <TableRow hover key={subscriptionPlan.id}>
                <TableCell>
                  <Chip
                    label={subscriptionPlan.code}
                    size="small"
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>
                  <Chip
                    label={subscriptionPlan.intervalPeriod}
                    size="small"
                    variant="outlined"
                  />
                </TableCell>
                <TableCell>{subscriptionPlan.description}</TableCell>
                <TableCell><code>{subscriptionPlan.stripePlanId}</code></TableCell>
                <TableCell>{subscriptionPlan.currency}</TableCell>
                <TableCell align="right">${subscriptionPlan.amount.toFixed(2)}</TableCell>
              </TableRow>
            )}
            {subscriptionPlans.meta.status === LOADING && (
              <TableRow>
                <TableCell><Skeleton variant='text' /></TableCell>
                <TableCell><Skeleton variant='text' /></TableCell>
                <TableCell><Skeleton variant='text' /></TableCell>
                <TableCell><Skeleton variant='text' /></TableCell>
                <TableCell><Skeleton variant='text' /></TableCell>
                <TableCell><Skeleton variant='text' /></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {subscriptionPlans.meta.isMoreAvailable && (
          <LoadMore
            onClick={() => fetchSubscriptionPlans()}
          />
        )}
      </Box>
    </>
  );
}
