import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import MediaSearch from 'views/components/Media/MediaSearch';
import { mediaItemsSelector } from 'state/api/assets-media/AssetMediaApiSelectors';
import { searchMedia } from 'state/api/assets-media/AssetMediaApiActions';
import { tokenSelector } from 'state/app/SessionSelectors';
import { categoryTypes } from 'lib/constants';

export default function MediaSearchContainer() {
  const accessToken = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const mediaItems = useSelector(mediaItemsSelector);
  const query = new URLSearchParams(useLocation().search);
  const urlCategory = query.get('category');
  const urlFolderId = query.get('folderId');
  const urlTerm = query.get('term');

  useEffect(() => {
      dispatch(searchMedia({
        accessToken,
        category: urlCategory,
        folderId: urlFolderId,
        mediaItems,
        term: urlTerm,
        context: [categoryTypes.ANIMATIONS, categoryTypes.VIDEO].includes(urlCategory) ? 'STOCK_ANIMATION' : null
      }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlCategory, urlTerm]);

  return (
    <MediaSearch
      mediaItems={mediaItems}
      onClickLoadMore={() => dispatch(searchMedia({
        accessToken,
        category: urlCategory,
        folderId: urlFolderId,
        mediaItems,
        term: urlTerm,
      }))}
      urlCategory={urlCategory}
      urlFolderId={urlFolderId}
      urlTerm={urlTerm}
    />
  );
}
