import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import { 
  Box, 
  Button, 
  Chip,
  makeStyles, 
  Paper, 
  Snackbar, 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import LoadMore from 'views/components/LoadMore/LoadMore';
import OrdersCouponsDialog from './OrdersCouponsDialog';
import Skeleton from '@material-ui/lab/Skeleton';
import { LOADING, READY } from 'lib/Status';
import { toDateStr } from 'lib/dateUtil';

const useStyles = makeStyles((theme) => ({
  chipSpacing: {
    marginRight: theme.spacing(1)
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  header2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const OrdersCouponList = ({
  createOrdersCoupons,
  fetchOrdersCoupons,
  ordersCoupons,
  updateOrdersCoupons
}) => {
  const classes = useStyles();
  const [ordersCouponsEdit, setOrdersCouponsEdit] = useState(null);
  const [successActionString, setSuccessActionString] = useState(null);
  
  useEffect(() => {
    if (ordersCoupons === null) {
      fetchOrdersCoupons();
    }
  });

  if (!ordersCoupons) {
    return null;
  }

  return (
    <>
      <div className={classes.headingContainer}>
        <h2 className={classes.header2}>Orders Coupons</h2>
        {ordersCoupons.meta.status === READY && (
          <Box marginTop={1.5}>
            <Button
              onClick={() => setOrdersCouponsEdit({})}
              startIcon={<AddIcon />}
              variant="contained"
            >
              Add Coupon
            </Button>
          </Box>
        )}
      </div>

      <Box
        component={Paper}
        p={2}
      >
        <Table>
          <TableHead>
            <TableRow>
              <Box component={TableCell} width="20%">Code</Box>
              <Box component={TableCell} width="20%">Apply To</Box>
              <Box component={TableCell} width="5%">Value</Box>
              <Box component={TableCell} width="5%">Type</Box>
              <Box component={TableCell} width="15%">Start</Box>
              <Box component={TableCell} width="15%">End</Box>
            </TableRow>
          </TableHead>
          <TableBody>
            {ordersCoupons.meta.status === READY
              && (ordersCoupons.payload || []).map((ordersCoupon) =>
              <TableRow
                hover
                key={ordersCoupon.code}
                onClick={() => setOrdersCouponsEdit(ordersCoupon)}
              >
                <TableCell>{ordersCoupon.code}</TableCell>
                <TableCell>
                  <Chip label={ordersCoupon.applyTo} key={ordersCoupon.applyTo} size="small" variant="outlined" className={classes.chipSpacing} />
                </TableCell>
                <TableCell>{ordersCoupon.value}</TableCell>
                <TableCell>{ordersCoupon.valueType}</TableCell>
                <TableCell>{toDateStr(ordersCoupon.startAt)}</TableCell>
                <TableCell>{toDateStr(ordersCoupon.endAt)}</TableCell>
              </TableRow>
            )}
            {ordersCoupons.meta.status === LOADING && (
              <TableRow>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {ordersCoupons.meta.isMoreAvailable && (
          <LoadMore
            onClick={() => fetchOrdersCoupons()}
          />
        )}
      </Box>

      {ordersCouponsEdit && (
        <OrdersCouponsDialog
          createOrdersCoupons={createOrdersCoupons}
          ordersCoupons={ordersCouponsEdit}
          onClose={() => setOrdersCouponsEdit(null)}
          updateOrdersCoupons={updateOrdersCoupons}
          setSuccessActionString={setSuccessActionString}
        />
      )}

      {successActionString !== null && (
        <Snackbar
          autoHideDuration={10000}
          onClose={() => setSuccessActionString(null)}
          open
        >
          <Alert
            severity="success"
            variant="filled"
          >
            Coupon {successActionString}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default OrdersCouponList