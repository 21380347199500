import * as types from 'state/api/authentication/AuthtenticationApiTypes';

const initState = {
  errors: {},
}

const processAuthenticationError = (payload) => {
  const response = payload.response;
  if (response.error_description === 'invalid credentials provided') {
    return {
      password: 'Invalid credentials provided'
    }
  }
}

const authenticationApiReducers = (state = initState, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_FAILURE: {
      return {
        ...state,
        errors: processAuthenticationError(action.payload)
      }
    }
    default:
      return state;
  }
};

export default authenticationApiReducers;