export const FETCH_TEAM_FOLDERS_REQUEST = 'ENTITIES/FOLDERS/FETCH_TEAM_FOLDERS_REQUEST'
export const FETCH_TEAM_FOLDERS_REQUEST_SUCCESS = 'ENTITIES/FOLDERS/FETCH_TEAM_FOLDERS_REQUEST_SUCCESS'
export const FETCH_TEAM_FOLDERS_REQUEST_FAILURE = 'ENTITIES/FOLDERS/FETCH_TEAM_FOLDERS_REQUEST_FAILURE'

export const ASSET_USER_WORKSPACE_FOLDERS_REQUEST = 'ENTITIES/FOLDERS/ASSET_USER_WORKSPACE_FOLDERS_REQUEST'
export const ASSET_USER_WORKSPACE_FOLDERS_SUCCESS = 'ENTITIES/FOLDERS/ASSET_USER_WORKSPACE_FOLDERS_SUCCESS'
export const ASSET_USER_WORKSPACE_FOLDERS_FAILURE = 'ENTITIES/FOLDERS/ASSET_USER_WORKSPACE_FOLDERS_FAILURE'

export const ASSET_USER_WORKSPACE_FOLDER_SIZES_REQUEST = 'ENTITIES/FOLDERS/ASSET_USER_WORKSPACE_FOLDER_SIZES_REQUEST'
export const ASSET_USER_WORKSPACE_FOLDER_SIZES_SUCCESS = 'ENTITIES/FOLDERS/ASSET_USER_WORKSPACE_FOLDER_SIZES_SUCCESS'
export const ASSET_USER_WORKSPACE_FOLDER_SIZES_FAILURE = 'ENTITIES/FOLDERS/ASSET_USER_WORKSPACE_FOLDERS_FAILURE'

export const ASSET_TEAM_CATALOGUE_FOLDER_CONTENT_REQUEST = 'API/ASSET_TEAM_CATALOGUE_FOLDER_CONTENT_REQUEST';
export const ASSET_TEAM_CATALOGUE_FOLDER_CONTENT_SUCCESS = 'API/ASSET_TEAM_CATALOGUE_FOLDER_CONTENT_SUCCESS';
export const ASSET_TEAM_CATALOGUE_FOLDER_CONTENT_FAILURE = 'API/ASSET_TEAM_CATALOGUE_FOLDER_CONTENT_FAILURE';

