import React, { useState, useEffect } from 'react';
import { Paper } from '@material-ui/core';
import { toDateTimeStr } from 'lib/dateUtil';
import { FAILED, LOADING, READY } from 'lib/Status';
import { 
  makeStyles,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputBase,
  Snackbar,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField 
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ClearIcon from '@material-ui/icons/Clear';
import getTeamType from 'lib/getTeamType';
import LoadMore from 'views/components/LoadMore/LoadMore';
import SearchIcon from '@material-ui/icons/Search';
import MuiTable from '@material-ui/core/Table';
import Skeleton from '@material-ui/lab/Skeleton';

const EASIL_TEAM_ID = process.env.REACT_APP_EASIL_TEAM_ID;

const useStyles = makeStyles({
  tight: {
    whiteSpace: 'nowrap',
    width: '1%',
  }
});

export const FontUpload = ({
  fetchTeams,
  fetchTeamSubteams,
  onSubmit,
  fontUploads,
  teams,
  teamSubteams,
}) => {
  const [ fontFile, setFontFile ] = useState();
  const [ teamId, setTeamId ] = useState('');
  const [input, setInput] = useState('');
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [fails, setFails] = useState(0);
  const [ name, setName ] = useState(null);
  const styles = useStyles();
  const data = name ? teams : teamSubteams;

  const handleFontUpload = (fontUploads) => {
    setUploadSuccessful(true);
    setFails(0);
    let failCount = 0
    if(!fontUploads.payload){ 
      return
    }
    fontUploads.payload.forEach(upload => {
      if (upload.status !== 201){
        failCount++
        setUploadFailed(true)
        setUploadSuccessful(false)
      }
    });
    setFails(failCount);
    setTeamId('');
  };

  useEffect(() => {
    if (fontUploads.payload){
      handleFontUpload(fontUploads);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fontUploads]);
  
  useEffect(() => {
    if (name) {
      if (teams === null
        || teams.meta.name !== name
      ) {
        fetchTeams({ name });
      }
    } else {
      if (teamSubteams === null
        || teamSubteams.meta.parentId !== EASIL_TEAM_ID
      ) {
        fetchTeamSubteams({
          parentId: EASIL_TEAM_ID,
          sortBy: 'createdAt',
          sortOrder: 'desc',
        });
      }
    }
  });

  if (!data || data.meta.status === FAILED) {
    return null;
  }

 return (
   <>
     <h2>Upload Font To Teams</h2>

     <Box component={Paper} pb={4} pt={1} px={4}>
       <Box py={2}>
         <form
         onSubmit={(event) => {
          event.preventDefault();
          return onSubmit(
            teamId,
            fontFile,
          );
        }}
         >
           <Grid container justify="center" spacing={3}>
             <Grid item>
               <TextField
                 fullWidth
                 InputLabelProps={{ shrink: true }}
                 inputProps={{ accept: '.otf, .ttf' }}
                 label="Font File"
                 onChange={(e) => setFontFile(e.target.files[0])}
                 required
                 type="file"
                 variant="outlined"
               />
               <br />
               <TextField
                 fullWidth
                 InputLabelProps={{ shrink: true }}
                 label="Team ID"
                 onChange={(e) => setTeamId(e.target.value)}
                 required
                 variant="outlined"
                 value={teamId}
               />
               <Box mt={2}>
                 <Button color="primary" type="submit" variant="contained">
                   Upload
                 </Button>
               </Box>
             </Grid>
           </Grid>
         </form>
       </Box>
       <Box mt={2}>
         <div>
           <h2>Search Teams</h2>

           <Paper
             component="form"
             onSubmit={(event) => {
               event.preventDefault();
               setName(input)
             }}
           >
             <Box display="flex" mb={2} pl={2}>
               <InputBase
                 placeholder="Search Teams"
                 fullWidth={true}
                 onChange={(e) => setInput(e.target.value)}
                 onKeyDown={(event) => {
                   if (
                     event.key === "Backspace" &&
                     event.target.value.length > 0 &&
                     (name || "").length
                   ) {
                     setInput("");
                   }
                 }}
                 value={input}
               />
               {!!input && (
                 <>
                   <IconButton
                     onClick={() => {
                       setInput("");
                     }}
                   >
                     <ClearIcon />
                   </IconButton>
                   <Divider
                     orientation="vertical"
                     style={{ height: "28px", marginTop: "10px" }}
                   />
                 </>
               )}
               <IconButton type="submit">
                 <SearchIcon />
               </IconButton>
             </Box>
           </Paper>

           <Box component={Paper} padding={2}>
             {(data.meta.status === LOADING ||
               (data.meta.status === READY &&
                 (data.payload || []).length > 0)) && (
               <>
                 {name ? (
                   <em>
                     Results for "<strong>{name}</strong>":
                   </em>
                 ) : (
                   <em>Easil sub-teams:</em>
                 )}

                 <TableContainer>
                   <MuiTable>
                     <TableHead>
                       <TableRow>
                         <TableCell>Name</TableCell>
                         <TableCell className={styles.tight}>Type</TableCell>
                         <TableCell align="right" className={styles.tight}>
                           Created At
                         </TableCell>
                       </TableRow>
                     </TableHead>
                     <TableBody>
                       {(data.payload || []).map((team) => (
                         <TableRow
                           hover={true}
                           key={team.id}
                           onClick={() => {
                            setTeamId(team.id)
                            window.scrollTo(0, 0)
                          }}
                         >
                           <TableCell>{team.name}</TableCell>
                           <TableCell className={styles.tight}>
                             {getTeamType(team)}
                           </TableCell>
                           <TableCell align="right" className={styles.tight}>
                             {toDateTimeStr(team.createdAt)}
                           </TableCell>
                         </TableRow>
                       ))}

                       {data.meta.status === LOADING && (
                         <TableRow>
                           <TableCell>
                             <Skeleton variant="text" />
                           </TableCell>
                           <TableCell>
                             <Skeleton variant="text" />
                           </TableCell>
                           <TableCell>
                             <Skeleton variant="text" />
                           </TableCell>
                         </TableRow>
                       )}
                     </TableBody>
                   </MuiTable>

                   {data.meta.isMoreAvailable && (
                     <LoadMore
                       onClick={() => {
                         if (name) {
                           fetchTeams({ name });
                         } else {
                           fetchTeamSubteams({
                             parentId: EASIL_TEAM_ID,
                             sortBy: "createdAt",
                             sortOrder: "desc",
                           });
                         }
                       }}
                     />
                   )}
                 </TableContainer>
               </>
             )}

             {data.meta.status === READY && (data.payload || []).length === 0 && (
               <Box fontStyle="italic" p={4} textAlign="center">
                 No results.
               </Box>
             )}
           </Box>
         </div>
       </Box>
     </Box>
     {uploadSuccessful && (
        <Snackbar
          autoHideDuration={8000}
          onClose={() => setUploadSuccessful(false)}
          open
        >
          <Alert
            severity="success"
            variant="filled"
          >
            Team Font Upload Complete.
          </Alert>
        </Snackbar>
      )}
     {uploadFailed &&(
        <Snackbar
          autoHideDuration={8000}
          onClose={() => setUploadFailed(false)}
          open
        >
          <Alert
            severity="warning"
            variant="filled"
          >
            Team Font Upload Complete. There were {fails} failed uploads.
          </Alert>
        </Snackbar>
      )}
   </>
 );
};
export default FontUpload;