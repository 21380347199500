import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    paddingTop: theme.spacing(2),
    color: "red"
  }
}));

export default function EditPrintOptionDialog({
  onClose,
  printOption,
  providerId,
  providerName,
  setSuccessActionString,
  updatePrintOption,
}) {
  const label = printOption.label;
  const size = printOption.sizeDescription;
  const width = printOption.width;
  const height = printOption.height;

  const classes = useStyles();
  const [stock, setStock] = useState(printOption.stock);
  const [finishing, setFinishing] = useState(printOption.finishing);
  const [description, setDescription] = useState(printOption.description);
  const [errors, setErrors] = useState({});

  const handleOnBlur = event => {
    const inputName = event.target.name;
    const value = event.target.value;

    // set error if no value has been provided
    if (!value) {
      setErrors({ 
        ...errors,
        [inputName]: `Please provide a ${inputName}`
      });
      return;
    }

    // set error if negative integer is provided
    if (event.target.type === "number" && (Number(value) < 0)) {
      setErrors({ 
        ...errors,
        [inputName]: `Please provide a positive value for ${inputName}`
      });
      return;
    }
    // clear error
    setErrors({ 
      ...errors,
      [inputName]: ""
    });
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await updatePrintOption({
      id: printOption.id,
      createdAt: printOption.createdAt,
      updatedAt: printOption.updatedAt,
      label,
      sizeDescription: size,
      stock,
      width,
      height,
      finishing,
      description,
      printProviderId: providerId,
      active: true
    }, printOption);

    if (response.error) {
      setErrors({
        ...errors,
        response: "There was an issue processing your request. Contact devs for support."
      });
      return
    }
    
    onClose();
    setSuccessActionString("Print option successfully updated");
  };

  return (
    <Dialog aria-labelledby="form-dialog-title" onClose={onClose} open={true} maxWidth="xs">
      <DialogTitle id="form-dialog-title">
        Add Print Option for {providerName}
      </DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="dense"
          label="Size"
          value={size}
          variant="outlined"
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="dense"
          label="Width (mm)"
          onChange={() => {}}
          value={width}
          variant="outlined"
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="dense"
          label="Height (mm)"
          onChange={() => {}}
          value={height}
          variant="outlined"
          disabled
        />

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Stock (User Facing)"
          margin="dense"
          onChange={e => {
            setStock(e.target.value); 
            setErrors({ ...errors, stock: "" })
          }}
          required
          onBlur={e => handleOnBlur(e)}
          name="stock"
          value={stock}
          variant="outlined"
          error={!!errors.stock}
          helperText={errors.stock}
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Finishing (User Facing - Optional)"
          margin="dense"
          onChange={e => {
            let text = e.target.value;
            if (!text) text = null;
            setFinishing(text);
          }}
          value={finishing}
          variant="outlined"
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Print Description (Internal Use Only)"
          margin="dense"
          onChange={e => {
            let text = e.target.value;
            if (!text) text = null;
            setDescription(text);
          }}
          value={description}
          variant="outlined"
        />
        {errors.response && (
          <div className={classes.errorMessage}>{errors.response}</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={handleSubmit}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
}