import React from 'react';
import { 
  Box, 
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cell: {
    display: "flex",
    borderBottom: "1px solid #dddddd"
  },
  description: {
    color: "darkgray"
  },
  white: {
    color: 'white'
  },
  table: {
    marginTop: theme.spacing(4)
  },
  quantity: {
    textAlign: "center"
  }
}));

const ExamplePrintOptionTable = ({ currency }) => {
  const classes = useStyles();
  return (
    <Table classes={{ root: classes.table }}>
      <TableHead>
        <TableRow>
          <Box 
            component={TableCell} 
            width="100%"
            bgcolor="#666666"
            classes={{ root: classes.white }}
          >
            LABEL
          </Box>
        </TableRow>
        <TableRow>
          <Box 
            component={TableCell} 
            width="100%"
            bgcolor="#dddddd"
          >
            SIZE
          </Box>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell classes={{ root: classes.cell }}>
            <Box 
              component="div" 
              width="50%"
            >
              STOCK
            </Box>
            <Box 
              component="div" 
              width="35%"
            >
              <div>FINISHING</div>
              <div className={classes.description}>DESCRIPTION</div>
            </Box>
            <Box 
              component="div" 
              width="7.5%"
              classes={{ root: classes.quantity }}
            >
              MIN QUANTITY
            </Box>
            <Box 
              component="div" 
              width="7.5%"
              classes={{ root: classes.quantity }}
            >
              <div>PRICE</div>
              <div className={classes.description}>${currency}</div>
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default ExamplePrintOptionTable