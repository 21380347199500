export const SUBSCRIPTION_REQUEST = Symbol('API/SUBSCRIPTION/REQUEST');
export const SUBSCRIPTION_SUCCESS = Symbol('API/SUBSCRIPTION/SUCCESS');
export const SUBSCRIPTION_FAILURE = Symbol('API/SUBSCRIPTION/FAILURE');

export const SUBSCRIPTION_PLANS_REQUEST = Symbol('API/SUBSCRIPTION_PLANS_REQUEST');
export const SUBSCRIPTION_PLANS_SUCCESS = Symbol('API/SUBSCRIPTION_PLANS_SUCCESS');
export const SUBSCRIPTION_PLANS_FAILURE = Symbol('API/SUBSCRIPTION_PLANS_FAILURE');

export const CONVERT_ACC_TO_SUBSCRIPTION_FAILURE =
  Symbol('API/SUBSCRIPTION/CONVERT_ACC_TO_SUBSCRIPTION_FAILURE');
export const CONVERT_ACC_TO_SUBSCRIPTION_REQUEST =
  Symbol('API/SUBSCRIPTION/CONVERT_ACC_TO_SUBSCRIPTION_REQUEST');
export const CONVERT_ACC_TO_SUBSCRIPTION_SUCCESS =
  Symbol('API/SUBSCRIPTION/CONVERT_ACC_TO_SUBSCRIPTION_SUCCESS');

export const CONVERT_TO_ACC_FAILURE = Symbol('API/SUBSCRIPTION/CONVERT_TO_ACC_FAILURE');
export const CONVERT_TO_ACC_REQUEST = Symbol('API/SUBSCRIPTION/CONVERT_TO_ACC_REQUEST');
export const CONVERT_TO_ACC_SUCCESS = Symbol('API/SUBSCRIPTION/CONVERT_TO_ACC_SUCCESS');

export const CREATE_TRIAL_FAILURE = Symbol('API/SUBSCRIPTION/CREATE_TRIAL_FAILURE');
export const CREATE_TRIAL_REQUEST = Symbol('API/SUBSCRIPTION/CREATE_TRIAL_REQUEST');
export const CREATE_TRIAL_SUCCESS = Symbol('API/SUBSCRIPTION/CREATE_TRIAL_SUCCESS');

export const UPDATE_FAILURE = Symbol('API/SUBSCRIPTION/UPDATE_FAILURE');
export const UPDATE_REQUEST = Symbol('API/SUBSCRIPTION/UPDATE_REQUEST');
export const UPDATE_SUCCESS = Symbol('API/SUBSCRIPTION/UPDATE_SUCCESS');

export const SUBSCRIPTION_COUPONS_REQUEST = Symbol('API/SUBSCRIPTION_COUPONS_REQUEST');
export const SUBSCRIPTION_COUPONS_SUCCESS = Symbol('API/SUBSCRIPTION_COUPONS_SUCCESS');
export const SUBSCRIPTION_COUPONS_FAILURE = Symbol('API/SUBSCRIPTION_COUPONS_FAILURE');

export const CREATE_SUBSCRIPTION_COUPON_REQUEST = Symbol('API/CREATE_SUBSCRIPTION_COUPON_REQUEST');
export const CREATE_SUBSCRIPTION_COUPON_SUCCESS = Symbol('API/CREATE_SUBSCRIPTION_COUPON_SUCCESS');
export const CREATE_SUBSCRIPTION_COUPON_FAILURE = Symbol('API/CREATE_SUBSCRIPTION_COUPON_FAILURE');

export const UPDATE_SUBSCRIPTION_COUPON_REQUEST = Symbol('API/UPDATE_SUBSCRIPTION_COUPON_REQUEST');
export const UPDATE_SUBSCRIPTION_COUPON_SUCCESS = Symbol('API/UPDATE_SUBSCRIPTION_COUPON_SUCCESS');
export const UPDATE_SUBSCRIPTION_COUPON_FAILURE = Symbol('API/UPDATE_SUBSCRIPTION_COUPON_FAILURE');
