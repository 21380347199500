import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import handleCategoriesChange from './handleCategoriesChange';
import mediaCategories from 'lib/mediaCategories';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { categoryTypes } from 'lib/constants';
import {
  ACCEPTABLE_MEDIA_TYPES,
  getGifDuration,
  isGifFile,
  getVideoDuration,
  isMp4,
} from "../../../lib/fileUtil";

export const MediaUploadIndividual = ({ onSubmit, urlCategory }) => {
  const [category, setCategory] = useState('');
  const [duration, setDuration] = useState(null);
  const [imageFile, setImageFile] = useState();
  const [name, setName] = useState('');
  const [priority, setPriority] = useState(99999);
  const [subscriptionCode, setSubscriptionCode] = useState('BASIC');
  const [tags, setTags] = useState([]);
  const [thumbFile, setThumbFile] = useState();

  useEffect(() => {
    if (urlCategory && urlCategory !== 'all') {
      setCategory(urlCategory);
    } else {
      setCategory('');
    }
  }, [urlCategory]);

  async function processFile(file) {
    setImageFile(file);
    if (isGifFile(file)) {
      getGifDuration(file).then(duration => setDuration(Math.floor(duration)));
    }
    if(isMp4(file)) {
      let videoDuration = await getVideoDuration(file)
      setDuration(Math.floor(videoDuration));
      setCategory(categoryTypes.VIDEO)
    }
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();

        return onSubmit(event, {
          categories: [{ name: category }, ...tags],
          duration,
          imageFile,
          name,
          priority: parseInt(priority, 10),
          subscriptionCode,
          thumbFile,
        });
      }}
    >
      <Grid
        container
        justify="center"
        spacing={3}
      >
        <Grid item>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: ACCEPTABLE_MEDIA_TYPES }}
            label="Media File"
            onChange={(e) => processFile(e.target.files[0])}
            required
            type="file"
            variant="outlined"
          />
          <br />
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputProps={{ accept: ACCEPTABLE_MEDIA_TYPES }}
            label="Thumbnail File"
            onChange={(e) => setThumbFile(e.target.files[0])}
            type="file"
            variant="outlined"
          />
        </Grid>
  
        <Grid item>
          <Box width="340px">
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Name"
              onChange={(e) => setName(e.target.value)}
              required
              variant="outlined"
              value={name}
            />

            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Category"
              onChange={(e) => setCategory(e.target.value)}
              select
              value={category}
              variant="outlined"
            >
              {mediaCategories
                .filter(({ isUnselectable }) => !isUnselectable)
                .map(({ label, name }) => (
                  <MenuItem key={name} value={name}>{label}</MenuItem>
                )
              )}
            </TextField>

            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Priority"
              onChange={(e) => setPriority(e.target.value)}
              required
              type="number"
              variant="outlined"
              value={priority}
            />

            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Subscription"
              onChange={e => setSubscriptionCode(e.target.value)}
              required
              select
              value={subscriptionCode}
              variant="outlined"
            >
              <MenuItem value="BASIC">Basic</MenuItem>
              <MenuItem value="EDGE">Edge</MenuItem>
              <MenuItem value="PLUS">Plus</MenuItem>
            </TextField>

            <Autocomplete
              clearOnBlur
              freeSolo
              fullWidth
              getOptionLabel={({ label, name }) => label || name}
              multiple
              onChange={(...args) => {
                setTags(handleCategoriesChange(...args));
              }}
              options={mediaCategories
                .filter(({ isUnselectable }) => !isUnselectable)
              }
              renderInput={(params) => (
                <TextField {...params}
                  InputLabelProps={{ shrink: true }}
                  label="Tags"
                  variant="outlined"
                />
              )}
              selectOnFocus
              value={tags}
            />

            <Box mt={2}>
              <Button
                color="primary"
                type="submit"
                variant="contained"
              >
                Upload
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};


export default MediaUploadIndividual;