import { printPricesByIdSelector } from "state/entities/printPrices/printPricesSelectors";
export const printOptionsSelector = state => state.entities.printOptions;
export const printOptionsByIdSelector = (state, printOptionId) => {
  const printOptions = printOptionsSelector(state);
  const printOption = printOptions[printOptionId];
  const printOptionPricing = printOption.pricing.map(pricingId => {
    return printPricesByIdSelector(state, pricingId);
  });
  return {
    ...printOption,
    pricing: printOptionPricing
  };
};