import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import getTeamType from 'lib/getTeamType';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { toDateStr } from 'lib/dateUtil';

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(2)
  },
  detailsContainer: {
    backgroundColor: '#f7fafc',
    padding: theme.spacing(2),
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  headingLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  stripeLink: {
    color: 'inherit',
  }
}));

export default function TeamSubscription({
  fetchSubscriptionPlans,
  fetchTeamSubscription,
  onCreateTrialClick,
  onExtendTrialClick,
  onSwitchClick,
  onSwitchToStripeClick,
  subscriptionPlans,
  team,
  teamId,
  teamSubscription,
}) {
  const classes = useStyles();

  useEffect(() => {
    if (subscriptionPlans === null) {
      fetchSubscriptionPlans();
    }
  });

  useEffect(() => {
    if (!teamSubscription
      || teamSubscription.meta.teamId !== teamId
    ) {
      fetchTeamSubscription({ teamId });
    }
  });

  if (!subscriptionPlans
    || !team
    || !team.payload
    || !teamSubscription
    || !teamSubscription.payload
    || teamSubscription.error
  ) {
    return null;
  }

  const teamType = getTeamType(team.payload);
  const {
    amount,
    code,
    isAccount,
    planId,
    processorCustomerId,
    renewAt,
    status,
    quantity,
  } = teamSubscription.payload;

  return (
    <>
      {teamSubscription && (
        <Paper>
          <div className={classes.headingContainer}>
            <div className={classes.headingLeft}>
              <Typography inline='true' variant="h6">Subscription</Typography>
            </div>
            <div>
              {code === 'BASIC'
                && status !== 'TRIALLING'
                && teamType === 'Organisation'
                && (
                <Button
                  className={classes.button}
                  onClick={onCreateTrialClick}
                  variant="outlined"
                >
                  Create Trial
                </Button>
              )}
              {status === 'TRIALLING'
                && teamType === 'Organisation'
                && (
                <Button
                  className={classes.button}
                  onClick={onExtendTrialClick}
                  variant="outlined"
                >
                  Extend Trial
                </Button>
              )}
              {!isAccount
                && teamType === 'Organisation'
                && (
                <Button
                  className={classes.button}
                  onClick={onSwitchClick}
                  variant="outlined"
                >
                  Switch to Account
                </Button>
              )}
              {isAccount
              && (
                <Button
                  className={classes.button}
                  onClick={onSwitchToStripeClick}
                  variant="outlined"
                >
                  Switch to Stripe
                </Button>
              )}
            </div>
          </div>
          <Divider />
          <div className={classes.detailsContainer}>
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="subtitle1">Amount</Typography>
                <Typography variant="body2">${amount}</Typography>
              </Grid>
              <Grid item >
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">Currency</Typography>
                <Typography variant="body2">
                  {((subscriptionPlans.payload || []).find(
                    ({ id }) => id === planId) || {}).currency}
                </Typography>
              </Grid>
              <Grid item >
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">Plan</Typography>
                <Typography variant="body2">{code}</Typography>
              </Grid>
              <Grid item >
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">Processor Reference</Typography>
                <Typography variant="body2">
                  {!isAccount ? (
                    <a
                      className={classes.stripeLink}
                      href={`https://dashboard.stripe.com/customers/${processorCustomerId}`}
                    >
                      {processorCustomerId}
                    </a>
                  ) : (
                    processorCustomerId
                  )}
                </Typography>
              </Grid>
              <Grid item >
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">Renew At</Typography>
                <Typography variant="body2">{toDateStr(renewAt)}</Typography>
              </Grid>
              <Grid item >
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">Status</Typography>
                <Typography variant="body2">{status}</Typography>
              </Grid>
              <Grid item >
                <Divider orientation="vertical" />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1">Team Members</Typography>
                <Typography variant="body2">{quantity}</Typography>
              </Grid>
            </Grid>
          </div>
        </Paper>
      )}
    </>
  );
}
