import { FAILED, READY } from 'lib/Status';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useHistory } from 'react-router-dom';
import Add from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import Divider from '@material-ui/core/Divider';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import mediaCategories from 'lib/mediaCategories';
import MediaSearchItem from './MediaSearchItem';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  select: {
    padding: theme.spacing(1, 1, 1, 2),
    '& :focus': {
      backgroundColor: 'white',
    }
  },
}));

export default function MediaSearch({
  mediaItems,
  onClickLoadMore,
  urlCategory,
  urlTerm,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [term, setTerm] = useState(urlTerm || '');

  useEffect(() => setTerm(urlTerm || ''), [urlCategory, urlTerm]);

  const handleRedirect = (event, category, term) => {
    let url = '/media';

    if (category && category !== 'all') {
      url += `&category=${encodeURIComponent(category)}`;
    }

    if (term) {
      url += `&term=${encodeURIComponent(term)}`;
    }

    history.replace(url.replace('&', '?'));
  }; 

  return (
    <>
      <h2>Media</h2>

      <Paper className={classes.root}>
        <Select
          className={classes.select}
          input={<InputBase />}
          labelId="category-label"
          label="Category"
          onChange={(event) => {
            handleRedirect(event, event.target.value, urlTerm);
          }}
          value={urlCategory || 'all'}
        >
          {mediaCategories.map(({ label, name }) => (
            <MenuItem key={name} value={name}>
              {name === 'all' ? 'All Categories' : label}
            </MenuItem>
          ))}
        </Select>
        <Divider className={classes.divider} orientation="vertical" />
        <InputBase
          className={classes.input}
          onChange={(event) => {
            if (event.target.value === '' && urlTerm) {
              handleRedirect(event, urlCategory);
            }
            setTerm(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleRedirect(event, urlCategory, term);
            }
          }}
          placeholder="Search Media"
          value={term}
        />
        {term && (
          <>
            <IconButton
              className={classes.iconButton}
              onClick={(e) => {
                setTerm('');
                handleRedirect(e, urlCategory);
              }}
            >
              <ClearIcon />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
          </>
        )}
        <IconButton
          type="submit"
          className={classes.iconButton}
          onClick={(e) => handleRedirect(e, urlCategory, term)}
        >
          <SearchIcon />
        </IconButton>
      </Paper>

      {(() => {
        if (!mediaItems) {
          return null;
        }
      
        if (mediaItems.meta.status === FAILED) {
          return <>Failed to load media.</>;
        }
      
        if (mediaItems.meta.status !== READY) {
          return <>Loading...</>;
        }

        if (!mediaItems.payload.length) {
          return <Alert severity="info">No results.</Alert>;
        }

        return (
          <>
            <Grid container spacing={2}>
              {mediaItems.payload.map((mediaItem) => (
                <Grid item key={mediaItem.id}>
                  <MediaSearchItem media={mediaItem} />
                </Grid>
              ))}
            </Grid>
            <Box
              display="flex"
              justifyContent="center"
              marginBottom={6}
              marginTop={4}
            >
              <Button
                color="secondary"
                component={Link}
                startIcon={<Add />}
                to={`/media/upload${!urlCategory ? '' : `?category=${urlCategory}`}`}
                variant="contained"
              >
                Add Media
              </Button>
              {mediaItems.meta.isMoreAvailable && (
                <Box marginLeft={2}>
                  <Button
                    endIcon={<ExpandMore />}
                    onClick={onClickLoadMore}
                    variant="contained"
                  >
                    More
                  </Button>
                </Box>
              )}
            </Box>
          </>
        );
      })()}
    </>
  );
}
