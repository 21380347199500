export const categoryTypes = {
    ALL: 'all',
    ARROWS: 'arrows',
    ANIMATIONS: 'animations',
    BANNER: 'banner',
    BORDER: 'border',
    ICON: 'icon',
    ILLUSTRATION: 'illustration',
    LETTER: 'letter',
    LINES: 'lines',
    LOGO: 'logo',
    PATTERN: 'pattern',
    DROPZONE: 'dropzone',
    SHAPES: 'shapes',
    TEXT: 'text',
    VIDEO: 'video',
}