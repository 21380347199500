import React from 'react';

export default function ExternalLink({
  children,
  destination,
}) {
  return (
    <a href={destination} target='_blank' rel='noopener noreferrer'>
      {children}
    </a>
  );
}
