import Apps from '@material-ui/icons/Apps';
import BorderStyle from '@material-ui/icons/BorderStyle';
import Extension from '@material-ui/icons/Extension';
import FontDownload from '@material-ui/icons/FontDownload';
import Image from '@material-ui/icons/Image';
import Layers from '@material-ui/icons/Layers';
import LineStyle from '@material-ui/icons/LineStyle';
import PermMedia from '@material-ui/icons/PermMedia';
import PlayCircle from '@material-ui/icons/PlayCircleFilled';
import Shuffle from '@material-ui/icons/Shuffle';
import TextFormat from '@material-ui/icons/TextFormat';
import Texture from '@material-ui/icons/Texture';
import ViewCarousel from '@material-ui/icons/ViewCarousel';
import Wallpaper from '@material-ui/icons/Wallpaper';

export default [{
    icon: PermMedia,
    isUnselectable: true,
    label: 'All',
    name: 'all',
    path: '/media/all',
  }, {
    icon: Shuffle,
    label: 'Arrows',
    name: 'arrows',
    path: '/media/arrows',
  }, {
    icon: PlayCircle,
    label: 'Animations',
    name: 'animations',
    path: '/media/animations',
  }, {
    icon: ViewCarousel,
    label: 'Banners',
    name: 'banner',
    path: '/media/banners',
  }, {
    icon: BorderStyle,
    label: 'Borders',
    name: 'border',
    path: '/media/borders',
  }, {
    icon: Apps,
    label: 'Icons',
    name: 'icon',
    path: '/media/icons',
  }, {
    icon: Image,
    label: 'Illustrations',
    name: 'illustration',
    path: '/media/illustrations',
  }, {
    icon: FontDownload,
    label: 'Letters',
    name: 'letter',
    path: '/media/letters',
  }, {
    icon: LineStyle,
    label: 'Lines',
    name: 'lines',
    path: '/media/lines',
  }, {
    icon: Wallpaper,
    label: 'Logos',
    name: 'logo',
    path: '/media/logos',
  }, {
    icon: Texture,
    label: 'Patterns',
    name: 'pattern',
    path: '/media/patterns',
  }, {
    icon: Layers,
    label: 'Shape Masks',
    name: 'dropzone',
    path: '/media/shapemasks',
  }, {
    icon: Extension,
    label: 'Shapes',
    name: 'shapes',
    path: '/media/shapes',
  }, {
    icon: TextFormat,
    label: 'Text',
    name: 'text',
    path: '/media/text',
  }, {
    icon: PlayCircle,
    label: 'Video',
    name: 'video',
    path: '/media/video',
  }
];
