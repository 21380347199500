import React, { useEffect, useState } from 'react';
import { FAILED, LOADING, READY } from 'lib/Status';
import { makeStyles } from '@material-ui/core';
import { toDateTimeStr } from 'lib/dateUtil';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import getTeamType from 'lib/getTeamType';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import UserWorkspaceDraftsContainer from 'views/containers/UserWorkspaceDraftsContainer';

const useStyles = makeStyles((theme) => ({
  tight: {
    whiteSpace: 'nowrap',
    width: '1%',
  },
  select: {
    padding: theme.spacing(1, 1, 1, 2),
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  emtpyHeadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  headingDivider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
  breadcrumbs: {
    cursor: 'pointer'
  },
  menuPaper: {
    width: '350px',
  },
  foldersWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "0px auto 24px",
  },
}));

export default function TeamUsers({
  fetchUserTeams,
  history,
  userId,
  userPreferences,
  updateUserPreferences,
  userTeams,
}) {
  const styles = useStyles();
  const [openRow, setOpenRow] = useState(null);

  useEffect(() => {
    if (!userTeams
      || userTeams.meta.userId !== userId
    ) {
      fetchUserTeams({ userId });
    }
  });

  if (!userTeams
    || !userTeams.meta
    || userTeams.meta.userId !== userId
  ) {
    return null;
  }

  const handleDesignButtonClick = (e, team) => {
    e.preventDefault();
    e.stopPropagation()
    if (openRow !== team.id) {
      setOpenRow(team.id);
    } else {
      setOpenRow(null);
    }
  };

  const handleUpdateUserPreferences = (e, team) => {
    e.preventDefault();
    e.stopPropagation();

    updateUserPreferences({
      teamId: team.id, 
      userId,
      createdAt: userPreferences.createdAt
    })
  };

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h6">
          User Teams
        </Typography>
      </Box>

      {userTeams.meta.status === FAILED ? (
        <Box
          borderTop="1px solid #eee"
          p={2}
          textAlign="center"
          fontStyle="italic"
        >
          Failed to load team users.
        </Box>
      ) : (
        <Box p={2}>
          {(userTeams.meta.status === LOADING
            || (userTeams.meta.status === READY
              && (userTeams.payload || []).length > 0
            ))
            && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell className={styles.tight}>Type</TableCell>
                      <TableCell align="right">Created At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(userTeams.payload || []).map((team) => (
                      <>
                        <TableRow
                          hover
                          key={team.id}
                          onClick={() => history.push(`/teams/${team.id}`)}
                        >
                          <TableCell className={styles.tight}>
                            <Button
                              onClick={(e) => {
                                handleDesignButtonClick(e, team);
                              }}
                              variant="outlined"
                            >Designs
                            </Button>
                          </TableCell>
                          <TableCell className={styles.tight}>
                            <Button
                              disabled={userPreferences.defaultTeamId === team.id}
                              onClick={(e) => {
                                handleUpdateUserPreferences(e, team);
                              }}
                              variant="outlined"
                            >Set Default Team
                            </Button>
                          </TableCell>
                          <TableCell>{`${team.name}`} {userPreferences.defaultTeamId === team.id && (<b>(Default Team)</b>)}</TableCell>
                          <TableCell className={styles.tight}>
                            {getTeamType(team)}
                          </TableCell>
                          <TableCell className={styles.tight}>
                            {toDateTimeStr(team.createdAt)}
                          </TableCell>
                        </TableRow>
                        {openRow === team.id && (
                          <UserWorkspaceDraftsContainer team={team} openRow={openRow}/>
                        )
                        }
                      </>
                    ))}

                    {(userTeams.meta.status === LOADING) && (
                      <TableRow>
                        <TableCell><Skeleton /></TableCell>
                        <TableCell><Skeleton /></TableCell>
                        <TableCell><Skeleton /></TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          }

          {(userTeams.meta.status === READY
            && (userTeams.payload || []).length === 0)
            && (
              <Box p={4} textAlign="center" fontStyle="italic">
                No team users.
              </Box>
            )}
        </Box>
      )}
    </Paper>
  );
}
