import React, { useState } from 'react';
import * as actions from 'state/api/users/UsersApiActions';
import * as assetActions from 'state/api/assets/AssetApiActions';
import * as selectors from 'state/api/users/UsersApiSelectors';
import * as assetSelectors from 'state/api/assets/AssetApiSelectors'
import * as folderSelectors from 'state/entities/folders/folderSelectors'
import { Route, Switch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector, isAdminSelector } from 'state/app/SessionSelectors';
import UserDetail from 'views/components/Users/UserDetail';
import UserSearch from 'views/components/Users/UserSearch';
import { fetchUserWorkspaceFolders, fetchUserWorkspaceFolderSizes } from 'state/entities/folders/folderActions'

export default function TeamContainer(props) {
  const accessToken = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const user = useSelector(selectors.userSelector);
  const users = useSelector(selectors.usersSelector);
  const userTeams = useSelector(selectors.userTeamsSelector);
  const userPreferences = useSelector(selectors.userPreferencesSelector);
  const userDrafts = useSelector(assetSelectors.userTeamDraftsSelector);
  const remixUrls = useSelector(state => state.api.assets.collectionRemixUrls)
  const userDraftsSizes = useSelector(assetSelectors.userTeamDraftsSizesSelector);
  const isAdmin = useSelector(isAdminSelector);
  const userTeamWorkspaceFolders = useSelector(folderSelectors.userWorkspaceFoldersSelector)
  const userWorkspaceFoldersSizes = useSelector(folderSelectors.userWorkspaceFoldersSizesSelector)
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);

  return (
    <Switch>
      <Route path="/users/:userId">
        <UserDetail
          fetchUser={(p) => dispatch(actions.fetchUser({ accessToken, ...p }))}
          deleteUserGDPR={(p) => dispatch(actions.deleteUserGDPR({ accessToken, ...p }))}
          deleteUserSoft={(p) => dispatch(actions.deleteUser({ accessToken, active: user.payload.active, ...p }))}
          fetchUserTeams={(p) => dispatch(actions.fetchUserTeams({ accessToken, ...p }))}
          fetchUserPreferences={(p) => dispatch(actions.fetchUserPreferences({ accessToken, ...p }))}
          fetchUserTeamDrafts={(p) => dispatch(assetActions.fetchUserTeamDrafts({ accessToken, ...p }))}
          fetchUserTeamDraftsSizes={(p) => dispatch(assetActions.fetchUserTeamDraftsSizes({ accessToken, ...p }))}
          fetchUserWorkspaceFolders={(p) => dispatch(fetchUserWorkspaceFolders({ accessToken, ...p }))}
          fetchUserWorkspaceFolderSizes={(p) => dispatch(fetchUserWorkspaceFolderSizes({ accessToken, ...p }))}
          updateUserPreferences={(p) => dispatch(actions.updateUserPreferences({ accessToken, ...p }))}
          user={user}
          userTeams={userTeams}
          userDrafts={userDrafts}
          userPreferences={userPreferences}
          userDraftsSizes={userDraftsSizes}
          userTeamWorkspaceFolders={userTeamWorkspaceFolders}
          userWorkspaceFoldersSizes={userWorkspaceFoldersSizes}
          isAdmin={isAdmin}
          remixUrls={remixUrls}
          setShowDeleteSuccess={setShowDeleteSuccess}
          {...props}
        />
      </Route>
      <Route path="/users">
        <UserSearch
          fetchUsers={(p) => dispatch(actions.fetchUsers({ accessToken, paginate: users || {}, ...p }))}
          users={users}
          setShowDeleteSuccess={setShowDeleteSuccess}
          showDeleteSuccess={showDeleteSuccess}
          {...props}
        />
      </Route>
    </Switch>
  );
}
