import store from 'state/store';
import mime from 'mime-types';
import * as types from 'state/api/assets/AssetApiTypes';
import { createAction } from 'lib/apiMiddlewareUtils';
import { currentUserIdSelector, tokenSelector } from 'state/app/SessionSelectors';
import { get as getPath } from 'lodash';

const BASE_ASSET_API = process.env.REACT_APP_HOST_ASSET_SERVICE;
const EASIL_TEAM_ID = process.env.REACT_APP_EASIL_TEAM_ID;

export const fetchCatalogue = ({ size }) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  if (!size) {
    throw new Error('Template code / size required.');
  }

  return createAction({
    endpoint: `${BASE_ASSET_API}/v2/teams/${EASIL_TEAM_ID}/catalogue/subscription`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
    method: 'GET',
    paginate: state.entities.collections.catalogue || {},
    params: {
      orderBy: 'priority',
      size,
    },
    types: [
      types.ASSET_CATALOGUE_REQUEST,
      types.ASSET_CATALOGUE_SUCCESS,
      types.ASSET_CATALOGUE_FAILURE,
    ],
  });
};

export const fetchTeamCatalogue = ({ size, teamId }) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);
  const _params = {
      orderBy: 'priority',
      size,
      scope: 'team',
  }

  return createAction({
    endpoint: `${BASE_ASSET_API}/v1/teams/${teamId}/catalogue`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
    method: 'GET',
    paginate: state.api.assets.teamCatalogue.sizes[size] || {},
    params: _params,
    types: [
      types.ASSET_TEAM_CATALOGUE_REQUEST,
      types.ASSET_TEAM_CATALOGUE_SUCCESS,
      types.ASSET_TEAM_CATALOGUE_FAILURE,
    ],
  });
};

export const fetchUserTeamDrafts = ({
  accessToken,
  teamId,
  userId,
  folderId,
  size
}) => {
  const state = store.getState();

  const _params = {
    count: 20,
  };

  if (folderId) {
    _params['folderId'] = folderId;
  }

  if (size && size !== "All Designs") {
    _params['size'] = size;
  }

  let pagination = {}

  if (!folderId && state.api.assets.userDrafts && state.api.assets.userDrafts.hasOwnProperty(teamId) && state.api.assets.userDrafts[teamId].hasOwnProperty(size)) {
    pagination = getPath(state.api.assets.userDrafts[teamId], size, {}) || {}
  }

  if (folderId && state.api.assets.userDrafts && state.api.assets.userDrafts.hasOwnProperty(teamId) && state.api.assets.userDrafts[teamId].hasOwnProperty("folders")) {
    pagination = getPath(state.api.assets.userDrafts[teamId].folders[folderId], size, {}) || {}
  }

  return createAction({
  endpoint: `${BASE_ASSET_API}/v1/teams/${teamId}/users/${userId}/drafts`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    accept: 'application/json',
  },
  method: 'GET',
  params: _params,
  paginate: pagination,
  types: [
    types.ASSET_USER_TEAM_DRAFTS_REQUEST,
    {type: types.ASSET_USER_TEAM_DRAFTS_SUCCESS,
      meta: {teamId, folderId, size, count: _params.count, isUserDrafts: true}, 
    },
    types.ASSET_USER_TEAM_DRAFTS_FAILURE,
  ],
});}

export const fetchUserTeamDraftsSizes = ({
  accessToken,
  teamId,
  userId,
}) => createAction({
  endpoint: `${BASE_ASSET_API}/v1/teams/${teamId}/users/${userId}/drafts/sizes`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    accept: 'application/json',
  },
  method: 'GET',
  params: {
    count: 100,
  },
  types: [
    types.ASSET_USER_TEAM_DRAFTS_SIZES_REQUEST,
    {type: types.ASSET_USER_TEAM_DRAFTS_SIZES_SUCCESS,
      meta: {teamId}, 
    },
    types.ASSET_USER_TEAM_DRAFTS_SIZES_FAILURE,
  ]
});

export const fetchTeamCatalogueSizes = ({teamId, folderId}) => {
  const accessToken = tokenSelector(store.getState());
  let endpointUrl = `${BASE_ASSET_API}/v2/teams/${teamId}/catalogue/sizes`
  if(folderId){
    endpointUrl += `?folderId=${folderId}`
  }

  return createAction({
    endpoint: endpointUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
    method: 'GET',
    params: {
      count: 100,
      scope: 'team',
    },
    types: [
      types.ASSET_TEAM_CATALOGUE_SIZES_REQUEST,
      types.ASSET_TEAM_CATALOGUE_SIZES_SUCCESS,
      types.ASSET_TEAM_CATALOGUE_SIZES_FAILURE,
    ]
  });
};

export const fetchCollectionRemixUrl = (collectionId) => {
  const accessToken = tokenSelector(store.getState());
  return createAction({
    body: JSON.stringify({ collectionId }),
    endpoint: `${BASE_ASSET_API}/v1/collections/${collectionId}/remix/url`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    types: [
      types.ASSET_CREATE_COLLECTION_REMIX_REQUEST,
      {
        type: types.ASSET_CREATE_COLLECTION_REMIX_SUCCESS,
        meta: {collectionId} 
      },
      types.ASSET_CREATE_COLLECTION_REMIX_FAILURE
    ]
  });
}

export const fetchCatalogueSizes = () => {
  const accessToken = tokenSelector(store.getState());

  return createAction({
    endpoint: `${BASE_ASSET_API}/v1/teams/${EASIL_TEAM_ID}/catalogue/subscription/sizes`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
    method: 'GET',
    params: {
      count: 100,
    },
    types: [
      types.ASSET_CATALOGUE_SIZES_REQUEST,
      types.ASSET_CATALOGUE_SIZES_SUCCESS,
      types.ASSET_CATALOGUE_SIZES_FAILURE,
    ]
  });
};

export const fetchDesigns = ({
  context = 'templates',
  scope = 'easil',
  size,
  term,
}) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${BASE_ASSET_API}/v1/designs`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
    method: 'GET',
    paginate: state.entities.collections.catalogue || {},
    params: {
      context,
      scope,
      size,
      term,
    },
    types: [
      types.ASSET_DESIGNS_REQUEST,
      types.ASSET_DESIGNS_SUCCESS,
      types.ASSET_DESIGNS_FAILURE,
    ],
  });
};

export const fetchDraftCollectionsForCurrentUser = () => {
  const state = store.getState();
  const accessToken = tokenSelector(state);
  const userId = currentUserIdSelector(state);

  return createAction({
    endpoint: `${BASE_ASSET_API}/v1/teams/${EASIL_TEAM_ID}/users/${userId}/collections`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
    method: 'GET',
    paginate: state.entities.collections.myDrafts || {},
    params: {
      status: 'DRAFT',
      userId,
    },
    types: [
      types.ASSET_USER_COLLECTIONS_DRAFTS_REQUEST,
      types.ASSET_USER_COLLECTIONS_DRAFTS_SUCCESS,
      types.ASSET_USER_COLLECTIONS_DRAFTS_FAILURE,
    ],
  });
};

export const fetchStagedCollections = () => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${BASE_ASSET_API}/v1/staged/collections`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      accept: 'application/json',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
    method: 'GET',
    paginate: state.entities.collections.stagedDesigns || {},
    types: [
      types.ASSET_COLLECTIONS_STAGED_REQUEST,
      types.ASSET_COLLECTIONS_STAGED_SUCCESS,
      types.ASSET_COLLECTIONS_STAGED_FAILURE,
    ],
  });
};

export const fetchStagedCollectionsForCurrentUser = () => {
  const state = store.getState();
  const accessToken = tokenSelector(state);
  const userId = currentUserIdSelector(state);

  return createAction({
    endpoint: `${BASE_ASSET_API}/v1/teams/${EASIL_TEAM_ID}/users/${userId}/collections`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
    method: 'GET',
    paginate: state.entities.collections.myStaged || {},
    params: {
      status: 'STAGED',
    },
    types: [
      types.ASSET_USER_COLLECTIONS_STAGED_REQUEST,
      types.ASSET_USER_COLLECTIONS_STAGED_SUCCESS,
      types.ASSET_USER_COLLECTIONS_STAGED_FAILURE,
    ],
  });
};

// update a collection from draft and staged environment
export const updateCollection = (collectionId, status) => {
  const accessToken = tokenSelector(store.getState());

  if (!['DRAFT', 'STAGED'].includes(status)) {
    throw new Error('Invalid collection status.');
  }

  return createAction({
    body: JSON.stringify({ collectionId, status }),
    endpoint: `${BASE_ASSET_API}/v1/collections/${collectionId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    types: [
      types.ASSET_UPDATE_COLLECTION_REQUEST,
      {
        type: types.ASSET_UPDATE_COLLECTION_SUCCESS,
        payload: { collectionId, status }
      },
      {
        type: types.ASSET_UPDATE_COLLECTION_FAILURE,
        payload: { collectionId }
      },
    ]
  });
};

// TODO: Separate into create, update and delete functions
export const updateCollectionSubscription = (
  method,
  collectionId,
  priority,
  subscriptionCode,
  priceUsd,
  priceAud
) => {
  const accessToken = tokenSelector(store.getState());

  if (!['DELETE', 'POST', 'PUT'].includes(method)) {
    throw new Error('Invalid HTTP method.');
  }

  return createAction({
    body: JSON.stringify({ collectionId, priority, subscriptionCode, priceUsd, priceAud }),
    endpoint: `${BASE_ASSET_API}/v1/collections/${collectionId}/subscriptions`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method,
    types: [
      types.ASSET_UPDATE_COLLECTION_SUBSCRIPTION_REQUEST,
      {
        type: types.ASSET_UPDATE_COLLECTION_SUBSCRIPTION_SUCCESS,
        payload: {
          method,
          collectionId,
          priority,
          subscriptionCode,
          priceUsd,
          priceAud
        }
      },
      types.ASSET_UPDATE_COLLECTION_SUBSCRIPTION_FAILURE,
    ]
  });
};

export const updateDesignThumbnail = (designId, collectionId, file) => async dispatch => {
  const accessToken = tokenSelector(store.getState());

  const contentType = mime.lookup(file.name);
  const body = {
    designId,
    responseContentType: contentType,
  };

  const fetchUrlResponse = await dispatch(createAction({
    body: JSON.stringify(body),
    endpoint: `${BASE_ASSET_API}/v1/designs/${designId}/thumbnail/url`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    types: [
      {
        type: types.ASSET_FETCH_DESIGN_THUMBNAIL_UPLOAD_URL_REQUEST,
        payload: {
          designId,
        }
      },
      types.ASSET_FETCH_DESIGN_THUMBNAIL_UPLOAD_URL_SUCCESS,
      types.ASSET_FETCH_DESIGN_THUMBNAIL_UPLOAD_URL_FAILURE,
    ]
  }));
  if (fetchUrlResponse.error) return;

  const uploadResponse = await dispatch(createAction({
    body: file,
    endpoint: fetchUrlResponse.payload.url,
    headers: {
      "Content-Type": contentType
    },
    method: 'PUT',
    types: [
      types.ASSET_UPLOAD_DESIGN_THUMBNAIL_FILE_REQUEST,
      types.ASSET_UPLOAD_DESIGN_THUMBNAIL_FILE_SUCCESS,
      types.ASSET_UPLOAD_DESIGN_THUMBNAIL_FILE_FAILURE,
    ]
  }));
  if (uploadResponse.error) return;

  const updateThumbnailBody = {
    designId,
    filename: fetchUrlResponse.payload.filename,
  }
  return dispatch(createAction({
    body: JSON.stringify(updateThumbnailBody),
    endpoint: `${BASE_ASSET_API}/v1/designs/${designId}/thumbnail`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    types: [
      types.ASSET_UPDATE_DESIGN_THUMBNAIL_FILE_REQUEST,
      types.ASSET_UPDATE_DESIGN_THUMBNAIL_FILE_SUCCESS,
      types.ASSET_UPDATE_DESIGN_THUMBNAIL_FILE_FAILURE,
    ]
  }));
};
