import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React from 'react';

export default function LoadMore({ onClick }) {
  return (
    <Box
      textAlign="center"
      paddingBottom={5}
      paddingTop={5}
    >
      <Button
        onClick={onClick}
        endIcon={<ExpandMore />}
        variant="contained"
      >
        More
      </Button>
    </Box>
  );
}
