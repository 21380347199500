import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CouponDialog from './CouponDialog';
import LoadMore from 'views/components/LoadMore/LoadMore';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { LOADING, READY } from 'lib/Status';
import { toDateStr } from 'lib/dateUtil';

const useStyles = makeStyles((theme) => ({
  chipSpacing: {
    marginRight: theme.spacing(1)
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  header2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export default function CouponsList({
  coupons,
  createSubscriptionCoupon,
  fetchCoupons,
  updateSubscriptionCoupon,
}) {
  const classes = useStyles();
  const [couponEdit, setCouponEdit] = useState(null);
  const [successActionString, setSuccessActionString] = useState(null);

  useEffect(() => {
    if (coupons === null) {
      fetchCoupons();
    }
  });

  if (!coupons) {
    return null;
  }

  return (
    <>
      <div className={classes.headingContainer}>
        <h2 className={classes.header2}>Coupons</h2>
        {coupons.meta.status === READY && (
          <Box marginTop={1.5}>
            <Button
              onClick={() => setCouponEdit({})}
              startIcon={<AddIcon />}
              variant="contained"
            >
              Add Coupon
            </Button>
          </Box>
        )}
      </div>

      <Box
        component={Paper}
        p={2}
      >
        <Table>
          <TableHead>
            <TableRow>
              <Box component={TableCell} width="20%">Code</Box>
              <Box component={TableCell} width="20%">Plans</Box>
              <Box component={TableCell} width="20%">Intervals</Box>
              <Box component={TableCell} width="5%">Value</Box>
              <Box component={TableCell} width="5%">Type</Box>
              <Box component={TableCell} width="15%">Start</Box>
              <Box component={TableCell} width="15%">End</Box>
            </TableRow>
          </TableHead>
          <TableBody>
            {coupons.meta.status === READY
              && (coupons.payload || []).map((coupon) =>
              <TableRow
                hover
                key={coupon.code}
                onClick={() => setCouponEdit(coupon)}
              >
                <TableCell><code>{coupon.code}</code></TableCell>
                <TableCell>
                  {coupon.eligiblePlans.map(plan => <Chip label={plan} key={coupon.code + plan} size="small" variant="outlined" className={classes.chipSpacing} />)}
                </TableCell>
                <TableCell>
                  {coupon.eligibleIntervals.map(interval => <Chip label={interval} key={coupon.code + interval} size="small" variant="outlined" className={classes.chipSpacing} />)}
                </TableCell>
                <TableCell>{coupon.value}</TableCell>
                <TableCell>{coupon.valueType}</TableCell>
                <TableCell>{toDateStr(coupon.startAt)}</TableCell>
                <TableCell>{toDateStr(coupon.endAt)}</TableCell>
              </TableRow>
            )}

            {coupons.meta.status === LOADING && (
              <TableRow>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
                <TableCell><Skeleton /></TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        {coupons.meta.isMoreAvailable && (
          <LoadMore
            onClick={() => fetchCoupons()}
          />
        )}
      </Box>

      {couponEdit && (
        <CouponDialog
          createSubscriptionCoupon={createSubscriptionCoupon}
          coupon={couponEdit}
          onClose={() => setCouponEdit(null)}
          updateSubscriptionCoupon={updateSubscriptionCoupon}
          setSuccessActionString={setSuccessActionString}
        />
      )}

      {successActionString !== null && (
        <Snackbar
          autoHideDuration={10000}
          onClose={() => setSuccessActionString(null)}
          open
        >
          <Alert
            severity="success"
            variant="filled"
          >
            Coupon {successActionString}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
