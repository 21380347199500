import { createAction } from 'lib/apiMiddlewareUtils';
import * as types from 'state/api/teams/TeamApiTypes';

const V1 = `${process.env.REACT_APP_HOST_USER_SERVICE}/v1`;
const V1Subscription = `${process.env.REACT_APP_HOST_SUBSCRIPTION_SERVICE}/v1`;
const V2 = `${process.env.REACT_APP_HOST_USER_SERVICE}/v2`;

export const fetchTeam = ({
  accessToken,
  teamId,
}) => createAction({
  endpoint: `${V1}/teams/${teamId}`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  },
  method: 'GET',
  params: { teamId },
  types: [
    types.TEAM_REQUEST,
    types.TEAM_SUCCESS,
    types.TEAM_FAILURE,
  ],
});

export const fetchParentTeam = ({
  accessToken,
  parentId,
  teamId,
}) => createAction({
  endpoint: `${V1}/teams/${parentId}`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  },
  method: 'GET',
  params: { parentId },
  types: [
    types.PARENT_TEAM_REQUEST,
    {
      type: types.PARENT_TEAM_SUCCESS,
      meta: { teamId },
    },
    types.PARENT_TEAM_FAILURE,
  ],
});

export const fetchAllParentTeams = ({accessToken, parentIds, teamId}) => (dispatch, getState) => {

  parentIds.forEach(parentId => {
    dispatch(fetchParentTeam({ accessToken, parentId, teamId }));
  });
}

export const fetchTeams = ({
  accessToken,
  name,
  paginate,
}) => createAction({
  endpoint: `${V1}/teams`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  },
  method: 'GET',
  paginate,
  params: { name },
  types: [
    types.TEAMS_REQUEST,
    types.TEAMS_SUCCESS,
    types.TEAMS_FAILURE,
  ],
});

export const fetchCancellingTeams = ({
  accessToken,
  paginate,
}) => createAction({
  endpoint: `${V1Subscription}/teams/subscriptions/?status=CANCELLING`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  },
  method: 'GET',
  paginate,
  types: [
    types.TEAMS_CANCELLING_REQUEST,
    types.TEAMS_CANCELLING_SUCCESS,
    types.TEAMS_CANCELLING_FAILURE,
  ],
});

export const fetchTeamUsers = ({ accessToken, teamId }) => (
  createAction({
    endpoint: `${V1}/teams/${teamId}/users`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
    method: 'GET',
    params: { teamId },
    types: [
      types.USERS_REQUEST,
      types.USERS_SUCCESS,
      types.USERS_FAILURE,
    ],
  })
);

export const fetchTeamSubteams = ({
  accessToken,
  paginate,
  parentId,
  sortBy,
  sortOrder,
  userId,
}) => createAction({
  endpoint: `${V2}/users/${userId}/teams`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  },
  method: 'GET',
  paginate,
  params: {
    parentId,
    sortBy,
    sortOrder,
    userId,
  },
  types: [
    types.SUBTEAMS_REQUEST,
    types.SUBTEAMS_SUCCESS,
    types.SUBTEAMS_FAILURE,
  ],
});

export const updateTeam = ({
  accessToken,
  logoFile,
  team,
}) => {
  const body = new FormData();

  body.append('country', team.country);
  body.append('id', team.id);
  body.append('name', team.name);
  body.append('logo', logoFile);
  
  return createAction({
    body,
    endpoint: `${V1}/teams/${team.id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
    method: 'PUT',
    params: { teamId: team.id },
    types: [
      types.TEAM_REQUEST,
      types.TEAM_SUCCESS,
      types.TEAM_FAILURE,
    ],
  });
};
