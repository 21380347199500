import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import BurstMode from '@material-ui/icons/BurstMode';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import Image from '@material-ui/icons/Image';
import ImageOutlined from '@material-ui/icons/ImageOutlined';
import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import PhotoLibrary from '@material-ui/icons/PhotoLibrary';

export default function DesignsDrawer({ isBrandManager }) {
  const location = useLocation();

  return (
    <List>
      <ListSubheader>
        <Box
          component={Link}
          ml={-2}
          onClick={(e) => e.stopPropagation()}
          to="/"
        >
          <IconButton>
            <ChevronLeft />
          </IconButton>
        </Box>
        Designs
      </ListSubheader>

      <ListItem
        component={Link}
        selected={location.pathname === '/designs'}
        to="/designs"
      >
        <ListItemIcon>
          <BurstMode />
        </ListItemIcon>
        <ListItemText primary='Catalogue' />
      </ListItem>
      <ListItem
        component={Link}
        selected={location.pathname === '/designs/mydrafts'}
        to="/designs/mydrafts"
      >
        <ListItemIcon>
          <ImageOutlined />
        </ListItemIcon>
        <ListItemText primary='My Drafts' />
      </ListItem>
      <ListItem
        component={Link}
        selected={location.pathname === '/designs/mystaged'}
        to="/designs/mystaged"
      >
        <ListItemIcon>
          <Image />
        </ListItemIcon>
        <ListItemText primary='My Staged Designs' />
      </ListItem>
      {isBrandManager && (
        <ListItem
          component={Link}
          selected={location.pathname === '/designs/staged'}
          to="/designs/staged"
        >
          <ListItemIcon>
            <PhotoLibrary />
          </ListItemIcon>
          <ListItemText primary='Staged Designs' />
        </ListItem>
      )}
      <br />
    </List>
  );
}
