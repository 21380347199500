import { templateSizesSelector } from "state/entities/templateSizes/templateSizesSelectors";
import { get as getPath } from 'lodash';

export const ordersCouponsSelector = (state) => state.api.orders.ordersCoupons;
export const printMarketingSelector = (state) => state.api.orders.printMarketingDescriptionUrls;
export const unassignedOrderLabelsSelector = (state) => state.api.orders.unassignedOrderLabels;
export const unassignedTemplateCodesSelector = (state) => state.api.orders.unassignedTemplateCodes;
export const printProvidersSelector = (state) => state.api.orders.printProviders;
export const printOptionsSelector = (state) => state.api.orders.printOptions;
export const templateCodesForLabelSelector = (state, labelName) => getPath(state, `api.orders.labelTemplateCodes.${labelName}.templateCodes`, []);
export const templateSizesForLabelSelector = (state, labelName) => {
  const templateCodes = templateCodesForLabelSelector(state, labelName);
  const templateSizes = templateSizesSelector(state);
  return templateCodes.reduce((templateSizeAccumulator, templateCode) => {
    const templateSize = templateSizes[templateCode];
    if (templateSize) {
      return [ ...templateSizeAccumulator, templateSize ];
    }
    return templateSizeAccumulator;
  }, []);
}
