import React, { useEffect } from 'react';
import { FAILED, LOADING, READY } from 'lib/Status';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

export default function TeamUsers({
  fetchTeamUsers,
  history,
  teamId,
  teamUsers,
}) {
  useEffect(() => {
    if (!teamUsers
      || teamUsers.meta.teamId !== teamId
    ) {
      fetchTeamUsers({ teamId });
    }
  });

  if (!teamUsers
    || !teamUsers.meta
    || teamUsers.meta.teamId !== teamId
  ) {
    return null;
  }

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h6">
          Team Users
        </Typography>
      </Box>

      {teamUsers.meta.status === FAILED ? (
        <Box
          borderTop="1px solid #eee"
          p={2}
          textAlign="center"
          fontStyle="italic"
        >
          Failed to load team users.
        </Box>
      ) : (
        <Box p={2}>
          {(teamUsers.meta.status === LOADING
            || (teamUsers.meta.status === READY
              && (teamUsers.payload || []).length > 0
            ))
            && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell align="right">Roles</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(() => {
                      switch (teamUsers.meta.status) {
                        case LOADING: {
                          return (
                            <TableRow>
                              <TableCell><Skeleton variant='text' /></TableCell>
                              <TableCell><Skeleton variant='text' /></TableCell>
                              <TableCell><Skeleton variant='text' /></TableCell>
                            </TableRow>
                          );
                        }

                        case READY: {
                          return teamUsers.payload.map(({ email, id, name, roles }) => (
                            <TableRow
                              hover
                              key={id}
                              onClick={() => history.push(`/users/${id}`)}
                            >
                              <TableCell>{name}</TableCell>
                              <TableCell>{email}</TableCell>
                              <TableCell align="right">
                                {(roles || []).map((role) => (
                                  <Box key={role} mb="2px">
                                    <Chip label={role} size="small" />
                                  </Box>
                                ))}
                              </TableCell>
                            </TableRow>
                          ));
                        }

                        default: {
                          return null;
                        }
                      }
                    })()}
                  </TableBody>
                </Table>
              </TableContainer>
            )
          }

          {(teamUsers.meta.status === READY
            && (teamUsers.payload || []).length === 0)
            && (
            <Box p={4} textAlign="center" fontStyle="italic">
              No team users.
            </Box>
          )}
        </Box>
      )}
    </Paper>
  );
}
