import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { FAILED, LOADING, READY } from 'lib/Status';
import CatalogueCard from 'views/components/CatalogueCard/CatalogueCard';
import LoadMore from 'views/components/LoadMore/LoadMore';
import PromotePopover from 'views/components/Designs/PromotePopover';
import DesignTags from 'views/components/DesignTags/DesignTags';
import { isBrandManagerSelector } from 'state/app/SessionSelectors';
import { Clear, Search as SearchIcon } from '@material-ui/icons';
import {
  fetchCatalogue,
  fetchCatalogueSizes,
  fetchDesigns,
  updateCollectionSubscription,
  fetchCollectionRemixUrl,
} from 'state/api/assets/AssetApiActions';
import {
  Divider,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Select,
  Snackbar
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { removeTagFromCatalogueCollection } from '../../../state/api/designTag/DesignTagActions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  menuPaper: {
    width: '260px',
  },
  select: {
    padding: theme.spacing(1, 1, 1, 2),
    '& :focus': {
      backgroundColor: 'white',
    }
  },
}));

export default function CatalogueContainer () {
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [menuEl, setMenuEl] = useState(null);
  const [promoteEl, setPromoteEl] = useState(null);
  const catalogue = useSelector(state => state.entities.collections.catalogue);
  const remixUrls = useSelector(state => state.api.assets.collectionRemixUrls)
  const catalogueSizes = useSelector(state => state.api.assets.catalogueSizes);
  const tagSuggestions = useSelector(state => state.entities.searchSuggestions);
  const dispatch = useDispatch();
  const isBrandManager = useSelector(isBrandManagerSelector);
  const classes = useStyles();
  const query = new URLSearchParams(useLocation().search);
  const urlSize = query.get('size');
  const urlTerm = query.get('term');
  const [term, setTerm] = useState(urlTerm || '');
  const history = useHistory();
  const [copyLinkToClipboard, setCopyLinkToClipboard] = useState(false);
  const [remixNotification, setRemixNotification] = useState(false)

  const { REACT_APP_WEB_APP_HOST, REACT_APP_PURCHASE_COLLECTION_PATH } = process.env;
  const PURCHASE_COLLECTION_URL = selectedDesign ? `${REACT_APP_WEB_APP_HOST}/${REACT_APP_PURCHASE_COLLECTION_PATH}/${selectedDesign.collectionId}` : "";

  useEffect(() => {
    if (catalogueSizes === null) {
      dispatch(fetchCatalogueSizes());
    }
  });

  const handleRedirect = (event, size, term) => {
    let url = '/designs';

    if (size && size !== 'all') {
      url += `&size=${encodeURIComponent(size)}`;
    }

    if (term) {
      url += `&term=${encodeURIComponent(term)}`;
    }

    history.replace(url.replace('&', '?'));
  }; 

  useEffect(() => {
    if (!urlSize && !urlTerm) {
      if (Array.isArray(catalogueSizes)) {
        handleRedirect(undefined, catalogueSizes[0].templateCode);
      }
    } else if (urlTerm) {
      if (!catalogue
        || catalogue.meta.size !== urlSize
        || catalogue.meta.term !== urlTerm
      ) {
        dispatch(fetchDesigns({ size: urlSize, term: urlTerm }));
      }
    } else if (urlSize) {
      if (!catalogue
        || catalogue.meta.size !== urlSize
        || catalogue.meta.term !== undefined
      ) {
        dispatch(fetchCatalogue({ size: urlSize }));
      }
    }
  });

  if (catalogue === null || catalogueSizes === null) {
    return null;
  }

  if (catalogueSizes === FAILED) {
    return <>Failed to fetch catalogue sizes from API.</>;
  }

  if (catalogueSizes === LOADING) {
    return <>Loading...</>;
  }

  if (!catalogueSizes.length) {
    return <>No catalogue sizes found.</>;
  }

  const handleCollectionRemixUrl = async ({collectionId}) => {
    let remixUrl;
    if(remixUrls[collectionId]) {
      remixUrl = remixUrls[collectionId];
    } else {
      const response = await dispatch(fetchCollectionRemixUrl(collectionId));
      remixUrl = response.payload.url;
    }
    navigator.clipboard.writeText(remixUrl);
    setCopyLinkToClipboard(true);
    setRemixNotification(true);
  };

  return (
    <>
      <h2>Design Catalogue</h2>

      <Paper className={classes.root}>
        <Select
          className={classes.select}
          input={<InputBase />}
          labelId="category-label"
          label="Category"
          onChange={(event) => {
            handleRedirect(
              event,
              event.target.value,
              urlTerm,
            );
          }}
          value={urlSize || 'all'}
        >
          <MenuItem
            disabled={!urlTerm}
            value={'all'}
          >
            All Sizes
          </MenuItem>
          {catalogueSizes.map(size => (
            <MenuItem key={size.templateCode} value={size.templateCode}>
              {size.name}
            </MenuItem>
          ))}
        </Select>
        <Divider className={classes.divider} orientation="vertical" />
        <InputBase
          className={classes.input}
          onChange={(event) => {
            if (event.target.value === '' && urlTerm) {
              handleRedirect(event, urlSize);
            }
            setTerm(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              handleRedirect(event, urlSize, term);
            }
          }}
          placeholder="Search Catalogue"
          value={term}
        />
        {term && (
          <>
            <IconButton
              className={classes.iconButton}
              onClick={(event) => {
                setTerm('');
                handleRedirect(event, urlSize);
              }}
            >
              <Clear />
            </IconButton>
            <Divider className={classes.divider} orientation="vertical" />
          </>
        )}
        <IconButton
          type="submit"
          className={classes.iconButton}
          onClick={(event) => handleRedirect(event, urlSize, term)}
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      {catalogue.meta.status === FAILED && <>Failed to fetch catalogue from API.</>}
      {catalogue.meta.status === LOADING && <>Loading...</>}
      {catalogue.meta.status === READY && catalogue.payload.length === 0 && <>No designs found...</>}
      {catalogue.meta.status === READY && catalogue.payload.length > 0 && (
        <>
          <Grid container spacing={3}>
            {catalogue.payload.map(design => (
              <Grid item key={design.id}>
                <CatalogueCard
                  design={design}
                  onDropDownClick={e => {
                    setMenuEl(e.target);
                    setSelectedDesign(design);
                  }}
                  showDropDown={isBrandManager}
                />
              </Grid>
            ))}
          </Grid>
          {catalogue.meta.isMoreAvailable && (
            <LoadMore onClick={() => {
              if (urlTerm) {
                dispatch(fetchDesigns({ size: urlSize, term: urlTerm }));
              } else if (urlSize) {
                dispatch(fetchCatalogue({ size: urlSize }));
              }
            }} />
          )}
        </>
      )}
      {selectedDesign && menuEl && (
        <Menu
          anchorEl={menuEl}
          classes={{ paper: classes.menuPaper }}
          open
          onClose={() => setMenuEl(null)}
        >
          <MenuItem
            onClick={() => {
              setPromoteEl(menuEl);
              setMenuEl(null);
            }}
          >
            Update Collection
          </MenuItem>
          <MenuItem
            onClick={() => {
              dispatch(updateCollectionSubscription('DELETE', selectedDesign.collectionId));
              setMenuEl(null);
            }}
          >
            Remove Collection
          </MenuItem>
          <MenuItem
            onClick={() => {
              navigator.clipboard.writeText(PURCHASE_COLLECTION_URL);
              setCopyLinkToClipboard(true);
            }}
          >
            Copy Purchase Collection URL
          </MenuItem>
          <MenuItem onClick={() => handleCollectionRemixUrl(selectedDesign)}>
            Get Collection Remix URL
          </MenuItem>
          <DesignTags
            collectionId={selectedDesign.collectionId}
            designId={selectedDesign.id}
            removeTagFunction={removeTagFromCatalogueCollection}
            tagSuggestions={tagSuggestions}
          />
        </Menu>
      )}
      {selectedDesign && promoteEl && (
        <PromotePopover
          anchorEl={promoteEl}
          collection={selectedDesign}
          onClose={() => setPromoteEl(null)}
          onSubmit={(event, priority, subscriptionCode, priceUsd, priceAud) => {
            event.preventDefault();
            dispatch(updateCollectionSubscription('PUT', selectedDesign.collectionId, priority, subscriptionCode, priceUsd, priceAud));
            setPromoteEl(null);
          }}
        />
      )}
      {copyLinkToClipboard && (
        <Snackbar
          autoHideDuration={8000}
          open
          onClose={() =>{ 
            setCopyLinkToClipboard(false)
            setRemixNotification(false)
          }}
        >
          <Alert
            severity="success"
            variant="filled"
          >
            {remixNotification ? 'Collection Remix link copied to clipboard' : 'Purchase Collection link copied to clipboard'}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}
