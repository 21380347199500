import React, { useEffect, useState } from 'react';
import { FAILED, READY } from 'lib/Status';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import handleCategoriesChange from './handleCategoriesChange';
import mediaCategories from 'lib/mediaCategories';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import { ACCEPTABLE_MEDIA_TYPES } from '../../../lib/fileUtil';
import { isMediaVideoFormat } from 'lib/mediaSourceHelpers';

export default function MediaItem({
  mediaItem,
  mediaItemCategories,
  onDelete,
  onUpdate,
}) {
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState('');
  const [priority, setPriority] = useState('');
  const [showDialog, setShowDialog] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showThumbInput, setShowThumbInput] = useState(false);
  const [subscriptionCode, setSubscriptionCode] = useState('');
  const [thumbFile, setThumbFile] = useState();

  useEffect(() => {
    const { payload: categoriesPayload } = mediaItemCategories || {};
    const { payload: mediaPayload } = mediaItem || {};

    setCategories(categoriesPayload ?? []);
    setName((mediaPayload || {}).name ?? '');
    setPriority((mediaPayload || {}).priority ?? '');
    setShowDialog(false);
    setShowThumbInput(false);
    setSubscriptionCode((mediaPayload || {}).subscriptionCode ?? '');
  }, [
    mediaItem,
    mediaItemCategories,
  ]);

  if (!mediaItem || !mediaItemCategories) {
    return null;
  }

  if (mediaItem.meta.status === FAILED
    || mediaItemCategories.meta.status === FAILED
  ) {
    return 'Failed to fetch data from API.';
  }

  if (mediaItem.meta.status !== READY
    || mediaItemCategories.meta.status !== READY
    || mediaItem.meta.mediaId !== mediaItemCategories.meta.mediaId
  ) {
    return 'Loading...';
  }

  const categoryLabelMap = {};
  const {
    payload: {
      id,
      thumbnailUrl,
      type,
      url,
    },
  } = mediaItem;

  mediaCategories.forEach(({ label, name }) => {
    categoryLabelMap[name] = label;
  });

  return (
    <>
      <h2>Media: {name}</h2>

      <Box component={Paper} p={6}>
        <Grid
          component="form"
          container
          justify="center"
          onSubmit={async (event) => {
            event.preventDefault();

            const updates = await onUpdate(event, {
              ...mediaItem.payload,
              categories,
              categoriesOld: mediaItemCategories.payload,
              name,
              priority: parseInt(priority, 10),
              subscriptionCode,
              thumbFile,
            });

            const hasErrors = updates.some((update) => update.error);
            if (!hasErrors) {
              setShowSuccess(true);
            }
          }}
          spacing={3}
        >
          <Grid item>
            <Box width="340px">
              <Box
                border="1px solid #ddd"
                borderRadius="borderRadius"
                mt={2}
                p={1}
                position="relative"
              >
                <Box
                  bgcolor="white"
                  color="rgba(0, 0, 0, 0.54)"
                  fontSize="12.5px"
                  left="6px"
                  lineHeight="12.5px"
                  position="absolute"
                  px="6px"
                  top="-6px"
                >
                  Image
                </Box>
                {!isMediaVideoFormat(url) ? (
                  <Box
                    height="110px"
                    style={{
                      backgroundImage: `url(${url})`,
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                    }}
                  />
                ) : (
                  <CardMedia
                    height="110px"
                    src={url}
                    component={"video"}
                    autoPlay
                    loop
                    style={{ backgroundSize: "contain" }}
                  />
                )}
              </Box>
              {showThumbInput ? (
                <TextField
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ accept: ACCEPTABLE_MEDIA_TYPES }}
                  label="Thumbnail File"
                  onChange={(e) => setThumbFile(e.target.files[0])}
                  type="file"
                  variant="outlined"
                />
              ) : (
                <Box
                  border="1px solid #ddd"
                  borderRadius="borderRadius"
                  mt={2}
                  p={1}
                  position="relative"
                >
                  <Box
                    bgcolor="white"
                    color="rgba(0, 0, 0, 0.54)"
                    fontSize="12.5px"
                    left="6px"
                    lineHeight="12.5px"
                    position="absolute"
                    px="6px"
                    top="-6px"
                  >
                    Thumbnail
                  </Box>
                  {!isMediaVideoFormat(url) ? (
                  <Box
                    height="110px"
                    style={{
                      backgroundImage: `url(${thumbnailUrl})`,
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                    }}
                  />
                ) : (
                  <CardMedia
                    height="110px"
                    src={thumbnailUrl}
                    component={"video"}
                    autoPlay
                    loop
                    style={{ backgroundSize: "contain" }}
                  />
                )}
                  <Box position="absolute" right="10px" top="10px">
                    <Fab
                      color="primary"
                      onClick={() => setShowThumbInput(true)}
                      size="small"
                    >
                      <EditIcon />
                    </Fab>
                  </Box>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item>
            <Box display="flex" flexDirection="column" width="340px">
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Name"
                onChange={(e) => setName(e.target.value)}
                required
                value={name}
                variant="outlined"
              />

              <Autocomplete
                clearOnBlur
                freeSolo
                fullWidth
                getOptionLabel={({ label, name }) =>
                  categoryLabelMap[name] || label || name
                }
                multiple
                onChange={(...args) => {
                  setCategories(handleCategoriesChange(...args));
                }}
                options={mediaCategories.filter(
                  ({ isUnselectable }) => !isUnselectable
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label="Categories"
                    variant="outlined"
                  />
                )}
                selectOnFocus
                value={categories}
              />

              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Priority"
                onChange={(e) => setPriority(e.target.value)}
                required
                value={priority}
                variant="outlined"
              />

              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Subscription"
                onChange={(e) => setSubscriptionCode(e.target.value)}
                required
                select
                value={subscriptionCode}
                variant="outlined"
              >
                <MenuItem value="BASIC">Basic</MenuItem>
                <MenuItem value="EDGE">Edge</MenuItem>
                <MenuItem value="PLUS">Plus</MenuItem>
              </TextField>

              <TextField
                disabled
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="ID"
                value={id || ""}
                variant="outlined"
              />

              <TextField
                disabled
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Type"
                value={type || ""}
                variant="outlined"
              />
            </Box>
            <br />
            <Button color="primary" type="submit" variant="contained">
              Save Changes
            </Button>
            &nbsp;&nbsp;
            <Button
              color="secondary"
              onClick={() => setShowDialog(true)}
              variant="contained"
            >
              Delete
            </Button>
            {showSuccess && (
              <Snackbar
                autoHideDuration={10000}
                onClose={() => setShowSuccess(false)}
                open
              >
                <Alert severity="success" variant="filled">
                  Media changes saved successfully
                </Alert>
              </Snackbar>
            )}
            <Dialog
              maxWidth="xs"
              open={showDialog}
              onClose={() => setShowDialog(false)}
            >
              <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  This action cannot be reverted. Would you like to permanently
                  delete this media item?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  color="secondary"
                  onClick={(e) => onDelete(e, { mediaId: id })}
                >
                  Delete
                </Button>
                <Button autoFocus onClick={() => setShowDialog(false)}>
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
