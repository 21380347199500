import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  makeStyles,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import IconPopoutMenu from "./IconPopoutMenu";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditPriceDialog from './EditPriceDialog';
import { printOptionsSortedByQuantity } from "state/entities/printPrices/printPricesSelectors";
import PrintOptionEllipsis from './PrintOptionEllipsis';
import ConfirmationDialog from 'views/components/ConfirmationDialog/ConfirmationDialog';
import EditPrintOptionDialog from "views/components/Orders/EditPrintOptionDialog";
import AddPrintOptionPriceDialog from "views/components/Orders/AddPrintOptionPriceDialog";

const useStyles = makeStyles((theme) => ({
  cell: {
    display: "flex",
    borderBottom: "1px solid #dddddd",
    alignItems: "center",
  },
  description: {
    color: "darkgray"
  },
  pricing: {
    textAlign: "center"
  },
  ellipsis: {
    position: "absolute",
    width: "12px",
    height: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  ellipsisWrapper: {
    position: "relative",
    marginLeft: "-13px"
  },
  optionEllipsisWrapper: {
    width: "0px",
    marginLeft: '8px',
    paddingRight: '8px'
  },
  stockBox: {
    width: "50%",
    display: 'flex',
    alignItems: "center"
  }
}));

export function PrintOptionStock({
  addPrintOptionPrice,
  deletePrintOption,
  deletePrintPriceOption,
  printOptions,
  providerId,
  providerName,
  setSuccessActionString,
  updatePrintOption,
  updatePrintOptionPrice,
}) {
  const classes = useStyles();
  const [isEditing, setIsEditing] = useState(false);

  // current printOption to delete
  const [isConfirming, setIsConfirming] = useState(null);
  const [isEditingPrintOption, setIsEditingPrintOption] = useState(null);
  const [addPriceDialog, setAddPriceDialog] = useState(null);


  const displayValue = (index, option) => {
    return index === 0 || option.id !== printOptions[index - 1]?.id ? option.stock : ""
  };
  const sortedPrintOptionsWithPricing = useSelector(state => printOptionsSortedByQuantity(state, printOptions));

  return (
    <>
      <TableBody>
        {sortedPrintOptionsWithPricing.map((option, index) => (
          <TableRow key={`${option.id}-${index}`}>
            <TableCell classes={{ root: classes.cell }}>
              <div
                className={classes.stockBox}
              >
                {displayValue(index, option) !== '' &&
                  <>
                    <div
                      className={classes.optionEllipsisWrapper}
                    >
                      <PrintOptionEllipsis
                        setIsConfirming={() => {
                          setIsConfirming(option)
                        }}
                        setIsEditingPrintOption={() => {
                          setIsEditingPrintOption(option);
                        }}
                        setAddPriceDialog={() => {
                          setAddPriceDialog(option)
                        }}
                      />
                    </div>
                    {displayValue(index, option)}
                  </>
                }
              </div>
              <Box
                component="div"
                width="35%"
              >
                <div>{option.finishing}</div>
                <div className={classes.description}>{option.description}</div>
              </Box>
              <Box
                component="div"
                width="7.5%"
                classes={{ root: classes.pricing }}
              >
                {option.pricing.minQuantity}
              </Box>
              <Box
                component="div"
                width="7.5%"
                classes={{ root: classes.pricing }}
              >
                {option.pricing.price}
              </Box>
              <Box
                component="div"
                width="0px"
                classes={{ root: classes.ellipsisWrapper }}
              >
                <IconPopoutMenu
                  options={[
                    {
                      label: "Edit Price",
                      onClick: () => setIsEditing(option)
                    },
                    {
                      label: "Delete Price",
                      onClick: () => {
                        deletePrintPriceOption(option);
                        setSuccessActionString("Print option successfully removed");
                      }
                    }
                  ]}
                  icon={<MoreHorizIcon />}
                />
              </Box>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      { /* EDIT PRINT OPTION */}
      {
        isEditingPrintOption &&
        <EditPrintOptionDialog
          printOption={isEditingPrintOption}
          updatePrintOption={updatePrintOption}
          onClose={() => setIsEditingPrintOption(null)}
          providerId={providerId}
          setSuccessActionString={setSuccessActionString}
          providerName={providerName}
        />
      }
      { /* DELETE PRINT OPTION - CONFIRMATION */}
      {
        isConfirming &&
        <ConfirmationDialog
          onSubmit={() => {
            deletePrintOption(isConfirming);
            setIsConfirming(null);
          }}
          onClose={() => setIsConfirming(null)}
          message={'Are you sure you want delete a print option?'}
          title={'Delete print option'}
        />
      }
      { /* ADD PRINT PRICE */}
      {
        addPriceDialog && (
        <AddPrintOptionPriceDialog
          printOption={addPriceDialog}
          onClose={() => setAddPriceDialog(null)}
          addPrintOptionPrice={addPrintOptionPrice}
          setSuccessActionString={setSuccessActionString}
        />
      )}
      { /* EDIT PRINT PRICE */}
      {isEditing &&
        <EditPriceDialog
          onClose={() => setIsEditing(null)}
          printPrice={isEditing}
          onUpdatePrintOptionPrice={args => updatePrintOptionPrice(args)}
          setSuccessActionString={setSuccessActionString}
        />
      }
    </>
  )
}

export default PrintOptionStock