import React, { useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TeamTrialDialog from './TeamTrialDialog';
import TeamDetail from './TeamDetail';
import TeamEditDialog from './TeamEditDialog';
import TeamSubscription from './TeamSubscription';
import TeamSubteams from './TeamSubteams';
import TeamCatalogue from './TeamCatalogue';
import TeamSwitchDialog from './TeamSwitchDialog';
import TeamUsers from './TeamUsers';
import ConfirmationDialog from 'views/components/ConfirmationDialog/ConfirmationDialog';
import TeamParentTeams from './TeamParentTeams';

const DIALOGS = {
  CREATE_TRIAL: 'CREATE_TRIAL',
  EDIT_TEAM: 'EDIT_TEAM',
  EXTEND_TRIAL: 'EXTEND_TRIAL',
  SWITCH_ACC: 'SWITCH_ACC',
  SWITCH_TO_STRIPE: 'SWITCH_TO_STRIPE'
};

export default function Team({
  convertAccountToSubscription,
  convertSubscriptionToAccount,
  createNewTrial,
  fetchAllParentTeams,
  fetchSubscriptionPlans,
  fetchTeam,
  fetchTeamSubscription,
  fetchTeamSubteams,
  fetchTeamUsers,
  fetchTeamCatalogue,
  fetchTeamCatalogueSizes,
  fetchTeamFolders,
  fetchTeamCatalogueFolderContent,
  teamCatalogue,
  teamCatalogueSizes,
  history,
  isProcessingTeam,
  remixUrls,
  subscriptionPlans,
  team,
  teamId,
  teamSubscription,
  teamSubteams,
  teamUsers,
  teamFolders,
  parentTeams,
  updateTeam,
  updateTeamSubscription,
}) {
  const [dialog, setDialog] = useState(null);

  useEffect(() => {
    if (!team
      || team.meta.teamId !== teamId
    ) {
      fetchTeam({ teamId });
    }
  });

  useEffect(() => {
    setDialog(null);
  }, [teamSubscription])

  return (
    <>
      <h2>Team</h2>

      <Box mb={2}>
        <TeamDetail
          fetchTeam={fetchTeam}
          onEditClick={() => setDialog(DIALOGS.EDIT_TEAM)}
          team={team}
          teamId={teamId}
        />
      </Box>

      <Box mb={2}>
        <TeamSubscription
          fetchSubscriptionPlans={fetchSubscriptionPlans}
          fetchTeamSubscription={fetchTeamSubscription}
          onCreateTrialClick={() => setDialog(DIALOGS.CREATE_TRIAL)}
          onExtendTrialClick={() => setDialog(DIALOGS.EXTEND_TRIAL)}
          onSwitchClick={() => setDialog(DIALOGS.SWITCH_ACC)}
          onSwitchToStripeClick={() => setDialog(DIALOGS.SWITCH_TO_STRIPE)}
          subscriptionPlans={subscriptionPlans}
          team={team}
          teamId={teamId}
          teamSubscription={teamSubscription}
        />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TeamUsers
            fetchTeamUsers={fetchTeamUsers}
            history={history}
            teamId={teamId}
            teamUsers={teamUsers}
          />
        </Grid>

        <Grid item xs={6}>
          <TeamSubteams
            fetchTeamSubteams={fetchTeamSubteams}
            history={history}
            parentId={teamId}
            teamSubteams={teamSubteams}
          />
        </Grid>
        <Grid item xs={6}>
          <TeamParentTeams
            fetchAllParentTeams={fetchAllParentTeams}
            history={history}
            teamId={teamId}
            team={team}
            teamSubteams={teamSubteams}
            parentTeams={parentTeams}
          />
        </Grid>
      </Grid>

      <Box mt={2}>
        <TeamCatalogue
          fetchTeamCatalogue={fetchTeamCatalogue}
          fetchTeamCatalogueSizes={fetchTeamCatalogueSizes}
          teamCatalogue={teamCatalogue}
          teamCatalogueSizes={teamCatalogueSizes}
          teamId={teamId}
          fetchTeamFolders={fetchTeamFolders}
          teamFolders={teamFolders}
          fetchTeamCatalogueFolderContent={fetchTeamCatalogueFolderContent}
          remixUrls={remixUrls}
        />
      </Box>

      {(dialog === DIALOGS.CREATE_TRIAL || dialog === DIALOGS.EXTEND_TRIAL) && (
        <TeamTrialDialog
          createNewTrial={createNewTrial}
          dialog={dialog}
          onClose={() => setDialog(null)}
          teamId={teamId}
          teamSubscription={teamSubscription}
          updateTeamSubscription={updateTeamSubscription}
        />
      )}

      {dialog === DIALOGS.EDIT_TEAM && (
        <TeamEditDialog
          isOpen
          isProcessing={isProcessingTeam}
          onCancel={() => setDialog(null)}
          onSave={updateTeam}
          team={team}
        />
      )}

      {dialog === DIALOGS.SWITCH_ACC && (
        <TeamSwitchDialog
          convertSubscriptionToAccount={convertSubscriptionToAccount}
          onClose={() => setDialog(null)}
          teamId={teamId}
        />
      )}

      {dialog === DIALOGS.SWITCH_TO_STRIPE && (
        <ConfirmationDialog
          onSubmit={() => convertAccountToSubscription(teamId)}
          onClose={() => setDialog(null)}
          title="Switch Immediately?"
          message="Are you sure you want to immediately switch this account to Stripe?"
        />
      )}
    </>
  );
}
