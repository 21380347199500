import store from 'state/store';
import * as types from './searchSuggestionsTypes';
import { createAction } from 'lib/apiMiddlewareUtils';
import { tokenSelector } from 'state/app/SessionSelectors';

const BASE_API = process.env.REACT_APP_HOST_ASSET_SERVICE;

export const fetchSearchSuggestions = term => {
  const accessToken = tokenSelector(store.getState());

  return createAction({
    endpoint: `${BASE_API}/v1/designs/suggestions`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
    },
    params: { term },
    method: 'GET',
    types: [
      types.FETCH_SUGGESTIONS_REQUEST,
      types.FETCH_SUGGESTIONS_SUCCESS,
      types.FETCH_SUGGESTIONS_FAILURE,
    ],
  });
};
