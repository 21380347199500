import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { toDateInputStr } from 'lib/dateUtil';

const subscriptionCodeConstants = {
  ALL: "ALL",
  EDGE: "EDGE",
  PLUS: "PLUS",
}

const subscriptionIntervalConstants = {
  ALL: "ALL",
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
}

const valueTypeConstants = {
  PERCENT: "%",
  DOLLAR: "$",
}

const parseSubscriptionCodeArray = (subscriptionCodeArray) => {
  if (!subscriptionCodeArray) {
    return null;
  }
  if (subscriptionCodeArray.length > 1) {
    return subscriptionCodeConstants.ALL;
  }
  return subscriptionCodeArray[0];
}

const parseIntervalArray = (intervalArray) => {
  if (!intervalArray) {
    return null;
  }
  if (intervalArray.length > 1) {
    return subscriptionIntervalConstants.ALL;
  }
  return intervalArray[0];
}

export default function CouponDialog({
  coupon = {},
  createSubscriptionCoupon,
  onClose,
  updateSubscriptionCoupon,
  setSuccessActionString,
}) {
  const [code, setCode] = useState(coupon.code);
  const [endAt, setEndAt] = useState(coupon.endAt);
  const [intervalPeriod, setIntervalPeriod] = useState(parseIntervalArray(coupon.eligibleIntervals) || subscriptionIntervalConstants.ALL);
  const [startAt, setStartAt] = useState(coupon.startAt);
  const [subscriptionCode, setSubscriptionCode] = useState(parseSubscriptionCodeArray(coupon.eligiblePlans) || subscriptionCodeConstants.ALL);
  const [value, setValue] = useState(coupon.value);
  const [valueType, setValueType] = useState(coupon.valueType || valueTypeConstants.PERCENT);

  const parseSubscriptionCode = (subscriptionCodeString) => {
    if (subscriptionCodeString === subscriptionCodeConstants.ALL) {
      return [subscriptionCodeConstants.PLUS, subscriptionCodeConstants.EDGE]
    }
    return [subscriptionCodeString]
  }

  const parseInterval = (intervalString) => {
    if (intervalString === subscriptionIntervalConstants.ALL) {
      return [subscriptionIntervalConstants.MONTHLY, subscriptionIntervalConstants.YEARLY]
    }
    return [intervalString]
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    let response;

    if (coupon.code) { // PUT coupon
      response = await updateSubscriptionCoupon({
        code,
        endAt,
        intervalPeriod: parseInterval(intervalPeriod),
        startAt,
        subscriptionCode: parseSubscriptionCode(subscriptionCode),
      });

      if (!response.error) {
        setSuccessActionString("updated")
        onClose(event);
      }
    } else { // POST coupon
      response = await createSubscriptionCoupon({
        code,
        endAt,
        intervalPeriod: parseInterval(intervalPeriod),
        startAt,
        subscriptionCode: parseSubscriptionCode(subscriptionCode),
        value: parseInt(value, 10),
        valueType,
      });

      if (!response.error) {
        setSuccessActionString("created")
        onClose(event);
      }
    }
  };

  return (
    <Dialog aria-labelledby="form-dialog-title" onClose={onClose} open={true} maxWidth="xs">
      <DialogTitle id="form-dialog-title">
        {coupon.code ? (
          <>Edit Coupon</>
        ) : (
          <>Add Coupon</>
        )}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {coupon.code ? (
            <>
              Please complete this form then click "Save" below
              to update this coupon.
            </>
          ) : (
            <>
              Please complete this form then click "Create" below
              to create this new coupon.
            </>
          )}
        </DialogContentText>

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Code"
          margin="dense"
          onChange={e => setCode(e.target.value)}
          value={code}
          variant="outlined"
          disabled={!!coupon.code}
        />

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Interval Period"
          margin="dense"
          onChange={e => setIntervalPeriod(e.target.value)}
          select
          value={intervalPeriod || subscriptionIntervalConstants.ALL}
          variant="outlined"
        >
          <MenuItem value={subscriptionIntervalConstants.ALL}><em>All</em></MenuItem>
          <MenuItem value={subscriptionIntervalConstants.MONTHLY}>Monthly</MenuItem>
          <MenuItem value={subscriptionIntervalConstants.YEARLY}>Yearly</MenuItem>
        </TextField>

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Subscription Code"
          margin="dense"
          onChange={e => setSubscriptionCode(e.target.value)}
          select
          value={subscriptionCode || subscriptionCodeConstants.ALL}
          variant="outlined"
        >
          <MenuItem value={subscriptionCodeConstants.ALL}><em>All</em></MenuItem>
          <MenuItem value={subscriptionCodeConstants.EDGE}>Edge</MenuItem>
          <MenuItem value={subscriptionCodeConstants.PLUS}>Plus</MenuItem>
        </TextField>

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Value"
          margin="dense"
          onChange={e => setValue(e.target.value)}
          value={value}
          variant="outlined"
          disabled={!!coupon.code}
        />

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Type"
          margin="dense"
          onChange={e => setValueType(e.target.value)}
          select
          value={valueType || valueTypeConstants.PERCENT}
          variant="outlined"
          disabled={!!coupon.code}
        >
          <MenuItem value={valueTypeConstants.PERCENT}>%</MenuItem>
          <MenuItem value={valueTypeConstants.DOLLAR}>$</MenuItem>
        </TextField>

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Start Date"
          margin="dense"
          onChange={e => setStartAt(e.target.value)}
          type="date"
          value={toDateInputStr(startAt)}
          variant="outlined"
        />

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="End Date"
          margin="dense"
          onChange={e => setEndAt(e.target.value)}
          type="date"
          value={toDateInputStr(endAt)}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={handleSubmit}
        >
          {coupon.code ? (
            <>Save</>
          ) : (
            <>Create</>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
