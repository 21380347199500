export const printPricesSelector = state => state.entities.printPrices;
export const printPricesByIdSelector = (state, printPriceId) => printPricesSelector(state)[printPriceId];
export const printPricesByIdsSelector = (state, printPriceIds = []) => printPriceIds.reduce(
  (accumulator, printPriceId) => {
    return {
      ...accumulator,
      [printPriceId]: printPricesByIdSelector(state, printPriceId)
    }
  },
  {}
);
export const printOptionsWithPricing = (state, printOptions) => {
  const pricingIds = printOptions.map(printOption => printOption.pricing);
  const pricing = printPricesByIdsSelector(state, pricingIds);

  return printOptions.map(printOption => ({
    ...printOption,
    pricing: pricing[printOption.pricing]
  }));
}
// eslint-disable-next-line array-callback-return
export const printOptionsSortedByQuantity = (state, printOptions) => printOptionsWithPricing(state, printOptions).sort((a, b) => {
  if (a.id === b.id) {
    return a.pricing.minQuantity - b.pricing.minQuantity
  }
});