import { immutableUpdate } from 'lib/immutability-helper/index';
import * as types from './folderTypes';
import * as catalogueTypes from 'state/api/assets/AssetApiTypes';
import { get as getPath } from 'lodash';

const initState = {
    teamFolders: null,
    userWorkspaceFolders: null,
    userWorkspaceFolderSizes: null,
}

export default function FolderReducers(state = initState, action) {
    switch(action.type) {
      case types.FETCH_TEAM_FOLDERS_REQUEST_SUCCESS: {
        const newEntities = getPath(
          action,
          "payload",
          []
        );
        return immutableUpdate(state, {
          teamFolders: {
            $autoArray: {
              $push: newEntities
            }
          },
        })
      }
      case catalogueTypes.CLEAR_TEAM_CATALOGUE: {
        return initState;
      }
      case types.ASSET_USER_WORKSPACE_FOLDERS_SUCCESS: {
        const { teamId } = action.meta;
        return immutableUpdate(state, {
          userWorkspaceFolders: {
            $auto: {
              [teamId]: {
                $autoArray: {
                  $set: action.payload
                }
              }
            },
          }
        });
      }
      case types.ASSET_USER_WORKSPACE_FOLDER_SIZES_SUCCESS: {
        const { teamId, folderId } = action.meta;
        let sizes;

        const AllSize = {
          templateCode: "All Designs",
          name: "All Designs",
        }
        if(action.payload) {
          sizes = [AllSize, ...action.payload]
        }
        return immutableUpdate(state, {
          userWorkspaceFolderSizes: {
            $auto: {
              [teamId]: {
                $auto: {
                  [folderId]: {
                    $autoArray: {
                      $set: sizes
                    }
                  }
                }
              }
            },
          }
        });
      }
      default:
        return state;
    }
}