import { combineReducers } from 'redux';
import collections from 'state/entities/collections/CollectionsReducers';
import searchSuggestions from 'state/entities/searchSuggestions/searchSuggestionsReducers';
import folders from 'state/entities/folders/folderReducers';
import printPrices from "state/entities/printPrices/printPricesReducers";
import printOptions from "state/entities/printOptions/printOptionsReducers";
import templateSizes from "state/entities/templateSizes/templateSizesReducers";

export default combineReducers({
  collections,
  searchSuggestions,
  folders,
  printPrices,
  printOptions,
  templateSizes
});
