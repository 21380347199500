import React, { useEffect } from 'react';
import { LOADING, READY } from 'lib/Status';
import { toDateTimeStr } from 'lib/dateUtil';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

export default function TeamSubteams({
  fetchTeamSubteams,
  history,
  parentId,
  teamSubteams,
}) {

  useEffect(() => {
    if (!teamSubteams
      || teamSubteams.meta.parentId !== parentId
    ) {
      fetchTeamSubteams({ parentId });
    }
  });

  if (!teamSubteams
    || !teamSubteams.meta
    || teamSubteams.meta.parentId !== parentId
  ) {
    return null;
  }

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h6">
          Team Sub-Teams
        </Typography>
      </Box>

      {(teamSubteams.meta.status === LOADING
        || (teamSubteams.payload || []).length > 0
        ) && (
        <Box p={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(teamSubteams.payload || []).map(({ createdAt, id, name }) => (
                  <TableRow
                    hover
                    key={id}
                    onClick={() => history.push(`/teams/${id}`)}
                  >
                    <TableCell>{name}</TableCell>
                    <TableCell align="right">
                      {toDateTimeStr(createdAt)}
                    </TableCell>
                  </TableRow>
                ))}

                {(teamSubteams.meta.status === LOADING) && (
                  <TableRow>
                    <TableCell><Skeleton /></TableCell>
                    <TableCell><Skeleton /></TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {teamSubteams
        && teamSubteams.meta.status === READY
        && (teamSubteams.payload || []).length === 0
        && (
        <Box fontStyle="italic" p={6} textAlign="center">
          No sub-teams.
        </Box>
      )}
    </Paper>
  );
}
