import React from 'react';
import background from './bg.png';
import Box from '@material-ui/core/Box';
import LoginContainer from 'views/containers/Login/LoginContainer';

export default function UnauthenticatedLayout() {
  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      minHeight="100vh"
      style={{
        backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
      }}
    >
      <LoginContainer />
    </Box>
  );
}
