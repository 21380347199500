import React, { useState, useEffect } from 'react';
import { 
  Box,
  Button,
  makeStyles, 
  Paper, 
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import EditIcon from '@material-ui/icons/Edit';
import Loading from 'views/components/Loading/Loading';
import LoadMore from 'views/components/LoadMore/LoadMore';
import { READY } from 'lib/Status';
import PrintOption from './PrintOption';
import ExamplePrintOptionTable from './ExamplePrintOptionTable';
import AddLabelDialog from "./AddLabelDialog";
import UpdatePrintProviderDialog from "./UpdatePrintProviderDialog";
import UnassignedPrintOption from './UnassignedPrintOption';
import Tooltip from "views/components/Tooltip/Tooltip";
import usePrevious from 'lib/usePrevious';
import Switch from '@material-ui/core/Switch'

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  header2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  printOptionsWrapper: {
    marginTop: theme.spacing(4)
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
    fontStyle: "italic"
  },
  headingRight: {
    display: "flex",
    "& :nth-child(1)": {
      marginRight: theme.spacing(2)
    }
  },
  ellipsisMenu: {
    textAlign: "right",
    paddingRight: 0
  },
  button: {
    minWidth: "14px",
    height: "30px",
    width: "30px",
    padding: "5px",
  }
}));

const PrintPricingList = ({
  addOrderLabel,
  addPrintOptionPrice,
  createPrintOption,
  deletePrintOption,
  deletePrintPriceOption,
  deletePrintProvider,
  fetchPrintOptionsByProvider,
  fetchPrintProviders,
  fetchTemplateSizesByOrderLabel,
  fetchUnassignedOrderLabels,
  fetchUnassignedTemplateCodes,
  isFetchingPrintOptions,
  isFetchingUnassignedOrderLabels,
  printOptions,
  printProviders,
  unassignedOrderLabels,
  unassignedTemplateCodes,
  updatePrintOptionPrice,
  updatePrintOption,
  updatePrintProvider
}) => {
  const classes = useStyles();
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [updateProviderDialog, setUpdateProviderDialog] = useState(false);
  const [showUnassignedLabels, setShowUnassignedLabels] = useState(false);
  const [successActionString, setSuccessActionString] = useState(null);
  const [addLabelDialog, setAddLabelDialog] = useState(null);
  const hasPrintOptionsForProvider = selectedProvider && (printOptions && printOptions[selectedProvider.id]?.options);
  const hasUnassignedLabels = selectedProvider && (unassignedOrderLabels && unassignedOrderLabels[selectedProvider.id]);

  const prevSelectedProvider = usePrevious(selectedProvider);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!printProviders) {
      fetchPrintProviders();
    }
    if(selectedProvider !== prevSelectedProvider){
      setShowUnassignedLabels(false);
    }
    if (showUnassignedLabels && !unassignedOrderLabels?.[selectedProvider.id]) {
      fetchUnassignedOrderLabels(selectedProvider.id);
    }
  });
  
  useEffect(() => {
    // assign selected print provider initially to first provider in array
    if (printProviders && !selectedProvider) {
      setSelectedProvider(printProviders.payload[0])
    }
  }, [printProviders, selectedProvider]);
  
  useEffect(() => {
    if (selectedProvider && !hasPrintOptionsForProvider) {
      fetchPrintOptionsByProvider({ printProviderId: selectedProvider.id })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProvider]);

  const printOptionsForProvider = hasPrintOptionsForProvider ? printOptions[selectedProvider.id]?.options || [] : [];

  return (
    <>
      <div className={classes.headingContainer}>
        <h2 className={classes.header2}>Print Pricing</h2>
      </div>
      {/* PRINT PROVIDERS TABLE */}
      <Box
        component={Paper}
        p={2}
      >
        <em>Print Providers:</em>
        <Table>
          <TableHead>
            <TableRow>
              <Box component={TableCell} width="45%">Name</Box>
              <Box component={TableCell} width="45%">Country</Box>
              <Box component={TableCell} width="5%"></Box>
            </TableRow>
          </TableHead>

          <TableBody>
            {printProviders && 
              printProviders.meta.status === READY &&
              printProviders.payload.map(provider => provider.active && (
                <TableRow
                  hover
                  onClick={() => {
                    setSelectedProvider(provider);
                    setShowUnassignedLabels(false);
                  }}
                  key={provider.id}
                >
                  <TableCell>{provider.name}</TableCell>
                  <TableCell>{provider.country}</TableCell>
                  <TableCell classes={{ root: classes.ellipsisMenu }}>
                    <Button
                      classes={{ root: classes.button }}
                      onClick={() => setUpdateProviderDialog(true)}
                      data-tip={"Update Print Provider"}
                      data-for="update-provider-tip"
                    >
                      <EditIcon fontSize="small" />
                    </Button>
                    <Tooltip id="update-provider-tip" place="bottom" />
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </Box>

      {isFetchingPrintOptions && (
        <Box fontStyle="italic" p={6} textAlign="center">
          <Loading />
        </Box>
      )}

      {/* PRINT OPTIONS FOR SELECTED PRINT PROVIDER */}
      {hasPrintOptionsForProvider && (
        <>
          <div className={classes.printOptionsWrapper}>
            <Box
              component={Paper}
              p={2}
            >
              <div className={classes.heading}>
                <div>Print options for <strong>{`${selectedProvider.name} ${selectedProvider.country}`}</strong>:</div>
                <div className={classes.headingRight}>
                  <Button
                    onClick={() => setAddLabelDialog(true)}
                    startIcon={<AddIcon />}
                    color="primary"
                    variant="contained"
                    size="small"
                  >
                    Add Label
                  </Button>
                  <div>{showUnassignedLabels ? 'Hide' : 'Show'} unassigned labels<Switch checked={showUnassignedLabels} onChange={() => setShowUnassignedLabels(!showUnassignedLabels)}/></div>
                </div>
              </div>
              <ExamplePrintOptionTable currency={selectedProvider.country === "US" ? "USD" : "AUD"}/>
              {isFetchingUnassignedOrderLabels && (
                <Box fontStyle="italic" p={6} textAlign="center">
                  <Loading />
                </Box>
              )}
              {(hasUnassignedLabels && showUnassignedLabels) && (
                 unassignedOrderLabels[selectedProvider.id].map(label => (
                  <UnassignedPrintOption
                    label={label}
                    providerName={`${selectedProvider.name} ${selectedProvider.country}`}
                    setSuccessActionString={setSuccessActionString}
                    createPrintOption={args => createPrintOption({ 
                      printProviderId: selectedProvider.id, 
                      ...args
                    })}
                    fetchTemplateSizesByOrderLabel={fetchTemplateSizesByOrderLabel}
                  />
                ))
              )}
              {Object.keys(printOptionsForProvider).map(label => (
                <PrintOption
                  key={label}
                  label={label}
                  option={printOptionsForProvider[label]}
                  addPrintOptionPrice={args => addPrintOptionPrice({
                    printProviderId: selectedProvider.id, 
                    ...args
                  })}
                  createPrintOption={args => createPrintOption({ 
                    printProviderId: selectedProvider.id, 
                    ...args
                  })}
                  fetchTemplateSizesByOrderLabel={fetchTemplateSizesByOrderLabel}
                  deletePrintPriceOption={deletePrintPriceOption}
                  setSuccessActionString={setSuccessActionString}
                  updatePrintOptionPrice={updatePrintOptionPrice}
                  deletePrintOption={deletePrintOption}
                  providerId={selectedProvider.id}
                  updatePrintOption={updatePrintOption}
                  providerName={`${selectedProvider.name} ${selectedProvider.country}`}
              />
              ))}
            </Box>
          </div>

          {successActionString !== null && (
            <Snackbar
              autoHideDuration={10000}
              onClose={() => setSuccessActionString(null)}
              open
            >
              <Alert
                severity="success"
                variant="filled"
              >
                {successActionString}
              </Alert>
            </Snackbar>
          )}

          {printOptions[selectedProvider.id].meta.isMoreAvailable && (
            <LoadMore 
              onClick={() => {
                fetchPrintOptionsByProvider({ printProviderId: selectedProvider.id })
              }}
            />
          )}

          {updateProviderDialog && (
            <UpdatePrintProviderDialog
              printProvider={selectedProvider}
              onClose={() => setUpdateProviderDialog(false)}
              updatePrintProvider={updatePrintProvider}
              setSelectedProvider={setSelectedProvider}
              setSuccessActionString={setSuccessActionString}
              deletePrintProvider={deletePrintProvider}
            />
          )}
          
          {addLabelDialog && (
            <AddLabelDialog
              onClose={() => setAddLabelDialog(false)}
              addOrderLabel={addOrderLabel}
              setSuccessActionString={setSuccessActionString}
              providerId={selectedProvider.id}
              fetchUnassignedTemplateCodes={fetchUnassignedTemplateCodes}
              unassignedTemplateCodes={unassignedTemplateCodes}
            />
          )}
        </>
      )}
    </>
  );

};

export default PrintPricingList
