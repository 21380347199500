import { FAILED, LOADING } from 'lib/Status';
import * as types from 'state/api/assets/AssetApiTypes';
import * as folderTypes from 'state/entities/folders/folderTypes';
import { immutableUpdate } from 'lib/immutability-helper/index';
import { get as getPath } from 'lodash';

const initState = {
  catalogueSizes: null,
  thumbnail: {
    designId: null,
    status: null,
  },
  collectionRemixUrls: {},
  teamCatalogue: {
    sizes: {},
    folders: {},
  },
  teamCatalogueSizes: null,
  userDrafts: null,
};

export default function AssetApiReducers(state = initState, action) {
  switch (action.type) {
    case types.ASSET_CATALOGUE_SIZES_FAILURE: {
      return { ...state, catalogueSizes: FAILED };
    }
    case types.ASSET_CATALOGUE_SIZES_REQUEST: {
      return { ...state, catalogueSizes: LOADING };
    }
    case types.ASSET_CATALOGUE_SIZES_SUCCESS: {
      return { ...state, catalogueSizes: action.payload ?? [] };
    }
    case types.ASSET_FETCH_DESIGN_THUMBNAIL_UPLOAD_URL_REQUEST: {
      return {
        ...state,
        thumbnail: {
          designId: action.payload.designId,
          status: 'LOADING',
        }
      }
    }
    case types.ASSET_UPDATE_DESIGN_THUMBNAIL_FILE_FAILURE: {
      return {
        ...state,
        thumbnail: {
          designId: null,
          status: 'FAILED',
        }
      };
    }
    case types.ASSET_UPDATE_DESIGN_THUMBNAIL_FILE_SUCCESS: {
      return {
        ...state,
        thumbnail: {
          designId: null,
          status: 'READY',
        }
      };
    }
    case types.ASSET_CREATE_COLLECTION_REMIX_SUCCESS: {
      return {
        ...state,
        collectionRemixUrls: {
          ...(state.collectionRemixUrls || {}),
          [action.meta.collectionId]: action.payload.url
        }
      };
    }


    case types.ASSET_USER_TEAM_DRAFTS_REQUEST: {
      return immutableUpdate(state, {
        userDrafts: {
          $auto: {
            isFetching: {
              $set: true,
            }
          },
        }
      });
    }

    case types.ASSET_USER_TEAM_DRAFTS_SUCCESS: {
      const { teamId, folderId, size, count } = action.meta;

      const newEntities = getPath(
        action,
        "payload",
        []
      );

      if (folderId) {
        return immutableUpdate(state, {
          userDrafts: {
            $auto: {
              [teamId]: {
                $auto: {
                  folders: {
                    $auto: {
                      [folderId]: {
                        $auto: {
                          [size]: {
                            $auto: {
                              designs: {
                                $autoArray: {
                                  $push: newEntities
                                },
                              },
                              meta: {
                                $set: {
                                  ...action.meta,
                                  isMoreAvailable: action.payload?.length >= count,
                                }
                              },
                            }
                          },
                        }
                      }
                    }
                  }
                }
              },
              isFetching: {
                $set: false,
              }
            },
          }
        });
      }
      return immutableUpdate(state, {
        userDrafts: {
          $auto: {
            [teamId]: {
              $auto: {
                [size]: {
                  $auto: {
                    designs: {
                      $autoArray: {
                        $push: newEntities
                      },

                    },
                    meta: {
                      $set: {
                        ...action.meta,
                        isMoreAvailable: action.payload?.length >= count,
                      }
                    },
                  }
                },
              }
            },
            isFetching: {
              $set: false,
            }
          },
        }
      });
    }

    case types.ASSET_USER_TEAM_DRAFTS_SIZES_SUCCESS: {
      const { teamId } = action.meta;
      const AllSize = {
        templateCode: "All Designs",
        name: "All Designs",
      }
      let sizes;
      if (action.payload) {
        sizes = [AllSize, ...action.payload]
      }
      return immutableUpdate(state, {
        userDraftsSizes: {
          $auto: {
            [teamId]: {
              $autoArray: {
                $set: sizes
              }
            }
          },
        }
      });
    }

    case types.ASSET_TEAM_CATALOGUE_REQUEST: {
      const { size } = action.meta;
      return immutableUpdate(state, {
        teamCatalogue: {
          sizes: {
            $auto: {
              [size]: {
                $auto: {
                  isFetching: {
                    $set: true,
                  }
                }
              }
            }
          },
        }
      });
    }

    case types.ASSET_TEAM_CATALOGUE_SUCCESS: {
      const { size } = action.meta;

      const newEntities = getPath(
        action,
        "payload",
        []
      );

      return immutableUpdate(state, {
        teamCatalogue: {
          sizes: {
            $auto: {
              [size]: {
                $auto: {
                  designs: {
                    $autoArray: {
                      $push: newEntities
                    }
                  },
                  meta: {
                    $set: {
                      ...action.meta,
                      isMoreAvailable: action.payload?.length >= action.meta.count,
                    }
                  },
                  isFetching: {
                    $set: false,
                  }
                }
              }
            }
          },
        }
      });
    }

    case types.CLEAR_TEAM_CATALOGUE: {
      return initState;
    }

    case types.ASSET_TEAM_CATALOGUE_SIZES_SUCCESS: {
      return { ...state, teamCatalogueSizes: action.payload ?? [] };
    }

    case folderTypes.ASSET_TEAM_CATALOGUE_FOLDER_CONTENT_REQUEST: {
      const { folderId } = action.meta;
      return immutableUpdate(state, {
        teamCatalogue: {
          folders: {
            $auto: {
              [folderId]: {
                $auto: {
                  isFetching: {
                    $set: true,
                  }
                }
              }
            }
          }
        }
      })
    }

    case folderTypes.ASSET_TEAM_CATALOGUE_FOLDER_CONTENT_SUCCESS: {
      const { folderId } = action.meta;
      const newEntities = getPath(
        action,
        "payload",
        []
      );
      return immutableUpdate(state, {
        teamCatalogue: {
          folders: {
            $auto: {
              [folderId]: {
                $auto: {
                  designs: {
                    $autoArray: {
                      $set: newEntities
                    }
                  },
                  meta: {
                    $set: {
                      ...action.meta,
                      isMoreAvailable: action.payload?.length >= action.meta.count,
                    }
                  },
                  isFetching: {
                    $set: false,
                  }
                }
              }
            }
          }
        }
      })
    }
    default:
      return state;
  }
}
