const handleCategoriesChange = (event, values, reason) => {
  switch (reason) {
    case 'create-option':
    case 'select-option': {
      const arr = values.slice(0, -1);
      let input = values.slice(-1)[0];

      if (typeof input === 'string') {
        input = {
          label: input,
          name: input.toLowerCase(),
        };
      }

      if (input && !arr.find((a) => a.name === input.name)) {
        arr.push(input);
      }

      return arr;
    }

    default: {
      return values;
    }
  }
};

export default handleCategoriesChange;
