import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

export default function TeamTrialDialog({
  createNewTrial,
  dialog,
  onClose,
  teamId,
  teamSubscription,
  updateTeamSubscription,
}) {
  const [trialDays, setTrialDays] = useState('');
  const [subscriptionCode, setSubscriptionCode] = useState('EDGE');

  const handleSubmit = async (event) => {
    event.preventDefault();
    let response;

    if (dialog === 'CREATE_TRIAL') {
      response = await createNewTrial({
        subscriptionCode,
        teamId,
        trialDays: parseInt(trialDays, 10),
      });
    }

    if (dialog === 'EXTEND_TRIAL'
      && teamSubscription
      && teamSubscription.payload
      && teamSubscription.payload.id
    ) {
      response = await updateTeamSubscription({
        subscriptionId: teamSubscription.payload.id,
        teamId,
        trialDays: parseInt(trialDays, 10),
      });
    }

    if (!(response || {}).error) {
      onClose(event);
    }
  };

  return (
    <Dialog open onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {dialog === 'CREATE_TRIAL' && <>Create New Trial</>}
        {dialog === 'EXTEND_TRIAL' && <>Extend Trial</>}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {dialog === 'CREATE_TRIAL' && (
            <>
              Please select a subscription code, enter a duration (in days) then
              click "Create" below to create a new trial for this organisation.
            </>
          )}

          {dialog === 'EXTEND_TRIAL' && (
            <>
              Please enter the total trial duration (in days) below then click
              the "Extend" button. If you are extending from a <u>30</u> day
              trial to a <u>90</u> day trial, input "90" below.
            </>
          )}
        </DialogContentText>

        <Box px={6} py={2}>
          {dialog === 'CREATE_TRIAL' && (
            <FormControl
              autoFocus
              fullWidth
              margin="dense"
              variant="outlined"
            >
              <InputLabel id="sub-code">Subscription Code</InputLabel>
              <Select
                label="Subscription Code"
                labelId="sub-code"
                onChange={e => setSubscriptionCode(e.target.value)}
                value={subscriptionCode}
              >
                <MenuItem value="EDGE">Edge</MenuItem>
                <MenuItem value="PLUS">Plus</MenuItem>
              </Select>
            </FormControl>
          )}

          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Duration (Days)"
            margin="dense"
            onChange={e => setTrialDays(e.target.value)}
            required
            type="number"
            value={trialDays}
            variant="outlined"
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={handleSubmit}
        >
          {dialog === 'CREATE_TRIAL' && <>Create</>}
          {dialog === 'EXTEND_TRIAL' && <>Extend</>}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
