import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Team from 'views/components/Team/Team';
import { currentUserIdSelector, tokenSelector } from 'state/app/SessionSelectors';

import {
  convertAccountToSubscription,
  convertSubscriptionToAccount,
  createNewTrial,
  fetchSubscriptionPlans,
  fetchTeamSubscription,
  updateTeamSubscription,
} from 'state/api/subscriptions/SubscriptionApiActions';

import {
  subscriptionPlansSelector,
  teamSubscriptionSelector
} from 'state/api/subscriptions/SubscriptionApiSelectors';

import {
  fetchAllParentTeams,
  fetchTeam,
  fetchTeamSubteams,
  fetchTeamUsers,
  updateTeam,
} from 'state/api/teams/TeamApiActions';

import {
  teamSelector,
  teamSubteamsSelector,
  teamUsersSelector,
  teamParentTeamsSelector,
} from 'state/api/teams/TeamApiSelectors';

import {
  teamCatalogueSelector,
  teamCatalogueSizesSelector,
} from 'state/api/assets/AssetApiSelectors';

import {
  fetchTeamCatalogue, fetchTeamCatalogueSizes
} from 'state/api/assets/AssetApiActions';

import { fetchTeamFolders, fetchTeamCatalogueFolderContent } from 'state/entities/folders/folderActions'

import { teamFoldersSelector } from 'state/entities/folders/folderSelectors';


export default function TeamContainer({ match, ...props }) {
  const dispatch = useDispatch();

  const accessToken = useSelector(tokenSelector);
  const team = useSelector(teamSelector);
  const teamSubscription = useSelector(teamSubscriptionSelector);
  const remixUrls = useSelector(state => state.api.assets.collectionRemixUrls)
  const teamSubTeams = useSelector(teamSubteamsSelector);
  const teamUsers = useSelector(teamUsersSelector);
  const teamCatalogue = useSelector(teamCatalogueSelector);
  const teamCatalogueSizes = useSelector(teamCatalogueSizesSelector)
  const teamFolders = useSelector(teamFoldersSelector)
  const parentTeams = useSelector(teamParentTeamsSelector);
  const userId = useSelector(currentUserIdSelector);

  // TODO: fetch* props to Team.. should be removed and handled here in the container.

  function onCovertToAccount(params) {
    dispatch(convertSubscriptionToAccount({ accessToken, ...params }));
  }

  function onConvertToSubscription(teamId) {
    dispatch(convertAccountToSubscription({ teamId }));
  }

  function onCreateNewTrial(params) {
    dispatch(createNewTrial({ accessToken, ...params }));
  }

  function onUpdateTeam(params) {
    dispatch(updateTeam({ accessToken, ...params }));
  }

  function onUpdateTeamSubscription(params) {
    dispatch(updateTeamSubscription({ accessToken, ...params }));
  }

  return (
    <Team
      convertSubscriptionToAccount={params => onCovertToAccount(params)}
      convertAccountToSubscription={teamId => onConvertToSubscription(teamId)}
      createNewTrial={params => onCreateNewTrial(params)}
      fetchSubscriptionPlans={() => dispatch((fetchSubscriptionPlans({ accessToken })))}
      fetchAllParentTeams={({parentIds, teamId}) => dispatch(fetchAllParentTeams({accessToken, parentIds, teamId}))}
      fetchTeam={({ teamId }) => dispatch(fetchTeam({ accessToken, teamId }))}
      fetchTeamSubscription={({ teamId }) => dispatch(fetchTeamSubscription({ accessToken, teamId }))}
      fetchTeamSubteams={({ parentId }) => dispatch(fetchTeamSubteams({ accessToken, parentId, userId }))}
      fetchTeamUsers={({ teamId }) => dispatch(fetchTeamUsers({ accessToken, teamId }))}
      fetchTeamCatalogue={(args) => dispatch(fetchTeamCatalogue(args))}
      fetchTeamCatalogueSizes={teamId => dispatch(fetchTeamCatalogueSizes(teamId))}
      fetchTeamFolders={(args) => dispatch(fetchTeamFolders(args))}
      fetchTeamCatalogueFolderContent={(args) => dispatch(fetchTeamCatalogueFolderContent(args))}
      remixUrls={remixUrls}
      subscriptionPlans={useSelector(subscriptionPlansSelector)}
      team={team}
      teamId={match.params.teamId}
      teamSubscription={teamSubscription}
      teamSubteams={teamSubTeams}
      teamUsers={teamUsers}
      teamCatalogue={teamCatalogue}
      teamCatalogueSizes={teamCatalogueSizes}
      teamFolders={teamFolders}
      parentTeams={parentTeams}
      updateTeam={({ logoFile, team }) => onUpdateTeam({ logoFile, team })}
      updateTeamSubscription={params => onUpdateTeamSubscription(params)}
      {...props}
    />
  );
}
