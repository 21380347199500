export default function getTeamType(team) {

  if (!team || !team.parentPathArr) {
    throw new Error('Invalid parentPathArr.');
  }

  switch (team.parentPathArr.length) {
    case 0: {
      return 'Root';
    }
    case 1: {
      return 'Organisation';
    }
    default: {
      const tier = team.parentPathArr.length - 1;
      return `Sub-Team (Tier ${tier})`;
    }
  }
}
