import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import EasilLogo from "views/components/Icons/EasilLogo/EasilLogo";

const useStyles = (theme) => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(8, 12),
  },
  button: {
    backgroundColor: 'rgb(119, 225, 200)',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    width: '300px',
    '&:hover': {
      backgroundColor: 'rgb(109, 215, 190)',
    },
  },
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      errors: props.errors,
      email: "",
      password: "",
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Bit dodgy
    if (props.errors.password && !state.errors.password) {
      return {
        errors: props.errors,
      }
    }
    return null;
  }

  handleChange(attribute, value) {
    this.setState({
      ...this.state,
      [attribute]: value,
      errors: {
        password: null,
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { email, password } = this.state;
    this.props.authenticate(email, password );
  }

  render() {
    const { classes } = this.props;
    const { email, password } = this.state;

    return (
      <Paper
        className={classes.container}
        component="form"
        onSubmit={this.handleSubmit}
      >
        <Box marginBottom={6}>
          <EasilLogo />
        </Box>

        <TextField
          autoFocus
          fullWidth
          id="username"
          label="Email"
          onChange={e => this.handleChange("email", e.target.value)}
          required
          type="email"
          value={email}
          variant="outlined"
        />

        <TextField
          error={Boolean(this.state.errors.password)}
          fullWidth
          helperText={this.state.errors.password}
          id="password"
          label="Password"
          onChange={e => this.handleChange("password", e.target.value)}
          required
          type="password"
          value={password}
          variant="outlined"
        />

        <Button
          className={classes.button}
          color="primary"
          type="submit"
          variant="contained"
        >
          Login
        </Button>
      </Paper>
    );
  }
}

Login.propTypes = {
  authenticate: PropTypes.func.isRequired,
  errors: PropTypes.object,
  isProcessing: PropTypes.bool.isRequired,
};

export default withStyles(useStyles, { withTheme: true })(Login)
