import React, { useState } from 'react';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Box from '@material-ui/core/Box';
import EasilIcon from '../Icons/EasilIcon';
import IconButton from '@material-ui/core/IconButton';
import MaterialAppBar from '@material-ui/core/AppBar';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: '#2c2e2f',
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function AppBar({
  onDrawerMenuClick,
  onLogoutClick,
}) {
  const classes = useStyles();
  const [menuEl, setMenuEl] = useState(null);
  const handleMenuClose = () => setMenuEl(null);

  return (
    <MaterialAppBar className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          className={classes.menuButton}
          color="inherit"
          edge="start"
          onClick={onDrawerMenuClick}
        >
          <MenuIcon />
        </IconButton>

        <Box
          color="inherit"
          component={Link}
          display="flex"
          flexGrow="1"
          style={{ textDecoration: 'none' }}
          to="/"
        >
          <EasilIcon />

          <Box fontSize="1.5em" marginLeft={2}>
            Admin
          </Box>
        </Box>

        <IconButton color="inherit" onClick={(e) => setMenuEl(e.target)}>
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={menuEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={menuEl !== null}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={(event) => {
            handleMenuClose(event);
            onLogoutClick(event);
          }}>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </MaterialAppBar>
  )
}
