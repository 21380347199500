import * as types from 'state/api/orders/OrdersApiTypes';
import { cloneDeep } from 'lodash';
import { immutableUpdate } from 'lib/immutability-helper';

const initState = {};

export default function printOptionsReducers(state = initState, action) {
    switch(action.type) {
      case types.PRINT_OPTIONS_BY_PRINT_PROVIDER_SUCCESS: {
        const printOptions = (action.payload || []).reduce((printOptionsAccumulator, currentPrintOption) => {
          const clonedOption = cloneDeep(currentPrintOption);

          clonedOption.pricing = currentPrintOption.pricing.map(currentPrice => currentPrice.id);
          
          return {
            ...printOptionsAccumulator,
            [clonedOption.id]: clonedOption
          }
        }, {});

        return {
          ...state,
          ...printOptions
        }
      }

      case types.DELETE_PRINT_PRICE_SUCCESS: {
        const printOption = action.meta.printOption;
        const updatedPricing = state[printOption.id].pricing.filter(priceId => priceId !== printOption.pricing.id);

        return {
          ...state,
          [printOption.id]: {
            ...state[printOption.id],
            pricing: updatedPricing
          }
        };
      }

      case types.ADD_PRINT_OPTION_SUCCESS: {
        const printOption = action.payload;
        return {
          ...state,
          [printOption.id]: printOption
        }
      }

      case types.ADD_PRINT_OPTION_PRICING_SUCCESS: {
        const printPrice = action.payload;
        return immutableUpdate(state, {
          [printPrice.printOptionId]: {
            $auto: {
              pricing: {
                $autoArray: {
                  $push: [printPrice.id]
                }
              }
            }
          }
        })
      }

      case types.UPDATE_PRINT_OPTION_SUCCESS: {
        const printOption = action.payload;
        return {
          ...state,
          [printOption.id]: {
            ...state[printOption.id],
            ...printOption
          }
        }
      }

      default:
        return state;
    }
    
}