import { reduceAction } from 'lib/apiMiddlewareUtils';
import * as types from 'state/api/teams/TeamApiTypes';
import { immutableUpdate } from 'lib/immutability-helper/index';

const initState = {
  team: null,
  teams: null,
  teamSubteams: null,
  teamUsers: null,
  teamsCancelling: null,
  parentTeams: {},
};

export default function TeamApiReducers(state = initState, action) {
  switch (action.type) {
    case types.SUBTEAMS_FAILURE:
    case types.SUBTEAMS_REQUEST:
    case types.SUBTEAMS_SUCCESS: {
      return { ...state, teamSubteams: reduceAction(state.teamSubteams, action) };
    }

    case types.TEAM_FAILURE:
    case types.TEAM_REQUEST:
    case types.TEAM_SUCCESS: {
      return { ...state, team: reduceAction(state.team, action) };
    }

    case types.TEAMS_FAILURE:
    case types.TEAMS_REQUEST:
    case types.TEAMS_SUCCESS: {
      return { ...state, teams: reduceAction(state.teams, action) };
    }

    case types.PARENT_TEAM_FAILURE:
    case types.PARENT_TEAM_REQUEST:
    case types.PARENT_TEAM_SUCCESS: {
      const { teamId } = action.meta;

      return immutableUpdate(state, {
        parentTeams: {
          $auto: {
            [teamId]: {
              $autoArray: {
                $push: [action.payload]
              }
            }
          },
        }
      });
    }

    case types.TEAMS_CANCELLING_FAILURE:
    case types.TEAMS_CANCELLING_REQUEST:
    case types.TEAMS_CANCELLING_SUCCESS: {
      return { ...state, teamsCancelling: reduceAction(state.teamsCancelling, action) };
    }

    case types.USERS_FAILURE:
    case types.USERS_REQUEST:
    case types.USERS_SUCCESS: {
      return { ...state, teamUsers: reduceAction(state.teamUsers, action) };
    }

    default:
      return state;
  }
}
