export const VIDEO_MEDIA_TYPES = ["mp4", "webm", "ogg"];

export const isMediaVideoFormat = mediaSource => {
  if (!mediaSource || !(typeof mediaSource === "string")) {
    return false;
  }
  const sourceSuffix = mediaSource.split(".").pop();
  if (!sourceSuffix) {
    return false;
  }
  return VIDEO_MEDIA_TYPES.includes(sourceSuffix.toLowerCase());
};