import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { templateSizesForLabelSelector } from "state/api/orders/OrdersApiSelectors";
import * as actions from 'state/api/orders/OrdersApiActions';
import TemplateSizesPopout from './TemplateSizesPopout';

export default function TemplateSizesPopoutContainer(props) {
  const dispatch = useDispatch();
  const templateSizes = useSelector(state => templateSizesForLabelSelector(state, props.labelName));
  const deleteTemplateCode = args => dispatch(actions.deleteTemplateCodeFromPrintLabel(args));

  return (
    <TemplateSizesPopout
      {...props}
      templateSizes={templateSizes}
      deleteTemplateCode={deleteTemplateCode}
    />
  );
}
