import React from "react";
import style from "./style.module.css";

const hexToRgb = hex => {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function(m, r, g, b) {
      return r + r + g + g + b + b;
    });
  
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  
    if (!result) return;
  
    const { r, g, b } = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    };
  
    return `${r}, ${g}, ${b}`;
};

const Loading = ({
  size = "32px",
  hexColor = "#D1D4D6",
  className,
  borderWidth,
  largeEdgeHexOverride
}) => {
  const borderSize =
    borderWidth || Math.round(size.substring(0, size.length - 2) / 16) || 1;

  const dynamicStyle = {
    width: size || "32px",
    height: size || "32px",
    borderTop: `${borderSize || 2}px solid rgba(${hexToRgb(
      largeEdgeHexOverride || hexColor
    )}, ${largeEdgeHexOverride ? 1 : 0.5})`,
    borderRight: `${borderSize || 2}px solid rgba(${hexToRgb(
      largeEdgeHexOverride || hexColor
    )}, ${largeEdgeHexOverride ? 1 : 0.5})`,
    borderBottom: `${borderSize || 2}px solid rgba(${hexToRgb(
      largeEdgeHexOverride || hexColor
    )}, ${largeEdgeHexOverride ? 1 : 0.5}`,
    borderLeft: `${borderSize || 2}px solid ${hexColor}`
  };

  return (
    <div style={dynamicStyle} className={`${style.loader} ${className}`}>
      loading
    </div>
  );
};

export default Loading;
