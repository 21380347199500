import React from 'react';
import { isMediaVideoFormat } from 'lib/mediaSourceHelpers';

export default function DesignCardThumbnail({width, design}) {
  return (
    <>
      {isMediaVideoFormat(design.thumbnailUrl) ? (
        <video
          muted
          loop
          autoplay="true"
          style={{ display: "block", width }}
        >
          <source src={design.thumbnailUrl} type="video/mp4" />
        </video>
      ) : (
        <img
          alt={design.title}
          src={design.thumbnailUrl}
          style={{ display: "block", width }}
        />
      )}
    </>
  )
}