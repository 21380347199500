export const ORDERS_COUPONS_FAILURE = Symbol('API/ORDERS_COUPONS_FAILURE');
export const ORDERS_COUPONS_REQUEST = Symbol('API/ORDERS_COUPONS_REQUEST');
export const ORDERS_COUPONS_SUCCESS = Symbol('API/ORDERS_COUPONS_SUCCESS');

export const CREATE_ORDERS_COUPONS_FAILURE = Symbol('API/CREATE_ORDERS_COUPONS_FAILURE');
export const CREATE_ORDERS_COUPONS_REQUEST = Symbol('API/CREATE_ORDERS_COUPONS_REQUEST');
export const CREATE_ORDERS_COUPONS_SUCCESS = Symbol('API/CREATE_ORDERS_COUPONS_SUCCESS');

export const UPDATE_ORDERS_COUPONS_FAILURE = Symbol('API/UPDATE_ORDERS_COUPONS_FAILURE');
export const UPDATE_ORDERS_COUPONS_REQUEST = Symbol('API/UPDATE_ORDERS_COUPONS_REQUEST');
export const UPDATE_ORDERS_COUPONS_SUCCESS = Symbol('API/UPDATE_ORDERS_COUPONS_SUCCESS');

export const DESCRIPTION_URL_FAILURE = Symbol('API/DESCRIPTION_URL_FAILURE');
export const DESCRIPTION_URL_REQUEST = Symbol('API/DESCRIPTION_URL_REQUEST');
export const DESCRIPTION_URL_SUCCESS = Symbol('API/DESCRIPTION_URL_SUCCESS');

export const CREATE_DESCRIPTION_URL_REQUEST = Symbol('API/CREATE_DESCRIPTION_URL_REQUEST');
export const CREATE_DESCRIPTION_URL_SUCCESS = Symbol('API/CREATE_DESCRIPTION_URL_SUCCESS');
export const CREATE_DESCRIPTION_URL_FAILURE = Symbol('API/CREATE_DESCRIPTION_URL_FAILURE');

export const DELETE_DESCRIPTION_URL_REQUEST = Symbol('API/DELETE_DESCRIPTION_URL_REQUEST');
export const DELETE_DESCRIPTION_URL_SUCCESS = Symbol('API/DELETE_DESCRIPTION_URL_SUCCESS');
export const DELETE_DESCRIPTION_URL_FAILURE = Symbol('API/DELETE_DESCRIPTION_URL_FAILURE');

export const UPDATE_DESCRIPTION_URL_REQUEST = Symbol('API/UPDATE_DESCRIPTION_URL_REQUEST');
export const UPDATE_DESCRIPTION_URL_SUCCESS = Symbol('API/UPDATE_DESCRIPTION_URL_SUCCESS');
export const UPDATE_DESCRIPTION_URL_FAILURE = Symbol('API/UPDATE_DESCRIPTION_URL_FAILURE');

export const UPDATE_PRINT_PRICE_REQUEST = Symbol('API/UPDATE_PRINT_PRICE_REQUEST');
export const UPDATE_PRINT_PRICE_SUCCESS = Symbol('API/UPDATE_PRINT_PRICE_SUCCESS');
export const UPDATE_PRINT_PRICE_FAILURE = Symbol('API/UPDATE_PRINT_PRICE_FAILURE');

export const PRINT_PROVIDER_REQUEST = Symbol('API/PRINT_PROVIDER_REQUEST');
export const PRINT_PROVIDER_SUCCESS = Symbol('API/PRINT_PROVIDER_SUCCESS');
export const PRINT_PROVIDER_FAILURE = Symbol('API/PRINT_PROVIDER_FAILURE');

export const DELETE_PRINT_PRICE_REQUEST = Symbol('API/DELETE_PRINT_PRICE_REQUEST');
export const DELETE_PRINT_PRICE_SUCCESS = Symbol('API/DELETE_PRINT_PRICE_SUCCESS');
export const DELETE_PRINT_PRICE_FAILURE = Symbol('API/DELETE_PRINT_PRICE_FAILURE');

export const PRINT_OPTIONS_BY_PRINT_PROVIDER_REQUEST = 
  Symbol('API/PRINT_OPTIONS_BY_PRINT_PROVIDER_REQUEST');
export const PRINT_OPTIONS_BY_PRINT_PROVIDER_SUCCESS = 
  Symbol('API/PRINT_OPTIONS_BY_PRINT_PROVIDER_SUCCESS');
export const PRINT_OPTIONS_BY_PRINT_PROVIDER_FAILURE = 
  Symbol('API/PRINT_OPTIONS_BY_PRINT_PROVIDER_FAILURE');

export const TEMPLATE_SIZES_BY_ORDER_LABEL_REQUEST = 
  Symbol('API/TEMPLATE_SIZES_BY_ORDER_LABEL_REQUEST');
export const TEMPLATE_SIZES_BY_ORDER_LABEL_SUCCESS = 
  Symbol('API/TEMPLATE_SIZES_BY_ORDER_LABEL_SUCCESS');
export const TEMPLATE_SIZES_BY_ORDER_LABEL_FAILURE = 
  Symbol('API/TEMPLATE_SIZES_BY_ORDER_LABEL_FAILURE');
  
export const ADD_PRINT_OPTION_REQUEST = 
  Symbol('API/ADD_PRINT_OPTION_REQUEST');
export const ADD_PRINT_OPTION_SUCCESS = 
  Symbol('API/ADD_PRINT_OPTION_SUCCESS');
export const ADD_PRINT_OPTION_FAILURE = 
  Symbol('API/ADD_PRINT_OPTION_FAILURE');

export const UPDATE_PRINT_OPTION_REQUEST = 
  Symbol('API/UPDATE_PRINT_OPTION_REQUEST');
export const UPDATE_PRINT_OPTION_SUCCESS = 
  Symbol('API/UPDATE_PRINT_OPTION_SUCCESS');
export const UPDATE_PRINT_OPTION_FAILURE = 
  Symbol('API/UPDATE_PRINT_OPTION_FAILURE');

export const ADD_PRINT_OPTION_PRICING_REQUEST = 
  Symbol('API/ADD_PRINT_OPTION_PRICING_REQUEST');
export const ADD_PRINT_OPTION_PRICING_SUCCESS = 
  Symbol('API/ADD_PRINT_OPTION_PRICING_SUCCESS');
export const ADD_PRINT_OPTION_PRICING_FAILURE = 
  Symbol('API/ADD_PRINT_OPTION_PRICING_FAILURE');

export const UPDATE_PRINT_PROVIDER_REQUEST = Symbol('API/UPDATE_PRINT_PROVIDER_REQUEST');
export const UPDATE_PRINT_PROVIDER_SUCCESS = Symbol('API/UPDATE_PRINT_PROVIDER_SUCCESS');
export const UPDATE_PRINT_PROVIDER_FAILURE = Symbol('API/UPDATE_PRINT_PROVIDER_FAILURE');

export const DELETE_PRINT_PROVIDER_REQUEST = Symbol('API/DELETE_PRINT_PROVIDER_REQUEST');
export const DELETE_PRINT_PROVIDER_SUCCESS = Symbol('API/DELETE_PRINT_PROVIDER_SUCCESS');
export const DELETE_PRINT_PROVIDER_FAILURE = Symbol('API/DELETE_PRINT_PROVIDER_FAILURE');

export const DELETE_TEMPLATE_CODE_FROM_PRINT_LABEL_REQUEST = 
  Symbol('API/DELETE_TEMPLATE_CODE_FROM_PRINT_LABEL_REQUEST');
export const DELETE_TEMPLATE_CODE_FROM_PRINT_LABEL_SUCCESS = 
  Symbol('API/DELETE_TEMPLATE_CODE_FROM_PRINT_LABEL_SUCCESS');
export const DELETE_TEMPLATE_CODE_FROM_PRINT_LABEL_FAILURE = 
  Symbol('API/DELETE_TEMPLATE_CODE_FROM_PRINT_LABEL_FAILURE');

export const DELETE_PRINT_OPTION_REQUEST = Symbol('API/DELETE_PRINT_OPTION_REQUEST');
export const DELETE_PRINT_OPTION_SUCCESS = Symbol('API/DELETE_PRINT_OPTION_SUCCESS');
export const DELETE_PRINT_OPTION_FAILURE = Symbol('API/DELETE_PRINT_OPTION_FAILURE');

export const UNASSIGNED_PRINT_OPTION_REQUEST = Symbol('API/UNASSIGNED_PRINT_OPTION_REQUEST');
export const UNASSIGNED_PRINT_OPTION_SUCCESS = Symbol('API/UNASSIGNED_PRINT_OPTION_SUCCESS');
export const UNASSIGNED_PRINT_OPTION_FAILURE = Symbol('API/UNASSIGNED_PRINT_OPTION_FAILURE');

export const UNASSIGNED_TEMPLATE_CODES_REQUEST = Symbol('API/UNASSIGNED_TEMPLATE_CODES_REQUEST');
export const UNASSIGNED_TEMPLATE_CODES_SUCCESS = Symbol('API/UNASSIGNED_TEMPLATE_CODES_SUCCESS');
export const UNASSIGNED_TEMPLATE_CODES_FAILURE = Symbol('API/UNASSIGNED_TEMPLATE_CODES_FAILURE');

export const ADD_ORDER_LABEL_REQUEST = 
  Symbol('API/ADD_ORDER_LABEL_REQUEST');
export const ADD_ORDER_LABEL_SUCCESS = 
  Symbol('API/ADD_ORDER_LABEL_SUCCESS');
export const ADD_ORDER_LABEL_FAILURE = 
  Symbol('API/ADD_ORDER_LABEL_FAILURE');