import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { isAdminSelector, isBrandManagerSelector } from 'state/app/SessionSelectors';
import AppBar from 'views/components/AppBar/AppBar';
import CatalogueContainer from 'views/containers/Designs/CatalogueContainer';
import Drawer from 'views/components/Drawer/Drawer';
import MediaItemContainer from 'views/containers/Media/MediaItemContainer';
import MediaSearchContainer from 'views/containers/Media/MediaSearchContainer';
import MediaUploadContainer from 'views/containers/Media/MediaUploadContainer';
import MyDraftsContainer from 'views/containers/Designs/MyDraftsContainer';
import MyStagedContainer from 'views/containers/Designs/MyStagedContainer';
import StagedDesignsContainer from 'views/containers/Designs/StagedDesignsContainer';
import SubscriptionsContainer from 'views/containers/SubscriptionsContainer';
import FontUploadContainer from 'views/containers/Fonts/FontUploadContainer';
import TeamContainer from 'views/containers/Team/TeamContainer';
import TeamListContainer from 'views/containers/Team/TeamListContainer';
import TeamCancellingContainer from 'views/containers/Team/TeamCancellingContainer';
import UsersContainer from 'views/containers/UsersContainer';
import OrdersCouponsContainer from 'views/containers/Orders/OrdersCouponsContainer';
import PrintMarketingContainer from 'views/containers/Orders/PrintMarketingContainer';
import PrintPricingContainer from 'views/containers/Orders/PrintPricingContainer';
import { logout } from 'state/app/SessionActions';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  content: {
    flexGrow: 1,
    minHeight: '100vh',
    overflow: 'auto',
  },
  root: {
    display: 'flex',
  },
}));

export default function AuthenticatedLayout() {
  const classes = useStyles();
  const isAdmin = useSelector(isAdminSelector);
  const isBrandManager = useSelector(isBrandManagerSelector);
  const [isDrawOpen, setDrawerOpen] = useState(false);

  return (
    <div className={classes.root}>
      <AppBar
        onDrawerMenuClick={() => setDrawerOpen(d => !d)}
        onLogoutClick={() => logout()}
      />
      <Drawer
        isDrawOpen={isDrawOpen}
        isAdmin={isAdmin}
        isBrandManager={isBrandManager}
        onDrawerClose={() => setDrawerOpen(false)}
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth='xl' className={classes.container}>
          <Switch>
            <Route path='/orders/coupons' component={OrdersCouponsContainer} />
            <Route path='/orders/print-marketing' component={PrintMarketingContainer} />
            <Route path='/orders/print-pricing' component={PrintPricingContainer} />
            <Route path='/coupons' component={SubscriptionsContainer} />
            <Route path='/cancelling' component={TeamCancellingContainer} />
            <Route path='/designs' component={CatalogueContainer} exact />
            <Route path='/designs/mydrafts' component={MyDraftsContainer} />
            <Route path='/designs/mystaged' component={MyStagedContainer} />
            <Route path='/designs/staged' component={StagedDesignsContainer} />
            <Route path='/media/upload' component={MediaUploadContainer} />
            <Route path='/media/:mediaId' component={MediaItemContainer} />
            <Route path='/media' component={MediaSearchContainer} />
            <Route path='/plans' component={SubscriptionsContainer} />
            <Route path='/teams/:teamId' component={TeamContainer} />
            <Route path='/teams' component={TeamListContainer} />
            <Route path="/users/:userId" component={UsersContainer} />
            <Route path="/users" component={UsersContainer} />
            <Route path="/fonts/upload" component={FontUploadContainer} />
          </Switch>
        </Container>
      </main>
    </div>
  );
}
