export const tokenSelector = state => state.app.session.token;

export const currentUserIdSelector = state => state.app.session.userId;

export const isAuthenticatedSelector = state =>
  Boolean(state.app.session.token) && state.app.session.sessionEnd > Date.now();

export const isAdminSelector = state => (
  (state.app.session.roles || []).some(
    role => 'ADMIN' === role
  )
);

export const isBrandManagerSelector = state => (
  (state.app.session.roles || []).some(
    role => 'BRAND_MANAGER' === role
  )
);

export const isDesignerSelector = state => (
  (state.app.session.roles || []).some(
    role => 'DESIGNER' === role
  )
);
