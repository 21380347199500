import React from 'react';
import * as actions from 'state/api/orders/OrdersApiActions';
import { Route, Switch } from 'react-router';
import { tokenSelector } from 'state/app/SessionSelectors';
import { useDispatch, useSelector } from 'react-redux';
import PrintMarketingList from 'views/components/Orders/PrintMarketingList';
import {
  fetchCatalogueSizes,
} from 'state/api/assets/AssetApiActions';


export default function PrintMarketingContainer() {
  const accessToken = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const catalogueSizes = useSelector(state => state.api.assets.catalogueSizes);
  const descriptionUrls = useSelector(state => state.api.orders.descriptionUrls);
  const fetchUrls = () => dispatch(actions.fetchDescriptionUrls({ accessToken, paginate: descriptionUrls || {} }));
  const fetchSizes = () => dispatch(fetchCatalogueSizes());
  const createDescriptionUrl = args => dispatch(actions.createDescriptionUrl({ ...args }));
  const deleteDescriptionUrl = args => dispatch(actions.deleteDescriptionUrl({ ...args }));
  const updateDescriptionUrl = args => dispatch(actions.updateDescriptionUrl({ ...args }));

  return (
    <Switch>
      <Route path="/orders/print-marketing">
        <PrintMarketingList
          fetchDescriptionUrls={fetchUrls}
          fetchSizes={fetchSizes}
          descriptionUrls={descriptionUrls}
          catalogueSizes={catalogueSizes}
          createDescriptionUrl={createDescriptionUrl}
          deleteDescriptionUrl={deleteDescriptionUrl}
          updateDescriptionUrl={updateDescriptionUrl}
          dispatch={dispatch}
        />
      </Route>
    </Switch>
  );
}
