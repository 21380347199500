import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import UserTeams from './UserTeams';
import { LOADING } from 'lib/Status';
import { toDateStr } from 'lib/dateUtil';
import UserDeleteDialogue from "views/components/Users/UserDeleteDialog";
import Tooltip from "views/components/Tooltip/Tooltip";
import { get as getPath } from 'lodash';

const initDialogueState = {
  open: false,
  heading: "",
  content: "",
  deleteType: null
};

export default function UserDetail ({
  deleteUserGDPR,
  deleteUserSoft,
  fetchUser,
  fetchUserPreferences,
  fetchUserTeams,
  fetchUserTeamDrafts,
  fetchUserTeamDraftsSizes,
  fetchUserWorkspaceFolders,
  fetchUserWorkspaceFolderSizes,
  history,
  isAdmin,
  match: { params: { userId } },
  setShowDeleteSuccess,
  updateUserPreferences,
  user,
  userTeams,
  userPreferences,
  userDrafts,
  userDraftsSizes,
  userTeamWorkspaceFolders,
  userWorkspaceFoldersSizes,
  teamCatalogue,
  remixUrls,
}) {
  // STATE
  const [deleteDialogue, setDeleteDialogue] = useState(initDialogueState);
  const [GPDRError, setGDPRError] = useState(null);

  // API CALLS
  useEffect(() => {
    if (user === null
      || user.meta.userId !== userId
    ) {
      fetchUser({ userId });
      fetchUserPreferences({ userId });
    }
  });

  if (!user || user.meta.userId !== userId) {
    return null;
  }
  
  const handleSubmit = async (event, deleteType) => {
    event.preventDefault();

    const deleteAction = deleteType === "soft" ? deleteUserSoft : deleteUserGDPR;
    
    let response = await deleteAction({ userId });
    
    if ((response || {}).error) {
      setGDPRError(`Send userId ${userId} to devs for deletion`);
      return;
    }

    setDeleteDialogue(false);
    setShowDeleteSuccess(true);
    history.goBack();
  }

  const isUserActive = getPath(user, "payload.active", false);

  return (
    <>
      <h2>User</h2>

      <Box
        component={Paper}
        display="flex"
        justifyContent="space-around"
        mb={2}
      >
        <Box p={2} textAlign="center">
          <Box
            color="#bbb"
            fontWeight="bold"
            fontSize="0.9em"
            style={{ textTransform: 'uppercase' }}
          >
            Name
          </Box>
          <Box>
            {user.meta.status === LOADING ? (
              <Skeleton />
            ) : (
              <>{user.payload.name}</>
            )}
          </Box>
        </Box>

        <Box p={2} textAlign="center">
          <Box
            color="#bbb"
            fontWeight="bold"
            fontSize="0.9em"
            style={{ textTransform: 'uppercase' }}
          >
            Email
          </Box>
          <Box>
            {user.meta.status === LOADING ? (
              <Skeleton />
            ) : (
              <>{user.payload.email}</>
            )}
          </Box>
        </Box>

        <Box p={2} textAlign="center">
          <Box
            color="#bbb"
            fontWeight="bold"
            fontSize="0.9em"
            style={{ textTransform: 'uppercase' }}
          >
            Active
          </Box>
          <Box>
            {user.meta.status === LOADING ? (
              <Skeleton />
            ) : (
              <>{user.payload.active ? (
                <CheckIcon fontSize="small" />
              ) : (
                <ClearIcon fontSize="small" />
              )}</>
            )}
          </Box>
        </Box>
        
        <Box p={2} textAlign="center">
          <Box
            color="#bbb"
            fontWeight="bold"
            fontSize="0.9em"
            style={{ textTransform: 'uppercase' }}
          >
            Created
          </Box>
          <Box>
            {user.meta.status === LOADING ? (
              <Skeleton />
            ) : (
              <>{toDateStr(user.payload.createdAt)}</>
            )}
          </Box>
        </Box>

        {isAdmin && isUserActive && (
          <Box p={2} textAlign="center" style={{ display: "flex" }}>
            <div
              data-tip={"Soft delete"}
              data-for="delete-button-tip"
            >
              <Button
                onClick={() => setDeleteDialogue({
                  open: true,
                  heading: `Delete the user ${user.payload.name}?`,
                  content: `Are you sure you want to soft delete this user?
                  \nOnce deleted, the users information can still be recovered.`,
                  deleteType: "soft"
                })}
                variant="outlined" 
                color="secondary"
                style={{ marginRight: '10px' }}
              >
                Delete
              </Button>
              <Tooltip id="delete-button-tip" place="bottom" />
            </div>
            <div
              data-tip={"Hard delete"}
              data-for="gdpr-delete-button"
            >
              <Button
                onClick={() => setDeleteDialogue({
                  open: true,
                  heading: `Permanently delete the user ${user.payload.name}?`,
                  content: `Are you sure you want to permanently delete this user?
                  \nOnce deleted, you won't be able to recover the user or their information.`,
                  deleteType: "hard"
                })}
                variant="contained" 
                color="secondary"
              >
                GDPR Delete
              </Button>
              <Tooltip id="gdpr-delete-button" place="bottom" />
            </div>
          </Box>
        )}
      </Box>

      <UserTeams
        fetchUserTeams={fetchUserTeams}
        fetchUserTeamDrafts={fetchUserTeamDrafts}
        userDrafts={userDrafts}
        userDraftsSizes={userDraftsSizes}
        userTeamWorkspaceFolders={userTeamWorkspaceFolders}
        userWorkspaceFoldersSizes={userWorkspaceFoldersSizes}
        history={history}
        updateUserPreferences={updateUserPreferences}
        userId={userId}
        userTeams={userTeams}
        userPreferences={userPreferences}
        fetchUserTeamDraftsSizes={fetchUserTeamDraftsSizes}
        fetchUserWorkspaceFolders={fetchUserWorkspaceFolders}
        fetchUserWorkspaceFolderSizes={fetchUserWorkspaceFolderSizes}
        teamCatalogue={teamCatalogue}
        remixUrls={remixUrls}
      />

      {deleteDialogue.open && (
        <UserDeleteDialogue
          isOpen={deleteDialogue.open}
          onClose={() => setDeleteDialogue(initDialogueState)}
          heading={deleteDialogue.heading}
          content={deleteDialogue.content}
          handleSubmit={e => handleSubmit(e, deleteDialogue.deleteType)}
          GPDRError={GPDRError}
        />
      )}
    </>
  );
}
