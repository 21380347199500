import uploadToS3 from 'lib/uploadToS3';
import { getUploadThumbData, updateMediaThumbnail } from 'state/api/assets-media/AssetMediaApiActions';

export default async ({
  accessToken,
  dispatch,
  mediaId,
  thumbFile
}) => {
  const uploadThumbData = await dispatch(
    getUploadThumbData({
      accessToken,
      mediaId,
      thumbFile,
    }),
  );

  await uploadToS3(uploadThumbData.payload.url, thumbFile);

  return await dispatch(updateMediaThumbnail({
    accessToken,
    filename: uploadThumbData.payload.filename,
    mediaId,
  }));
};
