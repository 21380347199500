import React from "react";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoginForm from 'views/components/Login/LoginForm';
import { errorsSelector } from 'state/ui/Login/LoginSelectors';
import { authenticate } from 'state/ui/Login/LoginActions';
import { isProcessingSelector } from 'state/ui/Login/LoginSelectors';

/**
 * Login container to map authentication state and actions to Login presentation.
 * @param authenticate: Authentication action
 * @param errors: Errors to be shown to the user
 * @param isProcessing: boolean to indicate if login is processing
 */
const LoginContainer = ({ authenticate, errors, isProcessing }) => {
  return (
    <LoginForm authenticate={authenticate} errors={errors} isProcessing={isProcessing} />
  );
};

const mapDispatchToProps = dispatch => ({
  authenticate: (username, password) => dispatch(authenticate(username, password))
});

const mapStateToProps = state => ({
  errors: errorsSelector(state),
  isProcessing: isProcessingSelector(state),
});

LoginContainer.propTypes = {
  authenticate: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
