import store from 'state/store';
import { createAction } from 'lib/apiMiddlewareUtils';
import { get as getPath } from 'lodash';
import * as types from "state/api/orders/OrdersApiTypes"
import { tokenSelector } from 'state/app/SessionSelectors';

const V1 = `${process.env.REACT_APP_HOST_ORDERS_SERVICE}/v1`;

export const fetchOrdersCoupons = ({
  accessToken,
  paginate,
}) => createAction({
  endpoint: `${V1}/coupons`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'GET',
  paginate,
  types: [
    types.ORDERS_COUPONS_REQUEST,
    types.ORDERS_COUPONS_SUCCESS,
    types.ORDERS_COUPONS_FAILURE,
  ],
});

export const createOrdersCoupons = ({
  accessToken,
  applyTo,
  code,
  endAt,
  startAt,
  value,
  valueType,
}) => createAction({
  endpoint: `${V1}/coupons`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'POST',
  body: JSON.stringify({
    applyTo,
    code,
    endAt,
    startAt,
    value,
    valueType,
  }),
  types: [
    types.CREATE_ORDERS_COUPONS_REQUEST,
    types.CREATE_ORDERS_COUPONS_SUCCESS,
    types.CREATE_ORDERS_COUPONS_FAILURE,
  ],
});

export const updateOrdersCoupons = ({
  accessToken,
  applyTo,
  code,
  createdAt,
  endAt,
  startAt,
  updatedAt,
  value,
  valueType,
}) => createAction({
  endpoint: `${V1}/coupons/${code}`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'PUT',
  body: JSON.stringify({
    applyTo,
    code,
    createdAt,
    endAt,
    startAt,
    updatedAt,
    value,
    valueType,
  }),
  types: [
    types.UPDATE_ORDERS_COUPONS_REQUEST,
    types.UPDATE_ORDERS_COUPONS_SUCCESS,
    types.UPDATE_ORDERS_COUPONS_FAILURE,
  ],
});

export const fetchDescriptionUrls = ({
  accessToken,
  paginate,
}) => createAction({
  endpoint: `${V1}/description-urls`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'GET',
  paginate,
  types: [
    types.DESCRIPTION_URL_REQUEST,
    types.DESCRIPTION_URL_SUCCESS,
    types.DESCRIPTION_URL_FAILURE,
  ],
});

export const createDescriptionUrl = ({
  templateCode,
  country,
  descriptionUrl,
}) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${V1}/templates/${templateCode}/country/${country}/description-url`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify({
      templateCode,
      country,
      descriptionUrl,
    }),
    types: [
      types.CREATE_DESCRIPTION_URL_REQUEST,
      types.CREATE_DESCRIPTION_URL_SUCCESS,
      types.CREATE_DESCRIPTION_URL_FAILURE,
    ],
  });
}

export const deleteDescriptionUrl = ({
  templateCode,
  country,
}) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${V1}/templates/${templateCode}/country/${country}/description-url`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    types: [
      types.DELETE_DESCRIPTION_URL_REQUEST,
      types.DELETE_DESCRIPTION_URL_SUCCESS,
      types.DELETE_DESCRIPTION_URL_FAILURE,
    ],
    params: {
      templateCode,
      country,
    }
  });
}

export const updateDescriptionUrl = ({
  templateCode,
  country,
  descriptionUrl,
}) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${V1}/templates/${templateCode}/country/${country}/description-url`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify({
      templateCode,
      country,
      descriptionUrl,
    }),
    types: [
      types.UPDATE_DESCRIPTION_URL_REQUEST,
      types.UPDATE_DESCRIPTION_URL_SUCCESS,
      types.UPDATE_DESCRIPTION_URL_FAILURE,
    ],
  });
}

export const fetchPrintProviders = ({ paginate }) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${V1}/print-providers`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    paginate,
    types: [
      types.PRINT_PROVIDER_REQUEST,
      types.PRINT_PROVIDER_SUCCESS,
      types.PRINT_PROVIDER_FAILURE,
    ],
  });
}

export const updatePrintProvider = ({
  printProvider
}) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);
  const providerId = printProvider.id;

  return createAction({
    endpoint: `${V1}/print-providers/${providerId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(printProvider),
    types: [
      types.UPDATE_PRINT_PROVIDER_REQUEST,
      types.UPDATE_PRINT_PROVIDER_SUCCESS,
      types.UPDATE_PRINT_PROVIDER_FAILURE,
    ],
  });
};

export const deactivatePrintProvider = ({
  providerId
}) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${V1}/print-providers/${providerId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    types: [
      types.DELETE_PRINT_PROVIDER_REQUEST,
      types.DELETE_PRINT_PROVIDER_SUCCESS,
      types.DELETE_PRINT_PROVIDER_FAILURE,
    ],
    params: {
      providerId
    }
  });
};

export const deletePrintPriceOption = (printOption) => {
  const optionId = printOption.pricing.printOptionId;
  const priceId = printOption.pricing.id;
  const printProviderId = printOption.pricing.printProviderId;
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${V1}/print-providers/${printProviderId}/print-options/${optionId}/pricing/${priceId}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    types: [
      types.DELETE_PRINT_PRICE_REQUEST,
      {
        type: types.DELETE_PRINT_PRICE_SUCCESS,
        meta: { printOption }
      },
      {
        type: types.DELETE_PRINT_PRICE_FAILURE,
        meta: { printOption }
      },
    ],
  });
}

export const fetchPrintOptionsByProvider = ({ printProviderId }) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  if (!printProviderId) {
    throw new Error('Print provider id required.');
  }

  return createAction({
    endpoint: `${V1}/print-providers/${printProviderId}/print-option-pricing`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    types: [
      types.PRINT_OPTIONS_BY_PRINT_PROVIDER_REQUEST,
      types.PRINT_OPTIONS_BY_PRINT_PROVIDER_SUCCESS,
      types.PRINT_OPTIONS_BY_PRINT_PROVIDER_FAILURE,
    ],
    paginate: getPath(state.api.orders.printOptions, printProviderId, {}),
    params: {
      printProviderId,
      count: 100
    }
  });
};

export const updatePrintOptionPrice = (printPrice) => {
  const {
    id,
    printProviderId,
    printOptionId,
    minQuantity,
    price
  } = printPrice
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${V1}/print-providers/${printProviderId}/print-options/${printOptionId}/pricing/${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify({
      ...printPrice,
      minQuantity: Number(minQuantity),
      price: Number(price)
    }),
    types: [
      types.UPDATE_PRINT_PRICE_REQUEST,
      types.UPDATE_PRINT_PRICE_SUCCESS,
      types.UPDATE_PRINT_PRICE_FAILURE,
    ],
  });
}

export const fetchTemplateSizesByOrderLabel = ({ labelName }) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  if (!labelName) {
    throw new Error('label name required.');
  }

  return createAction({
    endpoint: `${V1}/order-labels/${labelName}/templates`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    types: [
      types.TEMPLATE_SIZES_BY_ORDER_LABEL_REQUEST,
      types.TEMPLATE_SIZES_BY_ORDER_LABEL_SUCCESS,
      types.TEMPLATE_SIZES_BY_ORDER_LABEL_FAILURE,
    ],
    params: {
      labelName
    }
  });
};

export const createPrintOptionPrice = ({
  printProviderId,
  printOptionId,
  minQuantity,
  price,
  label,
  sizeDescription,
  stock
}) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  const printPricingBody = {
    printProviderId,
    printOptionId,
    minQuantity: Number(minQuantity),
    price: Number(price)
  };

  return createAction({
  endpoint: `${V1}/print-providers/${printProviderId}/print-options/${printOptionId}/pricing`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'POST',
  types: [
    types.ADD_PRINT_OPTION_PRICING_REQUEST,
    types.ADD_PRINT_OPTION_PRICING_SUCCESS,
    types.ADD_PRINT_OPTION_PRICING_FAILURE,
  ],
  body: JSON.stringify(printPricingBody),
  params: {
    label,
    sizeDescription,
    stock
  }
})};

export const createPrintOption = ({
  printProviderId, 
  label,
  sizeDescription,
  stock,
  width,
  height,
  finishing,
  description,
  minQuantity,
  price
}) => async dispatch => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  if (!printProviderId) {
    throw new Error('Print provider id required.');
  }

  const printOptionBody = {
    printProviderId,
    label,
    sizeDescription,
    stock,
    width: Number(width),
    height: Number(height),
    finishing,
    description
  }

  // create print option
  const createPrintOptionResponse = await dispatch(createAction({
    endpoint: `${V1}/print-providers/${printProviderId}/print-options`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    types: [
      types.ADD_PRINT_OPTION_REQUEST,
      types.ADD_PRINT_OPTION_SUCCESS,
      types.ADD_PRINT_OPTION_FAILURE,
    ],
    body: JSON.stringify(printOptionBody)
  }));

  if (createPrintOptionResponse.error) return { error: true };

  // add pricing to print option
  const printOptionId = createPrintOptionResponse.payload.id;
  return await dispatch(createPrintOptionPrice({
    printProviderId,
    printOptionId,
    minQuantity,
    price,
    label,
    sizeDescription,
    stock,
    width,
    height,
  }));
};

export const updatePrintOption = (printOption, originalPrintOption) => {
  const {
    id,
    printProviderId
  } = printOption
  const state = store.getState();
  const accessToken = tokenSelector(state);

  const body = {
    ...printOption,
    providerId: printProviderId
  };

  if (body.finishing === "") {
    body.finishing = null;
  }
  if (body.description === "") {
    body.description = null;
  }

  return createAction({
    endpoint: `${V1}/print-providers/${printProviderId}/print-options/${id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(body),
    types: [
      types.UPDATE_PRINT_OPTION_REQUEST,
      {
        type: types.UPDATE_PRINT_OPTION_SUCCESS,
        meta: { originalPrintOption }
      },
      types.UPDATE_PRINT_OPTION_FAILURE,
    ],
  });
}

export const deletePrintOption = (printOption) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${V1}/print-providers/${printOption.pricing.printProviderId}/print-options/${printOption.id}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    types: [
      types.DELETE_PRINT_OPTION_REQUEST,
      {
        type: types.DELETE_PRINT_OPTION_SUCCESS,
        meta: { printOption }
      },
      {
        type: types.DELETE_PRINT_OPTION_FAILURE,
        meta: { printOption }
      },
    ],
  });
};

export const deleteTemplateCodeFromPrintLabel = ({
  templateCode,
  orderLabel,
}) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${V1}/order-labels/${orderLabel}/templates/${templateCode}`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'DELETE',
    types: [
      types.DELETE_TEMPLATE_CODE_FROM_PRINT_LABEL_REQUEST,
      types.DELETE_TEMPLATE_CODE_FROM_PRINT_LABEL_SUCCESS,
      types.DELETE_TEMPLATE_CODE_FROM_PRINT_LABEL_FAILURE,
    ],
    params: {
      templateCode,
      orderLabel,
    }
  });
};

export const fetchUnassignedOrderLabels = ({ providerId, paginate }) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${V1}/print-providers/${providerId}/order-labels/unassigned`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    paginate,
    types: [
      types.UNASSIGNED_PRINT_OPTION_REQUEST,
      types.UNASSIGNED_PRINT_OPTION_SUCCESS,
      types.UNASSIGNED_PRINT_OPTION_FAILURE,
    ],
    params: {
      providerId,
      count: 100
    }
  });
};

export const createOrderLabel = ({
  orderLabel,
  templateCode,
  providerId
}) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
  endpoint: `${V1}/order-labels`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'POST',
  types: [
    types.ADD_ORDER_LABEL_REQUEST,
    types.ADD_ORDER_LABEL_SUCCESS,
    types.ADD_ORDER_LABEL_FAILURE,
  ],
  body: JSON.stringify({
    orderLabel,
    templateCode
  }),
  params: {
    providerId
  }
})};

export const fetchUnassignedTemplateCodes = ({ paginate }) => {
  const state = store.getState();
  const accessToken = tokenSelector(state);

  return createAction({
    endpoint: `${V1}/order-labels/template-codes/unassigned`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'GET',
    paginate,
    types: [
      types.UNASSIGNED_TEMPLATE_CODES_REQUEST,
      types.UNASSIGNED_TEMPLATE_CODES_SUCCESS,
      types.UNASSIGNED_TEMPLATE_CODES_FAILURE,
    ],
    params: {
      count: 100
    }
  });
};
