import React from "react";
import { makeStyles } from "@material-ui/core";
import ReactTooltip from "react-tooltip";

const useStyles = makeStyles(theme => ({
  toolTip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    fontSize: "12px",
    lineHeight: "20px",
    padding: "5px 12px !important",
    color: "white",
    backgroundColor: "black",
    textAlign: "center",
    opacity: "1 !important",
    whiteSpace: "pre-line",
    "& span": {
      textAlign: "left !important"
    }
  }
}))

/* See component props details at the end of file. */
const Tooltip = ({
  place = "left",
  type,
  effect = "solid",
  event,
  eventOff,
  globalEventOff,
  isCapture,
  offset,
  multiline,
  className,
  html,
  delayHide,
  delayShow = 250,
  delayUpdate,
  insecure,
  border,
  getContent,
  afterShow,
  afterHide,
  disable,
  scrollHide,
  resizeHide,
  wrapper,
  id
}) => {
  const classes = useStyles();

  return (
    <ReactTooltip
      place={place}
      type={type}
      effect={effect}
      event={event}
      eventOff={eventOff}
      globalEventOff={globalEventOff}
      isCapture={isCapture}
      offset={offset}
      multiline={multiline}
      className={`${className} ${classes.toolTip}`}
      html={html}
      delayHide={delayHide}
      delayShow={delayShow}
      delayUpdate={delayUpdate}
      insecure={insecure}
      border={border}
      getContent={getContent}
      afterShow={afterShow}
      afterHide={afterHide}
      disable={disable}
      scrollHide={scrollHide}
      resizeHide={resizeHide}
      wrapper={wrapper}
      id={id}
    />
  );
};

export default Tooltip;

/* Component Props, extracted from  https://github.com/wwayne/react-tooltip - 25/feb/2019 */
/* +---------------+-----------------+----------+-----------------------------+---------------------+
 * |Global         |Specific         |Type      |Values                       |Description          |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |place          |data-place       |String    |top,                         |placement            |
 * |               |                 |          |right,                       |                     |
 * |               |                 |          |bottom,                      |                     |
 * |               |                 |          |left                         |                     |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |type           |data-type        |String    |success,                     |theme                |
 * |               |                 |          |warning,                     |                     |
 * |               |                 |          |error,                       |                     |
 * |               |                 |          |info,                        |                     |
 * |               |                 |          |light                        |                     |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |effect         |data-effect      |String    |float,                       |behaviour            |
 * |               |                 |          |solid                        |of tooltip           |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |event          |data-event       |String    |e.g. click                   |custom               |
 * |               |                 |          |                             |event to             |
 * |               |                 |          |                             |trigger              |
 * |               |                 |          |                             |tooltip              |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |eventOff       |data-event-off   |String    |e.g. click                   |custom               |
 * |               |                 |          |                             |event to             |
 * |               |                 |          |                             |hide                 |
 * |               |                 |          |                             |tooltip              |
 * |               |                 |          |                             |(only makes          |
 * |               |                 |          |                             |effect               |
 * |               |                 |          |                             |after                |
 * |               |                 |          |                             |setting              |
 * |               |                 |          |                             |event                |
 * |               |                 |          |                             |attribute)           |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |globalEventOff |String           |e.g. click|global                       |                     |
 * |               |                 |          |event to                     |                     |
 * |               |                 |          |hide                         |                     |
 * |               |                 |          |tooltip                      |                     |
 * |               |                 |          |(global                      |                     |
 * |               |                 |          |only)                        |                     |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |isCapture      |data-iscapture   |Bool      |true,                        |when set to          |
 * |               |                 |          |false                        |true,                |
 * |               |                 |          |                             |custom               |
 * |               |                 |          |                             |event's              |
 * |               |                 |          |                             |propagation          |
 * |               |                 |          |                             |mode will            |
 * |               |                 |          |                             |be capture           |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |offset         |data-offset      |Object    |top,                         |data-offset="{'top': |
 * |               |                 |          |right,                       |10, 'left': 10}" for |
 * |               |                 |          |bottom,                      |specific and         |
 * |               |                 |          |left                         |offset={{top: 10,    |
 * |               |                 |          |                             |left: 10}} for       |
 * |               |                 |          |                             |global               |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |multiline      |data-multiline   |Bool      |true,                        |support <br>, <br /> |
 * |               |                 |          |false                        |to make multiline    |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |className      |data-class       |String    |extra custom                 |                     |
 * |               |                 |          |class, can use               |                     |
 * |               |                 |          |!important to                |                     |
 * |               |                 |          |overwrite                    |                     |
 * |               |                 |          |react-tooltip's              |                     |
 * |               |                 |          |default class                |                     |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |html           |data-html        |Bool      |true, false                  |<p data-tip="<p>HTML |
 * |               |                 |          |                             |tooltip</p>"         |
 * |               |                 |          |                             |data-html={true}></p>|
 * |               |                 |          |                             |or <ReactTooltip     |
 * |               |                 |          |                             |html={true} />, but  |
 * |               |                 |          |                             |see Security Note    |
 * |               |                 |          |                             |below.               |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |delayHide      |data-delay-hide  |Number    |<p data-tip="tooltip"        |                     |
 * |               |                 |          |data-delay-hide='1000'></p>  |                     |
 * |               |                 |          |or <ReactTooltip             |                     |
 * |               |                 |          |delayHide={1000} />          |                     |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |delayShow      |data-delay-show  |Number    |<p data-tip="tooltip"        |                     |
 * |               |                 |          |data-delay-show='1000'></p>  |                     |
 * |               |                 |          |or <ReactTooltip             |                     |
 * |               |                 |          |delayShow={1000} />          |                     |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |delayUpdate    |data-delay-update|Number    |<p data-tip="tooltip"        |                     |
 * |               |                 |          |data-delay-update='1000'></p>|                     |
 * |               |                 |          |or <ReactTooltip             |                     |
 * |               |                 |          |delayUpdate={1000} /> Sets a |                     |
 * |               |                 |          |delay in calling getContent  |                     |
 * |               |                 |          |if the tooltip is already    |                     |
 * |               |                 |          |shown and you mouse over     |                     |
 * |               |                 |          |another target               |                     |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |insecure       |null             |Bool      |true, false                  |Whether to inject the|
 * |               |                 |          |                             |style header into the|
 * |               |                 |          |                             |page dynamically     |
 * |               |                 |          |                             |(violates CSP        |
 * |               |                 |          |                             |style-src but is a   |
 * |               |                 |          |                             |convenient default)  |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |border         |data-border      |Bool      |true, false                  |Add one pixel white  |
 * |               |                 |          |                             |border               |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |getContent     |null             |Func or   |(dataTip) => {}, [(dataTip)  |Generate the tip     |
 * |               |                 |Array     |=> {}, Interval]             |content dynamically  |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |afterShow      |null             |Func      |(evt) => {}                  |Function that will be|
 * |               |                 |          |                             |called after tooltip |
 * |               |                 |          |                             |show, with event that|
 * |               |                 |          |                             |triggered show       |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |afterHide      |null             |Func      |(evt) => {}                  |Function that will be|
 * |               |                 |          |                             |called after tooltip |
 * |               |                 |          |                             |hide, with event that|
 * |               |                 |          |                             |triggered hide       |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |disable        |data-tip-disable |Bool      |true, false                  |Disable the tooltip  |
 * |               |                 |          |                             |behaviour, default is|
 * |               |                 |          |                             |false                |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |scrollHide     |data-scroll-hide |Bool      |true, false                  |Hide the tooltip when|
 * |               |                 |          |                             |scrolling, default is|
 * |               |                 |          |                             |true                 |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |resizeHide     |null             |Bool      |true, false                  |Hide the tooltip when|
 * |               |                 |          |                             |resizing the window, |
 * |               |                 |          |                             |default is true      |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 * |wrapper        |null             |String    |div, span                    |Selecting the wrapper|
 * |               |                 |          |                             |element of the react |
 * |               |                 |          |                             |tooltip, default is  |
 * |               |                 |          |                             |div                  |
 * +---------------+-----------------+----------+-----------------------------+---------------------+
 *  */
