import React from 'react';
import * as actions from 'state/api/orders/OrdersApiActions';
import * as selectors from 'state/api/orders/OrdersApiSelectors';
import { Route, Switch } from 'react-router';
import { tokenSelector } from 'state/app/SessionSelectors';
import { useDispatch, useSelector } from 'react-redux';
import OrdersCouponList from 'views/components/Orders/OrdersCouponList';

export default function OrdersCouponsContainer() {
  const accessToken = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const coupons = useSelector(selectors.ordersCouponsSelector);
  const create = (params) => dispatch(actions.createOrdersCoupons({ accessToken, ...params }) ); 
  const fetch = () => dispatch(actions.fetchOrdersCoupons({ accessToken, paginate: coupons || {} }) )
  const update = (params) => dispatch(actions.updateOrdersCoupons({ accessToken, ...params }) ); 

  return (
    <Switch>
      <Route path="/orders/coupons">
        <OrdersCouponList
          ordersCoupons={coupons}
          createOrdersCoupons={create}
          updateOrdersCoupons={update}
          fetchOrdersCoupons={fetch}
        />
      </Route>
    </Switch>
  );
}
