import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    paddingTop: theme.spacing(2),
    color: "red"
  }
}));

export default function AddPrintOptionDialog({
  addPrintOptionPrice,
  printOption,
  onClose,
  setSuccessActionString
}) {
  const classes = useStyles();
  const [minQuantity, setMinQuantity] = useState(null);
  const [price, setPrice] = useState(null);
  const [errors, setErrors] = useState({});
  const isAddDisabled = !(price && minQuantity) || Object.values(errors).some(error => !!error);

  const handleOnBlur = event => {
    const inputName = event.target.name;
    const value = event.target.value;

    // set error if no value has been provided
    if (!value) {
      setErrors({ 
        ...errors,
        [inputName]: `Please provide a ${inputName}`
      });
      return;
    }

    // set error if negative integer is provided
    if (event.target.type === "number" && (Number(value) < 0)) {
      setErrors({ 
        ...errors,
        [inputName]: `Please provide a positive value for ${inputName}`
      });
      return;
    }
    // clear error
    setErrors({ 
      ...errors,
      [inputName]: ""
    });
  }

  const {
    label,
    sizeDescription,
    stock,
    width,
    height,
    finishing,
    description
  } = printOption;
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await addPrintOptionPrice({
      printOptionId: printOption.id,
      minQuantity,
      price,
      label,
      sizeDescription,
      stock
    });

    if (response.error) {
      setErrors({
        ...errors,
        response: "There was an issue processing your request. Contact devs for support."
      });
      return
    }
    
    onClose();
    setSuccessActionString("Print option price successfully created");
  };

  return (
    <Dialog aria-labelledby="form-dialog-title" onClose={onClose} open={true} maxWidth="xs">
      <DialogTitle id="form-dialog-title">
        Add Print Option Price
      </DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="dense"
          label="Size"
          value={sizeDescription}
          variant="outlined"
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="dense"
          label="Width (mm)"
          onChange={() => {}}
          value={width}
          variant="outlined"
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          margin="dense"
          label="Height (mm)"
          onChange={() => {}}
          value={height}
          variant="outlined"
          disabled
        />

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Stock (User Facing)"
          margin="dense"
          name="stock"
          value={stock}
          variant="outlined"
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Finishing (User Facing - Optional)"
          margin="dense"
          value={finishing}
          variant="outlined"
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Print Description (Internal Use Only)"
          margin="dense"
          value={description}
          variant="outlined"
          disabled
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Min Quantity"
          margin="dense"
          onChange={e => {
            setMinQuantity(e.target.value); 
            setErrors({ ...errors, minQuantity: "" })
          }}
          required
          onBlur={e => handleOnBlur(e)}
          name="minQuantity"
          value={minQuantity}
          variant="outlined"
          type="number"
          error={!!errors.minQuantity}
          helperText={errors.minQuantity}
          InputProps={{
            inputProps: { min: 0 }
          }}
        />
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="Unit Price"
          margin="dense"
          onChange={e => {
            setPrice(e.target.value); 
            setErrors({ ...errors, price: "" })
          }}
          required
          onBlur={e => handleOnBlur(e)}
          name="price"
          value={price}
          variant="outlined"
          type="number"
          error={!!errors.price}
          helperText={errors.price}
          InputProps={{
            inputProps: { min: 0 }
          }}
        />
        {errors.response && (
          <div className={classes.errorMessage}>{errors.response}</div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={isAddDisabled}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}