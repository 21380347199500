import * as types from 'state/api/assets-media/AssetMediaApiTypes';
import { createAction } from 'lib/apiMiddlewareUtils';
import { categoryTypes } from 'lib/constants';

const URI = process.env.REACT_APP_HOST_ASSET_SERVICE;

export const addMediaCategories = ({
  accessToken,
  categoryNames,
  mediaId,
}) => createAction({
  body: JSON.stringify({ categoryNames, mediaId }),
  endpoint: `${URI}/v1/media/${mediaId}/categories/bulk`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'POST',
  types: [
    types.ADD_CATEGORY_REQUEST,
    types.ADD_CATEGORY_SUCCESS,
    types.ADD_CATEGORY_FAILURE,
  ],
});

export const addFontToSubTeams = ({
  accessToken,
  fontFile,
  teamId,
}) => {

  const formData = new FormData();
  formData.append('teamId', teamId)
  formData.append('fontFile', fontFile)

  return createAction({
  body: formData,
  endpoint: `${URI}/v1/teams/${teamId}/users/fonts`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',

  },
  method: 'POST',
  types: [
    types.UPLOAD_FONT_REQUEST,
    types.UPLOAD_FONT_SUCCESS,
    types.UPLOAD_FONT_FAILURE,
  ],
})};

export const createSubscriptionMedia = ({
  accessToken,
  duration,
  filename,
  mediaId,
  name,
  priority,
  subscriptionCode = 'BASIC',
}) => createAction({
  body: JSON.stringify({
    duration,
    filename,
    id: mediaId,
    name,
    priority,
    subscriptionCode,
  }),
  endpoint: `${URI}/v1/media/subscriptions`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'POST',
  types: [
    types.CREATE_REQUEST,
    types.CREATE_SUCCESS,
    types.CREATE_FAILURE,
  ],
});

export const createSubscriptionZipMedia = ({
  accessToken,
  categories,
  filename,
  referenceId,
  responseContentType,
  subscriptionCode = 'BASIC',
}) => createAction({
  body: JSON.stringify({
    categories,
    filename,
    referenceId,
    responseContentType,
    subscriptionCode,
  }),
  endpoint: `${URI}/v1/media/subscriptions/zip/url`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'POST',
  types: [
    types.UPLOAD_ZIP_REQUEST,
    types.UPLOAD_ZIP_SUCCESS,
    types.UPLOAD_ZIP_FAILURE,
  ],
});

export const deleteMedia = ({ accessToken, mediaId }) => createAction({
  endpoint: `${URI}/v1/media/${mediaId}/subscriptions`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  },
  method: 'DELETE',
  types: [
    types.DELETE_REQUEST,
    types.DELETE_SUCCESS,
    types.DELETE_FAILURE,
  ],
});

export const removeMediaCategory = ({
  accessToken,
  categoryId,
  mediaId,
}) => createAction({
  endpoint: `${URI}/v1/media/${mediaId}/categories/${categoryId}`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  },
  method: 'DELETE',
  types: [
    types.REMOVE_CATEGORY_REQUEST,
    types.REMOVE_CATEGORY_SUCCESS,
    types.REMOVE_CATEGORY_FAILURE,
  ],
});

export const fetchMedia = ({ accessToken, mediaId }) => createAction({
  endpoint: `${URI}/v1/media/${mediaId}`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
  },
  method: 'GET',
  params: { mediaId },
  types: [
    types.FETCH_REQUEST,
    types.FETCH_SUCCESS,
    types.FETCH_FAILURE,
  ],
});

export const fetchMediaCategories = ({
  accessToken,
  mediaId,
}) => createAction({
  endpoint: `${URI}/v1/media/${mediaId}/categories`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  },
  method: 'GET',
  params: { mediaId },
  types: [
    types.FETCH_CATEGORIES_REQUEST,
    types.FETCH_CATEGORIES_SUCCESS,
    types.FETCH_CATEGORIES_FAILURE,
  ],
});

export const getUploadImageData = ({
  accessToken,
  imageFile
}) => createAction({
  body: JSON.stringify({
    filename: imageFile.name,
    responseContentType: imageFile.type,
  }),
  endpoint: `${URI}/v1/media/subscriptions/url`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'POST',
  types: [
    types.UPLOAD_IMAGE_REQUEST,
    types.UPLOAD_IMAGE_SUCCESS,
    types.UPLOAD_IMAGE_FAILURE,
  ],
});

export const getUploadThumbData = ({
  accessToken,
  mediaId,
  thumbFile,
}) => createAction({
  body: JSON.stringify({
    filename: thumbFile.name,
    id: mediaId,
    responseContentType: thumbFile.type,
  }),
  endpoint: `${URI}/v1/media/${mediaId}/thumbnail/url`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'POST',
  types: [
    types.UPLOAD_IMAGE_REQUEST,
    types.UPLOAD_IMAGE_SUCCESS,
    types.UPLOAD_IMAGE_FAILURE,
  ],
});

export const searchMedia = ({
  accessToken,
  category,
  folderId,
  mediaItems,
  term,
  context
}) => {
  const params = {
    category,
    folderId,
    term,
    context
  }
  if ((category && category !== categoryTypes.ANIMATIONS) && (category && category !== categoryTypes.VIDEO)) {
    // Only add vector type when we're viewing a graphics category, otherwise we want to see
    // IMAGE types as well.
    params.type = 'vector';
  }
  if (category && category === categoryTypes.VIDEO) {
    delete params.category
    params.type = 'VIDEO'
  }
  if (category && category === categoryTypes.ANIMATIONS) {
    delete params.category
    params.type = 'ANIMATION'
  }
  return createAction({
    endpoint: `${URI}/v1/media`,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: 'application/json',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    },
    method: 'GET',
    paginate: mediaItems || {},
    params: params,
    types: [
      types.SEARCH_REQUEST,
      types.SEARCH_SUCCESS,
      types.SEARCH_FAILURE,
    ],
  });
};

export const updateMedia = ({
  accessToken,
  asset,
  createdAt,
  id,
  name,
  preview,
  priority,
  status,
  subscriptionCode,
  thumbnail,
  type,
  updatedAt,
}) => createAction({
  body: JSON.stringify({
    asset,
    createdAt,
    id,
    name,
    preview,
    priority,
    status,
    subscriptionCode,
    thumbnail,
    type,
    updatedAt,
  }),
  endpoint: `${URI}/v1/media/${id}/subscriptions`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'PUT',
  types: [
    types.UPDATE_REQUEST,
    types.UPDATE_SUCCESS,
    types.UPDATE_FAILURE,
  ],
});

export const updateMediaThumbnail = ({
  accessToken,
  filename,
  mediaId,
}) => createAction({
  body: JSON.stringify({
    filename,
    mediaId,
  }),
  endpoint: `${URI}/v1/media/${mediaId}/thumbnail`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'PUT',
  types: [
    types.UPDATE_THUMB_REQUEST,
    types.UPDATE_THUMB_SUCCESS,
    types.UPDATE_THUMB_FAILURE,
  ],
});
