export const mediaItemCategoriesSelector = (state) => (
  state.api.assetsMedia.mediaItemCategories
);

export const mediaItemSelector = (state) => (
  state.api.assetsMedia.mediaItem
);

export const mediaItemsSelector = (state) => (
  state.api.assetsMedia.mediaItems
);

export const fontUploadsSelector = (state) => (
  state.api.assetsMedia.fontUploads
);


