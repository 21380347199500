import React from 'react';
import * as actions from 'state/api/users/UsersApiActions';
import * as assetActions from 'state/api/assets/AssetApiActions';
import * as selectors from 'state/api/users/UsersApiSelectors';
import * as assetSelectors from 'state/api/assets/AssetApiSelectors'
import * as folderSelectors from 'state/entities/folders/folderSelectors'
import { useDispatch, useSelector } from 'react-redux';
import { tokenSelector } from 'state/app/SessionSelectors';
import { fetchUserWorkspaceFolders, fetchUserWorkspaceFolderSizes } from 'state/entities/folders/folderActions'
import UserWorkspaceDrafts from 'views/components/Users/UserWorkspaceDrafts';

export default function UserWorkspaceDraftsContainer(props) {
  const accessToken = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const user = useSelector(selectors.userSelector);
  const userTeams = useSelector(selectors.userTeamsSelector);
  const userDrafts = useSelector(assetSelectors.userTeamDraftsSelector);
  const remixUrls = useSelector(state => state.api.assets.collectionRemixUrls)
  const userDraftsSizes = useSelector(assetSelectors.userTeamDraftsSizesSelector);
  const userTeamWorkspaceFolders = useSelector(folderSelectors.userWorkspaceFoldersSelector)
  const userWorkspaceFoldersSizes = useSelector(folderSelectors.userWorkspaceFoldersSizesSelector)
  

  return (
    <UserWorkspaceDrafts
      fetchUser={(p) => dispatch(actions.fetchUser({ accessToken, ...p }))}
      deleteUserGDPR={(p) => dispatch(actions.deleteUserGDPR({ accessToken, ...p }))}
      deleteUserSoft={(p) => dispatch(actions.deleteUser({ accessToken, active: user.payload.active, ...p }))}
      fetchUserTeams={(p) => dispatch(actions.fetchUserTeams({ accessToken, ...p }))}
      fetchUserTeamDrafts={(p) => dispatch(assetActions.fetchUserTeamDrafts({ accessToken, ...p }))}
      fetchUserTeamDraftsSizes={(p) => dispatch(assetActions.fetchUserTeamDraftsSizes({ accessToken, ...p }))}
      fetchUserWorkspaceFolders={(p) => dispatch(fetchUserWorkspaceFolders({ accessToken, ...p }))}
      fetchUserWorkspaceFolderSizes={(p) => dispatch(fetchUserWorkspaceFolderSizes({ accessToken, ...p }))}
      user={user}
      userId={user.payload.id}
      userTeams={userTeams}
      userDrafts={userDrafts}
      userDraftsSizes={userDraftsSizes}
      userTeamWorkspaceFolders={userTeamWorkspaceFolders}
      userWorkspaceFoldersSizes={userWorkspaceFoldersSizes}
      remixUrls={remixUrls}
      {...props}
    />
  );
}
