export const thumbnailUpdateDesignSelector = state => state.api.assets.thumbnail.designId;

export const thumbnailUpdateStatusSelector = state => state.api.assets.thumbnail.status;

export const teamCatalogueSelector = (state) => state.api.assets.teamCatalogue;

export const teamCatalogueSizesSelector = (state) => state.api.assets.teamCatalogueSizes;

export const userTeamDraftsSelector = state => state.api.assets.userDrafts;

export const userTeamDraftsSizesSelector = state => state.api.assets.userDraftsSizes;
