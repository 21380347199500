export const ADD_REQUEST = Symbol('API/DESIGN_TAG/ADD_REQUEST');
export const ADD_SUCCESS = Symbol('API/DESIGN_TAG/ADD_SUCCESS');
export const ADD_FAILURE = Symbol('API/DESIGN_TAG/ADD_FAILURE');

export const ADD_FOR_COLLECTION_REQUEST = Symbol('API/DESIGN_TAG/ADD_FOR_COLLECTION_REQUEST');
export const ADD_FOR_COLLECTION_SUCCESS = Symbol('API/DESIGN_TAG/ADD_FOR_COLLECTION_SUCCESS');
export const ADD_FOR_COLLECTION_FAILURE = Symbol('API/DESIGN_TAG/ADD_FOR_COLLECTION_FAILURE');

export const FETCH_REQUEST = Symbol('API/DESIGN_TAG/FETCH_REQUEST');
export const FETCH_SUCCESS = Symbol('API/DESIGN_TAG/FETCH_SUCCESS');
export const FETCH_FAILURE = Symbol('API/DESIGN_TAG/FETCH_FAILURE');

export const REMOVE_REQUEST = Symbol('API/DESIGN_TAG/REMOVE_REQUEST');
export const REMOVE_SUCCESS = Symbol('API/DESIGN_TAG/REMOVE_SUCCESS');
export const REMOVE_FAILURE = Symbol('API/DESIGN_TAG/REMOVE_FAILURE');

export const REMOVE_FOR_COLLECTION_REQUEST = Symbol('API/DESIGN_TAG/REMOVE_FOR_COLLECTION_REQUEST');
export const REMOVE_FOR_COLLECTION_SUCCESS = Symbol('API/DESIGN_TAG/REMOVE_FOR_COLLECTION_SUCCESS');
export const REMOVE_FOR_COLLECTION_FAILURE = Symbol('API/DESIGN_TAG/REMOVE_FOR_COLLECTION_FAILURE');
