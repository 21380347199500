import React, { useEffect } from 'react';
import { FAILED, LOADING, READY } from 'lib/Status';
import { makeStyles } from '@material-ui/core';
import { toDateTimeStr } from 'lib/dateUtil';
import Box from '@material-ui/core/Box';
import LoadMore from 'views/components/LoadMore/LoadMore';
import MuiTable from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles({
  tight: {
    whiteSpace: 'nowrap',
    width: '1%',
  }
});

export default function TeamList({
  fetchCancellingTeams,
  history,
  teams,
}) {
  const styles = useStyles();

  useEffect(() => {
      if (teams === null) {
        fetchCancellingTeams();
      }
  });

  if (!teams || teams.meta.status === FAILED) {
    return null;
  }
  return (
    <div>
      <h2>Teams Cancelling</h2>
      <Box
        component={Paper}
        padding={2}
      >

        {(teams.meta.status === LOADING
          || (teams.meta.status === READY
            && (teams.payload || []).length > 0)
          )
          && (
          <>
            <TableContainer>
              <MuiTable>
                <TableHead>
                  <TableRow>
                    <TableCell>Team Name</TableCell>
                    <TableCell className={styles.tight}>
                      Email
                    </TableCell>
                    <TableCell className={styles.tight}>
                      Plan
                    </TableCell>
                    <TableCell align="right" className={styles.tight}>
                      Cancelling Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(teams.payload || []).map(team =>
                    <TableRow
                      hover={true}
                      key={team.id}
                      onClick={() => history.push(`/teams/${team.teamId}`)}
                    >
                      <TableCell>{team.teamName}</TableCell>
                      <TableCell className={styles.tight}>
                      {team.email}
                      </TableCell>
                      <TableCell className={styles.tight}>
                      {team.code}
                      </TableCell>
                      <TableCell align="right" className={styles.tight}>
                      {toDateTimeStr(team.renewAt)}
                      </TableCell>
                    </TableRow>
                  )}

                  {teams.meta.status === LOADING && (
                    <TableRow>
                      <TableCell><Skeleton variant='text' /></TableCell>
                      <TableCell><Skeleton variant='text' /></TableCell>
                      <TableCell><Skeleton variant='text' /></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </MuiTable>

              {teams.meta.isMoreAvailable && (
                <LoadMore
                  onClick={() => {
                    if (teams) {
                      fetchCancellingTeams();
                    }
                  }}
                />
              )}
            </TableContainer>
          </>
        )}

        {teams.meta.status === READY
          && (teams.payload || []).length === 0
          && (
          <Box
            fontStyle="italic"
            p={4}
            textAlign="center"
          >
            No results.
          </Box>
        )}
      </Box>
    </div>
  );
}
