import React from 'react';
import { CLEAR_API_ERROR } from 'state/api/common/CommonApiTypes';
import AuthenticatedLayout from 'views/containers/Root/AuthenticatedLayout';
import CssBaseline from '@material-ui/core/CssBaseline';
import ErrorSnack from 'views/components/ErrorSnack/ErrorSnack';
import UnauthenticatedLayout from 'views/containers/Root/UnauthenticatedLayout';
import { isAuthenticatedSelector } from 'state/app/SessionSelectors';
import { useDispatch, useSelector } from 'react-redux';

export default function Root() {
  const dispatch = useDispatch();
  const errorAction = useSelector(state => state.api.common.errorAction);
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  return (
    <>
      <CssBaseline />
      {isAuthenticated ? (
        <AuthenticatedLayout />
      ) : (
        <UnauthenticatedLayout />
      )}
      <ErrorSnack
        errorAction={errorAction}
        onClose={() => dispatch({ type: CLEAR_API_ERROR })}
      />
    </>
  );
}
