import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const PrintMarketingDialog = ({
  onClose,
  selectedSize,
  createDescriptionUrl,
  deleteDescriptionUrl,
  updateDescriptionUrl
}) => {

  // State
  const [auUrl, setAuUrl] = useState(selectedSize.urls.AU || "");
  const [usUrl, setUsUrl] = useState(selectedSize.urls.US || "");

  const countryCodes = {
    AU: 'AU',
    US: 'US'
  }

  const handleSubmit = async (event) => {
    Object.keys(countryCodes).forEach(country => {
      // POST description URL
      // If the selectedSize currently does not have a URL and the local modal state does. It will be a POST.
      const auPost = (!selectedSize.urls.AU && auUrl && country === countryCodes.AU);
      const usPost = (!selectedSize.urls.US && usUrl && country === countryCodes.US);

      // PUT description URL
      const auPut = ((selectedSize.urls.AU !== auUrl) && (auUrl !== "") && country === countryCodes.AU)
      const usPut = (selectedSize.urls.US !== usUrl && (usUrl !== "") && country === countryCodes.US)

      // DELETE description URL
      const auDelete = (selectedSize.urls.AU && !auUrl && country === countryCodes.AU)
      const usDelete = (selectedSize.urls.US && !usUrl && country === countryCodes.US)

      switch (true) {
        case auPost:
          createDescriptionUrl({
            templateCode: selectedSize.templateCode,
            country: country,
            descriptionUrl: auUrl
          });
          onClose();
          break;
        case usPost:
          createDescriptionUrl({
            templateCode: selectedSize.templateCode,
            country: country,
            descriptionUrl: usUrl,
          });
          onClose();
          break;
        case auDelete:
          deleteDescriptionUrl({
            templateCode: selectedSize.templateCode,
            country: country,
          })
          onClose();
          break;
        case usDelete:
          deleteDescriptionUrl({
            templateCode: selectedSize.templateCode,
            country: country,
          })
          onClose();
          break;
        case auPut:
          updateDescriptionUrl({
            templateCode: selectedSize.templateCode,
            country: country,
            descriptionUrl: auUrl,
          });
          onClose();
          break;
        case usPut:
          updateDescriptionUrl({
            templateCode: selectedSize.templateCode,
            country: country,
            descriptionUrl: usUrl,
          });
          onClose();
          break;
        default:
          return
      };
    })
  }

  return (
    <Dialog aria-labelledby="form-dialog-title" onClose={onClose} open={true} maxWidth="xs">
      <DialogTitle id="form-dialog-title">
        {Object.keys(selectedSize.urls).length > 0 ? (
          <>Edit URLs</>
        ) : (
          <>Add URLs</>
        )}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Please complete this form then click "Submit" below
          to update the description URLs.
        </DialogContentText>

        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="AU URL"
          margin="dense"
          onChange={e => {
            setAuUrl(e.target.value);
          }}
          value={auUrl}
          InputProps={{
            endAdornment: 
            <>
              {auUrl && 
                <InputAdornment position="end">
                  <IconButton onClick={() => setAuUrl('')}>
                    <ClearIcon/>
                  </IconButton>
                </InputAdornment>
              }
            </>
          }}
        />
        <br/>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: true }}
          label="US URL"
          margin="dense"
          onChange={e => {
            setUsUrl(e.target.value);
          }}
          value={usUrl}
          InputProps={{
            endAdornment: 
            <>
              {usUrl && 
                <InputAdornment position="end">
                  <IconButton onClick={() => setUsUrl('')}>
                    <ClearIcon/>
                  </IconButton>
                </InputAdornment>
              }
            </>
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PrintMarketingDialog;