export const SUBTEAMS_FAILURE = Symbol('API/TEAM/SUBTEAMS_FAILURE');
export const SUBTEAMS_REQUEST = Symbol('API/TEAM/SUBTEAMS_REQUEST');
export const SUBTEAMS_SUCCESS = Symbol('API/TEAM/SUBTEAMS_SUCCESS');

export const TEAM_REQUEST = Symbol('API/TEAM/TEAM_REQUEST');
export const TEAM_SUCCESS = Symbol('API/TEAM/TEAM_SUCCESS');
export const TEAM_FAILURE = Symbol('API/TEAM/TEAM_FAILURE');

export const PARENT_TEAM_REQUEST = Symbol('API/TEAM/PARENT_TEAM_REQUEST');
export const PARENT_TEAM_SUCCESS = Symbol('API/TEAM/PARENT_TEAM_SUCCESS');
export const PARENT_TEAM_FAILURE = Symbol('API/TEAM/PARENT_TEAM_FAILURE');

export const TEAMS_FAILURE = Symbol('API/TEAM/TEAMS_FAILURE');
export const TEAMS_REQUEST = Symbol('API/TEAM/TEAMS_REQUEST');
export const TEAMS_SUCCESS = Symbol('API/TEAM/TEAMS_SUCCESS');

export const TEAMS_CANCELLING_FAILURE = Symbol('API/TEAM/TEAMS_CANCELLING_FAILURE');
export const TEAMS_CANCELLING_REQUEST = Symbol('API/TEAM/TEAMS_CANCELLING_REQUEST');
export const TEAMS_CANCELLING_SUCCESS = Symbol('API/TEAM/TEAMS_CANCELLING_SUCCESS');

export const USERS_FAILURE = Symbol('API/TEAM/USERS_FAILURE');
export const USERS_REQUEST = Symbol('API/TEAM/USERS_REQUEST');
export const USERS_SUCCESS = Symbol('API/TEAM/USERS_SUCCESS');
