import React, { useEffect } from 'react';
import { toDateTimeStr } from 'lib/dateUtil';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

export default function TeamParentTeams({
  fetchAllParentTeams,
  history,
  teamId,
  team,
  teamSubteams,
  parentTeams
}) {

  useEffect(() => {
    if (team?.payload && !parentTeams[team.meta.teamId]) {
      fetchAllParentTeams({parentIds: team.payload.parentPathArr, teamId: team.meta.teamId});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  if (!team
    || !parentTeams[team.meta.teamId]
    || !parentTeams[team.meta.teamId]
  ) {
    return null;
  }

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h6">
          Team Parent Teams
        </Typography>
      </Box>

      {((parentTeams[team.meta.teamId] || []).length > 0
        ) && (
        <Box p={2}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Created At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(parentTeams[team.meta.teamId] || []).map(({ createdAt, id, name }) => (
                  <TableRow
                    hover
                    key={id}
                    onClick={() => history.push(`/teams/${id}`)}
                  >
                    <TableCell>{name}</TableCell>
                    <TableCell align="right">
                      {toDateTimeStr(createdAt)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {parentTeams[team.meta.teamId]
        && (parentTeams[team.meta.teamId] || []).length === 0
        && (
        <Box fontStyle="italic" p={6} textAlign="center">
          No parent teams.
        </Box>
      )}
    </Paper>
  );
}
