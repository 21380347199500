import { createAction } from 'lib/apiMiddlewareUtils';
import * as types from 'state/api/subscriptions/SubscriptionApiTypes';
import { tokenSelector } from '../../app/SessionSelectors';
import store from 'state/store';

const V1 = `${process.env.REACT_APP_HOST_SUBSCRIPTION_SERVICE}/v1`;

export const convertAccountToSubscription = ({ teamId }) => {
  const accessToken = tokenSelector(store.getState());

  console.debug(`TeamId ${teamId}`);
  return createAction({
    endpoint: `${V1}/teams/${teamId}/subscriptions`,
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      teamId,
    }),
    method: 'POST',
    types: [
      types.CONVERT_ACC_TO_SUBSCRIPTION_REQUEST,
      {
        type: types.CONVERT_ACC_TO_SUBSCRIPTION_SUCCESS,
        meta: { teamId }
      },
      {
        type: types.CONVERT_ACC_TO_SUBSCRIPTION_FAILURE,
        meta: { teamId }
      },
    ]
  });
}

export const fetchSubscriptionPlans = ({
  accessToken,
  paginate,
}) => createAction({
  endpoint: `${V1}/subscriptions`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  },
  method: 'GET',
  paginate,
  types: [
    types.SUBSCRIPTION_PLANS_REQUEST,
    types.SUBSCRIPTION_PLANS_SUCCESS,
    types.SUBSCRIPTION_PLANS_FAILURE,
  ]
});

export const fetchTeamSubscription = ({
  accessToken,
  teamId,
}) => createAction({
  endpoint: `${V1}/teams/${teamId}/subscriptions/current`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  },
  method: 'GET',
  params: { teamId },
  types: [
    types.SUBSCRIPTION_REQUEST,
    types.SUBSCRIPTION_SUCCESS,
    types.SUBSCRIPTION_FAILURE,
  ],
});

export const convertSubscriptionToAccount = ({
  accessToken,
  accountReference,
  amount,
  intervalPeriod,
  subscriptionCode,
  teamId,
}) => createAction({
  body: JSON.stringify({
    accountReference,
    amount,
    intervalPeriod,
    subscriptionCode,
    teamId,
  }),
  endpoint: `${V1}/teams/${teamId}/subscriptions/account`,
  headers: {
    'Authorization': `Bearer ${accessToken}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'POST',
  types: [
    types.CONVERT_TO_ACC_REQUEST,
    types.CONVERT_TO_ACC_SUCCESS,
    types.CONVERT_TO_ACC_FAILURE,
  ],
});

export const createNewTrial = ({
  accessToken,
  subscriptionCode,
  teamId,
  trialDays,
}) => createAction({
  body: JSON.stringify({
    subscriptionCode,
    teamId,
    trialDays,
  }),
  endpoint: `${V1}/teams/${teamId}/subscriptions/trial`,
  headers: {
    'Authorization': `Bearer ${accessToken}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'POST',
  types: [
    types.CREATE_TRIAL_REQUEST,
    types.CREATE_TRIAL_SUCCESS,
    types.CREATE_TRIAL_FAILURE,
  ],
});

export const updateTeamSubscription = ({
  accessToken,
  isAccount,
  planId,
  subscriptionCode,
  subscriptionId,
  teamId,
  trialDays,
}) => createAction({
  body: JSON.stringify({
    isAccount,
    planId,
    subscriptionCode,
    subscriptionId,
    teamId,
    trialDays,
  }),
  endpoint: `${V1}/teams/${teamId}/subscriptions/${subscriptionId}`,
  headers: {
    'Authorization': `Bearer ${accessToken}`,
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'PUT',
  types: [
    types.UPDATE_REQUEST,
    types.UPDATE_SUCCESS,
    types.UPDATE_FAILURE,
  ],
});

export const fetchCoupons = ({
  accessToken,
  paginate,
}) => createAction({
  endpoint: `${V1}/coupons`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
  },
  method: 'GET',
  paginate,
  types: [
    types.SUBSCRIPTION_COUPONS_REQUEST,
    types.SUBSCRIPTION_COUPONS_SUCCESS,
    types.SUBSCRIPTION_COUPONS_FAILURE,
  ],
});

export const createSubscriptionCoupon = ({
  accessToken,
  code,
  endAt,
  intervalPeriod,
  startAt,
  subscriptionCode,
  value,
  valueType,
}) => createAction({
  endpoint: `${V1}/coupons`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'POST',
  body: JSON.stringify({
    code,
    eligibleIntervals: intervalPeriod,
    eligiblePlans: subscriptionCode,
    endAt,
    startAt,
    value,
    valueType,
  }),
  types: [
    types.CREATE_SUBSCRIPTION_COUPON_REQUEST,
    types.CREATE_SUBSCRIPTION_COUPON_SUCCESS,
    types.CREATE_SUBSCRIPTION_COUPON_FAILURE,
  ],
});

export const updateSubscriptionCoupon = ({
  accessToken,
  code,
  endAt,
  intervalPeriod,
  startAt,
  subscriptionCode,
}) => createAction({
  endpoint: `${V1}/coupons/${code}`,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  method: 'PUT',
  body: JSON.stringify({
    code,
    eligibleIntervals: intervalPeriod,
    eligiblePlans: subscriptionCode,
    endAt,
    startAt,
  }),
  types: [
    types.UPDATE_SUBSCRIPTION_COUPON_REQUEST,
    types.UPDATE_SUBSCRIPTION_COUPON_SUCCESS,
    types.UPDATE_SUBSCRIPTION_COUPON_FAILURE,
  ],
});
