import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

export default function TeamSwitchDialog({
  convertSubscriptionToAccount,
  onClose,
  teamId,
}) {
  const [accountReference, setAccountReference] = useState('');
  const [amount, setAmount] = useState('');
  const [intervalPeriod, setIntervalPeriod] = useState('MONTHLY');
  const [subscriptionCode, setSubscriptionCode] = useState('EDGE');

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Convert To Account</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please complete this form then click "Convert" below
          to convert this team subscription to an account.
        </DialogContentText>

        <Box px={6} py={2}>
          <TextField
            fullWidth
            InputLabelProps={{ shrink: true }}
            label="Account Reference"
            margin="dense"
            onChange={e => setAccountReference(e.target.value)}
            required
            type="email"
            value={accountReference}
            variant="outlined"
          />

          <Box mt={-1}>
            <TextField
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Amount"
              margin="dense"
              onChange={e => setAmount(e.target.value)}
              required
              type="email"
              value={amount}
              variant="outlined"
            />
          </Box>

          <FormControl
            fullWidth
            margin="dense"
            variant="outlined"
          >
            <InputLabel id="interval">Interval Period</InputLabel>
            <Select
              label="Interval Period"
              labelId="interval"
              onChange={e => setIntervalPeriod(e.target.value)}
              value={intervalPeriod}
            >
              <MenuItem value="MONTHLY">Monthly</MenuItem>
              <MenuItem value="YEARLY">Yearly</MenuItem>
            </Select>
          </FormControl>

          <FormControl
            autoFocus
            fullWidth
            margin="dense"
            variant="outlined"
          >
            <InputLabel id="sub-code">Subscription Code</InputLabel>
            <Select
              label="Subscription Code"
              labelId="sub-code"
              onChange={e => setSubscriptionCode(e.target.value)}
              value={subscriptionCode}
            >
              <MenuItem value="EDGE">Edge</MenuItem>
              <MenuItem value="PLUS">Plus</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          color="primary"
          onClick={() => convertSubscriptionToAccount({
              accountReference,
              amount: parseFloat(amount),
              intervalPeriod,
              subscriptionCode,
              teamId,
            })
          }
        >
          Convert
        </Button>
      </DialogActions>
    </Dialog>
  );
}
