import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
// import { BrowserRouter as Router } from 'react-router'
import { Router } from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import * as serviceWorker from './serviceWorker';
import history from 'state/history';
import Root from 'views/containers/Root/Root';
import store from 'state/store';

const theme = createMuiTheme({
  overrides: {
    MuiDialogContent: {
      root: {
        padding: '0px 24px 16px 24px',
      }
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
      }
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer',
      },
    },
    MuiTextField: {
      root: {
        marginTop: '16px',
      }
    }
  },
});

ReactDOM.render(
  <ThemeProvider theme={theme} >
    <Provider store={store}>
      <Router history={history}>
        <Root />
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
