import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  Popover,
  Radio,
  RadioGroup,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttons: {
    textAlign: 'right',
  },
  promote: {
    padding: theme.spacing(2),
    width: '250px',
  },
  radio: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.radio}
      size="small"
      {...props}
    />
  );
}

export default function PromotePopover({
  anchorEl,
  collection,
  onClose,
  onSubmit,
}) {
  const [priority, setPriority] = useState((collection || {}).priority ?? 99999);
  const [subscriptionCode, setSubscriptionCode] = useState((collection || {}).subscriptionCode ?? 'BASIC');
  const [collectionPricing, setCollectionPricing] = useState({
    priceUsd: (collection || {}).priceUsd ?? 0,
    priceAud: (collection || {}).priceAud ?? 0
  })
  const { priceUsd, priceAud } = collectionPricing;

  const classes = useStyles();

  const handleUpdateSubscriptionPlan = code => {
    setSubscriptionCode(code);
    // set default prices for edge collection
    if (code === "EDGE") {
      setCollectionPricing({
        priceUsd: 29,
        priceAud: 39
      });
      return;
    }
    setCollectionPricing({
      priceUsd: 0,
      priceAud: 0
    });
  };

  return (
    <Popover
      anchorEl={anchorEl}
      onClose={onClose}
      open={!!anchorEl}
    >
      <form
        className={classes.promote}
        onSubmit={e => onSubmit(e, priority, subscriptionCode, priceUsd, priceAud)}
      >
        <FormControl component="fieldset">
          <FormLabel component="legend">Priority</FormLabel>
          <Input
            onChange={e => setPriority(parseInt(e.target.value, 10))}
            required
            type="number"
            value={priority}
          />
        </FormControl>
        <br />
        <br />
        <FormControl component="fieldset">
          <FormLabel component="legend">Subscription Plan</FormLabel>
          <RadioGroup
            onChange={e => handleUpdateSubscriptionPlan(e.target.value)}
            value={subscriptionCode}
          >
            <FormControlLabel value="BASIC" control={<StyledRadio />} label="Basic" />
            <FormControlLabel value="PLUS" control={<StyledRadio />} label="Plus" />
            <FormControlLabel value="EDGE" control={<StyledRadio />} label="Edge" />
          </RadioGroup>
        </FormControl>
        <br />
        <br />
        <FormControl component="fieldset">
          <FormLabel component="legend">Price USD</FormLabel>
          <Input
            onChange={e => setCollectionPricing({
              ...collectionPricing,
              priceUsd: parseFloat(e.target.value)
            })}
            required
            type="number"
            value={collectionPricing.priceUsd}
          />
        </FormControl>
        <br />
        <br />
        <FormControl component="fieldset">
          <FormLabel component="legend">Price AUD</FormLabel>
          <Input
            onChange={e => setCollectionPricing({
              ...collectionPricing,
              priceAud: parseFloat(e.target.value)
            })}
            required
            type="number"
            value={collectionPricing.priceAud}
          />
        </FormControl>
        <br />
        <br />
        <div className={classes.buttons}>
          <Button onClick={onClose}>
            Cancel
          </Button>
          &nbsp;
          <Button color="primary" type="submit" variant="contained">
            {collection ? 'Update' : 'Promote'}
          </Button>
        </div>
      </form>
    </Popover>
  )
}
