import uploadToS3 from "lib/uploadToS3";
import { createSubscriptionMedia, getUploadImageData } from "./AssetMediaApiActions";
import handleUpdateMediaCategories from "./handleUpdateMediaCategories";
import handleUpdateThumb from "./handleUpdateThumb";

const handleUploadMedia = async (event, {
  accessToken,
  categories,
  duration,
  dispatch,
  history,
  imageFile,
  name,
  priority,
  subscriptionCode,
  thumbFile,
}) => {
  const uploadImageData = await dispatch(getUploadImageData({
    accessToken,
    imageFile,
  }));

  if (uploadImageData.error) {
    return;
  }

  await uploadToS3(uploadImageData.payload.url, imageFile);

  const createSubscriptionMediaParams = {
    accessToken,
    filename: uploadImageData.payload.filename,
    mediaId: uploadImageData.payload.id,
    name,
    priority,
    subscriptionCode,
  };

  if (duration) {
    createSubscriptionMediaParams.duration = duration;
  }
  await dispatch(createSubscriptionMedia(createSubscriptionMediaParams));

  await handleUpdateMediaCategories({
    accessToken,
    categories,
    dispatch,
    mediaId: uploadImageData.payload.id,
  });

  if (thumbFile) {
    await handleUpdateThumb({
      accessToken,
      dispatch,
      mediaId: uploadImageData.payload.id,
      thumbFile,
    });
  }

  history.replace(`/media/${uploadImageData.payload.id}`);
};

export default handleUploadMedia;
