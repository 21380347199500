import * as types from 'state/api/users/UsersApiTypes';
import { reduceAction } from 'lib/apiMiddlewareUtils';
import { get as getPath, cloneDeep, isEmpty } from 'lodash';

const initState = {
  user: null,
  userTeams: null,
  users: null,
  userPreferences: null,
};

export default function UsersApiReducers(state = initState, action) {
  switch (action.type) {
    case types.FETCH_USER_FAILURE:
    case types.FETCH_USER_REQUEST:
    case types.FETCH_USER_SUCCESS: {
      return { ...state, user: reduceAction(state.user, action) };
    }

    case types.FETCH_USERS_FAILURE:
    case types.FETCH_USERS_REQUEST:
    case types.FETCH_USERS_SUCCESS: {
      return { ...state, users: reduceAction(state.users, action) };
    }

    case types.USER_TEAMS_FAILURE:
    case types.USER_TEAMS_REQUEST:
    case types.USER_TEAMS_SUCCESS: {
      return { ...state, userTeams: reduceAction(state.userTeams, action) };
    }

    case types.GDPR_DELETE_USER_SUCCESS: {
      const deletedUserId = getPath(state.user, "payload.id");

      if (!deletedUserId) return state;

      const updatedUsers = state.users.payload.filter(user => user.id !== deletedUserId);

      return { 
        ...state, 
        users: {
          ...state.users,
          payload: updatedUsers
        }
      };
    }

    case types.DELETE_USER_SUCCESS: {
      const userId = getPath(state.user, "payload.id");

      if (!userId) return state;

      const updatedUsersData = cloneDeep(state.users.payload);
      const currentUserData = updatedUsersData.find(user => user.id === userId);
      const userIndex = updatedUsersData.indexOf(currentUserData);

      if (userIndex === -1 || isEmpty(currentUserData)) {
        return state;
      }

      // update user data to prevent need for refetch
      const updatedUserData = {
        ...currentUserData,
        active: false,
        email: currentUserData.email + ".deleted"
      }

      // replace existing user data in state with updated object
      updatedUsersData.splice(userIndex, 1, updatedUserData);

      return { 
        users: {
          ...state.users,
          payload: updatedUsersData
        },
        user: null,
        userTeams: null
      };
    }

    case types.UPDATE_USER_PREFERENCES_SUCCESS:
    case types.FETCH_USER_PREFERENCES_SUCCESS: {
      return {
        ...state,
        userPreferences: {
          ...action.payload,
        }
      }
    }

    default:
      return state;
  }
}
