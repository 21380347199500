export const ASSET_CATALOGUE_REQUEST = 'API/ASSET_CATALOGUE_REQUEST';
export const ASSET_CATALOGUE_SUCCESS = 'API/ASSET_CATALOGUE_SUCCESS';
export const ASSET_CATALOGUE_FAILURE = 'API/ASSET_CATALOGUE_FAILURE';

export const ASSET_TEAM_CATALOGUE_REQUEST = 'API/ASSET_TEAM_CATALOGUE_REQUEST';
export const ASSET_TEAM_CATALOGUE_SUCCESS = 'API/ASSET_TEAM_CATALOGUE_SUCCESS';
export const ASSET_TEAM_CATALOGUE_FAILURE = 'API/ASSET_TEAM_CATALOGUE_FAILURE';

export const ASSET_TEAM_CATALOGUE_SIZES_REQUEST = 'API/ASSET_TEAM_CATALOGUE_SIZES_REQUEST';
export const ASSET_TEAM_CATALOGUE_SIZES_SUCCESS = 'API/ASSET_TEAM_CATALOGUE_SIZES_SUCCESS';
export const ASSET_TEAM_CATALOGUE_SIZES_FAILURE = 'API/ASSET_TEAM_CATALOGUE_SIZES_FAILURE';

export const ASSET_USER_TEAM_DRAFTS_FAILURE = 'API/ASSET_USER_TEAM_DRAFTS_FAILURE';
export const ASSET_USER_TEAM_DRAFTS_REQUEST = 'API/ASSET_USER_TEAM_DRAFTS_REQUEST';
export const ASSET_USER_TEAM_DRAFTS_SUCCESS = 'API/ASSET_USER_TEAM_DRAFTS_SUCCESS';

export const ASSET_USER_TEAM_DRAFTS_SIZES_FAILURE = 'API/ASSET_USER_TEAM_DRAFTS_SIZES_FAILURE';
export const ASSET_USER_TEAM_DRAFTS_SIZES_REQUEST = 'API/ASSET_USER_TEAM_DRAFTS_SIZES_REQUEST';
export const ASSET_USER_TEAM_DRAFTS_SIZES_SUCCESS = 'API/ASSET_USER_TEAM_DRAFTS_SIZES_SUCCESS';

export const ASSET_CATALOGUE_SIZES_REQUEST = 'API/ASSET_CATALOGUE_SIZES_REQUEST';
export const ASSET_CATALOGUE_SIZES_SUCCESS = 'API/ASSET_CATALOGUE_SIZES_SUCCESS';
export const ASSET_CATALOGUE_SIZES_FAILURE = 'API/ASSET_CATALOGUE_SIZES_FAILURE';

export const ASSET_COLLECTIONS_STAGED_REQUEST = 'API/ASSET_COLLECTIONS_STAGED_REQUEST';
export const ASSET_COLLECTIONS_STAGED_SUCCESS = 'API/ASSET_COLLECTIONS_STAGED_SUCCESS';
export const ASSET_COLLECTIONS_STAGED_FAILURE = 'API/ASSET_COLLECTIONS_STAGED_FAILURE';

export const ASSET_DESIGNS_REQUEST = 'API/ASSET_DESIGNS_REQUEST';
export const ASSET_DESIGNS_SUCCESS = 'API/ASSET_DESIGNS_SUCCESS';
export const ASSET_DESIGNS_FAILURE = 'API/ASSET_DESIGNS_FAILURE';

export const CLEAR_TEAM_CATALOGUE = 'API/CLEAR_TEAM_CATALOGUE'

export const ASSET_FETCH_DESIGN_THUMBNAIL_UPLOAD_URL_REQUEST
  = 'API/ASSET_FETCH_DESIGN_THUMBNAIL_UPLOAD_URL_REQUEST';
export const ASSET_FETCH_DESIGN_THUMBNAIL_UPLOAD_URL_SUCCESS
  = 'API/ASSET_FETCH_DESIGN_THUMBNAIL_UPLOAD_URL_SUCCESS';
export const ASSET_FETCH_DESIGN_THUMBNAIL_UPLOAD_URL_FAILURE
  = 'API/ASSET_FETCH_DESIGN_THUMBNAIL_UPLOAD_URL_FAILURE';

export const ASSET_USER_COLLECTIONS_DRAFTS_REQUEST = 'API/ASSET_USER_COLLECTIONS_DRAFTS_REQUEST';
export const ASSET_USER_COLLECTIONS_DRAFTS_SUCCESS = 'API/ASSET_USER_COLLECTIONS_DRAFTS_SUCCESS';
export const ASSET_USER_COLLECTIONS_DRAFTS_FAILURE = 'API/ASSET_USER_COLLECTIONS_DRAFTS_FAILURE';

export const ASSET_USER_COLLECTIONS_STAGED_REQUEST = 'API/ASSET_USER_COLLECTIONS_STAGED_REQUEST';
export const ASSET_USER_COLLECTIONS_STAGED_SUCCESS = 'API/ASSET_USER_COLLECTIONS_STAGED_SUCCESS';
export const ASSET_USER_COLLECTIONS_STAGED_FAILURE = 'API/ASSET_USER_COLLECTIONS_STAGED_FAILURE';

export const ASSET_UPDATE_COLLECTION_REQUEST = 'API/ASSET_UPDATE_COLLECTION_REQUEST';
export const ASSET_UPDATE_COLLECTION_SUCCESS = 'API/ASSET_UPDATE_COLLECTION_SUCCESS';
export const ASSET_UPDATE_COLLECTION_FAILURE = 'API/ASSET_UPDATE_COLLECTION_FAILURE';

export const ASSET_CREATE_COLLECTION_REMIX_REQUEST = 'API/ASSET_CREATE_COLLECTION_REMIX_REQUEST'
export const ASSET_CREATE_COLLECTION_REMIX_SUCCESS = 'API/ASSET_CREATE_COLLECTION_REMIX_SUCCESS'
export const ASSET_CREATE_COLLECTION_REMIX_FAILURE = 'API/ASSET_CREATE_COLLECTION_REMIX_FAILURE'

export const ASSET_UPDATE_DESIGN_THUMBNAIL_FILE_REQUEST
  = 'API/ASSET_UPDATE_DESIGN_THUMBNAIL_FILE_REQUEST';
export const ASSET_UPDATE_DESIGN_THUMBNAIL_FILE_SUCCESS
  = 'APU/ASSET_UPDATE_DESIGN_THUMBNAIL_FILE_SUCCESS';
export const ASSET_UPDATE_DESIGN_THUMBNAIL_FILE_FAILURE
  = 'API/ASSET_UPDATE_DESIGN_THUMBNAIL_FILE_FAILURE';

export const ASSET_UPDATE_COLLECTION_SUBSCRIPTION_REQUEST
  = 'API/ASSET_UPDATE_COLLECTION_SUBSCRIPTION_REQUEST';
export const ASSET_UPDATE_COLLECTION_SUBSCRIPTION_SUCCESS
  = 'API/ASSET_UPDATE_COLLECTION_SUBSCRIPTION_SUCCESS';
export const ASSET_UPDATE_COLLECTION_SUBSCRIPTION_FAILURE
  = 'API/ASSET_UPDATE_COLLECTION_SUBSCRIPTION_FAILURE';

export const ASSET_UPLOAD_DESIGN_THUMBNAIL_FILE_REQUEST
  = 'API/ASSET_UPLOAD_DESIGN_THUMBNAIL_FILE_REQUEST';
export const ASSET_UPLOAD_DESIGN_THUMBNAIL_FILE_SUCCESS
  = 'API/ASSET_UPLOAD_DESIGN_THUMBNAIL_FILE_SUCCESS';
export const ASSET_UPLOAD_DESIGN_THUMBNAIL_FILE_FAILURE
  = 'API/ASSET_UPLOAD_DESIGN_THUMBNAIL_FILE_FAILURE';
