import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { currentUserIdSelector, tokenSelector } from 'state/app/SessionSelectors';
import FontUpload from 'views/components/Fonts/FontUpload';
import { fetchTeams, fetchTeamSubteams } from 'state/api/teams/TeamApiActions';
import { teamsSelector, teamSubteamsSelector } from 'state/api/teams/TeamApiSelectors';
import { addFontToSubTeams} from 'state/api/assets-media/AssetMediaApiActions';
import { fontUploadsSelector } from 'state/api/assets-media/AssetMediaApiSelectors';

export default function FontUploadContainer({ history, location }, props) {
  const accessToken = useSelector(tokenSelector);
  const dispatch = useDispatch();
  const userId = useSelector(currentUserIdSelector);
  const teams = useSelector(teamsSelector);
  const teamSubteams = useSelector(teamSubteamsSelector);
  const fontUploads = useSelector(fontUploadsSelector);

  return (
    <FontUpload
      history={history}
      location={location}
      fontUploads={fontUploads}
      onSubmit={(teamId, fontFile) => dispatch(
        addFontToSubTeams({
        accessToken,
        teamId,
        fontFile,
      }))}
      fetchTeams={({ name }) => dispatch(
        fetchTeams({
          accessToken,
          name,
          paginate: teams || {},
        })
      )}
      fetchTeamSubteams={({ parentId, sortBy, sortOrder }) => dispatch(
        fetchTeamSubteams({
          accessToken,
          paginate: teamSubteams || {},
          parentId,
          sortBy,
          sortOrder,
          userId,
        })
      )}
      teams={teams}
      teamSubteams={teamSubteams}
      {...props}
    />
  );
}
