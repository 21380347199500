import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import { 
  Box,
  Button,
  makeStyles,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import PrintOptionStock from './PrintOptionStock';
import { useSelector } from 'react-redux';
import { printOptionsSelector } from "state/entities/printOptions/printOptionsSelectors";

import AddPrintOptionDialog from './AddPrintOptionDialog';

const useStyles = makeStyles((theme) => ({
  size: {
    display: "flex",
    alignItems: "center"
  }
}));

export const PrintOptionSize = ({
  addPrintOptionPrice,
  label,
  size,
  printOptionSize,
  deletePrintPriceOption,
  updatePrintOptionPrice,
  createPrintOption,
  deletePrintOption,
  setSuccessActionString,
  updatePrintOption,
  providerId,
  providerName
}) => {
  const printOptions = useSelector(printOptionsSelector);
  const classes = useStyles();
  const [addPrintOptionDialog, setAddPrintOptionDialog] = useState(false);
  
  let firstPrintOptionId;
  const optionsForSize = Object.values(printOptionSize) || [];
  let optionIndex = 0;
  do {
    const currentOptionId = optionsForSize[optionIndex][0];
    if (currentOptionId) {
      firstPrintOptionId = currentOptionId;
    }
    optionIndex++;
  } while (!firstPrintOptionId && optionIndex < optionsForSize.length)
  
  if (!firstPrintOptionId) {
    return null;
  }

  const { sizeDescription, width, height } = printOptions[firstPrintOptionId];
  const sizeTitle = `${sizeDescription} - ${width} x ${height}`;

  const getPrintOptionRows = (stock) => {
    return printOptionSize[stock].reduce((optionAccumulator, printOptionId) => {
      const printOption = printOptions[printOptionId];
      const optionsIncludingPricePoints = (printOption.pricing || []).map(price => {
        return {
          ...printOption,
          pricing: price
        }
      });
      return [
        ...optionAccumulator,
        ...optionsIncludingPricePoints
      ]
    }, []);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <Box 
            component={TableCell} 
            width="100%"
            bgcolor="#dddddd"
            classes={{ root: classes.size }}
          >
            <div style={{ marginRight: "22px" }}>{sizeTitle}</div>
            <Button 
              onClick={() => setAddPrintOptionDialog(true)}
              startIcon={<AddIcon />}
              color="primary"
              variant="contained"
              size="small"
            >
             Add Print Option
            </Button>
          </Box>
        </TableRow>
      </TableHead>

      {Object.keys(printOptionSize).map(stock => (
        <PrintOptionStock
          key={`${providerId}-${stock}`}
          deletePrintOption={deletePrintOption}
          deletePrintPriceOption={deletePrintPriceOption}
          printOptions={getPrintOptionRows(stock)}
          stock={stock}
          updatePrintOptionPrice={updatePrintOptionPrice}
          setSuccessActionString={setSuccessActionString}
          updatePrintOption={updatePrintOption}
          providerId={providerId}
          addPrintOptionPrice={addPrintOptionPrice}
          providerName={providerName}
        />
      ))}

      {addPrintOptionDialog && (
        <AddPrintOptionDialog
          label={label}
          size={size}
          width={width}
          height={height}
          onClose={() => setAddPrintOptionDialog(false)}
          createPrintOption={createPrintOption}
          setSuccessActionString={setSuccessActionString}
          disabled={['size','width','height']}
          providerName={providerName}
        />
      )}
    </>
  )
}

export default PrintOptionSize