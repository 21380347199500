import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import FontDownloadIcon from '@material-ui/icons/FontDownload';
import ListSubheader from "@material-ui/core/ListSubheader";
import Publish from '@material-ui/icons/Publish';

export default function MediaDrawer() {
  const location = useLocation();

  return (
    <List>
      <ListSubheader>
        <Box
          component={Link}
          ml={-2}
          onClick={(e) => e.stopPropagation()}
          to="/"
        >
          <IconButton>
            <ChevronLeft />
          </IconButton>
        </Box>
        Fonts
      </ListSubheader>
      <ListItem
        component={Link}
        selected={location.pathname.startsWith('/fonts/upload')}
        to="/fonts/upload"
      >
        <ListItemIcon>
          <Publish />
        </ListItemIcon>
        <ListItemText primary='Upload to team' />
      </ListItem>
      <ListItem button disabled>
        <ListItemIcon>
          <FontDownloadIcon />
        </ListItemIcon>
        <ListItemText primary='Manage Fonts' />
      </ListItem>
    </List>
  );
}
