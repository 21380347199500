import React from 'react';
import { useSelector } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import ExternalLink from 'views/components/ExternalLink/ExternalLink';
import { tokenSelector } from 'state/app/SessionSelectors';

const EDITOR_BASE_URL = `${process.env.REACT_APP_WEB_APP_HOST}/editor/`;

export default function EditorLinkContainer({ children, designId }) {
  const token = useSelector(state => tokenSelector(state));
  const destination = `${EDITOR_BASE_URL}${designId}?access_token=${token}`;

  return (
    <ExternalLink destination={destination}>
      <Tooltip title="Open in editor">
        {children}
      </Tooltip>
    </ExternalLink>
  );
}
