import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { getFilename } from 'lib/fileUtil';

class TeamEditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {
        name: null,
        country: null,
      },
      logoFile: null,
      logoFilename: null,
      logoSrc: null,
      team: (props.team || {}).payload,
    };

    this.deriveFilename = this.deriveFilename.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.openFileChooser = this.openFileChooser.bind(this);
    this.setFilePreview = this.setFilePreview.bind(this);

    this.fileUploader = React.createRef();
  }

  deriveFilename() {
    if (this.state.logoFilename && !this.state.team.logoUrl) {
      return this.state.logoFilename;
    } else if (!this.state.logoFilename && this.state.team.logoUrl) {
      return getFilename(this.state.team.logoUrl);
    }
    return null;
  }

  deriveImageSrc() {
    return (this.state.logoFile || !this.props.team.logoUrl) ? this.state.logoSrc : this.props.team.logoUrl;
  }
  
  handleChange(field, value) {
    this.setState({
      team: {
        ...this.state.team,
        [field]: value,
      },
      errors: {
        ...this.state.errors,
        [field]: null,
      }
    })
  }

  handleSave() {
    // TODO: Validate before saving
    const { logoFile, team } = this.state;
    this.props.onSave({ logoFile, team });
  }

  onFileChange(event) {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    const reader = new FileReader();
    const previewFile = this.setFilePreview;
    reader.onload = function(e) {
      previewFile(e.target.result, file);
    };
    reader.readAsDataURL(file);

    this.setState({
      logoFilename: getFilename(event.target.value),
    });
  }

  openFileChooser() {
    this.fileUploader.current.click();
  }

  setFilePreview(src, file) {
    this.setState({
      logoFile: file,
      logoSrc: src,
    });
  }

  render() {
    const { isOpen, isProcessing, onCancel } = this.props;
    return (
      <Dialog fullWidth maxWidth='xs' open={isOpen}>
        <DialogTitle>Edit Team</DialogTitle>
        <DialogContent>
          <Box
            alignItems="center"
            border="1px solid #eee"
            borderRadius="4px"
            display="flex"
            mb={1}
            mt={2}
            p={1}
          >
            <Avatar
              onClick={this.openFileChooser}
              src={this.deriveImageSrc()}
              style={{ cursor: 'pointer' }}
            />
            <Box ml={2}>
              <Typography>{this.deriveFilename()}</Typography>
              <input
                id='file'
                onChange={this.onFileChange}
                ref={this.fileUploader}
                style={{ display: 'none' }}
                type='file'
              />
            </Box>
          </Box>

          <TextField
            fullWidth
            id="name"
            InputLabelProps={{ shrink: true }}
            label="Name"
            margin="dense"
            onChange={e => this.handleChange('name', e.target.value)}
            value={this.state.team.name}
            variant="outlined"
          />

          <TextField
            disabled
            fullWidth
            id="country"
            InputLabelProps={{ shrink: true }}
            label="Country"
            margin="dense"
            onChange={e => this.handleChange('name', e.target.value)}
            value={this.state.team.country}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={isProcessing} onClick={onCancel} color='secondary'>
            Cancel
          </Button>

          <Button disabled={isProcessing} onClick={this.handleSave} color='primary'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

TeamEditDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  team: PropTypes.object.isRequired,
};

export default TeamEditDialog;
