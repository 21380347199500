import React, {useState} from 'react';
import PrintOptionSize from './PrintOptionSize';
import AddIcon from '@material-ui/icons/Add';
import {
  Box,
  Button,
  Table,
  makeStyles,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import TemplateSizesPopout from "./TemplateSizesPopoutContainer";
import AddPrintOptionDialog from './AddPrintOptionDialog';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(4)
  },
  white: {
    color: 'white',
    display: 'flex',
    alignItems: "center",
    justifyContent: "space-between",
  }
}));

const PrintOption = ({
  addPrintOptionPrice,
  createPrintOption,
  deletePrintOption,
  deletePrintPriceOption,
  fetchTemplateSizesByOrderLabel,
  label,
  option,
  providerId,
  providerName,
  setSuccessActionString,
  updatePrintOption,
  updatePrintOptionPrice,
}) => {
  const [addPrintOptionDialog, setAddPrintOptionDialog] = useState(false);

  const classes = useStyles();
  return (
    <>
      <Table classes={{ root: classes.table }}>
        <TableHead>
          <TableRow>
            <Box
              component={TableCell}
              width="100%"
              bgcolor="#666666"
              classes={{ root: classes.white }}
            >
              <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
                <div style={{ marginRight: "22px" }}>{label}</div>
                <Button
                  onClick={() => setAddPrintOptionDialog(true)}
                  startIcon={<AddIcon />}
                  color="primary"
                  variant="contained"
                  size="small"
                >
                 Add Size
                </Button>
              </div>
              <TemplateSizesPopout
                fetchTemplateSizesByOrderLabel={fetchTemplateSizesByOrderLabel}
                labelName={label}
                setSuccessActionString={setSuccessActionString}
              />
            </Box>
          </TableRow>
        </TableHead>
        {Object.keys(option).map(size => (
          <PrintOptionSize
            key={size}
            label={label}
            size={size}
            printOptionSize={option[size]}
            deletePrintOption={deletePrintOption}
            createPrintOption={createPrintOption}
            deletePrintPriceOption={deletePrintPriceOption}
            setSuccessActionString={setSuccessActionString}
            updatePrintOptionPrice={updatePrintOptionPrice}
            addPrintOptionPrice={addPrintOptionPrice}
            updatePrintOption={updatePrintOption}
            providerId={providerId}
            providerName={providerName}
          />
        ))}
      </Table>
      {addPrintOptionDialog && (
        <AddPrintOptionDialog
          label={label}
          onClose={() => setAddPrintOptionDialog(false)}
          createPrintOption={createPrintOption}
          disabled={[]}
          setSuccessActionString={setSuccessActionString}
          providerName={providerName}
        />
      )
      }
    </>
  )
}

export default PrintOption