import React, { useEffect, useState } from 'react';
import { 
  Box, 
  makeStyles, 
  Paper, 
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { READY } from 'lib/Status';
import PrintMarketingDialog from 'views/components/Orders/PrintMarketingDialog'
import usePrevious from 'lib/usePrevious';

const useStyles = makeStyles((theme) => ({
  headingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  header2: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const PrintMarketingList = ({
  fetchDescriptionUrls,
  descriptionUrls,
  catalogueSizes,
  createDescriptionUrl,
  deleteDescriptionUrl,
  updateDescriptionUrl,
  fetchSizes,
}) => {
  const classes = useStyles();

  // State
  const [content, setContent] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);

  const prevDescriptionUrls = usePrevious(descriptionUrls);
  const hasCatalogueSizesLoaded = (catalogueSizes && !(typeof catalogueSizes === "symbol"))
  useEffect(() => {
    if (catalogueSizes === null) {
      fetchSizes();
    }
    if (catalogueSizes === null) {
      fetchDescriptionUrls();
    }
    if ((descriptionUrls && hasCatalogueSizesLoaded) && prevDescriptionUrls !== descriptionUrls) {
      getContent();
    }
  });

  useEffect(() => {
    if (hasCatalogueSizesLoaded) {
      getContent();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogueSizes]);

  const getSizeMeasurements = (size) => {
    switch(size.measurementUnits) {
      case "mm":
        return `${size.widthInMm} mm x ${size.heightInMm} mm (W x H)`;
        
        case "in":
        return `${size.widthInInches} In x ${size.heightInInches} In (W x H)`;

      case "px":
        return `${size.widthInMm}px x ${size.heightInMm} px (W x H)`;

      default:
        return ''
    }
  };

  const getContent = () => {
    const { payload: urls } = descriptionUrls;
    const sizes = catalogueSizes.filter(size => size.templateType === 'print');

    // use this map/filter to get the associated descriptionUrl to template size/country
    const result = sizes.map(size => {
      const updatedUrls = urls.filter(url => url?.templateCode === size.templateCode)
      const updatedSizes = {...size, urls: {}}
      updatedUrls.forEach(url => {
        url.country === 'AU' ?  updatedSizes.urls['AU'] = url.descriptionUrl : updatedSizes.urls['US'] = url.descriptionUrl
      })
      return updatedSizes
    });

    setContent(result);
    return result;
  }

  // Get JSX for displayed URLs
  const getUrls = (urls) => {
    const urlsLength = Object.keys(urls).length
    if (urlsLength > 0) {
      return (
        <>
          {urls.AU ? `AU: ${urls.AU}` : ''}
          {urlsLength > 1 ? <br/> : <></>}
          {urls.US ? `US: ${urls.US}` : ''}
        </>
      )
    } else {
      return '-'
    }
  }

  if (catalogueSizes === null) {
    return null;
  }
  if (!catalogueSizes.length) {
    return <>No catalogue sizes found.</>;
  }

  return (
    <>
      <div className={classes.headingContainer}>
        <h2 className={classes.header2}>Print Marketing Managemet</h2>
      </div>
      <Box
        component={Paper}
        p={2}
      >
        <Table>
          <TableHead>
            <TableRow>
              <Box component={TableCell} width="20%">Template Size</Box>
              <Box component={TableCell} width="20%">Dimensions</Box>
              <Box component={TableCell} width="5%">URLs</Box>
            </TableRow>
          </TableHead>

          <TableBody>
            {(catalogueSizes && descriptionUrls.meta.status === READY)
              && content.map((size) =>
                <TableRow
                  hover
                  onClick={() => {
                    setSelectedSize(size);
                  }}
                >
                  <TableCell>{size.name}</TableCell>
                  <TableCell>{getSizeMeasurements(size)}</TableCell>
                  <TableCell>{getUrls(size.urls)}
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </Box>

      {selectedSize && (
        <PrintMarketingDialog
          onClose={() => setSelectedSize(null)}
          selectedSize={selectedSize}
          createDescriptionUrl={createDescriptionUrl}
          deleteDescriptionUrl={deleteDescriptionUrl}
          updateDescriptionUrl={updateDescriptionUrl}
        />
      )}
    </>
  );

};

export default PrintMarketingList
